import { DataService } from 'src/app/services/dataStore/data.service';
import { AlertService } from './../../services/alert/alert.service';
import { ImportExcelService } from './../../services/importExcel/import-excel.service';
import { DateFilterService } from './../../services/date/date-filter.service';
import { SharedService } from 'src/app/services/shared.service';
import { TaggingService } from './../../services/tagging.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { MessageService } from 'primeng/api';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/services/auth/auth-service.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Calendar } from 'primeng/calendar';

export interface UserData {
  invoiceId: number;
  poNumber: number;
  VenderId: string;
  Vendername: string;
  entity: string;
  uploaded: string;
  modified: string;
  status: string;
  amount: string;
  date: string;
}

export interface updateColumn {
  idtabColumn: number;
  ColumnPos: number;
  isActive: boolean;
}

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
  // encapsulation: ViewEncapsulation.Emulated
})
export class InvoiceComponent implements OnInit {
  displayInvoicePage: boolean = true;
  activeMenuName: string = 'invoice';
  users: UserData[];
  showPaginator: boolean;
  invoiceDispalyData = [];
  allInvoiceLength: number;
  showPaginatorAllInvoice: boolean;
  createInvoice: boolean;
  allSearchInvoiceString = [];
  visibleSidebar2: boolean;
  cols: any;
  invoiceColumns: any;
  poColumns: any;
  archivedColumns: any;
  allColumns: any;
  columnstodisplayInvoice = [];
  columnstodisplayPO = [];
  columnstodisplayArchived=[];

  updateColumns: updateColumn[] = [];
  poDispalyData: any[];
  poArrayLength: number;
  GRNDispalyData: any[];
  GRNArrayLength: number;
  receiptDispalyData: any[];
  receiptArrayLength: number = 0;
  archivedDisplayData: any[] = [];
  archivedArrayLength: number;
  showPaginatorArchived: boolean;
  archivedLength: number;
  rejectedDisplayData: any[] = [];
  // rejectedArrayLength: number;
  showPaginatorRejected: boolean;
  rejectedLength: number;
  showPaginatorPOTable: boolean;
  showPaginatorGRNTable: boolean;
  userDetails: any;
  usertypeBoolean: boolean;

  rangeDates: Date[];
  rangeDatesinv: Date[];
  rangeDates_soa: Date[];
  routeName: string;
  lastYear: number;
  displayYear: string;
  minDate: Date;
  maxDate: Date;
  columnstodisplayService=[];
  serviceColumns: any;
  showPaginatorServiceInvoice: boolean;
  serviceinvoiceDispalyData: any[];
  serviceInvoiceLength: any;
  allInColumnLength: any;
  allPOColumnLength: any;
  allARCColumnLength: any;
  allSRVColumnLength: any;
  filterData: any[];
  filterDataService: any[];
  totalInvoicesData: any[];
  filterDataArchived: any;

  @ViewChild('sidenav') sidenav: MatSidenav;
  events: string[] = [];
  opened: boolean;
  portal_name: string;
  invoiceTab: any;
  POTab: any;
  GRNTab: any;
  archivedTab: any;
  rejectedTab: any;
  serviceInvoiceTab: any;
  first: any;
  searchStr:string;
  
  // searchPOStr = '';
  // searchGRNStr = '';
  // searchArcStr = '';
  // searchRejStr = '';
  GRNExceptionTab: any;
  GRNExcpLength: number;
  tableImportData: any;
  isDesktop: boolean;
  resfrshBool: boolean;
  showFactsComponent: boolean;
  userEmailID: string;
  dialogHeader: string;
  entityName: any;
  selectedEntityId: any;
  entityList: any;
  filteredEnt: any[];
  vendorsList: any;
  filteredVendors: any;
  grnTabDownloadOpt = 'All';
  cardCount: number;
  searchText: string;
  
  close(reason: string) {
    this.sidenav.close();
  }
  APIParams: string;
  // offsetCountPO :number;
  // pageCountVariablePO :number;
  // offsetCountGRN :number;
  // pageCountVariableGRN :number;
  // offsetCountArc :number;
  // pageCountVariableArc :number;
  // offsetCountRej:number;
  // pageCountVariableRej:number;

  // GRNExcpDispalyData = [];
  // GRNExcpColumns = [];
  // showPaginatorGRNExcp: boolean;
  // columnstodisplayGRNExcp = [];
  // GRNExcpColumnLength: number;

  rejectedColumns: any = [];
  columnstodisplayrejected: any = [];
  rejectedColumnLength: number;

  GRNColumns: any = [];
  columnstodisplayGRN: any = [];
  GRNColumnLength: number;
  factsList = ['Accounts payable professionals are like financial superheroes, ensuring the bills get paid on time to keep the business running smoothly',
  'AI can be as smart as your pet! The AI in your smartphone can recognize your face, just like your dog knows you by sight.',
  "It's like magic! OCR can turn handwritten notes into searchable text on your computer",
  "Automation in accounts payable has become more prevalent, with AI and OCR technology used to streamline invoice processing and reduce errors"];
  search_placeholder = 'Ex : By Vendor. By PO, Select Date range from the Calendar icon';
  @ViewChild('datePicker') datePicker: Calendar;
  pageNumber:number = 1;
  pageId:string = 'Inv';
  maxSize = 7;
  labels: any = {
    previousLabel: '<',
    nextLabel: '>',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
};
selectedVendorId: any;
soa_uniSearch: string;
SOATableData = [];
columnsForSOA = [
  { dbColumnname: 'VendorName', columnName: 'Vendor Name' },
  { dbColumnname: 'EntityName', columnName: 'Entity' },
  { dbColumnname: 'InvoiceNo', columnName: 'Invoice Number' },
  { dbColumnname: 'PurchaseOrder', columnName: 'PO Number' },
  { dbColumnname: 'GRN Number', columnName: 'GRN Number' },
  { dbColumnname: 'Invoice Status', columnName: 'Invoice Status' },
  { dbColumnname: 'Description', columnName: 'Description' },
  { dbColumnname: 'InvoiceTotal', columnName: 'Amount' },
  { dbColumnname: 'Rejected BY', columnName: 'Rejected BY' },
];
columnLengthSOA:number;

  constructor(
    public route: Router,
    private AlertService: AlertService,
    private sharedService: SharedService,
    private SpinnerService: NgxSpinnerService,
    private messageService: MessageService,
    private dataService: DataService,
    private ImportExcelService: ImportExcelService,
    private dateFilterService: DateFilterService,
    private datePipe: DatePipe,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.userDetails = this.authService.currentUserValue;
    this.userEmailID = this.userDetails.userdetails.email;
    this.APIParams = `?offset=1&limit=50`;
    this.isDesktop = this.dataService.isDesktop;
    if (this.userDetails.user_type == 'customer_portal') {
      this.usertypeBoolean = true;
      this.portal_name = 'customer';
    } else if (this.userDetails.user_type == 'vendor_portal') {
      this.usertypeBoolean = false;
      this.portal_name = 'vendorPortal';
    }
    this.dataService.portalName = this.portal_name;
    this.columnLengthSOA = this.columnsForSOA.length;
    // this.offsetCountPO = this.dataService.offsetCountPO;
    // this.offsetCountGRN = this.dataService.offsetCountGRN;
    // this.offsetCountArc = this.dataService.offsetCountGRN;
    // this.offsetCountRej = this.dataService.offsetCountRej;
    // this.pageCountVariablePO = this.dataService.pageCountVariablePO;
    // this.pageCountVariableGRN = this.dataService.pageCountVariableGRN;
    // this.pageCountVariableArc = this.dataService.pageCountVariableArc;
    // this.pageCountVariableRej = this.dataService.pageCountVariableRej;
    this.routeForTabs();
    this.dateRange();
    this.restoreData();
    this.findActiveRoute();
    // this.readGRNExceptionData();
    // this.getInvoiceData();
    // this.getDisplayPOData();
    // this.getDisplayGRNdata();
    // this.getDisplayReceiptdata();
    this.deviceColumns();
  }
  deviceColumns() {
    if(this.dataService.isDesktop) {
      if (this.route.url == this.invoiceTab) {
        if(this.dataService.invTabColumns){
            this.invoiceColumns = this.dataService.invTabColumns;  
            this.allInColumnLength = this.invoiceColumns.length + 1;
            this.columnstodisplayInvoice = this.pushColumnsField(this.invoiceColumns);
        } else {
          this.getInvoiceColumns();
        }
      } else if (this.route.url == this.POTab) {
        if(this.dataService.poTabColumns){
            this.poColumns = this.dataService.poTabColumns;  
            this.allPOColumnLength = this.poColumns.length + 1;
            this.columnstodisplayPO = this.pushColumnsField(this.poColumns);
        } else {
          this.getPOColums();
        }
      } else if (this.route.url == this.archivedTab) {
        if(this.dataService.arcTabColumns){
            this.archivedColumns = this.dataService.arcTabColumns;  
            this.allARCColumnLength = this.archivedColumns.length + 1;
            this.columnstodisplayArchived = this.pushColumnsField( this.archivedColumns);
        } else {
          this.getArchivedColumns();
        }
      } else if (this.route.url == this.serviceInvoiceTab) {
        if(this.dataService.serTabColumns){
          this.serviceColumns = this.dataService.serTabColumns;  
          this.allSRVColumnLength = this.serviceColumns.length + 1;
          this.columnstodisplayService = this.pushColumnsField(this.serviceColumns);
      } else {
        this.getServiceColumns();
      }
      }
      this.prepareColumns();
    } else {
      this.mob_columns();
      this.prepareColumnsArray_mobile();
    }
  }

  restoreData() {
    this.totalInvoicesData = this.dataService.invoiceLoadedData;
    this.serviceinvoiceDispalyData = this.dataService.serviceinvoiceLoadedData;
    this.filterDataService = this.serviceinvoiceDispalyData;

    // this.filterData = this.invoiceDispalyData;
    // this.allInvoiceLength = this.dataService.invoiceLoadedData.length;
    // if (this.allInvoiceLength > 10) {
    //   this.showPaginatorAllInvoice = true;
    // }
    this.serviceInvoiceLength = this.serviceinvoiceDispalyData.length;
    if (this.serviceInvoiceLength > 10) {
      this.showPaginatorServiceInvoice = true;
    }
    this.filterForArchived();
    this.poDispalyData = this.dataService.poLoadedData;
    this.poArrayLength = this.dataService.POtableLength;
    if (this.poDispalyData.length > 10 && this.isDesktop) {
      this.showPaginatorPOTable = true;
    }
    this.GRNDispalyData = this.dataService.GRNLoadedData;
    this.GRNArrayLength = this.dataService.GRNTableLength;
    if (this.GRNDispalyData.length > 10 && this.isDesktop) {
      this.showPaginatorGRNTable = true;
    }
    this.archivedDisplayData = this.dataService.archivedDisplayData;
    this.archivedLength = this.dataService.ARCTableLength;
    if (this.archivedDisplayData.length > 10 && this.isDesktop) {
      this.showPaginatorArchived = true;
    }
    this.rejectedDisplayData = this.dataService.rejectedDisplayData;
    this.rejectedLength = this.dataService.rejectTableLength;
    if (this.rejectedDisplayData.length > 10 && this.isDesktop) {
      this.showPaginatorRejected = true;
    }
    this.receiptDispalyData = this.dataService.receiptLoadedData;
    this.receiptArrayLength = this.dataService.receiptLoadedData.length;
    this.visibleSidebar2 = this.sharedService.sidebarBoolean;
 
    if (this.dataService.receiptLoadedData.length == 0) {
      // this.getDisplayReceiptdata();
    }
  }

  routeForTabs() {
    this.invoiceTab = `/${this.portal_name}/invoice/allInvoices`;
    this.POTab = `/${this.portal_name}/invoice/PO`;
    this.GRNTab = `/${this.portal_name}/invoice/GRN`;
    this.archivedTab = `/${this.portal_name}/invoice/archived`;
    this.rejectedTab = `/${this.portal_name}/invoice/rejected`;
    this.GRNExceptionTab = `/${this.portal_name}/invoice/GRNExceptions`;
    this.serviceInvoiceTab = `/${this.portal_name}/invoice/ServiceInvoices`;
  }

  prepareColumns() {
    // this.GRNExcpColumns = [
    //   // { dbColumnname: 'VendorName', columnName: 'Vendor Name' },
    //   { dbColumnname: 'docheaderID', columnName: 'Invoice Number' },
    //   { dbColumnname: 'PODocumentID', columnName: 'PO Number' },
    //   { dbColumnname: 'GRNNumber', columnName: 'GRN Number' },
    //   { dbColumnname: 'EntityName', columnName: 'Entity' },
    //   { dbColumnname: 'RejectDescription', columnName: 'Description' },
    //   { dbColumnname: 'documentDate', columnName: 'Invoice Date' },
    //   { dbColumnname: 'totalAmount', columnName: 'Amount' },
    //   // { dbColumnname: 'documentPaymentStatus', columnName: 'Status' },
    // ];
    this.rejectedColumns = [
      // { dbColumnname: 'VendorName', columnName: 'Vendor Name' },
      { dbColumnname: 'docheaderID', columnName: 'Invoice Number' },
      { dbColumnname: 'PODocumentID', columnName: 'PO Number' },
      { dbColumnname: 'EntityName', columnName: 'Entity' },
      { dbColumnname: 'documentdescription', columnName: 'Description' },
      { dbColumnname: 'CreatedOn', columnName: 'Uploaded Date' },
      { dbColumnname: 'totalAmount', columnName: 'Amount' },
      // { dbColumnname: 'documentPaymentStatus', columnName: 'Status' },
    ];

    this.GRNColumns = [
      { dbColumnname: 'EntityName', columnName: 'Entity Name' },
      { dbColumnname: 'VendorName', columnName: 'Vendor Name' },
      { dbColumnname: 'PODocumentID', columnName: 'PO Number' },
      { dbColumnname: 'docheaderID', columnName: 'GRN Number' },
      { dbColumnname: 'InvoiceNumber', columnName: 'Invoice Number' },
      { dbColumnname: 'grn_status', columnName: 'GRN Status' },
      { dbColumnname: 'CreatedOn', columnName: 'Received Date' },
      { dbColumnname: 'firstName', columnName: 'Created By' },
      { dbColumnname: 'grn_type', columnName: 'source' }
    ];

    if (this.portal_name == 'customer') {
      this.rejectedColumns.unshift({
        dbColumnname: 'VendorName',
        columnName: 'Vendor Name',
      });
    }
    // this.GRNExcpColumns.forEach((val) => {
    //   this.columnstodisplayGRNExcp.push(val.dbColumnname);
    // });

    this.rejectedColumns.forEach((e) => {
      this.columnstodisplayrejected.push(e.dbColumnname);
    });

    this.GRNColumns.forEach((e) => {
      this.columnstodisplayGRN.push(e.dbColumnname);
    });

    // this.GRNExcpColumnLength = this.GRNExcpColumns.length + 1;
    this.rejectedColumnLength = this.rejectedColumns.length + 1;
    this.GRNColumnLength = this.GRNColumns.length + 1;

  }
  dateRange() {
    this.dateFilterService.dateRange();
    this.minDate = this.dateFilterService.minDate;
    this.maxDate = this.dateFilterService.maxDate;
  }

  findActiveRoute() {
    
    if (this.route.url == this.invoiceTab) {
      this.pageNumber = this.dataService.invTabPageNumber;
      this.searchText = this.dataService.invoiceGlobe;
      this.routeName = 'allInvoices';
      if (this.dataService.invoiceLoadedData.length == 0) {
        this.getInvoiceData();
      }
    } else if (this.route.url == this.POTab) {
      this.pageNumber = this.dataService.poTabPageNumber;
      if (this.dataService.poLoadedData.length == 0) {
        this.getDisplayPOData(this.APIParams);
      }
      this.routeName = 'PO';
      this.searchStr = this.dataService.searchPOStr;
    } else if (this.route.url == this.archivedTab) {
      this.pageNumber = this.dataService.arcTabPageNumber;
      if (this.dataService.archivedDisplayData.length == 0) {
        this.getDisplayARCData(this.APIParams);
      }
      this.routeName = 'archived';
      this.searchStr = this.dataService.searchArcStr;
    } else if( this.route.url == this.rejectedTab){
      this.pageNumber = this.dataService.rejTabPageNumber;
      this.routeName = 'rejected';
      if (this.dataService.rejectedDisplayData.length == 0) {
        this.getDisplayRejectedData(this.APIParams);
      }
      this.searchStr = this.dataService.searchRejStr;
    } else if( this.route.url == this.GRNTab){
      this.pageNumber = this.dataService.grnTabPageNumber;
      if (this.dataService.GRNLoadedData.length == 0) {
        this.getDisplayGRNdata(this.APIParams);
      }
      this.routeName = 'GRN';
      this.searchStr = this.dataService.searchGRNStr;
    } else if(this.route.url == this.serviceInvoiceTab){
      this.routeName = 'services';
      this.pageNumber = this.dataService.serviceTabPageNumber;
      this.searchText = this.dataService.serviceGlobe;
      if (this.dataService.serviceinvoiceLoadedData.length == 0) {
        this.getDisplayServiceInvoicedata();
      }
    }
    setTimeout(() => {
      this.universalSearch(this.searchText);
    }, 1000);
  }
  getInvoiceData() {
    this.SpinnerService.show();
    this.showFactsComponent = true;
    this.sharedService.getAllInvoice().subscribe(
      (data: any) => {
        const invoicePushedArray = [];
        if (data) {
          this.resfrshBool = false;
          data.ok.Documentdata.forEach((element) => {
            let invoiceData = {
              ...element.Document,
              ...element.Entity,
              ...element.DocumentSubStatus,
              ...element.EntityBody,
              ...element.ServiceProvider,
              ...element.ServiceAccount,
              ...element.VendorAccount,
              ...element.Vendor,
            };
            // invoiceData.append('docStatus',element.docStatus)

            invoiceData['docstatus'] = element.docstatus;
            if (this.portal_name == 'vendorPortal') {
              if (invoiceData['docstatus'] == 'Need To Review') {
                invoiceData['docstatus'] = 'Under Review';
              }
            }
            invoicePushedArray.push(invoiceData);
          });
          this.totalInvoicesData = invoicePushedArray;
          this.filterForArchived();
          this.dataService.invoiceLoadedData = invoicePushedArray;
        }
        this.showFactsComponent = false;
        this.SpinnerService.hide();
      },
      (error) => {
        this.error("Server error");
        this.SpinnerService.hide();
      }
    ), err=>{
      this.SpinnerService.hide();
    };
  }

  filterForArchived() {
    const archived = [];
    const allInvoices = [];
    const rejected = [];
    this.totalInvoicesData.forEach((ele) => {
      allInvoices.push(ele);
    });
    setTimeout(() => {
      // this.archivedDisplayData = archived;
      this.filterDataArchived = this.archivedDisplayData;
      // this.archivedLength = this.archivedDisplayData.length;
      // if (this.archivedDisplayData.length > 10) {
      //   this.showPaginatorArchived = true;
      // }
      this.invoiceDispalyData = allInvoices;
      // this.rejectedDisplayData = rejected;
      // this.rejectedLength = this.rejectedDisplayData.length;
      // if (this.rejectedDisplayData.length > 10) {
      //   this.showPaginatorRejected = true;
      // }

      this.filterData = this.invoiceDispalyData;
      this.allInvoiceLength = this.invoiceDispalyData.length;
      this.universalSearch(this.searchText);
      if (this.invoiceDispalyData.length > 10 && this.isDesktop) {
        this.showPaginatorAllInvoice = true;
      }
    }, 500);
  }

  getDisplayPOData(data) {
    this.SpinnerService.show();
    this.sharedService.getPOData(data).subscribe(
      (data: any) => {
        const poPushedArray = [];
        if (data.ok) {
          data.ok.podata.forEach((element) => {
            let poData = {
              ...element.Document,
              ...element.Entity,
              ...element.EntityBody,
              ...element.ServiceProvider,
              ...element.ServiceAccount,
              ...element.VendorAccount,
              ...element.Vendor,
            };
            poData.docstatus = element.docstatus;
            poPushedArray.push(poData);
          });
        }
        this.poDispalyData =
          this.dataService.poLoadedData.concat(poPushedArray);
        this.dataService.poLoadedData = this.poDispalyData;
        this.poArrayLength = data.ok.total_po;
        this.dataService.POtableLength = data.ok.total_po;
        if (this.poDispalyData.length > 10 && this.isDesktop) {
          this.showPaginatorPOTable = true;
        }
        this.SpinnerService.hide();
      },
      (error) => {
        this.error("Server error");
        this.SpinnerService.hide();
      }
    );
  }

  getDisplayGRNdata(data) {
    this.SpinnerService.show();
    this.sharedService.getGRNdata(data).subscribe((data: any) => {
      let grnD = []
      data.grndata?.forEach(ele=>{
        let merg = {...ele.Document}
        merg.EntityName = ele.EntityName;
        merg.VendorName = ele.VendorName;
        merg.grn_type = ele.grn_type;
        merg.firstName = ele.firstName;
        merg.InvoiceNumber = ele.InvoiceNumber;
        merg.grn_status = ele.grn_status;
        grnD.push(merg)
      })
     
      this.GRNDispalyData = this.dataService.GRNLoadedData.concat(grnD);
      this.dataService.GRNLoadedData = this.GRNDispalyData;
      this.dataService.GRNTableLength = data.grn_total;
      this.GRNArrayLength = data.grn_total;
      if (this.GRNDispalyData.length > 10 && this.isDesktop) {
        this.showPaginatorGRNTable = true;
      }
      this.SpinnerService.hide();
    });
  }

  getDisplayARCData(data) {
    this.SpinnerService.show();
    this.sharedService.getARCdata(data).subscribe((data: any) => {
      const invoicePushedArray = [];
      data?.result?.ven?.ok?.Documentdata?.forEach((element) => {
        let invoiceData = {
          ...element.Document,
          ...element.Entity,
          ...element.EntityBody,
          ...element.VendorAccount,
          ...element.Vendor,
          ...element.PaymentsInfo
        };
        
        // invoiceData.append('docStatus',element.docStatus)

        invoiceData['docstatus'] = element.docstatus;
        invoicePushedArray.push(invoiceData);
      });

      data?.result?.ser?.ok?.Documentdata?.forEach((element) => {
        let invoiceData = {
          ...element.Document,
          ...element.Entity,
          ...element.EntityBody,
          ...element.ServiceProvider,
          ...element.ServiceAccount,
          ...element.PaymentsInfo
        };
        invoiceData['docstatus'] = element.docstatus;
        invoicePushedArray.push(invoiceData);
      });
      
      this.archivedDisplayData.forEach((ele1) => {
        for (let name in ele1) {
          if (name == 'ServiceProviderName') {
            ele1['VendorName'] = ele1['ServiceProviderName'];
          }
        }
      });
      
      this.archivedDisplayData =
        this.dataService.archivedDisplayData.concat(invoicePushedArray);
        this.dataService.archivedDisplayData = this.archivedDisplayData;
      this.dataService.ARCTableLength = data?.result?.ven?.ok?.total_arc;
      if(this.dataService.posted_inv_type != 'ser'){
        this.archivedLength = data?.result?.ven?.ok?.total_arc;
      } else {
        this.archivedLength = data?.result?.ser?.ok?.total_arc;
      }
      if (this.archivedLength > 10 && this.isDesktop) {
        this.showPaginatorArchived = true;
      }
      this.SpinnerService.hide();
    });
  }

  getDisplayRejectedData(data) {
    this.SpinnerService.show();
    this.sharedService.getRejecteddata(data).subscribe((data: any) => {
      const invoicePushedArray = [];
      data?.result?.ven?.ok?.Documentdata?.forEach((element) => {
        let invoiceData = {
          ...element.Document,
          ...element.Entity,
          ...element.EntityBody,
          ...element.DocumentHistoryLogs,
          ...element.VendorAccount,
          ...element.Vendor,
        };
        invoiceData['docstatus'] = element.docstatus;
        invoicePushedArray.push(invoiceData);
      });

      data?.result?.ser?.ok?.Documentdata?.forEach((element) => {
        let invoiceData = {
          ...element.Document,
          ...element.Entity,
          ...element.EntityBody,
          ...element.ServiceProvider,
          ...element.ServiceAccount
        };
        
        // invoiceData.append('docStatus',element.docStatus)

        invoiceData['docstatus'] = element.docstatus;
        invoicePushedArray.push(invoiceData);
      });
      this.rejectedDisplayData.forEach((ele1) => {
        for (let name in ele1) {
          if (name == 'ServiceProviderName') {
            ele1['VendorName'] = ele1['ServiceProviderName'];
          }
        }
      });
      this.rejectedDisplayData =
        this.dataService.rejectedDisplayData.concat(invoicePushedArray);
      this.dataService.rejectedDisplayData = this.rejectedDisplayData;
      this.dataService.rejectTableLength = data?.result?.ven?.ok?.total_rejected;
      this.rejectedLength = data?.result?.ven?.ok?.total_rejected;
      if (this.rejectedDisplayData.length > 10 && this.isDesktop) {
        this.showPaginatorRejected = true;
      }
      this.SpinnerService.hide();
    });
  }

  // readGRNExceptionData(){
  //   this.sharedService.getGRNExceptionData('').subscribe((data:any)=>{
  //     const invoicePushedArray = [];
  //     data?.result?.ok?.Documentdata?.forEach((element) => {
  //       let invoiceData = {
  //         ...element.Document,
  //         ...element.Entity,
  //         ...element.EntityBody,
  //         ...element.VendorAccount,
  //         ...element.Vendor,
  //         ...element.GrnReupload
  //       };
  //       invoiceData['docstatus'] = element.docstatus;
  //       invoicePushedArray.push(invoiceData);
  //     });
  //     this.GRNExcpDispalyData =
  //       this.dataService.GRNExcpDispalyData.concat(invoicePushedArray);
  //     this.dataService.GRNExcpTableLength = this.GRNExcpDispalyData.length;
  //     this.GRNExcpLength = this.GRNExcpDispalyData.length;
  //     if (this.GRNExcpDispalyData.length > 10 && this.isDesktop) {
  //       this.showPaginatorGRNExcp = true;
  //     }
  //   })
  // }

  getDisplayServiceInvoicedata() {
    this.SpinnerService.show();
    this.sharedService.getServiceInvoices().subscribe(
      (data: any) => {
        const invoicePushedArray = [];
        if (data) {
          this.resfrshBool = false;
          data.ok.Documentdata.forEach((element) => {
            let invoiceData = {
              ...element.Document,
              ...element.Entity,
              ...element.EntityBody,
              ...element.ServiceProvider,
              ...element.ServiceAccount,
              ...element.DocumentSubStatus
            };
            // invoiceData.append('docStatus',element.docStatus)
            invoiceData['docstatus'] = element.docstatus;
            invoicePushedArray.push(invoiceData);
          });
          const allInvoicesService = [];
          invoicePushedArray.forEach((ele) => {
            if (ele.documentStatusID == 14) {
              this.archivedDisplayData.push(ele);
            } else {
              allInvoicesService.push(ele);
            }
          });
          // this.archivedDisplayData.forEach((ele1) => {
          //   for (let name in ele1) {
          //     if (name == 'ServiceProviderName') {
          //       ele1['VendorName'] = ele1['ServiceProviderName'];
          //     }
          //   }
          // });
          // this.filterForArchived();
          setTimeout(() => {
            this.serviceinvoiceDispalyData = allInvoicesService;
            this.filterDataService = this.serviceinvoiceDispalyData;
            this.dataService.serviceinvoiceLoadedData = allInvoicesService;
            this.serviceInvoiceLength = this.serviceinvoiceDispalyData.length;
            if (this.serviceinvoiceDispalyData.length > 10 && this.isDesktop) {
              this.showPaginatorServiceInvoice = true;
            }
            this.universalSearch(this.searchText);
            // this.filterDataArchived = this.archivedDisplayData;
            // this.archivedLength = this.archivedDisplayData.length;
            // if (this.archivedDisplayData.length > 10) {
            //   this.showPaginatorArchived = true;
            // }
          }, 500);
        }
        this.SpinnerService.hide();
      },
      (error) => {
        this.error("Server error");
        this.SpinnerService.hide();
      }
    );
  }

  getColumnsData() {}

  getInvoiceColumns() {
    // this.SpinnerService.show();
    this.updateColumns = [];
    this.sharedService.readColumnInvoice('INV').subscribe(
      (data: any) => {
        const pushedInvoiceColumnsArray = [];
        data.col_data.forEach((element) => {
          let arrayColumn = {
            ...element.DocumentColumnPos,
            ...element.ColumnPosDef,
          };
          pushedInvoiceColumnsArray.push(arrayColumn);
        });
        this.invoiceColumns = pushedInvoiceColumnsArray.filter((ele) => {
          return ele.isActive == 1;
        });
        
        this.columnstodisplayInvoice = this.pushColumnsField(this.invoiceColumns);
        this.invoiceColumns = this.invoiceColumns.sort(
          (a, b) => a.documentColumnPos - b.documentColumnPos
        );
        this.dataService.invTabColumns = this.invoiceColumns;
        this.allInColumnLength = this.invoiceColumns.length + 1;
        this.allColumns = pushedInvoiceColumnsArray.sort(
          (a, b) => a.documentColumnPos - b.documentColumnPos
        );
        this.allColumns.forEach((val) => {
          let activeBoolean;
          if (val.isActive == 1) {
            activeBoolean = true;
          } else {
            activeBoolean = false;
          }
          this.updateColumns.push({
            idtabColumn: val.idDocumentColumn,
            ColumnPos: val.documentColumnPos,
            isActive: activeBoolean,
          });
        });

        // this.SpinnerService.hide();
        // this.invoiceColumns= pushedInvoiceColumnsArray;
      },
      (error) => {
        this.error("Server error");
        // this.SpinnerService.hide();
      }
    );
  }

  getPOColums() {
    this.updateColumns = [];
    this.sharedService.readColumnInvoice('PO').subscribe(
      (data: any) => {
        const pushedPOColumnsArray = [];
        data.col_data.forEach((element) => {
          let arrayColumn = {
            ...element.ColumnPosDef,
            ...element.DocumentColumnPos,
          };
          pushedPOColumnsArray.push(arrayColumn);
        });
        this.poColumns = pushedPOColumnsArray.filter((element) => {
          return element.isActive == 1;
        });
        this.columnstodisplayPO = this.pushColumnsField(this.poColumns);
        this.poColumns = this.poColumns.sort(
          (a, b) => a.documentColumnPos - b.documentColumnPos
        );
        this.dataService.poTabColumns = this.poColumns
        this.allPOColumnLength = this.poColumns.length + 1;
        this.allColumns = pushedPOColumnsArray.sort(
          (a, b) => a.documentColumnPos - b.documentColumnPos
        );
        this.allColumns.forEach((val) => {
          let activeBoolean;
          if (val.isActive == 1) {
            activeBoolean = true;
          } else {
            activeBoolean = false;
          }
          this.updateColumns.push({
            idtabColumn: val.idDocumentColumn,
            ColumnPos: val.documentColumnPos,
            isActive: activeBoolean,
          });
        });
      },
      (error) => {
        alert(error?.error?.detail[0]?.msg);
      }
    );
  }

  getArchivedColumns() {
    this.updateColumns = [];
    this.sharedService.readColumnInvoice('ARC').subscribe(
      (data: any) => {
        const pushedArchivedColumnsArray = [];
        data.col_data.forEach((element) => {
          let arrayColumn = {
            ...element.DocumentColumnPos,
            ...element.ColumnPosDef,
          };
          pushedArchivedColumnsArray.push(arrayColumn);
        });
        this.archivedColumns = pushedArchivedColumnsArray.filter((element) => {
          return element.isActive == 1;
        });
        // const arrayOfColumnIdArchived = [];
        // this.archivedColumns.forEach((e) => {
        //   arrayOfColumnIdArchived.push(e.dbColumnname);
        // });
        this.columnstodisplayArchived = this.pushColumnsField( this.archivedColumns);
        this.allColumns = pushedArchivedColumnsArray.sort(
          (a, b) => a.documentColumnPos - b.documentColumnPos
        );
        this.archivedColumns = this.archivedColumns.sort(
          (a, b) => a.documentColumnPos - b.documentColumnPos
        );

        this.dataService.arcTabColumns = this.archivedColumns;
        this.allARCColumnLength = this.archivedColumns.length + 1;
        // this.allColumns = pushedPOColumnsArray
        this.allColumns.forEach((val) => {
          let activeBoolean;
          if (val.isActive == 1) {
            activeBoolean = true;
          } else {
            activeBoolean = false;
          }
          this.updateColumns.push({
            idtabColumn: val.idDocumentColumn,
            ColumnPos: val.documentColumnPos,
            isActive: activeBoolean,
          });
        });
      },
      (error) => {
        alert(error.statusText);
      }
    );
  }

  getServiceColumns() {
    // this.SpinnerService.show();
    this.updateColumns = [];
    this.sharedService.readColumnInvoice('INVS').subscribe(
      (data: any) => {
        const pushedArchivedColumnsArray = [];
        data.col_data.forEach((element) => {
          let arrayColumn = {
            ...element.DocumentColumnPos,
            ...element.ColumnPosDef,
          };
          pushedArchivedColumnsArray.push(arrayColumn);
        });
        this.serviceColumns = pushedArchivedColumnsArray.filter((element) => {
          return element.isActive == 1;
        });
        this.columnstodisplayService = this.pushColumnsField(this.serviceColumns);
        this.allColumns = pushedArchivedColumnsArray.sort(
          (a, b) => a.documentColumnPos - b.documentColumnPos
        );
        this.serviceColumns = this.serviceColumns.sort(
          (a, b) => a.documentColumnPos - b.documentColumnPos
        );
        this.dataService.serTabColumns = this.serviceColumns;
        this.allSRVColumnLength = this.serviceColumns.length + 1;
        // this.allColumns = pushedPOColumnsArray
        this.allColumns.forEach((val) => {
          let activeBoolean;
          if (val.isActive == 1) {
            activeBoolean = true;
          } else {
            activeBoolean = false;
          }
          this.updateColumns.push({
            idtabColumn: val.idDocumentColumn,
            ColumnPos: val.documentColumnPos,
            isActive: activeBoolean,
          });
          // this.SpinnerService.hide();
        });
      },
      (error) => {
        this.error("Server error");
        // this.SpinnerService.hide();
      }
    );
  }

  menuChange(value) {
    this.updateColumns = [];
    // this.tagService.activeMenuName = value;
    this.activeMenuName = value;
    // this.getInvoiceData();
    this.dataService.allPaginationFirst = 0;
    this.dataService.allPaginationRowLength = 10;
   
    if (value == 'invoice') {
      this.route.navigate([this.invoiceTab]);
      this.allSearchInvoiceString = [];
      if(!this.invoiceColumns){
        this.getInvoiceColumns();
      }
    } else if (value == 'po') {
      if(!this.poColumns){
        this.getPOColums();
      }
      this.route.navigate([this.POTab]);
      this.allSearchInvoiceString = [];
      this.searchStr = this.dataService.searchPOStr;
    } else if (value == 'grn') {
      this.route.navigate([this.GRNTab]);
      this.allSearchInvoiceString = [];
      this.searchStr = this.dataService.searchGRNStr;
    } else if (value == 'ServiceInvoices') {
      if(!this.serviceColumns){
        this.getServiceColumns();
      }
      this.route.navigate([this.serviceInvoiceTab]);
      this.allSearchInvoiceString = [];
    } else if (value == 'archived') {
      if(!this.archivedColumns){
        this.getArchivedColumns();
      }
      this.route.navigate([this.archivedTab]);
      this.allSearchInvoiceString = [];
      this.searchStr = this.dataService.searchArcStr;
    } else if (value == 'rejected') {
      this.route.navigate([this.rejectedTab]);
      this.allSearchInvoiceString = [];
      this.searchStr = this.dataService.searchRejStr;
    } else if (value == 'GRNException') {
      this.route.navigate([this.GRNExceptionTab]);
      this.allSearchInvoiceString = [];
    }
    setTimeout(() => {
      this.findActiveRoute();
    }, 500);
  }
  searchInvoiceDataV(value) {
    this.allSearchInvoiceString = [];
    this.allSearchInvoiceString = value.filteredValue;
  }
  showSidebar(value) {
    this.visibleSidebar2 = value;
    // this.sidenav.toggle();
    if (this.route.url == this.invoiceTab) {
      this.getInvoiceColumns();
    } else if (this.route.url == this.POTab) {
      this.getPOColums();
    } else if (this.route.url == this.archivedTab) {
      this.getArchivedColumns();
    } else if (this.route.url == this.rejectedTab) {
      this.getArchivedColumns();
    } else if (this.route.url == this.GRNExceptionTab) {
      this.getArchivedColumns();
    } else if (this.route.url == this.serviceInvoiceTab) {
      this.getServiceColumns();
    }
  }

  exportExcel() {
    if(!this.route.url.includes('GRN')){
      let exportData = [];
      if(!this.tableImportData){
        if (this.route.url == this.invoiceTab) {
          exportData = this.invoiceDispalyData;
        } else if (this.route.url == this.POTab) {
          exportData = this.poDispalyData;
        } else if (this.route.url == this.GRNTab) {
          exportData = this.GRNDispalyData;
        } else if (this.route.url == this.archivedTab) {
          exportData = this.archivedDisplayData;
        } else if (this.route.url == this.rejectedTab) {
          exportData = this.rejectedDisplayData;
        } else if (this.route.url == this.GRNExceptionTab) {
          exportData = this.rejectedDisplayData;
        } else if (this.route.url == this.serviceInvoiceTab) {
          exportData = this.serviceinvoiceDispalyData;
        }
      } else {
        exportData = this.tableImportData;
      }
      if (this.allSearchInvoiceString && this.allSearchInvoiceString.length > 0) {
        this.ImportExcelService.exportExcel(this.allSearchInvoiceString);
      } else if (exportData && exportData.length > 0) {
        this.ImportExcelService.exportExcel(exportData);
      } else {
        alert('No Data to import');
      }
    } else {
      this.dialogHeader = "GRN Reports";
      const dialog = document.querySelector('dialog');
      if (dialog) {
        dialog.showModal();
      }
    }
  }
  openSOADialog(){
    this.readEntity();
    this.getCustomerVendors();
    this.dialogHeader = "SOA Reports";
    const dialog = document.querySelector('dialog');
    if (dialog) {
      dialog.showModal();
    }
  }
  onOptionDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.allColumns, event.previousIndex, event.currentIndex);
    // if (this.route.url == '/customer/invoice/allInvoices') {
    this.allColumns.forEach((e, index) => {
      this.updateColumns.forEach((val) => {
        if (val.idtabColumn === e.idDocumentColumn) {
          val.ColumnPos = index + 1;
        }
      });
    });
  }
  order(v) {}
  activeColumn(e, value) {
    // if (this.route.url == '/customer/invoice/allInvoices') {
    this.updateColumns.forEach((val) => {
      if (val.idtabColumn == value.idDocumentColumn) {
        val.isActive = e.target.checked;
      }
    });
  }

  updateColumnPosition() {
    if (this.route.url == this.invoiceTab) {
      this.sharedService.updateColumnPOs(this.updateColumns, 'INV').subscribe(
        (data: any) => {
          this.getInvoiceColumns();
          this.success("Columns updated successfully");
        },
        (error) => {
          this.error("Server error");
        }
      );
    } else if (this.route.url == this.POTab) {
      this.sharedService.updateColumnPOs(this.updateColumns, 'PO').subscribe(
        (data: any) => {
          this.getPOColums();
          this.success("Columns updated successfully");
        },
        (error) => {
          this.error("Server error");
        }
      );
    } else if (
      this.route.url == this.archivedTab ||
      this.route.url == this.rejectedTab
    ) {
      this.sharedService.updateColumnPOs(this.updateColumns, 'ARC').subscribe(
        (data: any) => {
          this.getArchivedColumns();
          this.success("Columns updated successfully");
        },
        (error) => {
          this.error("Server error");
        }
      );
    } else if (this.route.url == this.serviceInvoiceTab) {
      this.sharedService.updateColumnPOs(this.updateColumns, 'INVS').subscribe(
        (data: any) => {
          this.getServiceColumns();
          this.success("Columns updated successfully");
        },
        (error) => {
          this.error("Server error");
        }
      );
    }
    // this.sidenav.close();
    this.visibleSidebar2 = false;
  }

  filterByDate(date) {
    if (date != '') {
      const frmDate = this.datePipe.transform(date[0], 'yyyy-MM-dd');
      const toDate = this.datePipe.transform(date[1], 'yyyy-MM-dd');
      // this.filterData = [];
      this.search_placeholder = `From "${frmDate}" to "${toDate}"`;
      if(frmDate && toDate){
        if (this.datePicker.overlayVisible) {
          this.datePicker.hideOverlay();
        }
        if (this.route.url == this.invoiceTab) {
          this.invoiceDispalyData = this.filterData;
          this.invoiceDispalyData = this.invoiceDispalyData.filter((element) => {
            const dateF = this.datePipe.transform(element.CreatedOn, 'yyyy-MM-dd')
            return dateF >= frmDate && dateF <= toDate;
          });
          this.allInvoiceLength = this.invoiceDispalyData.length;
        } else if (this.route.url == this.serviceInvoiceTab) {
          this.serviceinvoiceDispalyData = this.filterDataService;
          this.serviceinvoiceDispalyData = this.serviceinvoiceDispalyData.filter(
            (element) => {
              const dateF = this.datePipe.transform(element.CreatedOn, 'yyyy-MM-dd')
              return dateF >= frmDate && dateF <= toDate;
            }
          );
          this.serviceInvoiceLength = this.serviceinvoiceDispalyData.length;
        } else if (this.route.url == this.archivedTab) {
          this.archivedDisplayData = this.filterDataArchived;
          this.archivedDisplayData = this.archivedDisplayData.filter(
            (element) => {
              const dateF = this.datePipe.transform(element.CreatedOn, 'yyyy-MM-dd')
              return dateF >= frmDate && dateF <= toDate;
            }
          );
          this.archivedLength = this.archivedDisplayData.length;
        }
      }
    } else {
      this.invoiceDispalyData = this.filterData;
      this.allInvoiceLength = this.invoiceDispalyData.length;
      this.search_placeholder = 'Ex : By Vendor. By PO, Select Date range from the Calendar icon';
    }
  }
  clearDates() {
    this.filterByDate('');
  }

  paginate(event) {
    this.first = event.first;
    if (this.route.url == this.invoiceTab) {
      this.dataService.allPaginationFirst = this.first;
      this.dataService.allPaginationRowLength = event.rows;
      this.dataService.invTabPageNumber = event.pageNumber;
    } else if (this.route.url == this.POTab) {
      this.dataService.poPaginationFisrt = this.first;
      this.dataService.poPaginationRowLength = event.rows;
      this.dataService.poTabPageNumber = event.pageNumber;
      if (this.first >= this.dataService.pageCountVariablePO) {
        this.dataService.pageCountVariablePO = event.first;
        if (this.dataService.searchPOStr == '') {
          this.dataService.offsetCountPO++;
          this.APIParams = `?offset=${this.dataService.offsetCountPO}&limit=50`;
          this.getDisplayPOData(this.APIParams);
        } else {
          this.dataService.offsetCountPO++;
          this.APIParams = `?offset=${this.dataService.offsetCountPO}&limit=50&uni_search=${this.dataService.searchPOStr}`;
          this.getDisplayPOData(this.APIParams);
        }
      }
    } else if (this.route.url == this.GRNTab) {
      this.dataService.grnTabPageNumber = event.pageNumber;
      this.dataService.GRNPaginationFisrt = this.first;
      this.dataService.GRNPaginationRowLength = event.rows;
      if (this.first >= this.dataService.pageCountVariableGRN) {
        this.dataService.pageCountVariableGRN = event.first;
        if (this.dataService.searchGRNStr == '') {
          this.dataService.offsetCountGRN++;
          this.APIParams = `?offset=${this.dataService.offsetCountGRN}&limit=50`;
          this.getDisplayGRNdata(this.APIParams);
        } else {
          this.dataService.offsetCountGRN++;
          this.APIParams = `?offset=${this.dataService.offsetCountGRN}&limit=50&uni_search=${this.dataService.searchGRNStr}`;
          this.getDisplayGRNdata(this.APIParams);
        }
      }
    } else if (this.route.url == this.archivedTab) {
      this.dataService.arcTabPageNumber = event.pageNumber;
      this.dataService.archivedPaginationFisrt = this.first;
      this.dataService.archivedPaginationRowLength = event.rows;
      if (this.first >= this.dataService.pageCountVariableArc) {
        this.dataService.pageCountVariableArc = event.first;
        if (this.dataService.searchArcStr == '' && this.dataService.posted_inv_type == '') {
          this.dataService.offsetCountArc++;
          this.APIParams = `?offset=${this.dataService.offsetCountArc}&limit=50`;
          this.getDisplayARCData(this.APIParams);
        } else if (this.dataService.searchArcStr == '' && this.dataService.posted_inv_type != '') {
          this.dataService.offsetCountArc++;
          this.APIParams = `?offset=${this.dataService.offsetCountArc}&limit=50&inv_type=${this.dataService.posted_inv_type}`;
          this.getDisplayARCData(this.APIParams);
        } else if (this.dataService.searchArcStr != '' && this.dataService.posted_inv_type != '') {
          this.dataService.offsetCountArc++;
          this.APIParams = `?offset=${this.dataService.offsetCountArc}&limit=50&uni_search=${this.dataService.searchArcStr}&inv_type=${this.dataService.posted_inv_type}`;
          this.getDisplayARCData(this.APIParams);
        }else {
          this.dataService.offsetCountArc++;
          this.APIParams = `?offset=${this.dataService.offsetCountArc}&limit=50&uni_search=${this.dataService.searchArcStr}`;
          this.getDisplayARCData(this.APIParams);
        }
      }
    } else if (this.route.url == this.rejectedTab) {
      this.dataService.rejTabPageNumber = event.pageNumber;
      this.dataService.rejectedPaginationFisrt = this.first;
      this.dataService.rejectedPaginationRowLength = event.rows;
      if (this.first >= this.dataService.pageCountVariableRej) {
        this.dataService.pageCountVariableRej = event.first;
        if (this.dataService.searchRejStr == '') {
          this.dataService.offsetCountRej++;
          this.APIParams = `?offset=${this.dataService.offsetCountRej}&limit=50`;
          this.getDisplayRejectedData(this.APIParams);
        } else {
          this.dataService.offsetCountRej++;
          this.APIParams = `?offset=${this.dataService.offsetCountRej}&limit=50&uni_search=${this.dataService.searchRejStr}`;
          this.getDisplayRejectedData(this.APIParams);
        }
      }
    } else if (this.route.url == this.GRNExceptionTab) {
      this.dataService.GRNExceptionPaginationFisrt = this.first;
      this.dataService.GRNExceptionPaginationRowLength = event.rows;
    } else if (this.route.url == this.serviceInvoiceTab) {
      this.dataService.serviceTabPageNumber = event.pageNumber;
      this.dataService.servicePaginationFisrt = this.first;
      this.dataService.servicePaginationRowLength = event.rows;
    }
  }

  keySearch(str,event:KeyboardEvent){
    if(str == ''){
      this.APIParams = `?offset=1&limit=50`
      if (this.route.url == this.invoiceTab) {
      } else if (this.route.url == this.POTab) {
        this.dataService.poLoadedData = [];
          this.getDisplayPOData(this.APIParams);
      } else if (this.route.url == this.GRNTab) {
        this.dataService.GRNLoadedData = [];
          this.getDisplayGRNdata(this.APIParams);
      } else if (this.route.url == this.archivedTab) {
        this.dataService.archivedDisplayData = [];
          this.getDisplayARCData(this.APIParams);
      } else if (this.route.url == this.rejectedTab) {
        this.dataService.rejectedDisplayData = [];
          this.getDisplayRejectedData(this.APIParams);
      } else if (this.route.url == this.serviceInvoiceTab) {
      }
    }
    if (event.key === 'Enter') {
      this.filterString(str);
    }
  }

  filterString(event) {
    if (this.route.url == this.invoiceTab) {
      // this.dataService.allPaginationFirst = 0;
    } else if (this.route.url == this.POTab) {
      this.dataService.poPaginationFisrt = 0;
      this.dataService.offsetCountPO = 1;
      this.dataService.poLoadedData = [];
      this.dataService.searchPOStr = event;
      if (this.dataService.searchPOStr == '') {
        this.APIParams = `?offset=${this.dataService.offsetCountPO}&limit=50`;
        this.getDisplayPOData(this.APIParams);
      } else {
        this.APIParams = `?offset=${this.dataService.offsetCountPO}&limit=50&uni_search=${this.dataService.searchPOStr}`;
        this.getDisplayPOData(this.APIParams);
      }
      // this.dataService.poPaginationFisrt = 1;
    } else if (this.route.url == this.GRNTab) {
      this.dataService.GRNPaginationFisrt = 0;
      this.dataService.offsetCountGRN = 1;
      this.dataService.GRNLoadedData = [];
      this.dataService.searchGRNStr = event;
      if (this.dataService.searchGRNStr == '') {
        this.APIParams = `?offset=${this.dataService.offsetCountGRN}&limit=50`;
        this.getDisplayGRNdata(this.APIParams);
      } else {
        this.APIParams = `?offset=${this.dataService.offsetCountGRN}&limit=50&uni_search=${this.dataService.searchGRNStr}`;
        this.getDisplayGRNdata(this.APIParams);
      }
      // this.dataService.GRNPaginationFisrt = 1;
    } else if (this.route.url == this.archivedTab) {
      this.dataService.archivedPaginationFisrt = 0;
      this.dataService.offsetCountArc = 1;
      this.dataService.archivedDisplayData = [];
      this.dataService.searchArcStr = event;
      if (this.dataService.searchArcStr == '' && this.dataService.posted_inv_type == '') {
        this.APIParams = `?offset=${this.dataService.offsetCountArc}&limit=50`;
        this.getDisplayARCData(this.APIParams);
      } else if (this.dataService.searchArcStr == '' && this.dataService.posted_inv_type != '') {
        this.APIParams = `?offset=${this.dataService.offsetCountArc}&limit=50&inv_type=${this.dataService.posted_inv_type}`;
        this.getDisplayARCData(this.APIParams);
      } else if (this.dataService.searchArcStr != '' && this.dataService.posted_inv_type != '') {
        this.APIParams = `?offset=${this.dataService.offsetCountArc}&limit=50&uni_search=${this.dataService.searchArcStr}&inv_type=${this.dataService.posted_inv_type}`;
        this.getDisplayARCData(this.APIParams);
      } else {
        this.APIParams = `?offset=${this.dataService.offsetCountArc}&limit=50&uni_search=${this.dataService.searchArcStr}`;
        this.getDisplayARCData(this.APIParams);
      }
      // this.dataService.archivedPaginationFisrt = 1;
    } else if (this.route.url == this.rejectedTab) {
      this.dataService.rejectedPaginationFisrt = 0;
      this.dataService.offsetCountRej = 1;
      this.dataService.rejectedDisplayData = [];
      this.dataService.searchRejStr = event;
      if (this.dataService.searchRejStr == '') {
        this.APIParams = `?offset=${this.dataService.offsetCountRej}&limit=50`;
        this.getDisplayRejectedData(this.APIParams);
      } else {
        this.APIParams = `?offset=${this.dataService.offsetCountRej}&limit=50&uni_search=${this.dataService.searchRejStr}`;
        this.getDisplayRejectedData(this.APIParams);
      }
      // this.dataService.rejectedPaginationFisrt = 1;
    } else if (this.route.url == this.serviceInvoiceTab) {
    }
  }

  selectinvType(val){
    this.dataService.archivedDisplayData = [];
    this.dataService.posted_inv_type = val;
    this.getDisplayARCData(`?offset=${this.dataService.offsetCountArc}&limit=50&inv_type=${val}`);
  }

  filterEmit(event){
    this.tableImportData = event;
  }

  mob_columns(){
    this.invoiceColumns = [
      { columnName : 'Invoice Number', dbColumnname:'docheaderID'},
      { columnName : 'Vendor Name', dbColumnname:'VendorName'},
      { columnName : 'Entity', dbColumnname:'EntityName'},
      { columnName : 'PO Number', dbColumnname:'PODocumentID'}
    ];
    this.poColumns = [
      { columnName : 'Vendor Name', dbColumnname:'VendorName'},
      { columnName : 'Entity', dbColumnname:'EntityName'},
      { columnName : 'PO Number', dbColumnname:'docheaderID'},
      { columnName : 'Status', dbColumnname:'docstatus'}
    ];
    this.GRNColumns = [
      { columnName : 'GRN Number', dbColumnname:'docheaderID'},
      { columnName : 'Vendor Name', dbColumnname:'VendorName'},
      // { columnName : 'Entity', dbColumnname:'EntityName'},
      { columnName : 'PO Number', dbColumnname:'PODocumentID'},
    ];
    this.archivedColumns = [
      { columnName : 'Invoice Number', dbColumnname:'docheaderID'},
      { columnName : 'Vendor Name', dbColumnname:'VendorName'},
      { columnName : 'PO Number', dbColumnname:'PODocumentID'},
      { columnName : 'Payment status', dbColumnname:'PaymentStatus'},
    ];
    this.rejectedColumns = this.invoiceColumns;
    this.serviceColumns = [
      { columnName : 'Invoice Number', dbColumnname:'docheaderID'},
      { columnName : 'Service Provider', dbColumnname:'ServiceProviderName'},
      { columnName : 'Entity', dbColumnname:'EntityName'},
      { columnName : 'Service Account', dbColumnname:'Account'}
    ];
  }

  // to prepare display columns array
  prepareColumnsArray_mobile() {
    this.invoiceColumns.filter((element) => {
      this.columnstodisplayInvoice.push(element.dbColumnname);
    });
    this.poColumns.filter((element) => {
      this.columnstodisplayPO.push(element.dbColumnname);
    });
    this.GRNColumns.filter((element) => {
      this.columnstodisplayGRN.push(element.dbColumnname);
    });
    this.archivedColumns.filter((element) => {
      this.columnstodisplayArchived.push(element.dbColumnname);
    });
    this.rejectedColumns.filter((element) => {
      this.columnstodisplayrejected.push(element.dbColumnname);
    });
    this.serviceColumns.filter((element) => {
      this.columnstodisplayService.push(element.dbColumnname);
    });

    this.allInColumnLength = this.invoiceColumns.length + 1;
    this.allPOColumnLength = this.poColumns.length + 1;
    this.GRNColumnLength = this.GRNColumns.length + 1;
    this.allARCColumnLength = this.archivedColumns.length + 1;
    this.rejectedColumnLength = this.rejectedColumns.length + 1;
    this.allSRVColumnLength = this.serviceColumns.length + 1;
  }

  // Refresh the API's
  refreshInvoice(type){
    this.resfrshBool = true;
    if(type == 'inv'){
      this.getInvoiceData();
    } else if(type == 'ser'){
      this.getDisplayServiceInvoicedata();
    } else if(type == 'PO'){
      this.dataService.poLoadedData = [];
      this.getDisplayPOData(this.APIParams);
      this.resfrshBool = false;
    }
    
  }

  pushColumnsField(element){
    const arrayOfColumnId = [];
    element.forEach((e) => {
        arrayOfColumnId.push(e.dbColumnname);
      });
    return arrayOfColumnId;
  }

  universalSearch(txt){
      if(this.route.url == this.serviceInvoiceTab){
        this.dataService.serviceGlobe = txt;
        this.serviceinvoiceDispalyData = this.filterDataService;
        this.serviceinvoiceDispalyData = this.dataService.searchFilter(txt,this.filterDataService);
      } else if(this.route.url == this.invoiceTab){
        this.dataService.invoiceGlobe = txt;
        this.invoiceDispalyData = this.filterData;
        this.invoiceDispalyData = this.dataService.searchFilter(txt,this.filterData);
      }
  }
  closeDialog(){
    const dialog = document.querySelector('dialog');
    if(dialog){
      dialog.close();
    }
  }

  grnDownloadSelection(str){
    this.grnTabDownloadOpt = str;
  }

  email_download(){
    this.SpinnerService.show();
    let api_param = '';
    let api_body = {
        "email": this.userEmailID,
        "option": this.grnTabDownloadOpt
      }
    if(this.rangeDates){
      const frmDate = this.datePipe.transform(this.rangeDates[0], 'yyyy-MM-dd');
      const toDate = this.datePipe.transform(this.rangeDates[1], 'yyyy-MM-dd');
      api_param = `?start_date=${frmDate}&end_date=${toDate}`
    }
    this.sharedService.downloadGRN(api_param,api_body).subscribe((data:any)=>{
      this.success(data.result);
      this.SpinnerService.hide();
      this.closeDialog();
    })
  }
  success(msg) {
    this.AlertService.success_alert(msg);
  }
  error(msg) {
   this.AlertService.error_alert(msg);
  }
  onPageChange(number: number) {
    this.pageNumber = number;
}
readEntity(){
  this.dataService.entityData.subscribe((data:[])=>{
    this.entityList = data;
    this.filteredEnt = this.entityList;
  })
}

filterEntity(event) {
  let filtered: any[] = [];
  let query = event.query;

  if (this.entityList?.length > 0) {
    for (let i = 0; i < this.entityList?.length; i++) {
      let ent: any = this.entityList[i];
      if (ent.EntityName.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(ent);
      }
    }
  }
  this.filteredEnt = filtered;
}
selectEntity(value) {
  // this.selectedEntityId = value.idEntity;
  this.sharedService.selectedEntityId = value.idEntity;
  this.entityName = value;
}
getCustomerVendors() {
  this.sharedService
    .getVendorsListToCreateNewlogin(`?offset=1&limit=100`)
    .subscribe((data: any) => {
      this.vendorsList = data.vendorlist;
      // this.filteredVendors = this.vendorsList
    });
}
filterVendor(event) {
  let query = event.query.toLowerCase();
  if (query != '') {
    this.sharedService.getVendorsListToCreateNewlogin(`?offset=1&limit=100&ven_name=${query}`).subscribe((data: any) => {
      this.filteredVendors = data.vendorlist;
    });
  } else {
    this.filteredVendors = this.vendorsList;
  }
}
selectedVendor(val){
}
soaSearch(bool){
  // Initialize API parameter with usertype and filter_type
  let apiParam = `?usertype=${this.userDetails.user_type}&filter_type=${bool}`;
  
  // Add entity ID if selected
  if (this.selectedEntityId) {
    let ent_id = this.selectedEntityId?.idEntity;
    apiParam += `&ent_id=${ent_id}`;
  }

  // Add vendor ID if selected
  if (this.selectedVendorId) {
    let ven_name = this.selectedVendorId?.VendorName;
    apiParam += `&ven_name=${ven_name}`;
  }

  // Transform dates if rangeDates_soa is available
  let frmDate, toDate;
  if (this.rangeDates_soa) {
    frmDate = this.datePipe.transform(this.rangeDates_soa[0], 'yyyy-MM-dd');
    toDate = this.datePipe.transform(this.rangeDates_soa[1], 'yyyy-MM-dd');
    apiParam += `&start_date=${frmDate}&end_date=${toDate}`;
  }

  // Add unique search key if present
  if (this.soa_uniSearch) {
    apiParam += `&unq_key=${this.soa_uniSearch}`;
  }
  this.SpinnerService.show();
  this.sharedService.SOASearch(apiParam).subscribe((data:any)=>{
    this.SpinnerService.hide();
    if(bool){
      this.AlertService.addObject.detail = "Dear User, The Report will be sent to your email shortly."
      this.messageService.add( this.AlertService.addObject);
      this.closeDialog();
    } else {
      this.SOATableData = data;
      console.log(this.SOATableData);
    }
  },err=>{
    this.SpinnerService.hide();
    this.AlertService.errorObject.detail = "Server error."
    this.messageService.add( this.AlertService.errorObject);
  })
}
}
