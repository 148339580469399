<!-- <mat-drawer-container class="example-container" autosize style="display: none;">
  <mat-drawer #drawer class="example-sidenav sideBar d-flex" [mode]="sidebarMode" [opened]="isnotTablet">
    <div class="d_desk1">
      <div class="logotitle">
        <div class="logoPlaceholder">
          <img
            class="logo_img"
            src="assets/serinaLogo01.png"
            alt="logo"
            width="130"
            height="42"
          />
        </div>
        <div class="placeLogo">
          <span class="portalName">Customer Portal</span>
        </div>
      </div>
      <div class="routeLinks">
        <div
          class="navLink"
          routerLinkActive="active"
          data-text="Dashboard"
          [routerLink]="'home'"
        >
          <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
            home
          </span>
          Dashboard
        </div>

        <div
          *ngIf="uploadPermissionBoolean"
          class="navLink"
          routerLinkActive="active"
          data-text="Upload"
          [routerLink]="'uploadInvoices'"
        >
          <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
            upload_file
          </span>
          Upload
        </div>
        <div
        class="navLink"
        routerLinkActive="active"
        data-text="Exceptions"
        [routerLink]="'ExceptionManagement'"
        (click)="showInnerException()"
        *ngIf="excpetionPageAccess"
      >
        <i
          class="fa fa-pencil-square f-18 ml-r-12"
          aria-hidden="true"
          routerLinkActive="ml-9"
        ></i>

        Exceptions
      </div>
      <ul *ngIf="openBooleanException" class="showHide f-13">
        <li
          class="navLink"
          id="ex_sub"
          [routerLink]="'ExceptionManagement'"
          [ngClass]="
            router.url == '/customer/ExceptionManagement'
              ? 'active'
              : 'navLink'
          "
        >
          Vendor Based
        </li>
        <li
          class="navLink"
          id="ex_sub"
          [routerLink]="'ExceptionManagement/Service_ExceptionManagement'"
          [ngClass]="
            router.url ==
            '/customer/ExceptionManagement/Service_ExceptionManagement'
              ? 'active'
              : 'navLink'
          "
        >
          Service Based OCR
        </li>
      </ul>

      <div
        *ngIf="financeapproveDisplayBoolean"
        class="navLink"
        routerLinkActive="active"
        data-text="Approval"
        [routerLink]="'approved'"
      >
        <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
          verified
        </span>
        Approval
      </div>
      <div
        class="navLink"
        routerLinkActive="active"
        data-text="Create GRN"
        [routerLink]="'Create_GRN_inv_list'"
        *ngIf="GRNPageAccess"
      >
        <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
          verified
        </span>
        Create GRN
      </div>
        
        <div
          class="navLink"
          routerLinkActive="active"
          data-text="Document Status"
          [routerLink]="'invoice'"
        >
          <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
            receipt
          </span>
          Document Status
        </div>
        <div
          class="navLink"
          routerLinkActive="active"
          data-text="Summary"
          [routerLink]="'documentSummary'"
        >
          <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
            receipt
          </span>
          Summary
        </div>

        
        <div
          class="navLink"
          routerLinkActive="active"
          data-text="Vendors"
          [routerLink]="'vendor'"
          *ngIf="vendor_SP_PageAccess"
          (click)="sideMenuVendor()"
        >
          <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
            business_center
          </span>
          Vendors
        </div>
        <ul *ngIf="openBooleanVendor" class="showHide f-13">
          <li
            class="navLink"
            id="ex_sub"
            [routerLink]="'vendor'"
            [ngClass]="router.url == '/customer/vendor' ? 'active' : 'navLink'"
          >
            List
          </li>
          <li
            class="navLink"
            id="ex_sub"
            [routerLink]="'vendor/item_master'"
            [ngClass]="
              router.url.includes('item_master') ? 'active' : 'navLink'
            "
          >
            Item Master
          </li>
        </ul>

        <div
          class="navLink"
          [routerLink]="'serviceProvider'"
          routerLinkActive="active"
          data-text="Service Provider"
          (click)="showInner()"
          *ngIf="vendor_SP_PageAccess"
        >
          <i
            class="fa fa-user-secret f-18 ml-r-12"
            aria-hidden="true"
            routerLinkActive="ml-9"
          ></i>
          Service Provider
        </div>
        <ul *ngIf="openBoolean" class="showHide f-13">
          <li
            class="navLink"
            id="sp_sub"
            [routerLink]="'serviceProvider'"
            [ngClass]="
              router.url == '/customer/serviceProvider' ? 'active' : 'navLink'
            "
          >
            List
          </li>
          <li
            class="navLink"
            id="sp_sub"
            [routerLink]="'serviceProvider/UtilityInvokeBatch'"
            [ngClass]="
              router.url == '/customer/serviceProvider/UtilityInvokeBatch'
                ? 'active'
                : 'navLink'
            "
          >
            Utility Invoice Batch
          </li>
          
          <li
            *ngIf="isAGIUser"
            class="navLink"
            id="sp_sub"
            [routerLink]="'serviceProvider/EtisalatCostAllocation'"
            [ngClass]="
              router.url == '/customer/serviceProvider/EtisalatCostAllocation'
                ? 'active'
                : 'navLink'
            "
          >
            Upload Etisalat
          </li>
        </ul>

        <div
          *ngIf="addUsersBoolean"
          class="navLink"
          data-text="Roles"
          routerLinkActive="active"
          [routerLink]="'roles'"
        >
          <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
            manage_accounts
          </span>
          Roles
        </div>

        <div *ngIf="!isDesktop" class="navLink" data-text="Change Password" (click)="openDialog()">
          <i
            class="fa fa-lock f-18 ml-r-12"
            aria-hidden="true"
            routerLinkActive="ml-9"
          ></i>
          Change Password
        </div>

        <div
          *ngIf="!isDesktop"
          class="navLink"
          data-text="Logout"
          (click)="displayResponsivepopup = true"
        >
          <i
            class="fa fa-sign-out f-18 ml-r-12"
            aria-hidden="true"
            routerLinkActive="ml-9"
          ></i>
          Logout
        </div>

        <div
          class="navLink"
          routerLinkActive="active"
          data-text="Settings"
          [routerLink]="'settings'"
          *ngIf="settingsPageAccess"
        >
          <i
            class="fa fa-cogs f-18 ml-r-12"
            aria-hidden="true"
            routerLinkActive="ml-9"
          ></i>
          Settings
        </div>
      </div>

      <div class="copy">Copyrights <span class="cpr f-14">&#169;</span> 2023</div>
    </div>
  </mat-drawer>

  <div class="example-sidenav-content">
    <div class="bodyContent">
      <div class="d_desk1">
        <i
          class="fa fa-bars menu_icon f-16"
          aria-hidden="true"
          (click)="drawer.toggle()"
        ></i>
        <div class="f-right d_desk">
          <span class="userName last_log f-11">
            <span>Last login :&nbsp; </span>
            {{ last_login1 + "Z" | date: "medium" }} <span style="color: crimson;">{{timezone}}</span> &nbsp; &nbsp;
          </span>
          <span
            class="b-right"
            style="padding-right: 14px; cursor: pointer"
            [routerLink]="'notifications'"
          >
            <i
              class="pi pi-bell p-mr-4 p-text-secondary f-18"
              [value]="numberOfNotify"
              pBadge
            ></i
          ></span>

          <span class="ml-2 dropdown">
            <i class="fa fa-user-circle-o profileIcon" aria-hidden="true"></i>
          </span>
          <span class="b-left-sky clr userName"
            >{{ userDetails.userdetails.firstName }}
          </span>
          <span (clickOutside)="onClickedOutside($event)">
            <mat-icon
              class="sign_out dropdown"
              svgIcon="logout"
              title="Sign Out"
              (click)="isActive()"
            ></mat-icon>
            <div
              [ngStyle]="{ display: showLogout ? 'block' : 'none' }"
              class="dropdown-content"
            >
              <a (click)="openDialog()">Change Password</a>
              <a (click)="displayResponsivepopup = true">Logout</a>
            </div>
          </span>
        </div>
      </div>

      <nav class="d_mobile1">
        <section class="pos_rel" *ngIf="menubarBoolean">
          <div class="routeLinks route_mobile">
            <div
              class="navLink"
              routerLinkActive="active"
              data-text="Dashboard"
              [routerLink]="'home'"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                home
              </span>
              Dashboard
            </div>

            <div
              *ngIf="uploadPermissionBoolean"
              class="navLink"
              routerLinkActive="active"
              data-text="Upload"
              [routerLink]="'uploadInvoices'"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                upload_file
              </span>
              Upload
            </div>

            <div
              class="navLink"
              routerLinkActive="active"
              data-text="Summary"
              [routerLink]="'documentSummary'"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                receipt
              </span>
              Summary
            </div>

            <div
              class="navLink"
              routerLinkActive="active"
              data-text="Document Status"
              [routerLink]="'invoice'"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                receipt
              </span>
              Document Status
            </div>

            <div
              class="navLink"
              routerLinkActive="active"
              data-text="Exceptions"
              [routerLink]="'ExceptionManagement'"
              (click)="showInnerException()"
              *ngIf="excpetionPageAccess"
            >
              <i
                class="fa fa-pencil-square-o f-18 ml-r-12"
                aria-hidden="true"
                routerLinkActive="ml-9"
              ></i>

              Exceptions
            </div>
            <ul *ngIf="openBooleanException" class="showHide f-13">
              <li
                class="navLink"
                id="ex_sub"
                [routerLink]="'ExceptionManagement'"
                [ngClass]="
                  router.url == '/customer/ExceptionManagement'
                    ? 'active'
                    : 'navLink'
                "
              >
                Vendor Based
              </li>
              <li
                class="navLink"
                id="ex_sub"
                [routerLink]="'ExceptionManagement/Service_ExceptionManagement'"
                [ngClass]="
                  router.url ==
                  '/customer/ExceptionManagement/Service_ExceptionManagement'
                    ? 'active'
                    : 'navLink'
                "
              >
                Service Based OCR
              </li>
            </ul>

            <div
              *ngIf="financeapproveDisplayBoolean"
              class="navLink"
              routerLinkActive="active"
              data-text="Approval"
              [routerLink]="'approved'"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                verified
              </span>
              Approval
            </div>
            <div
              class="navLink"
              routerLinkActive="active"
              data-text="Create GRN"
              [routerLink]="'Create_GRN_inv_list'"
              *ngIf="GRNPageAccess"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                verified
              </span>
              Create GRN
            </div>
            <div
              class="navLink"
              routerLinkActive="active"
              data-text="Vendors"
              [routerLink]="'vendor'"
              *ngIf="vendor_SP_PageAccess"
              (click)="sideMenuVendor()"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                business_center
              </span>
              Vendors
            </div>
            <ul *ngIf="openBooleanVendor" class="showHide f-13">
              <li
                class="navLink"
                id="ex_sub"
                [routerLink]="'vendor'"
                [ngClass]="
                  router.url == '/customer/vendor' ? 'active' : 'navLink'
                "
              >
                List
              </li>
              <li
                class="navLink"
                id="ex_sub"
                [routerLink]="'vendor/item_master'"
                [ngClass]="
                  router.url.includes('item_master') ? 'active' : 'navLink'
                "
              >
                Item Master
              </li>
            </ul>

            <div
              class="navLink"
              [routerLink]="'serviceProvider'"
              routerLinkActive="active"
              data-text="Service Provider"
              (click)="showInner()"
              *ngIf="vendor_SP_PageAccess"
            >
              <i
                class="fa fa-user-secret f-18 ml-r-12"
                aria-hidden="true"
                routerLinkActive="ml-9"
              ></i>
              Service Provider
            </div>
            <ul *ngIf="openBoolean" class="showHide f-13">
              <li
                class="navLink"
                id="sp_sub"
                [routerLink]="'serviceProvider'"
                [ngClass]="
                  router.url == '/customer/serviceProvider'
                    ? 'active'
                    : 'navLink'
                "
              >
                List
              </li>
              <li
                class="navLink"
                id="sp_sub"
                [routerLink]="'serviceProvider/UtilityInvokeBatch'"
                [ngClass]="
                  router.url == '/customer/serviceProvider/UtilityInvokeBatch'
                    ? 'active'
                    : 'navLink'
                "
              >
                Utility Invoice Batch
              </li>
              
              <li
                *ngIf="isAGIUser"
                class="navLink"
                id="sp_sub"
                [routerLink]="'serviceProvider/EtisalatCostAllocation'"
                [ngClass]="
                  router.url ==
                  '/customer/serviceProvider/EtisalatCostAllocation'
                    ? 'active'
                    : 'navLink'
                "
              >
                Upload Etisalat
              </li>
            </ul>

            <div
              *ngIf="addUsersBoolean"
              class="navLink"
              data-text="Roles"
              routerLinkActive="active"
              [routerLink]="'roles'"
            >
              <span class="material-icons f-18 ml-r-12" routerLinkActive="ml-9">
                manage_accounts
              </span>
              Roles
            </div>

            <div
              class="navLink"
              routerLinkActive="active"
              data-text="Settings"
              [routerLink]="'settings'"
              *ngIf="settingsPageAccess"
            >
              <i
                class="fa fa-cogs f-18 ml-r-12"
                aria-hidden="true"
                routerLinkActive="ml-9"
              ></i>
              Settings
            </div>
            <div
              class="navLink"
              data-text="Change Password"
              (click)="openDialog()"
            >
              <i
                class="fa fa-lock f-18 ml-r-12"
                aria-hidden="true"
                routerLinkActive="ml-9"
              ></i>
              Change Password
            </div>

            <div
              class="navLink"
              data-text="Logout"
              (click)="displayResponsivepopup = true"
            >
              <i
                class="fa fa-sign-out f-18 ml-r-12"
                aria-hidden="true"
                routerLinkActive="ml-9"
              ></i>
              Logout
            </div>
          </div>
        </section>
      </nav>
      <router-outlet></router-outlet>
    </div>
  </div>
</mat-drawer-container> -->

<div class="layout">
  <nav class="desktop d-flex justify-content-between">
    <div class="d-flex" style="gap: 64px;">
      <div class="logo">
        <img src="assets/Serina Assets/new_theme/logo.png" alt="Serina logo" />
      </div>
      <div class="navLinks d-flex justify-content-between">
        <div
          class="nav_Link f-14"
          [routerLink]="'home'"
          routerLinkActive="active_cls"
        >
          Dashboard
        </div>
        <div
          class="nav_Link f-14"
          [routerLink]="'uploadInvoices'"
          routerLinkActive="active_cls"
          *ngIf="uploadPermissionBoolean"
        >
          Upload
        </div>
  
        <div
          class="nav_Link f-14"
          routerLinkActive="active_cls"
          [routerLink]="'ExceptionManagement'"
          (click)="showInnerException()"
          *ngIf="excpetionPageAccess"
        >
          Exceptions
        </div>
        <div
          class="nav_Link f-14"
          [routerLink]="'Create_GRN_inv_list'"
          routerLinkActive="active_cls"
          *ngIf="GRNPageAccess"
        >
          Create GRN
        </div>
        <div
          class="nav_Link f-14"
          [routerLink]="'invoice'"
          routerLinkActive="active_cls"
        >
          Document Status
        </div>
        <div
          class="nav_Link f-14"
          [routerLink]="'GRN_approvals'"
          routerLinkActive="active_cls"
          *ngIf="GRNApprovalAccess"
        >
          Approvals
        </div>
        <div style="position: relative;" (clickOutside)="onClickedOutside($event,'more')">
          <div class="nav_Link f-14" (click)="more_routes()"
          [ngClass]="{
            'active_cls': router.url.includes('/customer/vendor/') || router.url.includes('serviceProvider') || router.url.includes('documentSummary') || router.url.includes('roles')
          }"
          >
            {{more_text}} <span class="material-icons more_pos" >
              {{ more_icon}}
              </span>
              
          </div>
          <div
              [ngStyle]="{ display: more_icon == 'expand_less' ? 'block' : 'none' }"
              class="dropdown-content"
            >
              <a [routerLink]="'documentSummary'" routerLinkActive="active_sub_cls" (click)="onMenuChange('Summary')">Summary</a>
              <a [routerLink]="'vendor'" routerLinkActive="active_sub_cls" (click)="onMenuChange('Vendors & Service Providers')" *ngIf="vendor_SP_PageAccess">Vendors & Service Providers</a>
              <a [routerLink]="'roles'" routerLinkActive="active_sub_cls" (click)="onMenuChange('Roles')" *ngIf="addUsersBoolean">Roles</a>
            </div>
        </div>
        
        <!-- <div >
          <div
          class="nav_Link f-14"
          [routerLink]="'vendor'"
          routerLinkActive="active_cls"
          *ngIf="vendor_SP_PageAccess"
        >
          Vendors
        </div>
        <div
          class="nav_Link f-14"
          [routerLink]="'serviceProvider'"
          routerLinkActive="active_cls"
          *ngIf="vendor_SP_PageAccess"
        >
          Service Providers
        </div>
        <div
          class="nav_Link f-14"
          [routerLink]="'roles'"
          routerLinkActive="active_cls"
          *ngIf="addUsersBoolean"
        >
          Roles
        </div>
        </div> -->
      </div>
    </div>

    <div class="d-flex align-items-center ">
      <div class="user_d f-13">Card
        <span class="portal">
        <label class="switch">
          <input
            type="checkbox"
            [checked]="isTableView"
            (change)="onChangeUI($event.target.checked)"
          />
          <span class="slider round"></span>
        </label>
      </span> Table
    </div>
      <div class="short_name f-12">{{name_short}}</div>
      <div class="f-12 mr-1">{{ userDetails.userdetails.firstName }}</div>
      <div  (clickOutside)="onClickedOutside($event,'logout')">
        <mat-icon
          class="sign_out dropdown"
          svgIcon="logout"
          title="Sign Out"
          (click)="isActive()"
        ></mat-icon>
        <div
          [ngStyle]="{ display: showLogout ? 'block' : 'none' }"
          class="dropdown-content"
        >
        
          <a (click)="openDialog()">Change Password</a>
          <a (click)="confirm_pop()">Logout</a>
        </div>
    </div>
    </div>
    
  </nav>

  <section id="body_content">
    <router-outlet></router-outlet>
  </section>
</div>

<!-- Cnfirmation Dialog -->
<!-- <p-dialog
  header=" "
  [(visible)]="displayResponsivepopup"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '30vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div class="text-center">
    <div>
      <i
        class="pi pi-exclamation-triangle"
        style="font-size: 4rem; color: red"
      ></i>
    </div>
    <div class="deleteDivText">
      {{ BtnText }}
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center mb-3">
      <button class="btn btnVender bg-btn-success mr-3" (click)="logout()">
        Yes
      </button>
      <button
        class="btn btnVender bg-btn-cancel"
        (click)="displayResponsivepopup = false"
      >
        Cancel
      </button>
    </div>
  </ng-template>
</p-dialog> -->

<p-toast></p-toast>
