// floating-label.directive.ts
import { Directive, ElementRef, HostListener, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appFloatingLabel]',
})
export class FloatingLabelDirective implements AfterViewInit {
  private inputElement: HTMLInputElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.inputElement = this.el.nativeElement.querySelector('input');
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.toggleLabel(value);
  }

  @HostListener('blur')
  onBlur(): void {
    const value = this.inputElement.value;
    this.toggleLabel(value);
  }

  @HostListener('focusout', ['$event'])
  onFocusOut(event: FocusEvent): void {
    if (event.relatedTarget && (event.relatedTarget as Element).nodeName === 'P-AUTOCOMPLETE') {
      // Avoid handling the blur event when moving focus to another p-autocomplete
      return;
    }

    const value = this.inputElement.value;
    this.toggleLabel(value);
  }

  private toggleLabel(value: any): void {
    const label = this.el.nativeElement.previousElementSibling;

    if (value && (typeof value === 'string' || value instanceof String) && value.trim() !== '') {
      this.renderer.addClass(label, 'floating');
    } else {
      this.renderer.removeClass(label, 'floating');
    }
  }
}
