<!-- <h6 class="headh6 mb-0">Business Reports</h6> -->

<div
  style="overflow: hidden; min-height: 85vh;"
>
  <ul class="nav nav-pills centerClass">
    <div class="m-200 pipe">|</div>
    <li class="nav-item mr-1">
      <a
        class="nav-link f-14"
        [class.active-pill]="viewType == 'vendor'"
        (click)="choosepageTab('vendor')"
      >
        Vendor Based</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link f-14"
        [class.active-pill]="viewType == 'service'"
        (click)="choosepageTab('service')"
      >
        Service Based</a
      >
    </li>
    <div class="pipe" style="left: 0;">|</div>
  </ul>

    <ng-container [ngSwitch]="viewType">
      <!-- vendor invoices Tab -->
      <ng-container *ngSwitchCase="'vendor'">
        <app-vendor-based-charts></app-vendor-based-charts>
      </ng-container>

      <!-- Service invoices Tab -->
      <ng-container *ngSwitchCase="'service'">
        <app-service-based-charts></app-service-based-charts>
      </ng-container>
    </ng-container>
</div>
