<!-- <h6 class="headh6">Document Summary</h6> -->

<div class="s_p_body">
    <div class="d-flex justify-content-between p_head">
      <ul class="nav nav-tabs nav_sub" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="vendor-tab"
            data-toggle="tab"
            href="#vendor"
            role="tab"
            aria-controls="vendor"
            aria-selected="true"
            (click)="onTabChange('vendor')"
            > <span class="material-icons f-18 font_weight100">
              person_outline
              </span>
               Vendor Based</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="service-tab"
            data-toggle="tab"
            href="#service"
            role="tab"
            aria-controls="service"
            aria-selected="true"
            (click)="onTabChange('service')"
            > <span class="material-icons f-18 font_weight100">
              person_outline
              </span> Service Based</a
          >
        </li>
      </ul>

      <div class="f-12 nav_sub c_gap"> <span>Total Pages Count</span> <span class="f-22">{{ tabName == 'vendor'? totalSuccessPages : totalSuccessPagesSP }}</span></div>
      <div class="f-12 nav_sub c_gap"> <span>Total Invoices</span> <span class="f-22">{{ tabName == 'vendor'? totalInvoices : totalInvoicesSP }}</span></div>
      <div class="f-12 nav_sub c_gap"> <span>OCR Pages Count</span> <span class="f-22">{{ tabName == 'vendor'? totalSuccessPages : totalSuccessPagesSP }}</span></div>
      <div class="nav_sub">
          <div class="filter_box">
            <label for="uni" class="f-12">
              Search Universal <br />
              <input
                [(ngModel)]="searchText"
                type="text"
                id="uni"
                class="textFilter f-13"
                [placeholder]="search_placeholder"
              />
            </label>
          </div>
          <p-calendar
          [(ngModel)]="rangeDates"
          selectionMode="range"
          [minDate]="minDate"
          [maxDate]="maxDate"
          showButtonBar="true"
          (onClearClick)="clearDates()"
          placeholder="Select dates to Filter"
          [readonlyInput]="true"
          (onSelect)="filterData(rangeDates)"
          [showIcon]="true"
          inputId="range"
        ></p-calendar>
      </div>
    </div>
  <!-- <div class="down_filter">
    <div>
      <button class="btnUpload dwnload_btn " (click)="downloadReport()">
        Download Report
      </button>
    </div>
    <div class="selectFilter mr-2">
      <select
        class="f-12 form-control font_weight"
        (change)="selectEntityFilter($event.target.value)"
      >
        <option value="">Select Entity</option>
        <option value="ALL" selected>ALL</option>
        <option *ngFor="let entity of entity" [value]="entity.idEntity">
          {{ entity.EntityName }}
        </option>
      </select>
    </div>
    
  </div> -->

  <div
    class="tab-content"
    id="myTabContent"
  >
    <div
      class="tab-pane fade show active"
      id="vendor"
      role="tabpanel"
      aria-labelledby="vendor-tab"
    >
      <app-exception-table
        [invoiceColumns]="summaryColumn"
        [columnsToDisplay]="summaryColumnField"
        [showPaginatorAllInvoice]="showPaginatorSummary"
        [ColumnLength]="ColumnLengthVendor"
        [columnsData]="customerSummary"
        [searchText]="searchText"
      >
      </app-exception-table>
    </div>

    <div
      class="tab-pane fade"
      id="service"
      role="tabpanel"
      aria-labelledby="service-tab"
    >
      <app-exception-table
      [invoiceColumns]="summaryColumnSP"
      [columnsToDisplay]="summaryColumnFieldSP"
      [showPaginatorAllInvoice]="showPaginatorSummarySP"
      [ColumnLength]="ColumnLengthSP"
      [columnsData]="customerSummarySP"
      [searchText]="searchText"
    >
    </app-exception-table>
    </div>
  </div>
</div>

<p-toast></p-toast>
