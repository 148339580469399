import { AlertService } from './../../../services/alert/alert.service';
import { ExceptionsService } from './../../../services/exceptions/exceptions.service';
import { AuthenticationService } from './../../../services/auth/auth-service.service';
import { DataService } from './../../../services/dataStore/data.service';
import { Subscription, throwError } from 'rxjs';
import { PermissionService } from './../../../services/permission.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/services/shared.service';
import { TaggingService } from './../../../services/tagging.service';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { FormBuilder,NgForm } from '@angular/forms';
import { FormCanDeactivate } from '../../can-deactivate/form-can-deactivate';
import { SettingsService } from 'src/app/services/settings/settings.service';
import IdleTimer from '../../idleTimer/idleTimer';
import * as fileSaver from 'file-saver';
import { PopupComponent } from '../../popup/popup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { catchError, map, take } from 'rxjs/operators';
import { ConfirmationComponent } from '../../confirmation/confirmation.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CanUploadComponentDeactivate } from '../UnsavedChanges.guard';
import { Calendar } from 'primeng/calendar';
import { HttpEventType } from '@angular/common/http';
import { SupportpdfViewerComponent } from '../supportpdf-viewer/supportpdf-viewer.component';
export interface getApproverData {
  EntityID: number,
  EntityBodyID?: number,
  DepartmentID?: number,
  categoryID?: number,
  approver?: any[],
  description?: string
}
@Component({
  selector: 'app-comparision3-way',
  templateUrl: './comparision3-way.component.html',
  styleUrls: [
    './comparision3-way.component.scss',
    '../../invoice/view-invoice/view-invoice.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Comparision3WayComponent
  extends FormCanDeactivate
  implements OnInit, CanUploadComponentDeactivate {
  @ViewChild('form')
  form: NgForm;
  editable: boolean;
  inputData = [];
  vendorData = [];
  lineDisplayData: any;
  Itype: string;
  updateInvoiceData:any;
  headerName: string;
  editPermissionBoolean: boolean;
  changeApproveBoolean: boolean;
  financeApproveBoolean: boolean;
  fin_boolean: boolean = false;
  submitBtn_boolean: boolean;
  approveBtn_boolean: boolean;
  invoiceID: any;
  routeIdCapture: Subscription;
  rejectionComments: string = '';
  rejectReason: any;
  grnCreateBoolean: boolean = false;
  GRNObject = [];
  GRNObjectDuplicate = [];

  isPdfAvailable: boolean = true;
  userDetails: any;
  showPdf: boolean = true;
  btnText = 'Close';
  selectedPONumber;
  poList = [];
  filteredPO: any[];
  currentTab = 'header';
  lineItems: any;
  vendorAcId: any;
  financeapproveDisplayBoolean: boolean;
  displayrejectDialog: boolean;
  timer: any;
  callSession: any;
  invoiceNumber = '';
  invoiceDescription:string;
  vendorName: any;
  isGRNDataLoaded: boolean;
  content_type: any;
  lineTabBoolean: boolean;
  grnLineCount: any;

  addrejectcmtBool: boolean;
  isAdmin: boolean;
  GRN_PO_Bool: boolean;
  GRN_PO_Data = [];
  GRN_PO_tags = [];
  PO_GRN_Number_line = [];
  summaryColumn = [
    { field: 'PackingSlip', header: 'GRN Number' },
    { field: 'GRNField', header: 'GRN Field' },

  ];
  validateUnitpriceBool: boolean;
  grnList = [];
  selectedGRNList = [];
  selectedGRNLines = [];
  currentlyOpenedItemIndex = -1;
  GRNTabData: any;
  grnTabDatalength: number;
  batchData: any;
  progressDailogBool: boolean;
  portalName: string;
  GRNDialogBool: boolean;
  headerpop: string;
  descrptonBool = false;
  poDocId: any;
  po_num: any;
  subStatusId: any;
  statusId: number;
  isAmtStr: boolean;
  flipEnabled: boolean;
  docType: any;
  documentType: string;
  lineTable = [
    { header: 'S.No', field: '' },
    { header: 'Description', field: 'PODescription' },
    { header: 'PO quantity', field: 'PurchQty'},
    { header: 'UnitPrice', field: 'GRNUnitPrice' },
    { header: 'Inv - Quantity', field: 'Quantity' },
    { header: 'GRN - Quantity', field: 'GRNQuantity' },
    { header: 'AmountExcTax', field: 'GRNAmountExcTax' },
    { header: 'PO balance quantity', field: 'RemainPurchPhysical'},
    { header: 'Comments', field: '' }
  ];
  documentViewBool: boolean;
  isDesktop: boolean;
  APIResponse: any;
  isEmpty: boolean;
  filteredPOLine = [];
  isBatchFailed: boolean;
  batch_count = 0;

  sampleLineData = [];
  p_width: string;
  poNumbersList: any;
  vendorId: any;
  activePOId: string;
  poDate: any;
  displayYear;
  minDate: Date;
  maxDate: Date;
  lastYear: number;
  months: string[];
  selectedMonth: string;
  rangeDates: Date;
  searchPOArr: any;
  status_arr: any[];
  status_change_op: any;
  rejectModalHeader: string;
  GRNUploadID: any;
  reuploadBoolean: boolean;
  isServiceData: boolean;
  uploadtime: string = "00:00";
  doc_status: string;
  vendorUploadBoolean: boolean;
  pageType: string;
  item_code: any;
  GRN_line_total: number = 0;
  invoice_subTotal: any;
  po_total: any;
  enable_create_grn: boolean;
  uploadCompleted: boolean = true;
  filterGRNlist: any[];
  @ViewChild('datePicker') datePicker: Calendar;
  progress: number;
  uploadFileList = [];
  support_doc_list = [];
  approval_selection_boolean: boolean;
  documentInvType: any;
  isLCMInvoice: boolean;
  multiPOBool: boolean;
  isLinenonEditable: boolean;
  supportTabBoolean: boolean;
  isLCMTab: boolean;
  selectionTabBoolean: boolean;
  isLCMCompleted: boolean;
  costAllocation = [];
  allocationFileds = [];
  ERP: string;
  isRejectCommentBoolean: boolean;
  isApproveCommentBoolean: boolean;
  isLCMSubmitBoolean: boolean;
  BtnpopText: string;
  preApproveBoolean = false;
  approval_setting_boolean: boolean;
  DepartmentList: any;
  approversSendData: getApproverData[] = [];
  selectedDepartment: string;

  LCMTable = [
    { name: "Entity", id: 'drp', field: 'EntityName', data: [''] },
    { name: "PO Number", id: 'drp', field: 'PoDocumentId', data: [''] },
    { name: "PO Line Descrption", id: 'drp', field: 'PoLineDescription', data: [''] },
    { name: "Voyage Number", id: 'drp', field: 'VoyageNumber', data: [''] },
    { name: "Charges code", id: 'drp', field: 'CostCategory', data: [''] },
    { name: "Vessel Number", id: 'drp', field: 'AGIVesselNumber', data: [''] },
    { name: "Estimated value", id: 'text', field: 'EstimatedValue', data: [''] },
    { name: "Actualized value", id: 'text', field: 'ActualizedValue', data: [''] },
    { name: "Allocate", id: 'text', field: 'Allocate', data: [''] },
  ]
  LCMDataTable = [];
  approverList: any;
  categoryList: any;
  LCMObj = {
    EntityName: '',
    PoDocumentId: '',
    PoLineDescription: '',
    PoLineNumber: '',
    VoyageNumber: '',
    CostCategory: '',
    EstimatedValue: '',
    ActualizedValue: '',
    AGIVesselNumber: '',
    Allocate: '',
    ContextTableId: '',
    ContextRecId: '',
    MarkupTransRecId: ''
  }

  @ViewChild('LCMLineForm') LCMLineForm: NgForm;
  POlist_LCM = [];
  filteredEnt: any[];
  filteredLCMLines: any[];
  filteredVoyage: any[];
  filteredCost: any[];
  LCMItems = [];
  voyageList: any;
  selectedLCMLine: any;
  selectedVoyage: any;
  selectedEntity: any;
  max_allocation: number;
  est_val: number;
  act_val: number;
  AGIVesselNumber: string;
  selectedCost: any;
  chargeList: any;
  EntityName: any;
  itemId: any;
  lineNumber: any;
  ContextTableId: any;
  ContextRecId: any;
  uploadExcelValue: any;
  MarkupTransRecId: any;
  commentslabelBool = true;
  allocateTotal: any;
  balanceAmount: any;
  invoiceTotal: any;
  inv_line_total: number;
  entityList: any;
  entityName: any;
  commentsBool: boolean = true;
  selected_GRN_total: number;
  tagNameArr: any;

  userList_approved = [];
  rejectionUserId: number = 0;
  approvalRejectRecord = [];
  message: any;

  serviceProvidercode: any;
  reqServiceprovider: boolean = false;
  isFormValid: boolean = true;
  dynamicdata = [];
  normalCostAllocation: boolean;
  costTabBoolean: boolean = false;
  isEditMode = false;
  dynamicAllocationFileds = [
    // 
    { header: 'Project Category', field: 'bu_code' },
    // { header: 'Company Code', field: 'company_code' },
    // { header: 'Created On', field: 'created_on' },
    // { header: 'Document ID', field: 'documentID' },
    // { header: 'Driver Name', field: 'driver_name' },
    { header: 'Employee Code', field: 'emp_code' },
    { header: 'Employee Name', field: 'emp_name' },
    // { header: 'Entity Name', field: 'entity_name' },
    { header: 'Project ID', field: 'gl_code' },
    // { header: 'Allocation ID', field: 'iddynamiccostallocation' },
    { header: 'Service ID', field: 'item_number' },
    { header: 'VAT Group', field: 'segment' },
    { header: 'Amount', field: 'amount' },
    { header: 'Tax amount', field: 'calculatedtax' },
    // { header: 'Sub Ledger', field: 'subledger' },
  ]

  editedValues: { [key: string]: any } = {};

  editedData: any = {};
  rows: any[] = [this.getNewRow()];
  lineData: any;
  priceData: any;
  totalPoCost: any;
  totalInvCost: any;
  invTypeList: any[];
  isBoxOpen: boolean = false;
  activeTab: string = 'percentage';
  percentageData: string = '';
  amountData: string = '';
  isButtonDisabled: boolean = true;
  resultAmount: any;
  advanceAPIbody: any;
  grnAttachmentArray: any;
  isAprUser: boolean;
  totalTaxDynamic = 0;
  totalAmountDynamic = 0;
  ap_enabled_exc: boolean;
  projectIdArr: any;
  projectCArr: any;
  filteredProject: any[];
  temp_header_data: any[];
  temp_line_data: any[];
  batch_id: number;
  bulk_bool: boolean = false;
  client_name: string;
  isMoreRequired: boolean;
  moreInfoBool: boolean;
  grnNumber_enova: string;
  d_type: any;
  ent_code: string;
  filteredPreData: any[];
  invNumbersList: any;
  mappingForCredit = false;
  rectData: any;
  isManpower: boolean;
  manpower_metadata = [];
  manpowerTableHeaders: { header: string; field: string; }[];
  isManpowerTags: boolean;
  manPowerGRNData: any;
  manPowerAPI_request: any;
  manpowerHeaderId: any;
  po_qty_array: any;
  po_balance_qty_array: any;
  disable_save_btn: boolean = false;
  saveDisabled: boolean;
  grnTooltip: string;
  headerData = [];
  lineTableHeaders: string[];
  isDraft: boolean;
  serverError: boolean;
  fieldName: any;
  decimal_count:number;
  lineTooltip: string = 'Shows the total amount, calculated as Quantity × Unit Price - Discount(value/percentage), for the line item.';
  configData: any;
  flipPOData: any;
  invoiceDate: Date | null = null;
  selectALL_grn_lines: boolean = false;
  fieldType: any = [
    { id:1, name:'JournalName', type:'Dropdown' },
    { id:2, name:'ReceivingOperatingUnit', type:'Dropdown' },
    { id:3, name:'MainAccount', type:'Dropdown' },
    { id:4, name:'Description', type:'FreeText' },
    { id:5, name:'Department', type:'Dropdown' },
    { id:6, name:'Segments', type:'Dropdown' },
    { id:7, name:'Product', type:'Dropdown' },
    { id:8, name:'BSMovements', type:'Dropdown' },
    { id:9, name:'BSLocation', type:'Dropdown' },
    { id:10, name:'InterCompany', type:'Dropdown' },
    { id:11, name:'FixedAssetDepartment', type:'Dropdown' },
    { id:12, name:'FixedAssetGroup', type:'Dropdown' },
    { id:13, name:'SalesTaxGroup', type:'Dropdown' },
    { id:14, name:'ItemSalesTaxGroup', type:'Dropdown' },
    { id:15, name:'BankAccount', type:'Dropdown' },
    { id:16, name:'OffsetCompany', type:'Dropdown' },
    { id:16, name:'JournalTransactionDate', type:'Date' },
    { id:17, name:'InvoiceRecievedinEmmar', type:'Date' }
 ];
  old_tag: any;
  fieldData: any;
  temp_line_data_non_po: any;
  updatedNonPOLine: { tag_id: any; tag_name: any; prev_value: any; curr_value: any; };
  prePayData: any;

  constructor(
    public tagService: TaggingService,
    public router: Router,
    private authService: AuthenticationService,
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    private exceptionService: ExceptionsService,
    private AlertService: AlertService,
    private SpinnerService: NgxSpinnerService,
    private permissionService: PermissionService,
    private dataService: DataService,
    private settingService: SettingsService,
    private SharedService: SharedService,
    private mat_dlg: MatDialog,
    private datePipe: DatePipe
  ) {
    super();
    this.exceptionService.getMsg().pipe(take(2)).subscribe((msg) => {
      if (msg == 'mapping') {
        this.getInvoiceFulldata('');
      }
    })
  }

  ngOnInit(): void {
    this.configData = this.dataService?.configData;
    this.ERP = this.configData?.erpname;
    this.client_name = this.configData?.client_name;
    this.decimal_count = this.configData?.miscellaneous?.No_of_Decimals;
    if(!this.decimal_count){
      this.decimal_count = 2;
     }
    if (this.client_name == 'SRG') {
      this.mappingForCredit = true;
    } 
    const commonTags = [
      { header: 'S.No', field: '' },
      { header: 'Description', field: 'Name' },
      { header: 'PO quantity', field: 'PurchQty'},
      { header: 'UnitPrice', field: 'UnitPrice' }, 
      { header: 'GRN - Quantity', field: 'GRNQty' },
      { header: 'AmountExcTax', field: 'GRNAmountExcTax' },
      { header: 'PO balance quantity', field: 'RemainPurchPhysical'},
      // { header: 'Actions', field:''}
    ];


    if (this.client_name == 'Cenomi') {
      commonTags.splice(6, 0, { header: 'PO Remaining %', field: 'percentage_po'});
    }
    this.GRN_PO_tags = [...commonTags];

    this.rejectReason = this.dataService.rejectReason;
    this.GRN_PO_Bool = this.dataService.grnWithPOBoolean;
    this.ent_code = this.dataService.ent_code;
    this.flipEnabled = true;
    // this.flipEnabled = this.dataService.configData.flipBool;
    this.userDetails = this.authService.currentUserValue;
    this.isDesktop = this.dataService.isDesktop;
    this.documentViewBool = this.isDesktop;
    this.enable_create_grn = this.permissionService.enable_create_grn;
    this.doc_status = this.dataService?.editableInvoiceData?.status;
    this.batch_id = this.dataService?.editableInvoiceData?.batch_id;
    this.activatedRoute.queryParams.subscribe(params => {
      this.uploadtime = params.uploadtime;
    });
    if (this.userDetails.user_type == 'vendor_portal') {
      this.portalName = 'vendorPortal';
    } else {
      this.portalName = 'customer'
    }
    this.getEntity();
    this.initialData();
    this.ERPCostAllocation();
    this.AddPermission();
    this.getDate();
    if(this.GRN_PO_Bool){
      this.lineTable = commonTags;
    } 
    this.isAdmin = this.dataService.isAdmin;

  }
  getPdfBool(event) {
    this.isPdfAvailable = event;
  }
  doc_view() {
    this.showPdf = true;
    this.documentViewBool = !this.documentViewBool
  }

  idleTimer(time, str) {
    this.timer = new IdleTimer({
      timeout: time, //expired after 180 secs
      clean: str,
      onTimeout: () => {
        if (this.router.url.includes('comparision-docs')) {
          this.router.navigate([`${this.portalName}/ExceptionManagement`]);
          this.error("Session expired for editing invoice");
        }
      },
    });
  }

  updateSessionTime() {
    let sessionData = {
      session_status: true,
      "client_address": JSON.parse(sessionStorage.getItem('userIp'))
    };
    this.exceptionService
      .updateDocumentLockInfo(sessionData)
      .subscribe((data: any) => { });
  }

  initialData() {
    this.routeIdCapture = this.activatedRoute.params.subscribe((params) => {
      this.SharedService.invoiceID = params['id'];
      this.exceptionService.invoiceID = params['id'];
      this.invoiceID = params['id'];
    });
    this.editable = this.tagService.editable;
    // this.fin_boolean = this.permissionService.financeApproveBoolean;
    if (this.router.url.includes('approvals') && this.permissionService.financeApproveBoolean) {
      this.fin_boolean = true;
    }

    this.submitBtn_boolean = this.tagService.submitBtnBoolean;
    this.approveBtn_boolean = this.tagService.approveBtnBoolean;
    this.approval_selection_boolean =
      this.tagService.approval_selection_boolean;
    this.isLCMInvoice = this.tagService.LCM_boolean;
    this.documentType = this.dataService.documentType;
    this.documentInvType = this.tagService.documentType;
    this.userDetails = this.authService.currentUserValue;
    // this.approvalType = this.tagService.approvalType;
    this.financeapproveDisplayBoolean =
      this.settingService.finaceApproveBoolean;
    this.subStatusId = this.dataService.subStatusId;
    this.statusId = this.dataService.statusId;


    if (
      this.router.url.includes('invoice/InvoiceDetails/vendorUpload') ||
      this.router.url.includes('invoice/InvoiceDetails/CustomerUpload')
    ) {
      this.vendorUploadBoolean = true;
      this.uploadCompleted = false;
    } else {
      this.vendorUploadBoolean = false;
    }
    if (this.router.url.includes('InvoiceDetails') || this.router.url.includes('comparision-docs')) {
      this.Itype = 'Invoice';
      if (this.editable && !['advance invoice', 'non-po', 'credit note'].includes(this.documentType) || this.mappingForCredit) {
        this.readLineItems();
      }
    } else if (this.router.url.includes('PODetails')) {
      this.Itype = 'PO';
    } else if (this.router.url.includes('GRNDetails')) {
      this.Itype = 'GRN';
    } else if (this.router.url.includes('serviceDetails')) {
      this.Itype = 'Service';
    }

    if (this.router.url.includes('Create_GRN_inv_list') || this.router.url.includes('GRN_approvals') || this.GRN_PO_Bool) {
      if (this.permissionService.GRNPageAccess == true) {
        this.grnCreateBoolean = true;
        this.showPdf = false;
        this.btnText = 'View invoice';
        this.currentTab = 'line';
        if (this.GRN_PO_Bool) {
          if(this.dataService.isEditGRN){
            this.tagService.headerName = 'Update GRN';
            this.Itype = 'GRN';
          } else {
            this.tagService.headerName = 'Create GRN with PO';
            this.Itype = 'PO';
          }
          
          this.getInvoiceFulldata_po();
        } else {
          if (this.router.url.includes('GRN_approvals')) {
            this.tagService.headerName = 'GRN Approval';
          } else {
            this.tagService.headerName = 'Create GRN with invoice';
          }
          this.readGRNInvData();
          this.Itype = 'Invoice';
          this.isPdfAvailable = false;

        }
      } else {
        alert('Sorry!, you do not have access');
        this.router.navigate(['customer/invoice/allInvoices']);
      }
    } else {
      this.getInvoiceFulldata('');
      if (this.tagService.editable == true && this.grnCreateBoolean == false) {
        this.updateSessionTime();
        this.idleTimer(180, 'Start');
        this.callSession = setTimeout(() => {
          this.updateSessionTime();
        }, 250000);
      }
    }
    this.routeOptions();
    if (this.fin_boolean) {
      this.getRejectionComments();
    }
    this.headerName = this.tagService.headerName;

  }

  //to add manpower metadata
  manpowerMetadataFunction() {
    const drf: MatDialogRef<ConfirmationComponent> = this.confirmFun("Please confirm whether you want to add manpower data in GRN?", "confirmation", "Confirmation")
    drf.afterClosed().subscribe((bool) => {
      if (bool) {
        this.open_dialog_comp("manpower_metadata");
        
      }
    })
  }

  routeOptions() {
    if (this.documentInvType == 'lcm' || this.documentInvType == 'multipo') {
      if (this.documentInvType == 'multipo') {
        this.multiPOBool = true;
        this.currentTab = "line";
      }
      this.isLinenonEditable = true;
    }
    if (this.approval_selection_boolean == true && this.isLCMInvoice == true) {
      this.supportTabBoolean = false;
      this.isLCMTab = true;
      this.readPONumbersLCM(this.dataService.entityID);
      this.showPdf = false;
      this.btnText = 'View invoice';
      this.currentTab = "LCM";

    } else if (this.approval_selection_boolean == true && this.isLCMInvoice == false) {
      this.readDepartment();
      this.readCategoryData();
      this.showPdf = false;
      this.btnText = 'View invoice';
      this.currentTab = "approver_selection";
      this.selectionTabBoolean = true;
      this.supportTabBoolean = true;
      this.isLCMCompleted = true;

    } else {
      this.selectionTabBoolean = false;
    }
  }
  // cost allocation selection based on ERP
  ERPCostAllocation() {
    if (this.ERP == 'JD') {
      this.allocationFileds = [
        { header: 'Element', field: 'Element' },
        { header: 'Business Unit', field: 'costCenter' },
        { header: 'Company Code', field: 'interco' },
        { header: 'Non Vat ItemCodes', field: 'fixedAssetDepartment' },
        { header: 'Vat ItemCode', field: 'fixedAssetGroup' },
        { header: 'Object Code', field: 'mainAccount' },
        { header: 'Element Factor', field: 'elementFactor' },
      ]
    } else if (this.ERP == 'Dynamics' || this.ERP == 'Oracle') {
      this.allocationFileds = [
        { header: 'Element', field: 'Element' },
        { header: 'Cost Center', field: 'costCenter' },
        { header: 'Product', field: 'product' },
        { header: 'Project', field: 'project' },
        { header: 'Interco', field: 'interco' },
        { header: 'Segments', field: 'segments' },
        { header: 'BSMovements', field: 'bsMovements' },
        { header: 'fixedAssetDepartment', field: 'fixedAssetDepartment' },
        { header: 'fixedAssetGroup', field: 'fixedAssetGroup' },
        { header: 'Main Account', field: 'mainAccount' },
        { header: 'Element Factor', field: 'elementFactor' },
      ]
    } else if (this.ERP == 'SAP') {
      this.allocationFileds = [
        { header: 'Element', field: 'Element' },
        { header: 'Cost Center', field: 'costCenter' },
        { header: 'Product', field: 'product' },
        { header: 'Project', field: 'project' },
        { header: 'Interco', field: 'interco' },
        { header: 'Segments', field: 'segments' },
        { header: 'BSMovements', field: 'bsMovements' },
        { header: 'fixedAssetDepartment', field: 'fixedAssetDepartment' },
        { header: 'fixedAssetGroup', field: 'fixedAssetGroup' },
        { header: 'Main Account', field: 'mainAccount' },
        { header: 'Element Factor', field: 'elementFactor' },
      ]
    }
  }
  //to add permission based on user role
  AddPermission() {
    if (
      this.permissionService.editBoolean == true &&
      this.permissionService.changeApproveBoolean == false &&
      this.permissionService.financeApproveBoolean == false
    ) {
      this.editPermissionBoolean = true;
    } else if (
      this.permissionService.editBoolean == true &&
      this.permissionService.changeApproveBoolean == true &&
      this.permissionService.financeApproveBoolean == false
    ) {
      this.changeApproveBoolean = true;
    } else if (
      this.permissionService.editBoolean == true &&
      this.permissionService.changeApproveBoolean == true &&
      this.permissionService.financeApproveBoolean == true
    ) {
      this.financeApproveBoolean = true;
    }
  }

  // changing the tab based on the selection
  changeTab(val) {
    this.currentTab = val;
    if (val == 'header' || val == 'cost' || val == 'fixed') {
      this.showPdf = true;
      this.btnText = 'Close';

    } else {
      this.showPdf = false;
      this.btnText = 'View invoice';
    }
    if (this.currentTab === 'support') {
      this.supportTabBoolean = true;

    } else if (this.currentTab === 'approver_selection') {

      this.selectionTabBoolean = true;
      this.supportTabBoolean = true;
    } else {
      this.supportTabBoolean = false;
      this.selectionTabBoolean = false;
    }

    if (this.currentTab == 'LCM') {
      this.isLCMTab = true;
    }
    if (val == 'cost' || val == 'dynamic') {
      this.costTabBoolean = true;
    } else {
      this.costTabBoolean = false;
    }
    if(val == 'grn'){
      if(!this.GRNTabData){
        this.getGRNtabData();
      }
    } 
    if(val == 'prepay' && !this.prePayData){
      this.getPrepaymentDetails();
    }
  }

  getPrepaymentDetails(){
    this.SpinnerService.show();
    this.exceptionService.prepaymentDetails(this.po_num).subscribe((data:any)=>{
      this.SpinnerService.hide();
      console.log(data);
      this.prePayData = data;
    },err=>{
      this.SpinnerService.hide();
      this.error("Server error");
    })
  }

  // preparing the po lines data to display in the create grn with po screen
  get_PO_GRN_Lines() {
    this.GRNObject = [];
    this.descrptonBool = true;
    let linesData = [];

    this.dataService.GRN_PO_Data.forEach((ele,i)=>{
      linesData.push({})
      for(const line in ele){
        linesData[i][line] = {Value: ele[line]}
      }
      if (this.client_name === 'Cenomi' && !this.dataService.isEditGRN) {
        linesData[i]['GRNQty'] = { Value: '' };
      } else if(this.client_name === 'Cenomi' && this.dataService.isEditGRN) {
        linesData[i]['GRNQty'] = { Value: ele['GRNQty'] };
      } else {
        linesData[i]['GRNQty'] = { Value: ele['RemainPurchPhysical'] };
      }
      const unitPrice = typeof ele?.UnitPrice === 'string' ? parseFloat(ele.UnitPrice.replace(/,/g, '')) : ele?.UnitPrice;
      let amount;
      if(this.dataService.isEditGRN){
        amount = (unitPrice * ele.GRNQty);
        amount = this.decimalRoundOff(amount);
      } else {
        amount = (unitPrice * ele.PurchQty);
        amount = this.decimalRoundOff(amount);
      }
      this.GRN_line_total += Number(amount);
      if(this.client_name === 'Cenomi' && !this.dataService.isEditGRN){  
        linesData[i]['GRNAmountExcTax'] = {Value: ''}
      } else {
        linesData[i]['GRNAmountExcTax'] = {Value: amount}
      }

      if(this.client_name === 'Cenomi' && this.GRN_PO_Bool){
        const POQty = ele?.PurchQty ? parseFloat(ele.PurchQty.replace(/,/g, '')) : 0;
        const POBalanceQty = ele?.RemainPurchPhysical ? parseFloat(ele.RemainPurchPhysical.replace(/,/g, '')) : 0;
        let percentage:any = '0.00';
        if (POQty !== 0) {
          // const PORemaining =  (POQty - POBalanceQty) * 100;
          percentage = (POBalanceQty / POQty) * 100;
          percentage = this.decimalRoundOff(percentage);
        }
        linesData[i]['percentage_po'] = {Value: percentage}
      }
      
    })
    const timeSheetTag = this.GRN_PO_tags?.find(item => item.TagName === 'Is Timesheets');
    if (timeSheetTag) {
      this.GRN_PO_tags.forEach(item => {
        if (item.TagName == 'GRN - Quantity' || item.TagName == 'AmountExcTax') {
          item.linedata.forEach(el => {
            timeSheetTag.linedata.forEach(item => {
              if (el.LineNumber == item.LineNumber && item.Value == true || item.Value == 'Yes') {
                el.is_timesheets = true;
              }
            });
          });
        }
      });
    }
    this.po_qty_array = this.GRN_PO_tags.find(item => item.TagName === 'PO Qty');
    this.po_balance_qty_array = this.GRN_PO_tags.find(item => item.TagName === 'PO Balance Qty');
    this.lineDisplayData = linesData;
    this.grnLineCount = this.lineDisplayData[0]?.linedata;
    this.lineDisplayData.forEach(el=>{
      el.checked = false;
    })
    this.isGRNDataLoaded = true;
  }

  getInvoiceFulldata_po() {
    this.SpinnerService.show();
    let doc_type = 'po';
    if(this.Itype == 'GRN'){
      doc_type = 'grn'
    }
    this.SharedService.getInvoiceInfo(false,doc_type).subscribe(
      (data: any) => {
        this.inputData = data?.ok?.headerdata;
        let GRN_linedata = data?.ok?.linedata;
       if(this.dataService.isEditGRN){
        let po_lines = this.dataService.po_lines;
        this.dataService.GRN_PO_Data = this.exceptionService.convertData(GRN_linedata, this.exceptionService.po_num);
        po_lines?.forEach(line=>{
          this.dataService.GRN_PO_Data.forEach(grn_line=>{
            if(line.LineNumber == grn_line.LineNumber){
              grn_line.PurchQty = line.PurchQty;
              grn_line.RemainPurchPhysical = line.RemainPurchPhysical;
            }
          })
        })
       } 
        this.manpower_metadata = this.dataService?.grn_manpower_metadata?.headerFields;
        if (this.client_name == 'Cenomi' && this.router.url.includes('Create_GRN_inv_list')) {
          if (this.manpower_metadata?.length < 1 && !this.dataService.isEditGRN) {
            this.manpowerMetadataFunction();
          } else {
            this.createTimeSheetDisplayData('old');
          }
        }
        this.SpinnerService.hide();
        this.get_PO_GRN_Lines();
        this.headerDataOrder();
        if (data?.ok?.vendordata) {
          this.vendorData = {
            ...data?.ok?.vendordata[0].Vendor,
            ...data?.ok?.vendordata[0].VendorAccount,
            ...data?.ok?.vendordata[0].VendorUser,
          };
          this.vendorName = this.vendorData['VendorName'];
          this.vendorId = this.vendorData['idVendor'];
        }
        this.getPODocId(this.SharedService.po_num,this.dataService.entityID,this.vendorData['idVendorAccount']);
        this.SpinnerService.hide();
      },
      (error) => {
        this.SpinnerService.hide();
        this.error("Server error");
      }
    );
  }

  getInvoiceFulldata(str) {
    this.SpinnerService.show();
    this.vendorData = [];
    this.inputData = [];
    let bool = false;

    if (this.Itype == 'PO' || this.Itype == 'GRN' || this.Itype == 'Service' || this.dataService.documentType == 'advance invoice' || this.dataService?.documentType?.startsWith('non') || this.dataService.documentType == 'credit note' && !this.mappingForCredit) {
      this.pageType = "normal";
    } else {
      this.pageType = "mapping";
      bool = true;
    }
    let doc_type = 'invoice';
    doc_type = this.Itype;
    if(this.Itype == 'Service'){
      doc_type = 'invoice'
    }
    this.SharedService?.getInvoiceInfo(bool,doc_type.toLowerCase()).subscribe(
      (data: any) => {
        let response = data.ok;
        this.lineData = data?.ok?.linedata;
        
        if (response?.uploadtime) {
          this.uploadtime = response?.uploadtime;
        }
        if (response.doc_type) {
          this.docType = response?.doc_type?.toLowerCase();
          this.documentType = response?.doc_type?.toLowerCase();
        }
        this.getInvTypes();
        if (this.pageType == "mapping") {
          this.calculateCost();
        }
        response?.cost_alloc?.forEach(cost => {
          let merge = { ...cost.AccountCostAllocation }
          this.costAllocation.push(merge);
        })
        this.normalCostAllocation = false;
        response?.dynamic_cost_alloc?.forEach(dynamic => {
          this.dynamicdata.push(dynamic);
          this.totalTaxDynamic = this.totalTaxDynamic + Number(dynamic?.calculatedtax);
          this.totalAmountDynamic = this.totalAmountDynamic + Number(dynamic?.amount);
        })
        this.inputData = response?.headerdata;
        this.isMoreRequired = response?.approverData?.more_info_required;

        if (response?.approverData?.to_approve_by) {
          let ap_id = response?.approverData?.to_approve_by[0];
          if (ap_id == this.SharedService.userId) {
            this.isAprUser = true;
          }
        }
        this.invoiceNumber = this.dataService.invoiceNumber;
        if (response?.servicedata) {
          this.isServiceData = true;
          this.vendorData = {
            ...response.servicedata[0].ServiceAccount,
            ...response.servicedata[0].ServiceProvider,
          };
          this.vendorName = this.vendorData['ServiceProviderName'];
          this.serviceProvidercode = this.vendorData['ServiceProviderCode'];
          if (this.vendorName == 'SANAM') {
            this.isFormValid = false;
            this.reqServiceprovider = true;
          }
          else {
            this.reqServiceprovider = false;
          }
        }
        this.headerDataOrder();
        let poNum = this.po_num;
        if(!this.po_num){
          poNum = this.exceptionService.po_num;
        }
        this.po_num = poNum;
        
        if (this.documentType == 'credit note') {
          this.getPOs();
          this.getVendorInvoices(this.po_num)
          this.projectCArr = this.dataService.projectCArr;
          this.projectIdArr = this.dataService.projectIdArr;
        }
        this.lineDisplayData = response.linedata;
        let fieldOrder;
        if(this.Itype == 'PO'){
          fieldOrder = {
            "LineNumber": 1,
            "ItemId": 2,
            "Name": 3,
            "ProcurementCategory": 4,
            "PurchQty": 5,
            "UnitPrice": 6,
            "DiscAmount": 7,
            "DiscPercent": 8
          };
        } else {
          fieldOrder = response?.customfieldorder;
        }

        if (this.lineDisplayData.length > 0) {
            this.lineDisplayData.forEach((element) => {
              // adding additional fields for po line description
            if(this.client_name == 'Cenomi'){
                this.lineItems?.forEach(item=>{
                  if(item?.itemCode == element?.invoice_itemcode){
                    element.lines.Description.po_line.Value = `${item?.itemCode}-${item?.Name}-${item?.UnitPrice}-${item?.SHIP_TO_ORG}-${item?.Qty}`
                  }
                })
            }
              Object.keys(element.lines).forEach((key) => {
                if(this.fieldType.filter(item=>item.name == key).length > 0){
                  element.lines[key].fieldType = this.fieldType.filter(item=>item.name == key)[0].type;
                  if(element.lines[key].fieldType == 'Dropdown'){
                    element.lines[key].lookupid = element.lines[key].Value;
                  } else if(element.lines[key].fieldType == 'Date'){
                    element.lines[key].Value = this.convertToDate(element.lines[key].Value);
                  }
                }
              });
          })
          // sorting the line table headers based on the fieldOrder
          this.lineTableHeaders = Object.keys(this.lineDisplayData[0].lines)
            .sort((a, b) => (fieldOrder[a] || 100) - (fieldOrder[b] || 100));
        }
        this.temp_line_data = JSON.parse(JSON.stringify(response.linedata));
        let vendorData = response?.vendordata;
        if (vendorData) {
          this.isServiceData = false;
          this.vendorData = {
            ...vendorData[0].Vendor,
            ...vendorData[0].VendorAccount,
            ...vendorData[0].VendorUser,
          };
          this.vendorAcId = this.vendorData['idVendorAccount'];
          this.vendorName = this.vendorData['VendorName'];
          this.vendorId = this.vendorData['idVendor'];
        }
        this.getPODocId(this.po_num,this.dataService.entityID,this.vendorData['idVendorAccount']);
        this.support_doc_list = response?.support_doc?.files;
        if (this.support_doc_list == null) {
          this.support_doc_list = []
        }
        if (str != 'batch') {
          setTimeout(() => {
            this.SpinnerService.hide();
          }, 500);
        }
      },
      (error) => {
        this.SpinnerService.hide();
        this.error("Server error");
        this.serverError = true;
      }
    );
  }

  convertToDate(dateString: string): Date {
    const parts = dateString.split('/'); // Split by "/"
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month index starts from 0
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }
  getPOs() {
    this.poList = [];
    this.exceptionService.getInvoicePOs().subscribe(((data: any) => {
      data.forEach(ele => {
        this.poList.push(ele.PODocumentID);
      })
    }))
  }
  getVendorInvoices(po_num) {
    this.invNumbersList = []
    this.SharedService.readVenInvoices(po_num).subscribe((data: any) => {
      data.forEach(ele => {
        this.invNumbersList.push(ele.docheaderID);
      })
    })
  }

  headerDataOrder() {
    this.headerData = [];
    if(!this.isServiceData){
      const orderMap = {
        'VendorName': 1,
        'VendorAddress': 2,
        'PurchaseOrder': 3,
        'PurchId': 3,
        'POHeaderId': 3,
        'InvoiceId': 4,
        'bill_number': 4,
        'InvoiceTotal': 5,
        'InvoiceDate': 6,
        'TotalTax': 7,
        'SubTotal': 8,
        'PaymentTerm': 9,
        'TRN': 10,
        'DueDate': 11,
      };

      if(this.inputData){
        Object?.keys(this.inputData)?.forEach(label => {

          const order = orderMap[label];
          label = label.toString();
          if (order) {
            this.inputData[label].order = order;
            if (label === 'PurchaseOrder' || label === 'PurchId' || label === 'POHeaderId') {
              this.po_num = this.inputData[label]?.Value;
            } else if (label === 'InvoiceId' || label === 'bill_number') {
              this.invoiceNumber = this.inputData[label]?.Value;
            } else if (label === 'SubTotal') {
              this.invoice_subTotal = this.inputData[label]?.Value;
            } else if (label === 'invoiceDate') {
              this.invoiceDate = this.inputData[label]?.Value;
            }
          }
          this.headerData.push({TagLabel: label, ...this.inputData[label]})

        });
      }
    } else {
      const orderMap = {
        'company name': 1,
        'customer po box': 2,
        'bill number': 3,
        'customer trn': 4,
        'bill issue date': 5,
        'account number': 6,
        'bill period': 7
      };

      Object.keys(this.inputData).forEach(label => {
        const order = orderMap[label];
        if (order) {
          this.inputData[label].order = order;
          if (label === 'InvoiceId' || label === 'bill_number') {
            this.invoiceNumber = this.inputData[label]?.Value;
          } else if (label === 'SubTotal') {
            this.invoice_subTotal = this.inputData[label]?.Value;
          }
        }
        this.headerData.push({TagLabel: label, ...this.inputData[label]})

      });
    }
    // Separate items with and without the 'order' key
    const withOrder = this.headerData.filter(item => item.hasOwnProperty('order'));
    const withoutOrder = this.headerData.filter(item => !item.hasOwnProperty('order'));

    // Sort the items with 'order' by their 'order' value
    withOrder.sort((a, b) => a.order - b.order);

    // Concatenate the sorted items with 'order' and those without 'order'
    this.headerData = withOrder.concat(withoutOrder);
  }

  readGRNInvData() {
    this.SpinnerService.show();
    this.SharedService.readReadyGRNInvData().subscribe(
      (data: any) => {
        this.lineDisplayData = [];
       const lineData = data.ok?.linedata;
        this.grnLineCount = lineData.length;
        this.lineDisplayData = lineData;
        lineData.forEach(item=>{
          Object.keys(item).forEach(label=>{
            if(label === 'GRNAmountExcTax'){
              this.GRN_line_total = this.GRN_line_total + Number(item[label]?.Value)
            } else if(label === 'PODescription' && item[label]?.Value){
              this.descrptonBool = true;
            }
          })
        })
        this.inputData = data?.ok?.headerdata?.headerData;
        
        this.headerDataOrder();
        let poNum = this.po_num;
        if(!this.po_num){
          poNum = this.exceptionService.po_num;
        }
        this.po_num = poNum;
        this.vendorData = {
          ...data.ok.vendordata[0].Vendor,
          ...data.ok.vendordata[0].VendorAccount,
        };
        this.vendorAcId = this.vendorData['idVendorAccount'];
        this.vendorName = this.vendorData['VendorName'];
        this.vendorId = this.vendorData['idVendor'];
        this.getPODocId(this.po_num,this.dataService.entityID,this.vendorAcId);

        // this.selectedRule = data.ok.ruledata[0].Name;
        // this.poList = data.all_pos;
        if (this.router.url.includes('GRN_approvals')) {
          let index = this.lineDisplayData.findIndex(tag => tag.TagName == 'Inv - Quantity');
          this.lineDisplayData.splice(index, 1)
        }
        this.isGRNDataLoaded = true;
        setTimeout(() => {
          this.SpinnerService.hide();
        }, 100);

      },
      (error) => {
        this.SpinnerService.hide();
        this.error("Server error");
        this.serverError = true;
      }
    );
  }
  trackByIndex(index: number, item: any): number {
    return index;
  }

  hasPoLine(item: any): boolean {
    return Object.values(item.lines).some((line: any) => line.Value?.po_line);
  }

  calculateTotal(data){
    let subTotal,tax;
    this.headerData.forEach(el=>{
    if(el.TagLabel == 'SubTotal'){
        subTotal = el.Value.toString();
      } else if(el.TagLabel == 'TotalTax'){
        tax = el.Value.toString();
      }
    })
    let decimalCount = 0;
    let des_arr = [];
    if(subTotal?.includes('.')){
      des_arr.push(subTotal?.split('.')[1]?.length);
    }
    if(tax?.includes('.')){
      des_arr.push(tax?.split('.')[1]?.length);
    }
    let invoiceTotal:any = Number(subTotal) + Number(tax);
    if(des_arr.length > 0){
      decimalCount = Math.max(...des_arr);
      invoiceTotal = invoiceTotal;
      invoiceTotal = this.decimalRoundOff(invoiceTotal);
    }
    this.onChangeValue('InvoiceTotal',invoiceTotal?.toString(),data);
    setTimeout(()=>{
      this.saveChanges();
    },1000)
  }

  onChangeValue(key, value, data) {
    let oldValue;
    if (['TotalTax','InvoiceTotal', 'SubTotal'].includes(key)) {
      if (value == '' || isNaN(+value)) {
        this.isAmtStr = true;
      } else {
        oldValue = data?.Value?.toString();
        if(['TotalTax', 'SubTotal'].includes(key)){
          this.headerData.forEach(el=>{
            if(el.TagLabel == 'InvoiceTotal'){
              el.isChanged = true;
            } else if(key == 'SubTotal' && el.TagLabel == 'SubTotal'){
              el.Value = value;
            } else if(key == 'TotalTax' && el.TagLabel == 'TotalTax'){
              el.Value = value;
            }
          })
        } else {
          this.headerData.forEach(el=>{
            if(el.TagLabel == 'InvoiceTotal' && key == 'InvoiceTotal'){
              el.Value = value;
              el.isChanged = false;
            }
          })
        }
        this.isAmtStr = false;
      }
    }

    let updateValue = {
      "header": {}
    };
    updateValue.header[key] = value;
    this.updateInvoiceData = updateValue;
  }

  async onChangeLineValue(key, value, fieldData) {
    const itemCode = fieldData?.ItemCode;
    let unitPrice;  
    let amounExcTax;
    let new_value;
    this.lineDisplayData?.forEach(tag => {
      if(itemCode == tag.ItemCode){
        for(const line in tag.lines){
            if(key == 'Quantity' && line == 'Quantity'){
              fieldData.oldValue = tag.lines['Quantity'].Value;
              tag.lines['Quantity'].Value = value;
              tag.lines['AmountExcTax'].isChanged = true;   
            }
            if(key == 'UnitPrice' && line == 'UnitPrice'){
              fieldData.oldValue = tag.lines['UnitPrice'].Value;
              tag.lines['UnitPrice'].Value = value;
              tag.lines['AmountExcTax'].isChanged = true;   
            }
            if(key == 'AmountExcTax' && line == 'AmountExcTax'){
              fieldData.oldValue = tag.lines['AmountExcTax'].Value;
              tag.lines['AmountExcTax'].Value = value;
              tag.lines['AmountExcTax'].isChanged = false;      
            }
            if(key == 'Discount' && line == 'Discount'){
              fieldData.oldValue = tag.lines['Discount'].Value;
              tag.lines['Discount'].Value = value;
              tag.lines['Discount'].isChanged = false;      
            }
            if(key == 'DiscPercent' && line == 'DiscPercent'){
              fieldData.oldValue = tag.lines['DiscPercent'].Value;
              tag.lines['DiscPercent'].Value = value;
              tag.lines['DiscPercent'].isChanged = false;      
            }
            amounExcTax = tag.lines['AmountExcTax']?.Value;
            unitPrice = tag.lines['UnitPrice']?.Value;
        }
      }
    })
  if(value?.includes('=')){
    let count = this.decimal_count;
    if (key == 'Quantity' && value.includes('=')) {
      new_value = (Number(amounExcTax) / Number(unitPrice));
      new_value = this.decimalRoundOff(new_value);
      this.lineDisplayData.forEach(tag => {
        if(itemCode == tag.ItemCode){
          for(const line in tag.lines){
            if(key == 'Quantity' && line == 'Quantity'){
              tag.lines['Quantity'].Value = new_value;
            }
            if(line == 'AmountExcTax'){
              tag.lines['AmountExcTax'].isChanged = false;
            }
          }
        }
      });
    }
    fieldData.Value = new_value;
    value =  new_value;
  }

    if (key == 'Quantity' || key == 'UnitPrice' || key == 'AmountExcTax') {
      if (value == '' || isNaN(+value)) {
        this.isAmtStr = true;
      } else {
        this.isAmtStr = false;
        this.calculateCost();
      }
      
    } else if (key == 'Description') {
      if (value == '') {
        this.isEmpty = true;
      } else {
        this.isEmpty = false;
      }
    }
    if(fieldData.oldValue != value){ 
    let updateValue = {
      "line": [
        {
          "itemcode": 0,
          "data": {}
        }
      ]
    };
    updateValue.line[0].itemcode = fieldData?.ItemCode || fieldData?.itemCode?.Value;
    updateValue.line[0].data[key] =  value;
    this.updateInvoiceData = updateValue;
    }
  }
  
  calculateAmount(itemCode,data){
    let UnitPrice, Quantity,discount,discPercentage;
    this.lineDisplayData.forEach(tag => {
      if(tag.ItemCode == itemCode){
        Quantity = tag?.lines?.Quantity?.Value;
        UnitPrice = tag?.lines?.UnitPrice?.Value;
        discount = tag?.lines?.Discount?.Value;
        discPercentage = tag?.lines?.DiscPercent?.Value;
      }
    })
    let amount;
    if(discount || discPercentage){
      amount = this.preciseMultiply(UnitPrice * Quantity)
      if(discount){
        amount = this.preciseMultiply(Quantity *( UnitPrice - discount))
      } else if(discPercentage){
        amount = this.preciseMultiply(Quantity *( UnitPrice - (UnitPrice * discPercentage / 100)));
      }
    } else {
      amount = this.preciseMultiply(UnitPrice * Quantity);
    }
    amount = this.decimalRoundOff(amount);
    this.onChangeLineValue('AmountExcTax',amount.toString(),data);
    setTimeout(() => {
      this.saveChanges();
    }, 1000);
  }

  decimalRoundOff(num: any) {
    return Number(num).toLocaleString(undefined,
       { minimumFractionDigits: this.decimal_count, 
          maximumFractionDigits: this.decimal_count,
          useGrouping: false 
        });
  }

  preciseMultiply(value) {
    let factor = Math.pow(10, this.decimal_count+1);
    return Math.round((value + Number.EPSILON) * factor) / factor;
  }

  saveChanges() {
    if (!this.isAmtStr && !this.isEmpty) {
      if (this.updateInvoiceData) {
        this.SharedService.updateInvoiceDetails(this.updateInvoiceData
        ).subscribe(
          (data: any) => {
            this.success('Changes saved successfully')
            delete this.updateInvoiceData;
          },
          (err) => {
            delete this.updateInvoiceData;
            this.error("Server error or Please check the data");
          }
        );
      }
    } else {
      delete this.updateInvoiceData;
      let err = ''
      if (this.isAmtStr) {
        err = 'Alphabets not allowed in the Quantity and Amount Fields.';
      } else if (this.isEmpty) {
        err = "'Description' field cannot be empty";
      }
      this.error(err);
      this.isEmpty = false;
    }
  }

  proceedToBatch(bool) {
    this.getInvoiceFulldata('batch');
    this.GRNUploadID = this.dataService.reUploadData?.grnreuploadID;
    if (this.GRNUploadID != undefined && this.GRNUploadID != null) {
      this.reuploadBoolean = true;
    } else {
      this.reuploadBoolean = false;
    }
    setTimeout(() => {
      let count = 0;
      const errors = {
        header: new Set<string>(),
        line: new Set<string>(),
      };
      const errorMessages = {
        InvoiceTotal: "Please review the 'Invoice Details' tab. 'Invoice Total' is not valid (empty/incorrect)",
        SubTotal: "Please review the 'Invoice Details' tab. 'Sub Total' is not valid (empty/incorrect)",
        PurchaseOrder: "Please review the 'Invoice Details' tab. 'Purchase Order' field is empty",
        InvoiceDate: "Please review the 'Invoice Details' tab. 'Invoice Date' is empty. Please specify a valid date",
        InvoiceId: "Please review the 'Invoice Details' tab. 'Invoice ID' is empty",
        Quantity: "Please review the 'Line Details' tab. 'Quantity' in the Line details is empty or zero",
        UnitPrice: "Please review the 'Line Details' tab. 'Unit Price' in the Line details is not valid (empty/incorrect)",
        AmountExcTax: "Please review the 'Line Details' tab. 'Amount Excluding Tax' in the Line details is not valid (empty/incorrect)",
        Amount: "Please review the 'Line Details' tab. 'Amount' in the Line details is not valid (empty/incorrect)",
      };
      
      
      // Helper function to check if a value is invalid
      const isInvalidValue = (value: any) => value === '' || isNaN(+value);
      
      // Validate input data
      Object.keys(this.inputData).forEach((key) => {
        if (['InvoiceTotal', 'SubTotal'].includes(key)) {
          if (isInvalidValue(this.inputData[key]?.Value)) {
            count++;
            errors.header.add(key);
          }
        } else if (['PurchaseOrder', 'InvoiceDate', 'InvoiceId'].includes(key)) {
          if (this.inputData[key].Value === '') {
            count++;
            errors.header.add(key);
          }
        }
      });
      
      // Validate line display data
      this.lineDisplayData.forEach((element) => {
        Object.keys(element.lines).forEach((key) => {
          if (['Quantity', 'UnitPrice', 'AmountExcTax', 'Amount'].includes(key)) {
        
                if (isInvalidValue(element.lines[key].Value)) {
                  count++;
                  errors.line.add(key);
                }
        
                if (element.lines[key] === 'Quantity' && +element.lines[key].Value === 0) {
                  count++;
                  errors.line.add('Quantity');
                }
          } else if (['Description'].includes(key)) {
            if (element.lines[key]?.Value == '') {
              count++;
              errors.line.add(key);
            }
          }
        });

      });
      // Handle success or display error messages
      if (count == 0) {
        this.uploadCompleted = true;
        // this.sendToBatch();
        if (!this.isServiceData) {
          this.vendorSubmit();
        } else {
          this.serviceSubmit(bool);
        }
      } else {
        this.SpinnerService.hide();
      
        // Display individual errors for header and line tags
        errors.header.forEach((tag) => {
          setTimeout(() => this.error(errorMessages[tag]), 50);
        });
      
        errors.line.forEach((tag) => {
          setTimeout(() => this.error(errorMessages[tag]), 10);
        });
      }
      
    }, 1000);
  }

  sendToBatch() {
    this.exceptionService.send_batch_approval().subscribe(
      (data: any) => {
        this.dataService.invoiceLoadedData = [];
        this.SpinnerService.hide();
        this.success("Sent to Batch Successfully!");
        this.syncBatch();

      },
      (error) => {
        this.error("Server error");
      }
    );
  }
  vendorSubmit() {
    this.SpinnerService.show();
    if(this.router.url.includes('uploadtime')){
      this.SharedService.vendorSubmit(this.reuploadBoolean, this.uploadtime).subscribe(
        (data: any) => {
          this.dataService.invoiceLoadedData = [];
          this.SpinnerService.hide();
          if (this.router.url.includes('ExceptionManagement')) {
            this.success("Sent to Batch Successfully!")
          } else {
            if (!this.GRNUploadID) {
              this.success("Uploaded to Serina Successfully!")
            }
          }
          this.syncBatch();
  
        },
        (error) => {
          this.error("Server error");
        }
      );
    } else {
      this.success("Sent to Batch Successfully!");
      this.syncBatch();
    }
  }
  serviceSubmit(bool) {
    // if(!this.normalCostAllocation){
    //   if(this.reqServiceprovider){
    //     this.exceptionService.submitAllocationDetails(this.rows)
    //     .subscribe((data: any) => {
    //       this.success("submitted successfully.")
    //       setTimeout(() => {
    //         this._location.back();
    //       }, 1000);
    //     }, err => {
    //       this.error("Server error");
    //     })
    //   } else {
    //     const group: { iddynamiccostallocation: string, [key: string]: string }[] = [];
    //     const groupedValues: { [key: string]: { [key: string]: string; iddynamiccostallocation: string } } = {};
    //       for (const key in this.editedValues) {
    //         const [iddynamiccostallocation, property] = key.split(',');

    //         if (!groupedValues[iddynamiccostallocation]) {
    //           groupedValues[iddynamiccostallocation] = {
    //             iddynamiccostallocation: iddynamiccostallocation,
    //           };
    //         }
    //         groupedValues[iddynamiccostallocation][property] = this.editedValues[key];
    //       }

    //       this.exceptionService.editedDynamicAllocationDetails(groupedValues)
    //       .subscribe((data: any) => {
    //         this.success("submitted successfully")
    //         setTimeout(() => {
    //           this._location.back();
    //         }, 1000);
    //       }, err => {
    //         this.error("Server error");
    //       })
    //     }
    //   }
    // else{
    this.SpinnerService.show();
    this.SharedService.serviceSubmit(``).subscribe((data: any) => {
      this.success("Sent to Batch Successfully!");
      this.dataService.serviceinvoiceLoadedData = [];
      setTimeout(() => {
        this.SpinnerService.hide();
        if (this.router.url.includes('CustomerUpload')) {
          this.router.navigate([`${this.portalName}/invoice/ServiceInvoices`]);
        } else {
          this._location.back();
        }
      }, 1000);
    }, err => {
      this.error("Server error");
      this.SpinnerService.hide();
    })
    // }
  }
  syncBatch() {
    this.SpinnerService.show();
    this.SharedService.syncBatchTrigger(`?re_upload=false`).subscribe((data: any) => {
      if(data){
        this.headerpop = 'Batch Progress'
        this.p_width = '350px';
        this.progressDailogBool = true;
        this.GRNDialogBool = false;
        this.batchData = data[this.invoiceID]?.complete_status;
        let last_msg = this.batchData[this.batchData.length - 1].msg;
        let sub_status = this.batchData[this.batchData.length - 1]?.sub_status;
        this.subStatusId = sub_status;
        this.isBatchFailed = false;
        this.batchData.forEach(el => {
          if (el.msg.includes('Tax')) {
            this.getInvoiceFulldata('');
          }
        })
        if (last_msg == 'Batch ran to an Exception!' || last_msg == 'Matching Failed - Batch Failed' && this.batch_count <= 2) {
          this.batch_count++;
          this.isBatchFailed = true;
        }
        if (!(this.batch_count <= 2)) {
          this.error("Dear User, Kindly check with Serina's support team regarding this invoice.")
        }
        this.SpinnerService.hide();
      } else {
        this.error("Issue with the batch");
        this.SpinnerService.hide();
      }
    }, err => {
      this.SpinnerService.hide();
      this.error("Server error");
    });
  }

  routeToMapping() {
    this.exceptionService.invoiceID = this.invoiceID;
    this.tagService.editable = true;
    this.tagService.submitBtnBoolean = true;
    this.tagService.headerName = 'Edit Invoice';
    let sub_status = null;
    let last_status = null;
    for (const el of this.batchData) {
      if (el.status == 0) {
        sub_status = el.sub_status;
      }
    };
    if (!sub_status) {
      sub_status = this.batchData[this.batchData.length - 1].sub_status;
      last_status = this.batchData[this.batchData.length - 1].status;
    }
    last_status = this.batchData[this.batchData.length - 1].status;
    this.ap_enabled_exc = last_status;
    this.subStatusId = sub_status;
    this.dataService.subStatusId = sub_status;
    if(!(this.router.url.includes('Create_GRN_inv_list') || this.router.url.includes('GRN_approvals'))){
      if (this.portalName == 'vendorPortal') {
        if ([8, 16, 18, 19, 33, 21, 27, 29].includes(sub_status)) {
          this.processAlert(sub_status);
        } else {
          this.router.navigate([`${this.portalName}/invoice/allInvoices`]);
        }
      } else {
        if ([8, 16, 17, 18, 19, 33, 21, 27, 29, 51, 54, 70, 75, 101, 102, 104,216].includes(sub_status)) {
          this.processAlert(sub_status);
        } else if (sub_status == 34) {
          this.update("Please compare the PO lines with the invoices. We generally recommend the 'PO flip' method to resolve issues of this type.")
        } else if (sub_status == 7 || sub_status == 23 || sub_status == 10 || sub_status == 35 || sub_status == 23) {
          this.router.navigate([`${this.portalName}/ExceptionManagement`]);
        } else {
          this.router.navigate([`${this.portalName}/invoice/allInvoices`]);
        }
      }
    } else {
      if(last_status == 0){
        this.error("Please choose a differnt GRN, Invoice total and GRN total are not matching");
      } else {
        this._location.back();
      }
    }
    this.progressDailogBool = false;
  }

  processAlert(subStatus: number): void {
    if (subStatus == 18) {
      this.update("Invoice Total and 'Sub-Total+Tax' Mismatch Identified. Kindly check Entry");
    } else if (subStatus == 19) {
      this.update("Dear User, Sub total is not matching with the invoice lines total.");
    } else if (subStatus == 29) {
      this.update("Dear User, OCR error found. please check");
    } else if (subStatus == 51) {
      this.update("Dear User, Invoice type is LCM, Please add the lines for the LCM invoice.");
      this.currentTab = 'LCM';
    } else if (subStatus == 54) {
      this.update("Dear User, Invoice type is MultiPO,Please Check the invoice total is not matching with the lines.")
      this.currentTab = 'line';
    } else if (subStatus == 70) {
      if (this.portalName == 'customer' && this.userDetails['permissioninfo'].set_approval_enabled) {
        this.readDepartment();
        this.readCategoryData();
        this.approval_selection_boolean = true;
        this.isLCMCompleted = true;
        this.update('Please add the approvers')
        this.changeTab('approver_selection')
      } else {
        this.router.navigate([`${this.portalName}/invoice/allInvoices`]);
      }
    } else if(subStatus == 216){
      this.update("Qty x (UnitPrice - Discount) is not Equal to Amount Exclusive of Tax, please check")
    } else {
      this.getInvoiceFulldata('');
      this.update("Please check the values in invoice.");
      this.getGRNtabData();
    }

  }

  financeApprove() {
    this.SpinnerService.show();
    // this.rejectModalHeader = 'Add Approval Comments';
    // this.displayrejectDialog = true;
    // Document approved by ${this.userDetails['userdetails'].firstName} \n comments: 
    let desc = this.ap_api_body();
    this.SharedService.financeApprovalPermission(desc).subscribe(
      (data: any) => {
        this.dataService.invoiceLoadedData = [];
        this.success(data.result);
        this.displayrejectDialog = false;
        this.closeDialog();
        setTimeout(() => {
          this.SpinnerService.hide();
          this._location.back();
        }, 1000);
      },
      (error) => {
        this.error(error.statusText);
        this.SpinnerService.hide();
        this.displayrejectDialog = false;
      }
    );
  }
  backToInvoice() {
    if (
      !this.router.url.includes('vendorUpload') ||
      !this.router.url.includes('CustomerUpload')
    ) {
      this._location.back();
    } else if (
      this.router.url.includes('vendorUpload') &&
      this.router.url.includes('CustomerUpload') &&
      this.submitBtn_boolean == true
    ) {
      if (
        confirm(
          ` Are you sure you want cancel process ? \n if you click OK you will lost your invoice meta data.`
        )
      ) {
        this._location.back();
      }
    } else {
      this._location.back();
    }
  }


  viewPdf() {
    this.showPdf = !this.showPdf;
    if (this.showPdf != true) {
      this.btnText = 'View invoice';
    } else {
      this.btnText = 'Close';
    }
  }

  filterPO(event) {
    this.filteredPO = this.dataService.uni_filter(this.poList,'PODocumentID',event);
  }

  onSelectPO(value) {
    if (confirm(`Are you sure you want to change PO Number?`)) {
      this.exceptionService.updatePONumber(value.PODocumentID).subscribe(
        (data: any) => {
          this.success("PO Number updated successfully")
          this.getInvoiceFulldata('');
        },
        (error) => {
          this.error("Server error");
        }
      );
    }
  }

  readLineItems() {
    this.exceptionService.readLineItems().subscribe((data: any) => {
      this.lineItems = data.description;
      data?.description?.forEach(el=>{
        if(el.itemCode && el.Name){
          el.Value = `${el.itemCode}-${el.Name}-${el.UnitPrice}-${el.SHIP_TO_ORG}-${el.Qty}`
        }
      })
    });
  }
  filterPOLine(event) {
    this.filteredPOLine = this.dataService.uni_filter(this.lineItems,'Value',event);
  }
  lineMapping(inv_code, po_code) {
    this.updateLine(inv_code,po_code);
  }

  updateLine(inv_code,po_code) {
    this.exceptionService
      .updateLineItems(inv_code,po_code,1,this.vendorAcId)
      .subscribe(
        (data: any) => {
          this.success("Line item updated successfully");
          this.getInvoiceFulldata('');
        },
        (error) => {
          this.error("Server error");
        }
      );
  }

  selectReason(reasn) {
    if (reasn == 'Others') {
      this.addrejectcmtBool = true;
    } else {
      this.addrejectcmtBool = false;
    }
  }
  rejectKepup(val) {
    this.rejectionComments = val;
  }

  Reject() {
    if (!this.router.url.includes('GRN_approvals')) {
      let rejectionData = {
        documentdescription: this.rejectionComments,
        userAmount: 0,
      };
      this.uploadCompleted = true;
      this.SpinnerService.show();
      if (this.fin_boolean && this.rejectionUserId != 0) {
        this.exceptionService.rejectApprove(rejectionData, this.rejectionUserId).subscribe((data: any) => {
          this.dataService.invoiceLoadedData = [];
          this.success(data.result);
          this.displayrejectDialog = false;
          setTimeout(() => {
            this.SpinnerService.hide();
            this.router.navigate([`${this.portalName}/ExceptionManagement`]);
          }, 1000);
        }, err => {
          this.error("Server error");
          this.SpinnerService.hide();
        })
      } else {
        this.SharedService.vendorRejectInvoice(rejectionData
        ).subscribe(
          (data: any) => {
            this.dataService.invoiceLoadedData = [];
            this.success("Rejection Notification sent to Vendor")
            this.displayrejectDialog = false;
            setTimeout(() => {
              this.SpinnerService.hide();
              this.router.navigate([`${this.portalName}/ExceptionManagement`]);
            }, 1000);
          },
          (error) => {
            this.error("Server error");
            this.SpinnerService.hide();
          }
        );
      }

    } else {
      this.SharedService.rejectGRN().subscribe((data: any) => {
        if (data?.status == 'success') {
          this.success(data.message)
        } else {
          this.error(data.message);
        }
        this.displayrejectDialog = false;
        setTimeout(() => {
          this.router.navigate([`${this.portalName}/GRN_approvals`]);
        }, 1000);
      })
    }
  }

  getApprovedUserList() {
    this.userList_approved = [];
    this.SpinnerService.show();
    this.exceptionService.getApprovedUsers().subscribe((data: any) => {

      if (typeof (data?.result) != "string") {
        data?.result?.forEach(el => {
          this.userList_approved.push(el);
        });
      } else {
        this.userList_approved.push({ firstName: "Vendor", idUser: 0, lastName: "" });
      }
      this.SpinnerService.hide();
    })
  }
  selectUserForReject(event) {
    this.rejectionUserId = event;
  }
  onChangeGrn(fieldName, val,linedata) {
    // const po_qty_value = this.po_qty_array?.linedata.find(data => data.idDocumentLineItems === lineItem.idDocumentLineItems)?.Value;
    // const po_balance_qty_value = this.po_balance_qty_array?.linedata.find(data => data.idDocumentLineItems === lineItem.idDocumentLineItems)?.Value;
    const po_qty_value = linedata?.PurchQty?.Value;
    const po_balance_qty_value = linedata?.RemainPurchPhysical?.Value;
    let thresholdPecent;
    if(this.dataService?.configData?.miscellaneous?.overdeliverypct){
      thresholdPecent = this.dataService?.configData?.miscellaneous?.overdeliverypct;
    } else {
      thresholdPecent = linedata?.OverDeliveryPct?.Value;
    }
    let checking_value;
    let error_msg;
    let threshold;
    let po_value;
    if(thresholdPecent){
      threshold = po_qty_value * thresholdPecent / 100;
    }
    if(po_balance_qty_value){
      checking_value = po_balance_qty_value;
      error_msg = 'PO Balance Quantity';
    } else {
      checking_value = po_qty_value;
      error_msg = 'PO Quantity';
    }
    this.disable_save_btn = false;
    let finalCheckValue = Number(checking_value);
    if(thresholdPecent){
      finalCheckValue = Number(checking_value) + Number(threshold);
    }
    if(finalCheckValue < Number(val)){
       this.error(`GRN Quantity cannot be greater than ${error_msg}`);
      this.grnTooltip = `GRN Quantity cannot be greater than ${error_msg}`;
      this.disable_save_btn = true;
      return;
    }
    if (this.GRN_PO_Bool) {
      this.updateAmountExcTax(fieldName, val, linedata);
    } else {
      this.updateAmountExcTax(fieldName, val, linedata);
      this.update("Please add comments as well")
    }
  }
  onChangeGrnAmount(lineItem, val) {
    // const grnUnitPrice = this.lineDisplayData.find(item => item.TagName == 'UnitPrice')
    // .linedata.find(data => data.idDocumentLineItems === lineItem.idDocumentLineItems);
    let grnQty:any = (Number(val) / Number(lineItem?.UnitPrice?.Value));
    grnQty = this.decimalRoundOff(grnQty);
    const po_balance_qty_value = lineItem?.RemainPurchPhysical?.Value;

    let checking_value;
    let error_msg;
    if(po_balance_qty_value){
      checking_value = po_balance_qty_value;
      error_msg = 'PO Balance Quantity';
    } 
    this.disable_save_btn = false;
    if(Number(checking_value) < Number(grnQty)){
      // this
      // this.lineDisplayData.find(data => data.TagName == 'GRN - Quantity').linedata.find(data => data.idDocumentLineItems === lineItem.idDocumentLineItems).Value = lineItem.old_value;
      this.error(`GRN Quantity cannot be greater than ${error_msg}`);
      this.grnTooltip = `GRN Quantity cannot be greater than ${error_msg}`;
      this.disable_save_btn = true;
      return;
    }

    this.GRN_line_total = 0;
    this.lineDisplayData.forEach(ele => {
      Object.keys(ele).forEach((label:any)=> {
        if (label === 'GRNAmountExcTax') {
            if(ele?.LineNumber?.Value == lineItem.LineNumber?.Value){
              ele[label].Value = val;
            }
            this.GRN_line_total = this.GRN_line_total + Number(ele[label].Value);
        } else if(label === 'GRNQty'){
          if(ele?.LineNumber?.Value == lineItem.LineNumber?.Value){
            ele[label].Value = grnQty;
            ele[label].ErrorMsg = "Quantity changed";
          }
        }
      })
    })
  }
  updateAmountExcTax(fieldName, newQuantity: number, linedata) {
    if (fieldName) {
      const unitPrice = linedata.GRNUnitPrice || linedata.UnitPrice;
      let amountExcTax:any = (Number(unitPrice.Value) * newQuantity);
      amountExcTax = this.decimalRoundOff(amountExcTax);

      this.GRN_line_total = 0;
      this.lineDisplayData.forEach(item=>{
        Object.keys(item).forEach(label=>{
          if(label === 'GRNAmountExcTax'){
            this.GRN_line_total = this.GRN_line_total + Number(item[label].Value);
          } else if(label === 'GRNQty'){
            if(item?.LineNumber?.Value == linedata?.LineNumber?.Value){
              item[label].Value = newQuantity;
              item[label].ErrorMsg = "Quantity changed";
            }
          }
          if(linedata?.LineNumber?.Value == item?.LineNumber?.Value){
            
            item['GRNAmountExcTax'].Value = amountExcTax;
          }
        })
      })
    }
  }

  deleteGrnLine(id) {
    const drf: MatDialogRef<ConfirmationComponent> = this.confirmFun('Are you sure you want to delete this line?', 'confirmation', 'Confirmation')
    drf.afterClosed().subscribe((bool:boolean) => {
      if (bool) {
        this.SpinnerService.show();
        this.lineDisplayData = this.lineDisplayData.filter(record => {
          return !record?.checked;
        });
        this.GRN_line_total = 0;
        this.lineDisplayData.forEach(ele => {
          Object.keys(ele).forEach(label=>{
            if(label === 'GRNAmountExcTax'){
              this.GRN_line_total = this.GRN_line_total + Number(ele[label].Value);
            }
          })
        })
        this.SpinnerService.hide();
        this.selectALL_grn_lines = false;
      }
    })
  }
  confirmFun(body, type, head) {
    return this.mat_dlg.open(ConfirmationComponent, {
      width: '400px',
      height: '300px',
      hasBackdrop: false,
      data: { body: body, type: type, heading: head, icon: 'assets/Serina Assets/new_theme/Group 1336.svg' }
    })
  }
  bulk_confirm() {
    const drf: MatDialogRef<ConfirmationComponent> = this.confirmFun('We found more invoices for the same batch, do you want to approve all the invoices for the batch?', 'confirmation', 'Confirmation')
    drf.afterClosed().subscribe((bool) => {
      const dialog = document.querySelector('dialog');
      if (dialog) {
        dialog.showModal();
        this.bulk_bool = bool;
        if (bool) {
          document.getElementById("cmt").style.display = "block";
        } else {
          document.getElementById("cmt").style.display = "none";
        }
      }

    })

  }
  closeDialog() {
    const dialog = document.querySelector('dialog');
    if (dialog) {
      dialog.close();
    }
  }
  openFilterDialog(event){
    let top = event.clientY + 10 + "px";
    let left;
      left = "calc(29% + 100px)";
    const dialog = document.querySelector('dialog');
    dialog.style.top = top;
    dialog.style.left = left;
    if(dialog){
      dialog.showModal();
    }
  }
  updateCharacterCount(event){
    this.invoiceDescription = event.target.value;
    if (this.invoiceDescription.length > 250) {
      this.invoiceDescription = this.invoiceDescription.substring(0, 250);
      event.target.value = this.invoiceDescription;
    }
  }
  delete_confirmation(id) {
    const drf: MatDialogRef<ConfirmationComponent> = this.confirmFun('Are you sure you want to delete this line?', 'confirmation', 'Confirmation')

    drf.afterClosed().subscribe((bool) => {
      if (bool) {
        this.removeLine(id)
      }
    })
  }

  confirm_pop(grnQ, boolean, txt) {
    this.isDraft = false ;
    if(txt.includes('saved')){
      this.isDraft = true ;
    }
    let label = 'GRNQuantity';
    if(this.GRN_PO_Bool){
      label = 'GRNQty'
    }
    let validationBool = this.lineDisplayData?.some(el=> el[label]?.Value == '' ||  el[label]?.Value == undefined);
    if (validationBool && !this.isDraft) {
      this.error('Please add a valid GRN Quantity');
      return;
    }
    const drf: MatDialogRef<ConfirmationComponent> = this.confirmFun('Kindly confirm the number of GRN lines.', 'confirmation', 'Confirmation')
    drf.afterClosed().subscribe((bool) => {
      if (bool) {
        if (this.isManpowerTags && this.manPowerAPI_request) {
          this.createTimesheetAPI();
        }
        this.onSave_submit(grnQ, boolean, txt);
      }
    })
  }

  onSave_submit(grnQ, boolean, txt) {
    if (this.descrptonBool) {
      this.GRNObjectDuplicate = this.GRNObject;
      if (this.GRN_PO_Bool || this.router.url.includes("GRN_approvals")) {
        this.GRNObjectDuplicate = this.GRNObjectDuplicate.filter(val => val.tagName != 'AmountExcTax');
        this.GRNObjectDuplicate.forEach((val, i) => {
          if (val.is_mapped == 'Price' && grnQ[val?.idDocumentLineItems] != 0) {
            let obj = {
              Value: grnQ[val?.idDocumentLineItems] * val?.Value, ErrorMsg: '', idDocumentLineItems: val?.idDocumentLineItems, is_mapped: '', tagName: 'AmountExcTax'
            }
            this.GRNObjectDuplicate.splice(this.GRNObjectDuplicate.length + 1, 0, obj)
          }

        })
      } else {
        if(!this.GRN_PO_Bool){
          // this.validateInvPOUnitPrice();
        }
      }
      let emptyBoolean: boolean = false;
      let commentBoolean = false;
      let errorMsg: string;
      this.GRNObjectDuplicate.forEach((ele, ind) => {
        if (ele.Value === '') {
          emptyBoolean = true;
          errorMsg = 'Please fill in all the given fields.';
        } else if (ele.Value != ele.old_value && !this.GRN_PO_Bool) {
          if (
            ele.ErrorMsg == null ||
            ele.ErrorMsg == '' ||
            ele.ErrorMsg == 'None' ||
            ele.ErrorMsg == 'none'
          ) {
            commentBoolean = true;
            errorMsg =
              'Kindly add Comments for lines that are Adjusted.';
          }
        } else if (ele.Value == 0) {
          if (this.GRN_PO_Bool) {
            if (ele.tagName == 'Quantity') {
              this.GRNObjectDuplicate = this.GRNObjectDuplicate.filter(val => val?.idDocumentLineItems != ele?.idDocumentLineItems);
            }
            // this.GRNObjectDuplicate = this.GRNObjectDuplicate.filter(val=> val.tagName != 'AmountExcTax');
          } else {
            commentBoolean = true;
            errorMsg =
              'Field Value cannot be Null!';
          }

        }
      });
      if (emptyBoolean == false && commentBoolean == false) {

        if (
          boolean == true
        ) {
          if (this.GRN_PO_Bool) {
            if(this.configData?.miscellaneous?.mandatory_ref_number){
              if (this.invoiceNumber) {
                this.grnDuplicateCheck(boolean);
                } else {
                    this.error("Dear user, please add the invoice number.");
                }
            } else {
              this.grnDuplicateCheck(boolean);
            }
          } else {
            setTimeout(() => {
              if (this.router.url.includes('GRN_approvals')) {
                this.grnDuplicateCheck(boolean);
              } else {
                this.CreateGRNAPI(boolean, txt);
              }
            }, 1000);
          }
        } else {
          if (!this.GRN_PO_Bool) {
            setTimeout(() => {
              this.CreateGRNAPI(false, 'GRN data saved successfully');
            }, 1000);
          } else {
            this.GRNObjectDuplicate = this.GRNObjectDuplicate.filter(val => val.tagName != 'AmountExcTax');
          }
        }
      } else {
        this.error(errorMsg);
      }
    } else {
      this.error("Description Unavailable. Kindly Contact the Technical Team.");
    }
  }

  createGRNWithPO(bool,payLoad) {
    this.SpinnerService.show();
    let inv_param = '';
    if (this.invoiceNumber) {
      const encodedInvoiceNumber = encodeURIComponent(this.invoiceNumber);
      inv_param += `&inv_num=${encodedInvoiceNumber}`;
    }
    if (this.invoiceDescription) {
      inv_param += `&document_description=${encodeURIComponent(this.invoiceDescription)}`;
    }
    if(this.invoiceDate){ 
      let date = this.invoiceDate.toISOString()
      inv_param += `&grn_date=${date}`;
    }
    let manPower = '';
    if (this.manpowerHeaderId && this.dataService.isEditGRN) {
      manPower = `&ManPowerHeaderId=${this.manpowerHeaderId}&isdraft=${this.isDraft}&grn_doc_id=${this.invoiceID}`;
    } else if(this.manpowerHeaderId && !this.dataService.isEditGRN){
      manPower = `&ManPowerHeaderId=${this.manpowerHeaderId}&isdraft=${this.isDraft}`;
    } else if(!this.manpowerHeaderId && this.dataService.isEditGRN){
      manPower = `&isdraft=${this.isDraft}&grn_doc_id=${this.invoiceID}`;
    } else if(!this.manpowerHeaderId && this.isDraft){
      manPower = `&isdraft=${this.isDraft}`;
    }
    if(this.isManpowerTags && !this.manpowerHeaderId && this.manPowerAPI_request){
      this.SpinnerService.hide();
      return;
    }
    this.SharedService.createGRNWithPO(inv_param, manPower,payLoad).subscribe((data: any) => {
      this.SpinnerService.hide();
      if (data.status == 'Posted') {
        this.success(data.message);
        setTimeout(() => {
          this.router.navigate(['/customer/Create_GRN_inv_list']);
        }, 2000);
      }  else if(data.status == 'Draft') {
        this.update(data.message);
        setTimeout(() => {
          this.router.navigate(['/customer/Create_GRN_inv_list']);
        }, 2000);
      } else {
        this.progressDailogBool = true;
        this.p_width = '350px';
        this.headerpop = 'GRN Creation Status';
        this.APIResponse = data.message;
      }
      if(data.grn_doc_id && this.uploadFileList.length >0){
        this.uploadSupport(data.grn_doc_id);
      }

    }, err => {
      this.SpinnerService.hide();
      this.error("Server error");
    })

  }

  grnDuplicateCheck(boolean) {
    if (this.lineDisplayData.length > 0) {
    this.SpinnerService.show();
      let arr = [];
      let grnWithPOPayload = [];
      this.lineDisplayData.forEach((objV) => {
        Object.keys(objV).forEach(ele=>{
            if (this.router.url.includes("GRN_approvals")) {
              if (ele == 'Quantity') {
                let obj = {
                  line_id: objV?.ItemCode,
                  quantity: objV[ele]?.Value,
                }
                arr.push(obj)
              }
            } else {
              if (ele == 'GRNQty') {
                let obj = {
                  line_id: objV?.LineNumber?.Value,
                  quantity: objV[ele]?.Value,
                }
                arr.push(obj)
              }
            }
        });
        let objData = {
          itemCode : objV?.LineNumber?.Value.toString(),
          lineData: {
            'Quantity' : { Value : objV?.GRNQty?.Value },
            'UnitPrice' : { Value: objV?.UnitPrice?.Value},
            'Name' : { Value: objV?.Name?.Value}
          }
        }
        grnWithPOPayload.push(objData);
      })
      // const uniqarr = arr.filter((val,ind,arr)=> ind == arr.findIndex(v=>v.line_id == val.line_id && v.quantity == val.quantity));
      let duplicateAPI_response: string;
      let extra_param = '';
      if (this.router.url.includes('GRN_approvals')) {
        extra_param = `&grn_id=${this.invoiceID}`
      }
      this.SharedService.duplicateGRNCheck(arr, extra_param).subscribe((data: any) => {
        duplicateAPI_response = data.result;
        this.SharedService.checkGRN_PO_balance(false).subscribe((data: any) => {
          let negativeData = [];
          let negKey = {};
          let bool: boolean;
          for (let key in data?.result) {
            let valuee = data.result[key];
            this.GRNObjectDuplicate.forEach((ele) => {
              if (ele.tagName == 'Quantity' && ele.idDocumentLineItems == key && (+valuee < +ele.Value)) {
                negKey[key] = valuee;
                negativeData.push(valuee);
              }
            })
          }
          if (negativeData.length <= 0) {
            if (duplicateAPI_response == 'successful') {
              if (this.router.url.includes("GRN_approvals")) {
                this.Approve_grn();
              } else {
                this.createGRNWithPO(boolean,grnWithPOPayload);
              }

            } else {
              this.AlertService.errorObject.detail = duplicateAPI_response;
              this.error(duplicateAPI_response);
            }
          } else {
            let str: string = JSON.stringify(negKey);
            this.error(`Please check available quantity in the line numbers (${str})`);
          }
        }, err => {
          this.error('Server error')
        })
      }, err => {
        this.error('Server error')
      })
      this.SpinnerService.hide();
    } else {
      alert('There are no lines to create GRN, if you are able to see the lines then please check the quantity');
      this.GRNObjectDuplicate = this.GRNObjectDuplicate.filter(val => val.tagName != 'AmountExcTax');
    }
  }
  CreateGRNAPI(boolean, txt) {
      if (this.validateUnitpriceBool && !confirm("Invoice 'unit-price' is not matching with PO. Do you want to proceed?")) {
        return;
      }
      this.grnAPICall(boolean, txt);
  }

  grnAPICall(boolean, txt) {
    let inv_des = '';
    if(this.invoiceDescription &&this.invoiceDescription !== ''){
      inv_des += `&document_description=${this.invoiceDescription}`;
    }
    if(this.invoiceDate){
      let date:string = this.datePipe.transform(this.invoiceDate,'dd/MM/yyyy');
      date = date.toString();
      inv_des += `&grn_date=${date}`;
    }
    let grnWithInvPayload = [];
    this.lineDisplayData.forEach((objV) => {
      let objData = {
        itemCode : objV?.invoice_itemcode?.Value.toString(),
        lineData: {
          'Quantity' : { Value : objV?.GRNQuantity?.Value },
          'UnitPrice' : { Value: objV?.GRNUnitPrice?.Value},
          'GRNDescription' : { Value: objV?.GRNDescription?.Value}
        }
      }
      grnWithInvPayload.push(objData)

    })
    this.SpinnerService.show();
    this.SharedService.saveGRNData(
      boolean, grnWithInvPayload,inv_des
    ).subscribe(
      (data: any) => {
        this.SpinnerService.hide();
        if (data.status == 'Posted') {
          this.success(data.message);
          setTimeout(() => {
            this.router.navigate(['/customer/Create_GRN_inv_list']);
          }, 2000);
        } else if(data.status == 'Draft') {
          this.update(data.message);
          setTimeout(() => {
            this.router.navigate(['/customer/Create_GRN_inv_list']);
          }, 2000);
        } else {
          this.progressDailogBool = true;
          this.p_width = '350px';
          this.headerpop = 'GRN Creation Status';
          this.APIResponse = data.message;
        }

      },
      (error) => {
        this.SpinnerService.hide();
        if (error.status == 403) {
          this.progressDailogBool = true;
          this.headerpop = 'GRN Creation Status';
          this.APIResponse = error.error.Error;
          this.error("Invoice quantity beyond threshold");
        } else {
          this.error("Server error");
        }
      }
    );
  }

  validateInvPOUnitPrice() {
    let arr = []
    this.lineDisplayData.forEach(el=>{
      arr.push({"invoice_itemcode": el?.invoice_itemcode?.Value});
    })
    this.SharedService.validateUnitprice(arr).subscribe((data: any) => {
      if (data.result.length > 0) {
        this.validateUnitpriceBool = true;
      } else {
        this.validateUnitpriceBool = false;
      }
    })
  }

  async open_dialog_comp(str) {
    let w = '70%';
    let h = '92vh';
    let response;
    if (str == 'Amend') {
      this.displayrejectDialog = false;
      w = '40%';
      h = '40vh';
    } else if (str == 'flip line') {
      try {
        if(!this.flipPOData){
          const data: any = await this.exceptionService.getPOLines('').toPromise();
           this.flipPOData = data.Po_line_details;
        }
        response = { podata: this.flipPOData, sub_total: this.invoice_subTotal };
      } catch (error) {
        console.error('Error fetching PO lines:', error);
        return;
      }
    } else if (str == 'manpower') {
      w = '80%';
      h = '82vh';
      response = { "grnData_po": this.lineDisplayData }
    } else if (str == 'manpower_metadata') {
      w = '60%';
      h = '65vh';
      response = { "manpower_metadata": this.manpower_metadata }
    }
    this.SpinnerService.show();
    const matdrf: MatDialogRef<PopupComponent> = this.mat_dlg.open(PopupComponent, {
      width: w,
      height: h,
      hasBackdrop: false,
      data: { type: str, resp: response, rejectTxt: this.rejectionComments }
    });
    this.SpinnerService.hide();
    if (str == 'Amend') {
      matdrf.afterClosed().subscribe((resp: any) => {
        this.rejectionComments = resp;
        if (resp) {
          this.Reject();
        }
      })
    } else if (str == 'manpower_metadata') {
      this.SpinnerService.show();
      matdrf.afterClosed().subscribe((resp: any) => {
        if(resp) {
          this.manpower_metadata = resp;
          this.createTimeSheetDisplayData("new");
        }
        this.SpinnerService.hide();
      })
    } else if (str == 'manpower') {
      this.SpinnerService.show();
      matdrf.afterClosed().subscribe((resp: any) => {
        if (resp) {
          this.manPowerAPI_request = {
            "startdate": resp?.dates?.startdate,
            "enddate": resp?.dates?.enddate,
            "data": []
          }
          if(this.dataService.isEditGRN){
            this.manPowerAPI_request.grnDocumentId = this.invoiceID;
          }
          const response = this.prepare_manpower_payload(resp.data);
          this.manPowerAPI_request.data = response;
          this.manPowerGRNData = resp.data;
          
          // Replacing the GRN Qty with timesheet qty
          this.lineDisplayData = this.lineDisplayData.map(el => {
            for(const tag in el){
              if(tag == 'GRNQty'){
                this.manPowerGRNData.forEach(x=>{
                  if(x.LineNumber.Value == el.LineNumber.Value){
                    this.onChangeGrn('GRNQty', x.GRNQty.Value, x);
                    el.GRNQty.Value = x.GRNQty.Value;
                  }
                })
              }
            }
            return el;
          })
          this.SpinnerService.hide();
        }
        this.SpinnerService.hide();
      })
    }
  }

  prepare_manpower_payload(inputData){
    this.saveDisabled = false;
    let shiftData = [];
    inputData.forEach(el=>{
      let quantity = [];
      for(const tag in el){
        if(!['GRNAmountExcTax','GRNQty','LineNumber','Name','PurchId','PurchQty','RemainPurchPhysical','RemainInventPhysical','UnitPrice','durationMonth','isTimesheets','monthlyQuantity','shiftName','shifts','checked','percentage_po'].includes(tag)){
          if(el[tag].Value){
            quantity.push({date:tag, quantity: el[tag].Value})
          } else {
            this.saveDisabled = true;
          }
        }
      }
      shiftData.push({
        itemCode: el.LineNumber.Value,
        shift: el.shiftName.Value,
        quantity: JSON.stringify(quantity),
        durationmonth: el.durationMonth.Value
      });
    })
    return shiftData
  }

  createTimeSheetDisplayData(str) {
    this.dataService.GRN_PO_Data.forEach(ele => {
      this.manpower_metadata.forEach(meta => {
        if (ele.LineNumber == meta.itemCode) {
          ele.isTimesheets = str == "new" ? meta.isTimesheets : true;
          ele.durationMonth = ele.isTimesheets ? meta.durationMonth || "NA" : "NA";
          ele.shifts = ele.isTimesheets ? meta.shifts || "NA" : "NA";
          let monthlyQuantity = 0;
          if (ele.durationMonth && ele.isTimesheets) {
              monthlyQuantity = ele.PurchQty / ele.durationMonth;
            }
          ele.monthlyQuantity = this.decimalRoundOff(monthlyQuantity);
          if(ele.isTimesheets){
            this.isManpower = true;
          }
        }
      })
    })
    this.isManpowerTags = true;
    this.create_GRN_PO_tags();
    if (str == 'new') {
      this.get_PO_GRN_Lines();
    }
    this.SpinnerService.hide();
  }
  createTimesheetAPI() {
    
    this.exceptionService.createTimesheet(this.SharedService.po_doc_id,this.manPowerAPI_request, 'PO').subscribe((data: any) => {
      if (data.status.toLowerCase() == 'success') {
        this.success(data.message);
        this.manpowerHeaderId = data.ManPowerHeaderId;
      } else {
        this.error(data.message);
      }
    },err=>{
      this.error("Server error");
      this.SpinnerService.hide();
    })
  }
  create_GRN_PO_tags() {
    this.lineTable.splice(8, 0, { header: 'Duration in months', field: 'durationMonth' }, { header: 'Monthly quantity', field: 'monthlyQuantity' }, { header: 'Is Timesheets', field: 'isTimesheets' }, { header: 'Number of Shifts', field: 'shifts' })
  }
  open_dialog(str) {
    if (str == 'reject') {
      this.rejectModalHeader = 'ADD Rejection Comments';
    } else if (str == 'approve') {
      this.rejectModalHeader = 'Add Pre-approval Comments';
      if (this.preApproveBoolean == false) {
        this.rejectModalHeader = 'Please Add Comments';
        this.isRejectCommentBoolean = false;
        this.isApproveCommentBoolean = true;
        this.isLCMSubmitBoolean = false;
      }
    } else if (str == 'more') {
      this.rejectModalHeader = 'Please Add Comments';
      this.moreInfoBool = true;
    } else {
      this.rejectModalHeader = "Check Item Code Availability";
    }
    this.displayrejectDialog = true;

  }
  addComments(val) {
    this.rejectionComments = val;
    if (this.rejectionComments.length > 1) {
      this.commentsBool = false;
    } else {
      this.commentsBool = true;
    }
  }
  removeLine(itemCode) {
    this.exceptionService.removeLineData(itemCode).subscribe((data: any) => {
      if (data.status == "deleted") {
        this.success("Line item deleted")

        this.displayrejectDialog = false;
        this.getInvoiceFulldata('');
      }
    }, err => {
      this.error("Server error");
      this.displayrejectDialog = false;
    })
  };

  CheckItemStatus(item) {
        let addLineData = {
          "documentID": this.invoiceID,
          "itemCode": item
        };
        this.exceptionService.addLineItem(addLineData).subscribe((data: any) => {
          if (data.status == "added"){
            this.success("Line item Added")
            this.getInvoiceFulldata('');
          } else {
            this.error(data.message)
          }

        });
        this.displayrejectDialog = false;
  }
  getGRNnumbers(po_num) {
    this.SpinnerService.show();
    this.SharedService.checkGRN_PO_duplicates(po_num).subscribe((data: any) => {
      this.grnList = data?.result;
      this.filterGRNlist = this.grnList;
      this.SpinnerService.hide();
    }, error => {
      this.SpinnerService.hide();
      this.error("Server error");
    })
  }
  ChangeGRNData() {
    if (this.selectedGRNList.length > 0) {
      this.progressDailogBool = false;
      this.SharedService.updateGRNnumber(this.selectedGRNList).subscribe(data => {
        this.getGRNtabData();
        this.success("GRN Data Updated. Kindly click 'Next' button to send the invoice to the batch");
        if(this.router.url.includes('Inv_vs_GRN_details')){
          this.syncBatch();
        }

      }, err => {
        this.error("Server error");
      })
    }
  }

  getGRNtabData() {
    this.SpinnerService.show();
    this.SharedService.getGRNTabData().subscribe((data: any) => {
      this.GRNTabData = data?.result;
      this.grnTabDatalength = Object.keys(this.GRNTabData).length;
      this.SpinnerService.hide();
    }, err => {
      this.error("Server error");
      this.SpinnerService.hide();
    })
  }

  setOpened(itemIndex) {
    this.currentlyOpenedItemIndex = itemIndex;
  }

  setClosed(itemIndex) {
    if (this.currentlyOpenedItemIndex === itemIndex) {
      this.currentlyOpenedItemIndex = -1;
    }
  }

  opengrnDailog() {
    if(!(this.grnList?.length > 0)){
      this.getGRNnumbers(this.poDocId);
    }
    this.GRNDialogBool = true;
    this.progressDailogBool = true;
    this.headerpop = 'Select GRN';
    this.p_width = '70vw';
  }

  getPODocId(po_num,ent_id,ven_ac_id) {
    this.SharedService.get_poDoc_id(po_num,ent_id,ven_ac_id).subscribe((data: any) => {
      this.poDocId = data.result;
      this.SharedService.po_doc_id = data.result;
    })
  }
  selectedGRN(event, grn_num) {
    this.SpinnerService.show();
    if(grn_num.mapped && event.target.checked){
      const drf:MatDialogRef<ConfirmationComponent> = this.confirmFun("Are you sure you want to map this GRN again?", 'confirmation', 'Confirmation')
      drf.afterClosed().subscribe((bool:Boolean)=>{
        if(bool){
          this.grnFucntion(event, grn_num);
        } else {
          this.grnList.forEach(grn=>{
            if(grn.GRNNumber == grn_num.GRNNumber){
              grn.isChecked = false;
            }
          });
          this.SpinnerService.hide();
        }
      })
    } else {
      this.grnFucntion(event, grn_num);
    }
  }
  grnFucntion(event, grn_num){
    this.SpinnerService.hide();
    delete this.updateInvoiceData;
    let bool: boolean = event.target.checked;
    this.readGRNLines(grn_num.GRNNumber, bool);
  }
  readGRNLines(grn_num, bool) {
    this.SpinnerService.show();
    this.SharedService.getGRN_Lines(grn_num).subscribe((data: any) => {
      this.PO_GRN_Number_line = data.result[0];
      this.SpinnerService.hide();
      if (bool) {
        this.grnList.forEach(grn=>{
          if(grn.GRNNumber == grn_num){
            grn.isChecked = true;
          }
        });
        let data = [...this.PO_GRN_Number_line]
        this.selectedGRNList.push(grn_num);
        this.selectedGRNLines.push({ grnNumber: grn_num, linesData: data });
      } else {
        this.grnList.forEach(grn=>{
          if(grn.GRNNumber == grn_num){
            grn.isChecked = false;
          }
        })
        if (this.selectedGRNList.length > 0) {
          this.selectedGRNList = this.selectedGRNList.filter(grn => grn != grn_num);
          this.selectedGRNLines = this.selectedGRNLines.filter(grn => grn.grnNumber != grn_num);
        }
      }
      let total_arr = []
      this.selectedGRNLines.forEach(el => {
        total_arr.push(el.linesData)
      })
      this.selected_GRN_total = total_arr.reduce((acc, arr) => {
        return acc + arr.reduce((subAcc, obj) => {
          if (obj.AmountExcTax) {
            return subAcc + parseFloat(obj.AmountExcTax);
          } else {
            return subAcc + (parseFloat(obj.UnitPrice) * parseFloat(obj.Quantity));
          }
        }, 0);
      }, 0);
    }, err => {
      this.error("Server error");
      this.SpinnerService.hide();
    })
  }
  getPO_numbers(idVen) {
    this.SpinnerService.show();
    this.SharedService.getPo_numbers(idVen).subscribe((data: any) => {
      this.poNumbersList = data.result;
      this.filteredPO = data.result;
      this.SpinnerService.hide();
    })
  }

  poDailog(data) {
    this.progressDailogBool = true;
    this.headerpop = "Confirm PO number";
    this.p_width = '70vw';
    this.getPO_numbers(this.vendorId);
    this.searchPOArr = data
    this.getDate();
  }
  filterPOnumber(event) {
    if (this.poNumbersList?.length > 0) {
      this.filteredPO = this.dataService.uni_filter(this.poNumbersList,'PODocumentID',event);
    }
  }
  POsearch(val) {
    if (this.headerpop == 'Confirm PO number') {
      this.poNumbersList = this.filteredPO;
      if (this.poNumbersList?.length > 0) {
        this.poNumbersList = this.poNumbersList.filter(el => {
          return el.Document.PODocumentID.toLowerCase().includes(val.toLowerCase())
        });
      }
    } else {
      this.grnList = this.filterGRNlist;
      if (this.grnList?.length > 0) {
        this.grnList = this.grnList.filter(el => {
          return el.GRNNumber.toLowerCase().includes(val.toLowerCase())
        });
      }
    }
  }
  selectedPO(id, event) {
    delete this.updateInvoiceData;
    let id_ = `${id}-confirmPo`;
    let po_num_text = document.getElementById(id_).innerHTML;
    let po_num = po_num_text.trim();
    let poData = this.poNumbersList.filter((val) => {
      return val.PODocumentID == po_num
    });
    this.poDate = JSON.parse(poData[0].CreateDateTime);
    this.activePOId = po_num;
    this.SharedService.po_doc_id = po_num;
    this.SharedService.po_num = po_num;
    this.readPOLines(po_num, poData[0].idDocument);
  }

  readPOLines(po_num,po_id) {
    this.SpinnerService.show();
    this.SharedService.getPO_Lines(po_num,po_id).subscribe((data: any) => {
      this.SpinnerService.hide();
      this.PO_GRN_Number_line = data?.result;
      return data?.result;
    }, err => {
      this.error("Server error");
      this.SpinnerService.hide();
    })
  }

  confirmPO() {
    let updateValue = {
      "header": {}
    };
    updateValue.header['PurchaseOrder'] = this.SharedService.po_num;
    this.updateInvoiceData = updateValue;
    this.saveChanges();
    this.progressDailogBool = false;
    setTimeout(() => {
      this.readLineItems();
      this.getInvoiceFulldata('');
    }, 1000);
  }
  getDate() {
    this.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let today = new Date();
    let month = today.getMonth();
    this.selectedMonth = this.months[month];
    let year = today.getFullYear();
    this.lastYear = year - 2;
    this.displayYear = `${this.lastYear}:${year}`;
    let prevYear = year - 2;

    this.minDate = new Date();
    this.minDate.setMonth(month);
    this.minDate.setFullYear(prevYear);

    this.maxDate = new Date();
    this.maxDate.setMonth(month);
    this.maxDate.setFullYear(year);
  }


  filterByDate(date) {
    if (date != '') {
      const frmDate = this.datePipe.transform(date[0], 'yyyy-MM-dd');
      const toDate = this.datePipe.transform(date[1], 'yyyy-MM-dd');
      if (frmDate && toDate) {
        if (this.datePicker.overlayVisible) {
          this.datePicker.hideOverlay();
        }
        this.poNumbersList = this.filteredPO;
        this.poNumbersList = this.poNumbersList.filter((element) => {
          const dateF = this.datePipe.transform(element.DocumentData.Value, 'yyyy-MM-dd')
          return dateF >= frmDate && dateF <= toDate;
        });
      }
    } else {
      this.poNumbersList = this.filteredPO;
    }
  }
  clearDates() {
    this.filterByDate('');
  }

  status_dialog() {
    this.progressDailogBool = true;
    this.headerpop = "Please select the status";
    this.p_width = '350px';
    this.status_arr = [
      { name: 'Sent to ERP', st_obj: { status: '7', subStatus: '77' } },
      { name: 'Posted', st_obj: { status: '14', subStatus: '77' } }
    ];
  }
  status_change() {
    this.SpinnerService.show();
    let obj = {
      "documentStatusID": this.status_change_op.status,
      "documentsubstatusID": this.status_change_op.subStatus
    }
    this.SharedService.changeStatus(obj).subscribe((data: any) => {
      this.success(data.result);

      this.progressDailogBool = false;
      this.SpinnerService.hide();
    }, err => {
      this.SpinnerService.hide();
      this.error("Server error");
    })
  }

  onOptionDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.sampleLineData, event.previousIndex, event.currentIndex);
  }
  Approve_grn() {
    this.SpinnerService.show();
    this.exceptionService.approve_grn().subscribe((data: any) => {
      this.SpinnerService.hide();
      if (data.status?.toLowerCase() == 'posted') {
        this.success(data.message);

        setTimeout(() => {
          this._location.back();
        }, 1000);
      } else {
        this.error(data.message);
      }
    }, err => {
      this.SpinnerService.hide();
      if (err.status == 403) {
        this.error(err.error.Error);
      } else {
        this.error('Server error');
      }
    })
  }
  canDeactivate(): boolean {
    if (!this.uploadCompleted) {
      return window.confirm(
        'Please click the "Next" button to process the document otherwise upload processs will terminate. Do you really want to leave?'
      );
    }
    return true;
  }
  onSelectFileApprove(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.uploadFileList.push(event.target.files[i]);
    }
    this.uploadSupport(this.invoiceID);
  }
  onSelectFile(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.uploadFileList.push(event.target.files[i]);

    }
  }
  removeUploadQueue(index) {
    this.uploadFileList.splice(index, 1);
  }

  uploadSupport(id) {
    this.progress = 1;
    const formData: any = new FormData();
    let cleanedFile,extension;
    for (const file of this.uploadFileList) {
      const cleanedFileName = file.name.replace(/\.(?=.*\.)/g, '');
      extension = cleanedFileName.split('.')[1];
      cleanedFile = cleanedFileName.split('.')[0];
      formData.append('files', file, cleanedFileName);
    }
    let filename = `supporting_doc_${cleanedFile}_${this.invoiceID}.${extension}`;
    if(this.support_doc_list.includes(filename)){
      this.error(`The supporting document ${filename} is already uploaded.`); 
      return;
    }
    this.SpinnerService.show();
    this.SharedService.uploadSupportDoc(id,formData)
      .pipe(
        map((event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.progress = Math.round((100 / event.total) * event.loaded);
          } else if (event.type == HttpEventType.Response) {
            this.progress = null;
            this.success("Supporting Documents uploaded Successfully");
            this.uploadFileList = [];
            this.support_doc_list = [];
            event.body?.result?.forEach(ele => {
              this.support_doc_list.push(ele);
            });
            this.SpinnerService.hide()
          }
        }),
        catchError((err: any) => {
          this.progress = null;
          this.error("Server error");
          this.SpinnerService.hide()
          return throwError(err.message);
        })
      )
      .toPromise();
  }

  downloadDoc(doc_name, type) {
    let encodeString = encodeURIComponent(doc_name);
    this.SharedService.downloadSupportDoc(encodeString).subscribe(
      (response: any) => {
        let blob: any = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        if (type == 'view') {
          const dailogRef: MatDialogRef<SupportpdfViewerComponent> = this.mat_dlg.open(SupportpdfViewerComponent, {
            width: '90vw',
            height: '95svh',
            hasBackdrop: true,
            data: { file: url }
          })
        } else {
          fileSaver.saveAs(blob, doc_name);
          this.success("Document downloaded successfully.");
        }

      },
      (err) => {
        this.error("Server error");
      }
    );
  }

  deleteSupport(file){
    const drf: MatDialogRef<ConfirmationComponent> = this.confirmFun('Are you sure you want to delete this file?', 'confirmation', 'Confirmation');
    drf.afterClosed().subscribe((bool:boolean)=>{
      if(bool){
        this.SpinnerService.show();
        this.SharedService.deleteSupport(file).subscribe((data:any)=>{
          if(data.status == 'success'){
           this.support_doc_list = [];
            this.support_doc_list = data.files;
            this.success("Deleted successfully.");
            this.SpinnerService.hide();
          } else {
            this.error("Failed to delete file.");
            this.SpinnerService.hide();
          }
        },err=>{
          this.error("Server error");
          this.SpinnerService.hide();
        })
      }
    })
  }

  grnAttachmentDoc(base64, type) {
    let blob: any = this.base64ToBlob(base64);
    const url = window.URL.createObjectURL(blob);
    if (type == 'view') {
      const dailogRef: MatDialogRef<SupportpdfViewerComponent> = this.mat_dlg.open(SupportpdfViewerComponent, {
        width: '90vw',
        height: '95svh',
        hasBackdrop: true,
        data: { file: url }
      })
    } else {
      fileSaver.saveAs(blob, `GRN attachment_invoice_number_${this.invoiceNumber}.pdf`);
      this.success("Document downloaded successfully.");
    }
  }

  getGrnAttachment() {
    this.SharedService.getGRNAttachment().subscribe((data: any) => {
      this.grnAttachmentArray = data;
    }, err => {
      this.error("Server error");
    })
  }

  base64ToBlob(base64) {
    // Extract the MIME type from the Base64 string if present
    const base64Pattern = /^data:(.*);base64,(.*)$/;
    const matches = base64?.match(base64Pattern);

    let mimeType;
    let data;

    if (matches) {
      mimeType = matches[1]; // Extracted MIME type
      data = matches[2]; // Base64 data
    } else {
      // Fallback MIME type if not specified in the Base64 string
      mimeType = 'application/octet-stream';
      data = base64;
    }

    // Decode the Base64 string
    const byteCharacters = atob(data);

    // Create an array for the byte characters
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Convert the byte numbers array into a Uint8Array
    const byteArray = new Uint8Array(byteNumbers);
    // Create a Blob from the Uint8Array
    return new Blob([byteArray], { type: mimeType });
  }

  getEntity() {
    this.dataService.getEntity().subscribe((data: any) => {
      this.entityList = data;
      this.SharedService.selectedEntityId = this.dataService.entityID;
      this.entityList.forEach(val => {
        if (this.dataService.entityID == val.idEntity) {
          this.entityName = val.EntityName;
          this.EntityName = val.EntityName;
        }
      })
    });
  }

  readDepartment() {
    this.SharedService.getDepartment().subscribe((data: any) => {
      this.DepartmentList = data.department;
      let deparmrnt_id;
      if (this.tagService.batchProcessTab == 'editApproveBatch') {
        deparmrnt_id = this.dataService.editableInvoiceData.approverData.hierarchy_details.DepartmentID;
        this.DepartmentList.forEach(ele => {
          if (ele.idDepartment == deparmrnt_id) {
            this.selectedDepartment = ele?.DepartmentName;
          }
        })
      } else {
        deparmrnt_id = this.DepartmentList[0]?.idDepartment
        this.selectedDepartment = this.DepartmentList[0]?.DepartmentName;
      }
      this.approversSendData.push({
        EntityID: this.SharedService.selectedEntityId,
        DepartmentID: deparmrnt_id
      })
      this.readApproverData();
      // this.approversSendData[0].DepartmentID = this.DepartmentList[0]?.idDepartment ? this.DepartmentList[0]?.idDepartment : null;
      // this.entityDeptList = this.entityBodyList[0].department
    });
  }
  onSelectDepartment(val) {
    this.DepartmentList.forEach(ele => {
      if (ele.DepartmentName == val) {
        this.approversSendData[0].DepartmentID = ele.idDepartment
      }
    })
    this.readApproverData();
  }
  readCategoryData() {
    this.SharedService.readCategory().subscribe((data: any) => {
      this.categoryList = data;
    })
  }

  onSelectCategory(val) {
    this.approversSendData[0].categoryID = val;
  }

  readApproverData() {
    this.SpinnerService.show();
    this.approversSendData[0].approver = [];
    this.approverList = {}
    this.SharedService.readApprovers(this.approversSendData[0]).subscribe((data: any) => {
      let resultData = data?.result
      let array = [];
      let list = [];
      let count = 0;
      for (const item in resultData) {
        count = count + 1;
        list = resultData[item].sort((a, b) => a.userPriority - b.userPriority);
        this.approverList[`${item}_${count}`] = list;
        array.push(resultData[item][0]?.User?.idUser);
      }
      this.approversSendData[0].approver = array;
      this.SpinnerService.hide();
    }, err => {
      this.SpinnerService.hide();
      if (err.status == 403) {
        this.error("Approvers are not available for this combination");
      } else {
        this.error("Server error");
      }
    });
  }

  onSelectApprovers(value, index) {
    this.approversSendData[0].approver[index] = value;
  }

  onSelectPreApprove(bool) {
    if (bool == true) {
      this.open_dialog('approve');
    }
  }

  onSubmitApprovers() {
    this.approversSendData[0].description = this.rejectionComments;
    if (this.preApproveBoolean == false) {
      this.sendApprovalAPI();
    } else {
      if (this.preApproveBoolean == true) {
        this.sendApprovalAPI();
      } else {
        this.error("Please add pre approval comments");
      }
    }
  }
  sendApprovalAPI() {
    this.SpinnerService.show();
    this.SharedService.setApprovers(this.approversSendData[0], this.preApproveBoolean).subscribe((data: any) => {
      this.SpinnerService.hide();
      if (data?.error_status) {
        this.error(data?.error_status)
      } else {
        this.success(data?.result);
        setTimeout(() => {
          this.router.navigate([`${this.portalName}/invoice/allInvoices`]);
        }, 1000);
      }
    },
      (err) => {
        this.SpinnerService.hide();
        this.error("Server error");
      })
  }
  filterEntity(event) {
    this.filteredEnt = this.dataService.uni_filter(this.entityList,'EntityName',event);
  }

  onSelectEntity(event, type) {
    if (type == 'change') {
      this.exceptionService.changeEntity(event.idEntity).subscribe((data: any) => {
        if (data.status == 'success') {
          this.success("Entity changed successfully and batch triggered.");
          this.syncBatch();
        } else {
          this.error(data.message)
        }

      }, err => {
        this.error("Server error")
      })
    } else {
      this.entityList.forEach(val => {
        if (event == val.EntityName) {
          this.readPONumbersLCM(val.idEntity);
        }
        this.LCMObj.EntityName = this.EntityName;
        this.LCMLineForm.control.patchValue(this.LCMObj);
      })
    }
  }
  readPONumbersLCM(ent_id) {
    this.SharedService.getLCMPOnum(ent_id).subscribe((data: any) => {
      this.POlist_LCM = data.LCMPoNumbers;
      this.LCMObj.EntityName = this.EntityName;
      this.LCMLineForm.control.patchValue(this.LCMObj);
    })
  }

  filterPOnumber_LCM(event) {
    this.filteredPO = this.dataService.uni_filter(this.POlist_LCM,'PODocumentID',event);
  }

  selectedPO_lcm(value) {
    this.selectedPONumber = value.PODocumentID;
    this.readLCMLines(value.PODocumentID);
  }
  readLCMLines(po_num) {
    this.LCMItems = [];
    this.SpinnerService.show();
    this.SharedService.getLCMLines(po_num).subscribe((data: any) => {
      let LCMLineData = data.PoLineData;
      let count = 0;
      for (const item in LCMLineData) {
        count = count + 1;
        this.LCMItems.push({ PoLineDescription: item, id: count, values: LCMLineData[item] });
      }
      this.voyageList = data.VoyageNumbers;
      this.SpinnerService.hide();
    }, err => {
      this.SpinnerService.hide();
      this.error("Server error");
    })
  }
  filterLCMLine(event) {
    this.filteredLCMLines = this.dataService.uni_filter(this.LCMItems,'PoLineDescription',event);
  }
  OnSelectLine(event) {
    this.selectedLCMLine = event.PoLineDescription;
    this.itemId = event.values.ItemId;
    // this.selectedVoyage = event.values.Voyage;
    // this.act_val = event.values.ActualizedValue;
    // this.est_val = event.values.EstimatedValue;
    // this.max_allocation = event.values.AllocateRange;
    this.lineNumber = event.values.LineNumber;
    this.ContextTableId = event.values.ContextTableId;
    this.ContextRecId = event.values.ContextRecId;
    // this.AGIVesselNumber = event.values.AGIVesselNumber;
    this.readChargeCode(event.values.dataAreaId, this.ContextRecId, this.ContextTableId)
  }

  readChargeCode(dataArea, ContextRecId, ContextTableId) {
    this.SpinnerService.show();
    this.SharedService.getChargesCode(dataArea, ContextRecId, ContextTableId).subscribe((data: any) => {
      this.chargeList = data.value;
      this.SpinnerService.hide();
    }, err => {
      this.SpinnerService.hide();
      this.error("Server error");
    })
  }
  filterCost(event) {
    this.filteredCost = this.dataService.uni_filter(this.chargeList,'MarkupCode',event);
  }
  onSelectCost(event) {
    this.selectedCost = event.MarkupCode;
    this.MarkupTransRecId = event.MarkupTransRecId;
    this.SpinnerService.show();
    this.SharedService.getEstActValue(this.selectedPONumber, this.lineNumber, event.MarkupCode).subscribe((data: any) => {
      this.selectedVoyage = data.voyage;
      this.act_val = data.act_val;
      this.est_val = data.est_val;
      this.AGIVesselNumber = data.vessel_num;
      this.SpinnerService.hide();
    }, err => {
      this.SpinnerService.hide();
    })
  }
  AddLCMLine(value) {
    let Obj = {
      EntityName: this.EntityName,
      PoDocumentId: this.selectedPONumber,
      PoLineDescription: this.selectedLCMLine,
      PoLineNumber: this.lineNumber,
      VoyageNumber: this.selectedVoyage,
      CostCategory: this.selectedCost,
      EstimatedValue: this.est_val,
      ActualizedValue: this.act_val,
      Allocate: value.Allocate,
      ContextTableId: this.ContextTableId,
      ContextRecId: this.ContextRecId,
      AGIVesselNumber: this.AGIVesselNumber,
      MarkupTransRecId: this.MarkupTransRecId
    }

    this.SaveLCM(Obj);
  }
  deleteLCMLine(index) {
    this.LCMDataTable.splice(index, 1);
  }
  onChange(evt) {
    const formData = new FormData();
    formData.append("file", evt.target.files[0]);
    this.uploadExcel_LCM(formData)
  }
  uploadExcel_LCM(file) {
    this.SpinnerService.show();
    this.SharedService.uploadLCM_xl(file).subscribe((data: any) => {
      if (data?.data) {
        this.readSavedLCMLineData();
        this.success(data.data);
      } else if (data?.error) {
        let str = data.error.toString();
        this.error(str);
      }
      this.SpinnerService.hide();
    }, err => {
      this.SpinnerService.hide();
      this.error("Server error");
    })
    delete this.uploadExcelValue;
  }
  downloadLCMTemplate() {
    this.SharedService.downloadLCMTemplate('').subscribe((data: any) => {
      this.excelDownload(data, 'LCM upload template');
    })
  }
  excelDownload(data, type) {
    let blob: any = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    let d = new Date();
    let datestring = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + " " +
      d.getHours() + ":" + d.getMinutes();
    fileSaver.saveAs(blob, `${type}-(${datestring})`);
  }
  readSavedLCMLineData() {
    this.SpinnerService.show()
    this.SharedService.getsavedLCMLineData().subscribe((data: any) => {
      this.LCMDataTable = data.data;
      const sum = this.LCMDataTable.reduce((accumulator, object) => {
        return accumulator + Number(object.Allocate);
      }, 0);
      this.allocateTotal = this.decimalRoundOff(sum);
      let bal: any = Number(this.invoiceTotal) - this.allocateTotal;
      this.balanceAmount = parseFloat(bal);
      this.balanceAmount = this.decimalRoundOff(this.balanceAmount);
      this.SpinnerService.hide();
    }, err => {
      this.SpinnerService.hide();
    })
  }
  SaveLCM(obj) {
    this.SpinnerService.show();
    this.SharedService.saveLCMdata([obj], true).subscribe((data: any) => {
      if (data?.result) {
        this.SpinnerService.hide();
        this.success(data?.result)
        this.LCMObj.EntityName = this.EntityName;
        this.LCMLineForm.control.patchValue(this.LCMObj);
        this.readSavedLCMLineData();
      } else if (data?.error) {
        this.SpinnerService.hide();
        this.error(data?.error);
      }
    }, err => {
      this.error('Server error');
    })
  }

  submitLCMLines() {
    this.SpinnerService.show();
    this.SharedService.saveLCMdata(this.LCMDataTable, false).subscribe((data: any) => {
      if (data?.result[2] == true) {
        this.success('LCM Lines added please select Approvers');
        this.isLCMCompleted = true;
        this.selectionTabBoolean = true;
        this.supportTabBoolean = true;
        this.isLCMInvoice = false;
        this.readDepartment();
        this.readCategoryData();
        this.getInvoiceFulldata('');
        this.currentTab = 'approver_selection';
      } else {
        this.success('LCM Lines created');
        setTimeout(() => {
          this._location.back();
        }, 1000);
      }
      this.displayrejectDialog = false;
      this.SpinnerService.hide();

    }, err => {
      this.displayrejectDialog = false;
      this.error('Server error');
      this.SpinnerService.hide();
    })

  }
  calculateCost() {
    let totalpoCost = 0;
    let totalinvCost = 0;
    this.lineData?.forEach(el=>{
      let pounitPrice = parseFloat(el?.lines?.UnitPrice?.po_line?.Value);
      const poquantity = parseFloat(el?.lines?.Quantity?.po_line?.Value);
      let invunitPrice = parseFloat(el?.lines?.UnitPrice?.Value);
      const invquantity = parseFloat(el?.lines?.Quantity?.Value);
      const po_discount = parseFloat(el?.lines?.Discount?.po_line?.Value);
      const inv_discount = parseFloat(el?.lines?.Discount?.Value);
      const po_discountPercent = parseFloat(el?.lines?.DiscPercent?.po_line?.Value);
      const inv_discountPercent = parseFloat(el?.lines?.DiscPercent?.Value);
      if(po_discount && po_discount != 0){
        pounitPrice = pounitPrice - po_discount;
      }
      if(inv_discount && inv_discount != 0){
        invunitPrice = invunitPrice - inv_discount;
      }
      if(po_discountPercent && po_discountPercent != 0){
        pounitPrice = pounitPrice - (pounitPrice * po_discountPercent / 100);
      }
      if(inv_discountPercent && inv_discountPercent != 0){
        invunitPrice = invunitPrice - (invunitPrice * inv_discountPercent / 100);
      }
    
      if (!isNaN(pounitPrice) && !isNaN(poquantity)) {
        totalpoCost += pounitPrice * poquantity;
      }
      if (!isNaN(invunitPrice) && !isNaN(invquantity)) {
        totalinvCost += invunitPrice * invquantity;
      }
    });
      this.po_total = this.decimalRoundOff(totalpoCost);
      this.totalInvCost = this.decimalRoundOff(totalinvCost);
   
  }
  getRejectionComments() {
    this.exceptionService.rejectCommentsList().subscribe((data: any) => {
      this.approvalRejectRecord = data;
    })
  }
  success(msg) {
    this.AlertService.success_alert(msg);
  }
  error(msg) {
    this.AlertService.error_alert(msg);
  }
  update(msg) {
    this.AlertService.update_alert(msg);
  }

  reqDataValidation() {
    for (const row of this.rows) {
      if (!row.driver_name || !row.company_name) {
        this.isFormValid = true;
        break;
      }
    }
  }
  updateEditedValue(iddynamiccostallocation: any, key: string, value: any) {
    this.editedValues[iddynamiccostallocation + ',' + key] = value;

  }
  addRow(index: number) {
    this.isFormValid = false;
    this.rows.push(this.getNewRow());
  }
  getNewRow() {
    return { driver_name: '', company_name: '' };
  }
  removeRow(index: number) {
    // Remove the row at the specified index
    this.rows.splice(index, 1);
  }

  getInvTypes() {
    this.exceptionService.getInvTypes().subscribe((data: any) => {
      this.invTypeList = data.data;
      data.data.forEach(el => {
        if (el?.toLowerCase() == this.docType) {
          this.docType = el;
        } 
        const docTypeMap = {
          'credit': 'Invoice',
        };

        this.docType = docTypeMap[this.docType] || this.docType;
        this.d_type = this.docType;
      })
    })
  }

  onSelectInvType(event) {
    this.exceptionService.changeInvType(event?.value?.toLowerCase()).subscribe((data: any) => {
      if (data.status == 'success') {
        this.success("Invoice type changed successfully, and sent to batch.");
        this.syncBatch();
      } else {
        this.error(data.message)
      }
    }, err => {
      this.error("Server error")
    })
  }

  openBox() {
    this.isBoxOpen = true;
    this.activeTab = 'percentage';
  }

  saveData(tab: string) {
    let pa_data;
    let inv_type = true;

    if (tab === 'percentage') {
      pa_data = this.percentageData;
      inv_type = true;
    } else {
      pa_data = this.amountData;
      inv_type = false;
    }
    this.SharedService.uploadPercentageAndAmountDetails(pa_data, inv_type, tab).subscribe((data: any) => {
      this.success("Submitted successfully")
      setTimeout(() => {
        this.isBoxOpen = false;
      }, 100);
    }, err => {
      this.error("Server error");
      setTimeout(() => {
        this.isBoxOpen = false;
      }, 100);
    });
  }
  closeBox(): void {
    this.isBoxOpen = false;
    // Reset other properties as needed
  }
  updateButtonState(tab: string) {
    // Enable the button only when both percentageData and amountData are provided
    this.isButtonDisabled = !(this.percentageData || this.amountData);
    if (this.percentageData == '') {
      this.resultAmount = 0;
    }
    if (tab === 'percentage') {
      this.isButtonDisabled = !/^[0-9]*$/.test(this.percentageData);
      this.SharedService.getAmountofPercentage(this.percentageData).subscribe((data: any) => {
        this.resultAmount = data;
        // this.cdr.detectChanges();
      });
    } else if (tab === 'amount') {
      this.isButtonDisabled = !/^[0-9]*$/.test(this.amountData);
    }
  }

  onChangeAdvance(data, in_value, tagName) {
    this.advanceAPIbody = {
      "linenumber": data.itemCode,
      "prev_value": data.Value.toString(),
      "id_documentline": data.idDocumentLineItems,
      "value": in_value,
      "tagname": tagName
    };
  }
  saveAdChanges() {
    if (this.advanceAPIbody && this.advanceAPIbody.value != '') {
      this.SpinnerService.show();
      this.exceptionService.getAdPercentage(this.advanceAPIbody).subscribe((data: any) => {
        this.SpinnerService.hide();
        if (data?.status == "Success") {
          this.success("Updated succesfully");
          this.inv_line_total = 0;
          this.lineDisplayData.forEach(tag => {
            let tagName = 'AmountExcTax';
            if (this.advanceAPIbody.tagname == 'AmountExcTax') {
              tagName = 'AdvancePercent';
            }
            tag.linedata.forEach(ele => {
              if (tag.TagName == tagName) {
                if (ele.DocumentLineItems.itemCode == this.advanceAPIbody.linenumber) {
                  ele.DocumentLineItems.Value = data?.result?.value;
                }
              }
              // if(this.advanceAPIbody.tagname == 'AmountExcTax'){
              //   this.inv_line_total = parseFloat(this.advanceAPIbody.value) + this.inv_line_total ;
              // }
              if (tag.TagName == 'AmountExcTax') {
                this.inv_line_total = this.inv_line_total + parseFloat(ele.DocumentLineItems.Value);
              }
            })
          })
        } else {
          this.error(data?.msg);
        }
      }, err => {
        this.SpinnerService.hide();
        this.error("Server error");
      })
    }
  }

  filterPreDrop(event, tag) {
    let arr = [];
    if (tag == 'PurchaseOrder') {
      arr = this.poList;
    } else {
      arr = this.invNumbersList;
    }
    this.filteredPreData = this.dataService.uni_filter(arr,tag,event);
  }
  filterDropNon(event,tag){
    this.SpinnerService.show();
    let timeout = 10;
    if(this.old_tag != tag || this.fieldData == undefined){
      if(tag == 'BankAccount'){
        timeout = 1500;
      } else {
        timeout = 500;
      }
      this.old_tag = tag;
      this.fieldData = [];
      let param = `&inv_id=${this.invoiceID}&ent_id=${this.dataService.entityID}&id_vendor=${this.vendorId}`;
      this.exceptionService.getLabelData(tag,param).subscribe((data:any)=>{
        this.fieldData = data;
        this.fieldData.forEach(ele=>{
          ele.lookupid = `${ele?.lookupid}  -  ${ele?.lookupvalue}`;
        });
      })
    }
    setTimeout(() => {
      let filtered: any[] = [];
      let query = event.query;
      if(this.fieldData?.length > 0){
        for (let i = 0; i < this.fieldData?.length; i++) {
          let account: any = this.fieldData[i];
          if (account?.lookupid?.toString()?.toLowerCase()?.includes(query?.toLowerCase())) {
            filtered.push(account);
          }
        }
      } else {
        this.SpinnerService.hide();
      }
      if (filtered.length == 0) {
        filtered = [{ lookupid: "No results found", value: "No results found",  disabled: true }];
      }
      this.filteredPreData = filtered;
      this.SpinnerService.hide();
    }, timeout);
  }
  // onSelectPrePay(event, value, tagname, index,type) {
  //   let old_value,tag_id;
  //   if(this.documentType == 'non-po credit note' || this.documentType == 'non-po tax invoice'){
  //     this.temp_line_data_non_po?.forEach(tag => {
  //       if (tag.TagName == tagname) {
  //         old_value = tag.linedata[index].DocumentLineItems.Value
  //         tag_id = tag.linedata[index].DocumentLineItems.idDocumentLineItems;
  //       }
  //     })
  //   } else {
  //     this.temp_line_data.forEach(tag => {
  //       if (tag.tagname == tagname) {
  //         old_value = tag.items[index].linedetails[0].invline[0].DocumentLineItems.Value
  //       }
  //     })
  //   }
  //   let obj = {
  //     tag_id: ['non-po tax invoice','non-po credit note'].includes(this.documentType)? tag_id:value.idDocumentLineItems,
  //     tag_name: tagname,
  //     prev_value: old_value || '',
  //     curr_value: ['non-po tax invoice','non-po credit note'].includes(this.documentType)? event.lookupid:event,
  //   }
  //   if(type == 'Date'){
  //     obj.curr_value = this.datePipe.transform(event,'dd/MM/yyyy').toString();
  //     obj.prev_value = old_value ? this.datePipe.transform(old_value,'dd/MM/yyyy').toString() : '';
  //   }
  //   this.updatedNonPOLine = obj
  //   this.saveCustomData();
  // }

  onChangeCustomData(tagname, value, itemData) {
    let obj = {
      tag_id: itemData.idDocumentLineItems,
      tag_name: tagname,
      prev_value: itemData.Value || '',
      curr_value: value,
    }
    this.updatedNonPOLine = obj;
  }

  saveCustomData() {
    if(this.updatedNonPOLine){
      this.exceptionService.savePreData(this.updatedNonPOLine).subscribe((data: any) => {
        this.success('Changes saved successfully');
        delete this.updatedNonPOLine;
      }, err => {
        this.error("Server error or Please check the data");
      })
    }
  }
  onSelectPrePay(tagname,event, value, type) {
    if(type == 'Dropdown'){
      this.lineDisplayData.forEach(tag=>{
        if(value.ItemCode == tag.ItemCode){
          tag.lines[tagname].Value = event.lookupid;
          tag.lines[tagname].lookupid = event.lookupid;
          tag.lines[tagname].fieldType = type;
        }
      })
      this.onChangeLineValue(tagname,event.lookupid,value);
    } else if(type == 'Date'){
      let date = this.datePipe.transform(event,'dd/MM/yyyy').toString()
      this.onChangeLineValue(tagname,date,value);
    }
    setTimeout(() => {
      this.saveChanges();
    }, 1000);
  }

  filterProject(event, tag) {
    let arr = [];
    this.SpinnerService.show();
    if (tag == 'Project') {
      arr = this.projectIdArr;
    } else {
      arr = this.projectCArr;
    }
    this.filteredProject = this.dataService.uni_filter(arr,tag,event);
    this.SpinnerService.hide();
  }

  onSelectProject(event, value) {
    this.onChangeValue(value.TagLabel,event,value);
    setTimeout(() => {
      this.saveChanges();
    }, 1000);
  }
  ap_api_body() {
    return {
      "desp": `${this.rejectionComments}`,
      "bulk_approval": this.bulk_bool
    }
  }
  moreInfoFun() {
    this.SpinnerService.show();
    let desc = this.ap_api_body();
    this.exceptionService.sendToMore(desc).subscribe((data: any) => {
      if (data.result == 'success') {
        this.success(data.message);
        setTimeout(() => {
          this.SpinnerService.hide();
          this._location.back();
        }, 1000);
      } else {
        this.error(data.message);
      }
      this.SpinnerService.hide();
    })
  }
  proceedMoreInfo() {
    this.SpinnerService.show();
    let desc = this.ap_api_body();
    this.exceptionService.proceedMoreInfo(desc).subscribe((data: any) => {
      if (data.result == 'success') {
        this.success(data.message);
        setTimeout(() => {
          this._location.back();
        }, 1000);
      } else {
        this.error(data.message);
      }
      this.SpinnerService.hide();
    })
  }
  onHighlight(data) {
    this.rectData = data;
  }
  onChecked(value) {
    this.isManpower = value
  }

  progressiveAmount(amount, id) {
    this.exceptionService.amountToApply(amount, id).subscribe((data: any) => {
      if (data?.status == 'sucess') {
        this.success(data.msg)
      } else {
        this.error(data.msg)
      }
    }, err => {
      this.error("Server error")
    })
  }

  getProjectData(){
    this.exceptionService.readProjectData().subscribe((data:any)=>{
      this.projectIdArr = data.result.value;

    },err=>{
    })
  }
  getProjectCatData(){
    this.exceptionService.readProjectCatData().subscribe((data:any)=>{
      this.projectCArr = data.result.value;
    },err=>{
    })
  }


  selectLine(bool,index,item){
    let selctionIds = [];
    this.lineDisplayData.forEach(el=>{
      if(el.checked && !selctionIds.includes(el.ItemCode)){
        selctionIds.push(el.ItemCode);
      } else if(!el.checked && selctionIds.includes(el.ItemCode)){
        selctionIds = selctionIds.filter(id => id !== el.ItemCode);
      }
    })
    if(selctionIds.length == this.lineDisplayData?.length){
      this.selectALL_grn_lines = true;
    } else {
      this.selectALL_grn_lines = false;
    }

  }

  selectAllLines(bool){
    this.lineDisplayData.forEach(el=>{
      el.checked = bool;
    })
  }
  shouldRenderInput(){
    return this.client_name === 'Cenomi' && this.GRN_PO_Bool;
  }
  getFieldType() {
    this.exceptionService.getFieldType('').subscribe((data: any) => {
      this.fieldType = data;
    })
   }
   getLableData(name){
     let param = `&inv_id=${this.invoiceID}&ent_id=${this.dataService.entityID}&id_vendor=${this.vendorId}`;
     let fieldData:any;
     this.exceptionService.getLabelData(name,param).subscribe((data:any)=>{
       fieldData = data;
     },err=>{
       this.error("Server error");
     })
     return fieldData;
   }
  
  ngOnDestroy() {
    let sessionData = {
      session_status: false,
      "client_address": JSON.parse(sessionStorage.getItem('userIp'))
    };
    this.exceptionService
      .updateDocumentLockInfo(sessionData)
      .subscribe((data: any) => { });
    clearTimeout(this.callSession);
    this.AlertService.addObject.severity = 'success';
    this.tagService.financeApprovePermission = false;
    this.tagService.approveBtnBoolean = false;
    this.tagService.submitBtnBoolean = false;
    this.dataService.grnWithPOBoolean = false;
    this.dataService.poLineData = [];
    delete this.SharedService.fileSrc;
    delete this.dataService.documentType;
    delete this.tagService.approval_selection_boolean;
    delete this.dataService.subStatusId;
    delete this.dataService.added_manpower_data;
    delete this.dataService.number_of_days;
    delete this.SharedService.po_num;
    delete this.exceptionService.po_num;
    delete this.SharedService.invoiceID;
    delete this.exceptionService.invoiceID;
    delete this.dataService.grn_manpower_metadata;
    delete this.dataService.manpowerResponse
    delete this.tagService?.headerName
    this.mat_dlg.closeAll();
    this.dataService.isEditGRN = false;
  }
  labelHighlight(text){
    this.fieldName = text;
  }

}
