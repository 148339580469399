<div *ngIf="!serverError" class="row invoice-body" [ngClass]="headerpop == 'Batch Progress' && progressDailogBool ? 'p_events_none' : 'p_events_all'">
  <div class="viewPdf d-flex">
    <div
      class="d-flex"
      *ngIf="
        ((editPermissionBoolean ||
          changeApproveBoolean ||
          financeApproveBoolean) &&
          submitBtn_boolean &&
          isDesktop &&
          !isServiceData &&
          currentTab == 'line') ||
        (router.url.includes('Inv_vs_GRN_details') && !GRN_PO_Bool)
      "
    >
      <button
        *ngIf="
          (portalName == 'customer' &&
            !isServiceData &&
            !fin_boolean &&
            editable &&
            !['advance invoice', 'non-po', 'credit note','non-po tax invoice','non-po credit note'].includes(
              documentType
            )) ||
          mappingForCredit ||
          (router.url.includes('Inv_vs_GRN_details') && !GRN_PO_Bool)
        "
        type="button"
        class="secondary_btn f-12 m-t-neg ml-2 mr-2"
        (click)="opengrnDailog()"
      >
        Select GRN
      </button>
      <button
        *ngIf="
          portalName == 'customer' &&
          ((router.url.includes('Upload') &&
            (documentType == 'credit' ||
              documentType == 'progressive invoice')) ||
            [8, 16, 17, 18, 19, 21, 27, 34, 205].includes(subStatusId) ||
            (documentType == 'advance invoice' &&
              [29, 108].includes(subStatusId)))
        "
        type="button"
        class="secondary_btn f-12 mr-2"
        (click)="open_dialog_comp('flip line')"
      >
        Flip PO
      </button>

      <button
        *ngIf="(subStatusId == 32 || subStatusId == 40) && isAdmin"
        class="level_3_btn f-12 mr-2"
        type="button"
        (click)="status_dialog()"
      >
        Change status
      </button>
    </div>

    <div
      *ngIf="
        Itype == 'Invoice' &&
        statusId != 10 &&
        editable &&
        !fin_boolean &&
        !headerName?.includes('Create')
      "
      class="mr-2 invType"
    >
      <p-autoComplete
        [(ngModel)]="entityName"
        (onSelect)="onSelectEntity($event, 'change')"
        placeholder="Select Entity"
        [suggestions]="filteredEnt"
        (completeMethod)="filterEntity($event)"
        field="EntityName"
        [dropdown]="true"
        name="entity"
        styleClass="mr-3"
      >
        <ng-template let-ent pTemplate="item">
          <div class="ent-item">
            <div class="f-12">{{ ent.EntityName }}</div>
          </div>
        </ng-template>
      </p-autoComplete>

      <p-dropdown
        (onChange)="onSelectInvType($event)"
        [options]="invTypeList"
        placeholder="Select invoice type"
        name="invType"
        [(ngModel)]="docType"
      >
      </p-dropdown>
    </div>
    <div class="f-12 mt-2 mr-2" *ngIf="!editable || fin_boolean">
      <span
        *ngIf="ent_code || d_type"
        class="status_btn"
        style="text-transform: capitalize"
        ><span *ngIf="ent_code">Entity Code - {{ ent_code }} </span>
        <span *ngIf="d_type"
          ><span *ngIf="ent_code">&</span> Invoice type - {{ d_type }}</span
        ></span
      >
    </div>
    <button
      *ngIf="!isPdfAvailable && isDesktop && currentTab != 'header'"
      class="primary_btn mr-2 f-12"
      (click)="viewPdf()"
      type="button"
    >
      {{ btnText }}
    </button>
  </div>

  <div
    class="col-md-12 p-0"
    [ngClass]="showPdf && !isPdfAvailable ? 'col-lg-5' : 'col-lg-12'"
  >
    <div class="col-12 dummy_div"></div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li
        class="nav-item mr-2"
        pTooltip="Header details"
        tooltipPosition="top"
        (click)="changeTab('header')"
      >
        <a
          class="nav-link"
          [ngClass]="currentTab == 'header' ? 'active' : ''"
          id="header-tab"
          data-toggle="tab"
          href="#header"
          role="tab"
          aria-controls="header"
          aria-selected="false"
          ><span class="fa fa-file-o f-16 font_weight100"></span
          >{{ Itype }} Details</a
        >
      </li>
      <li
        class="nav-item"
        pTooltip="Line details"
        tooltipPosition="top"
        (click)="changeTab('line')"
      >
        <a
          class="nav-link"
          id="line-tab"
          data-toggle="tab"
          href="#line"
          role="tab"
          aria-controls="line"
          aria-selected="false"
          [ngClass]="currentTab == 'line' ? 'active' : ''"
        >
          <span class="fa fa-columns f-16 font_weight100"></span>Line Details</a
        >
      </li>

      <li
        class="nav-item ml-2"
        pTooltip="PO Lines details"
        tooltipPosition="top"
        (click)="changeTab('poline')"
        *ngIf="
          Itype == 'Invoice' &&
          ['Emaar Hospitality'].includes(client_name) &&
            documentType?.toLowerCase() == 'non-po credit note'
        "
      >
        <a
          class="nav-link"
          id="POline-tab"
          data-toggle="tab"
          href="#POline"
          role="tab"
          aria-controls="POline"
          aria-selected="false"
          [ngClass]="currentTab == 'poline' ? 'active' : ''"
          >PO Lines</a
        >
      </li>

      <li
        class="nav-item ml-2"
        pTooltip="GRN details"
        tooltipPosition="top"
        *ngIf="
          !grnCreateBoolean &&
          Itype == 'Invoice' &&
          !(
            ['Emaar Hospitality'].includes(client_name) &&
            documentType?.toLowerCase() == 'credit note' ||
            documentType?.toLowerCase() == 'non-po credit note' || 
            documentType?.toLowerCase() == 'non-po tax invoice' || 
            documentType?.toLowerCase() == 'advance invoice'
          )
        "
        (click)="changeTab('grn')"
      >
        <a
          class="nav-link"
          id="grn-tab"
          data-toggle="tab"
          href="#grn"
          role="tab"
          aria-controls="grn"
          aria-selected="false"
          [ngClass]="currentTab == 'grn' ? 'active' : ''"
          ><span class="fa fa-columns f-16 font_weight100"></span>GRN Data</a
        >
      </li>
      <li
        class="nav-item ml-2"
        pTooltip="Pre payment details"
        tooltipPosition="top"
        *ngIf="documentType?.toLowerCase() == 'advance invoice' || documentType?.toLowerCase() == 'proforma invoice'"
        (click)="changeTab('prepay')"
      >
        <a
          class="nav-link"
          id="prepay-tab"
          data-toggle="tab"
          href="#prepay"
          role="tab"
          aria-controls="prepay"
          aria-selected="false"
          [ngClass]="currentTab == 'prepay' ? 'active' : ''"
          ><span class="fa fa-file-o f-16 font_weight100"></span>Pre Payment Details</a
        >
      </li>
      <li
        class="nav-item ml-2"
        pTooltip="Supporting documents"
        tooltipPosition="top"
        *ngIf="['Invoice','GRN'].includes(Itype) && !approval_selection_boolean || tagService?.headerName?.includes('GRN')"
        (click)="changeTab('support')"
      >
        <a
          class="nav-link"
          id="support-tab"
          data-toggle="tab"
          href="#support"
          role="tab"
          aria-controls="support"
          aria-selected="false"
          [ngClass]="currentTab == 'support' ? 'active' : ''"
          ><span class="fa fa-columns f-16 font_weight100"></span>Supporting
          Docs</a
        >
      </li>

      <li
        class="nav-item ml-2"
        pTooltip="history"
        tooltipPosition="top"
        *ngIf="approvalRejectRecord.length > 0 && fin_boolean"
        (click)="changeTab('history')"
      >
        <a
          class="nav-link"
          id="history-tab"
          data-toggle="tab"
          href="#history"
          role="tab"
          aria-controls="history"
          aria-selected="false"
          [ngClass]="currentTab == 'history' ? 'active' : ''"
          ><span class="fa fa-columns f-16 font_weight100"></span>History
        </a>
      </li>
      <li
        *ngIf="approval_selection_boolean && isLCMCompleted"
        class="nav-item ml-2"
        pTooltip="Approver Selection"
        tooltipPosition="top"
        (click)="changeTab('approver_selection')"
      >
        <a
          class="nav-link"
          id="approver_selection-tab"
          data-toggle="tab"
          href="#approver_selection"
          role="tab"
          aria-controls="approver_selection"
          aria-selected="false"
          [ngClass]="
            approval_selection_boolean &&
            !isLCMInvoice &&
            currentTab == 'approver_selection'
              ? 'active'
              : ''
          "
          >Approver Selection</a
        >
      </li>
      <li
        class="nav-item ml-2"
        pTooltip="Fixed Cost allocation"
        tooltipPosition="top"
        (click)="changeTab('cost')"
        *ngIf="isServiceData && client_name != 'Enova'"
      >
        <a
          class="nav-link"
          id="cost-tab"
          data-toggle="tab"
          href="#cost"
          role="tab"
          aria-controls="cost"
          aria-selected="true"
          [ngClass]="currentTab == 'cost' ? 'active' : ''"
          >Fixed Allocation</a
        >
      </li>

      <li
        class="nav-item ml-2"
        pTooltip="Dynamic cost allocation"
        tooltipPosition="top"
        (click)="changeTab('dynamic')"
        *ngIf="isServiceData && dynamicdata.length > 0"
      >
        <a
          class="nav-link"
          id="dynamic-tab"
          data-toggle="tab"
          href="#dynamic"
          role="tab"
          aria-controls="dynamic"
          aria-selected="true"
          [ngClass]="currentTab == 'dynamic' ? 'active' : ''"
          >Dynamic Allocation</a
        >
      </li>
      <!-- <button class="raiseAlert" pTooltip="raise an alert" tooltipPosition="top"><i class="fa fa-exclamation-triangle"
            aria-hidden="true"></i></button> -->
    </ul>
    <div class="tabsDiv bg-design-all">
      <div *ngIf="!isDesktop" class="vi_mb_btn f-12" (click)="doc_view()">
        <i class="fa fa-eye"></i> View Document
      </div>
      <form
        #form="ngForm"
        class="tabDiv"
        [ngClass]="currentTab == 'line' ? 'line_height' : ''"
        id="myTabContent"
      >
        <div class="tab-content">
          <ng-container *ngTemplateOutlet="header"></ng-container>
          <ng-container *ngTemplateOutlet="line"></ng-container>
          <ng-container *ngTemplateOutlet="cost"></ng-container>
          <ng-container *ngTemplateOutlet="dynamic"></ng-container>
          <ng-container *ngTemplateOutlet="poline"></ng-container>
          <ng-container *ngTemplateOutlet="grn"></ng-container>
          <ng-container *ngTemplateOutlet="prepay"></ng-container>
          <ng-container *ngTemplateOutlet="support"></ng-container>
          <ng-container *ngTemplateOutlet="history"></ng-container>
          <ng-container *ngTemplateOutlet="approver_selection"></ng-container>
        </div>

        <!-- header -->
        <ng-template #header>
          <div
            class="tab-pane fade"
            [ngClass]="currentTab == 'header' ? 'show active' : ''"
            id="header"
            role="tabpanel"
            aria-labelledby="header-tab"
          >
            <!-- <span class="boxopen" *ngIf="documentType == 'advance invoice'" >
            <div class="button-container">
              <button class="advSettingsButton" (click)="openBox()"> Pre Payment </button>
            </div>
          </span> -->
            <div
              class="header_container"
              [ngClass]="!editable ? 'disable_div' : ''"
            >
              <div class="d-flex justify-content-between">
                <div class="f-14">{{ Itype }}/Purchase Details</div>
                <div
                  class="f-12 status_btn"
                  *ngIf="doc_status"
                  [title]="doc_status"
                  style="background-color: inherit"
                >
                  {{ doc_status }}
                </div>
              </div>
              <div class="f-13" style="color: green" *ngIf="Itype == 'Invoice'">
                Upload successfully completed in {{ uploadtime }}
              </div>
              <div class="displayFlex mt-2">
                <div *ngFor="let value of headerData">
                  <div
                    *ngIf="!value?.TagLabel?.toLowerCase()?.includes('dummy')"
                    class="input_container"
                    [ngClass]="
                      value?.TagLabel?.includes('Vendor')
                        ? 'large_input_box'
                        : ''
                    "
                    [ngStyle]="{
                      border:
                        value?.isError >= 1 && !value?.ErrorMsg?.includes('Low')
                          ? '1px solid #D81616'
                          : subStatusId == 110 &&
                            value?.TagLabel == 'Current Month VAT'
                          ? '1px solid #D81616'
                          : value?.TagLabel == 'Customer TRN Number' &&
                            value?.Value == ''
                          ? '1px solid #D81616'
                          : value?.isError >= 1 &&
                            value?.ErrorMsg?.includes('Low')
                          ? '1px solid #f7c83e'
                          : value?.isError === 0 && value?.IsUpdated === 0
                          ? '0px solid lightgray'
                          : value?.IsUpdated > 0
                          ? '1px solid #37dcc7'
                          : ''
                    }"
                  >
                    <label
                      class="label-head"
                      style="margin-bottom: -5px"
                      (click)="labelHighlight(value?.TagLabel)"
                      >{{ value.TagLabel }}
                      <span
                        *ngIf="value?.TagLabel?.toLowerCase() == 'invoicedate'"
                        >(YYYY-MM-DD)</span
                      >
                      <span
                        *ngIf="
                          value?.TagLabel == 'InvoiceTotal' && value?.isChanged
                        "
                        class="calc"
                        style="top: 0px"
                        pTooltip="Please click here to auto calculate the Invoice total"
                        tooltipPosition="top"
                        (click)="calculateTotal(value)"
                        ><i class="fa fa-calculator" aria-hidden="true"></i>
                      </span>
                      <span
                        *ngIf="value?.isError == 1"
                        [pTooltip]="value?.ErrorMsg"
                        tooltipPosition="top"
                        ><i
                          class="fa fa-info-circle info_icon"
                          aria-hidden="true"
                        ></i
                      ></span>
                      <span
                        *ngIf="
                          value?.TagLabel == 'PurchaseOrder' &&
                          editable &&
                          !router.url.includes('Create_GRN_inv_list')
                        "
                      >
                        <i
                          class="fa fa-search info_icon pointer po_s_icon"
                          aria-hidden="true"
                          (click)="poDailog(value)"
                        ></i>
                      </span>
                    </label>
                    <input
                      *ngIf="!value?.TagLabel?.includes('Project')"
                      id="field-{{ value?.TagLabel }}"
                      #inputv
                      [type]="
                        value?.TagLabel?.includes('AmountToApply')
                          ? 'number'
                          : 'text'
                      "
                      [value]="value.Value"
                      [title]="value.Value"
                      [disabled]="
                        !editable ||
                        fin_boolean ||
                        router.url.includes('Create_GRN_inv_list') || grnCreateBoolean
                      "
                      (change)="
                        value?.TagLabel?.includes('AmountToApply')
                          ? progressiveAmount(
                              inputv.value,
                              value.idDocumentData
                            )
                          : onChangeValue(value.TagLabel, inputv.value, value)
                      "
                      (blur)="saveChanges()"
                      (click)="labelHighlight(value?.TagLabel)"
                      class="form-control inv_inp_cls"
                    />
                    <div
                      *ngIf="value?.TagLabel?.includes('Project')"
                      class="project"
                    >
                      <p-autoComplete
                        [(ngModel)]="value.Value"
                        (onSelect)="onSelectProject($event, value)"
                        placeholder=""
                        [suggestions]="filteredProject"
                        (completeMethod)="
                          filterProject($event, value?.TagLabel, value?.Value)
                        "
                        field=""
                        [dropdown]="true"
                        [name]="value.TagLabel"
                        styleClass="project"
                        [disabled]="!editable || fin_boolean || grnCreateBoolean"
                      >
                      </p-autoComplete>
                    </div>
                    <span *ngIf="value?.updates?.val">
                      <span class="old_value"
                        >Prev :
                        <span
                          style="color: #f38a6b"
                          [title]="value?.updates?.val"
                          >"{{ value?.updates?.val }}"</span
                        >
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <!-- vendor or service details -->
            <div class="header_container disable_div mt-2">
              <div class="d-flex justify-content-between">
                <div class="f-14">
                  {{ isServiceData ? "Service" : "Vendor" }} Details as per ERP
                </div>
                <div class="f-12">View Only</div>
              </div>
              <div class="displayFlex">
                <div *ngFor="let data of vendorData | keyvalue; let i = index">
                  <div
                    class="input_container"
                    [ngClass]="
                      data?.key?.includes('VendorName') ? 'large_input_box' : ''
                    "
                    *ngIf="!data?.key?.startsWith('id')"
                  >
                    <label class="label-head" style="margin-bottom: -5px"
                      >{{ data.key }}
                    </label>
                    <input
                      type="text"
                      [value]="data.value"
                      [title]="data.value"
                      class="form-control inv_inp_cls"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="isBoxOpen" class="amount-box">
              <h6 class="headamtbox">Please Select</h6>
              <div class="close-icon" (click)="closeBox()">
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>

              <ul class="tabs">
                <span
                  class="percentage"
                  (click)="activeTab = 'percentage'"
                  [class.active]="activeTab === 'percentage'"
                  >Percentage</span
                >
                <span
                  class="amount"
                  (click)="activeTab = 'amount'"
                  [class.active]="activeTab === 'amount'"
                  >Amount</span
                >
              </ul>
              <div class="tabContent">
                <div *ngIf="activeTab === 'percentage'" class="tabBg">
                  <label for="percentageInput" class="boxHead"
                    >Add Percentage:</label
                  >
                  <input
                    type="text"
                    name="percent"
                    id="percentageInput"
                    [(ngModel)]="percentageData"
                    (ngModelChange)="updateButtonState('percentage')"
                    pattern="[0-9]*"
                  />
                  <label>Amount: {{ resultAmount }}</label>
                  <!-- <input type="text" [value]="resultAmount" readonly> -->
                  <!-- <button (click)="savePercentage()">Submit Percentage</button> -->
                  <div class="subButton">
                    <button
                      class="submitButton"
                      (click)="saveData('percentage')"
                      [disabled]="isButtonDisabled"
                    >
                      Submit
                    </button>
                  </div>
                </div>

                <div *ngIf="activeTab === 'amount'" class="tabBg">
                  <label for="amountInput" class="boxHead">Add Amount:</label>
                  <input
                    type="text"
                    id="amountInput"
                    name="amount"
                    [(ngModel)]="amountData"
                    (input)="updateButtonState('amount')"
                    pattern="[0-9]*"
                  />

                  <!-- <button (click)="saveAmount()">Submit Amount</button> -->
                  <div class="subButton">
                    <button
                      class="submitButton"
                      (click)="saveData('amount')"
                      [disabled]="isButtonDisabled"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <!-- line and GRN creations -->
        <ng-template #line>
          <!-- line -->
          <div
            class="tab-pane fade"
            [ngClass]="currentTab == 'line' ? 'show active' : ''"
            id="line"
            role="tabpanel"
            aria-labelledby="line-tab"
          >
            <div
              *ngIf="
                !isDesktop &&
                editable &&
                router.url.includes('comparision-docs') &&
                pageType == 'mapping'
              "
              class="d-flex mb-1"
              style="gap: 5px"
            >
              <button
                *ngIf="
                  portalName == 'customer' &&
                  client_name == 'Enova' &&
                  !isServiceData &&
                  !fin_boolean &&
                  editable &&
                  !['advance invoice'].includes(documentType)
                "
                type="button"
                class="level_3_btn f-12 m-t-neg ml-2 mr-2"
                (click)="opengrnDailog()"
              >
                Select GRN
              </button>
            </div>
            <div *ngIf="!grnCreateBoolean">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex">
                  <div class="f-14 mr-3">
                    Invoice Line/Purchase Line Details
                  </div>
                  <div
                    class="f-12 status_btn"
                    *ngIf="doc_status"
                    style="background-color: inherit"
                  >
                    {{ doc_status }}
                  </div>
                </div>

                <div
                  class="d-flex f-12 basic_details text_color"
                  *ngIf="!isServiceData"
                >
                  <div class="mr-2">{{ vendorName }}</div>
                  <div class="pipe mr-2">|</div>
                  <div class="mr-2">{{ po_num }}</div>
                  <div class="pipe mr-2">|</div>
                  <div>{{ invoiceNumber }}</div>
                </div>

                <div
                  class="d-flex"
                  [ngClass]="
                    isDesktop ? 'justify-content-end' : 'justify-content-start'
                  "
                >
                  <button
                    *ngIf="editable && !fin_boolean"
                    id="addLine"
                    class="level_3_btn f-12 mr-4"
                    (click)="open_dialog('AddLine')"
                    type="button"
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i> Add Line
                  </button>
                </div>
              </div>

              <table class="w-100 mt-2 ex_table">
                <thead>
                  <tr>
                    <th
                      class="f-12 text-center"
                      style="min-width: 50px; background-color: #ebebeb"
                    >
                      S.No
                    </th>
                    <th
                      *ngFor="let header of lineTableHeaders"
                      class="lineName"
                    >
                      {{ header }}
                    </th>
                    <th class="lineName" style="width: 50px" *ngIf="editable">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let item of lineDisplayData; let i = index"
                  >
                    <tr
                      [ngClass]="pageType == 'mapping' ? 'invoice_color' : ''"
                    >
                      <ng-container *ngIf="item.lines">
                        <td
                          class="linevalue p-2 text-center"
                          style="width: 50px"
                        >
                          {{ i + 1 }}
                        </td>
                        <td *ngFor="let header of lineTableHeaders">
                          <div class="d-flex f-12 td_padding doc_height" 
                            [ngStyle]="{ borderBottom:(documentType == 'advance invoice' || documentType == 'non-po')? '1px solid lightgray':'' }"
                            data-text="IN"
                            [ngClass]="
                            header == 'Description'
                                ? 'textType in_bg_clr'
                                : ''
                            ">
                            <input
                              *ngIf="
                                !['PurchaseOrder', 'InvoiceNumber'].includes(
                                  header
                                ) && (item?.lines[header]?.fieldType == 'FreeText' || !item?.lines[header]?.fieldType)
                              "
                              #inputv
                              type="text"
                              [ngClass]="
                                header.includes(
                                  'Description',
                                  'Name',
                                  'POLineDescription',
                                  'GRNLineDescription'
                                ) || header =='Name'
                                  ? 'w-des'
                                  : 'w-sm'
                              "
                              [ngStyle]="{
                                borderBottom:
                                  item.lines[header]?.isError >= 1 &&
                                  !item.lines[header]?.ErrorMsg?.includes('Low')
                                    ? ' 2px solid red'
                                    : item.lines[header]?.isError >= 1 &&
                                      item.lines[header]?.ErrorMsg?.includes('Low')
                                    ? ' 2px solid #f7c83e'
                                    : item.lines[header]?.updates ||
                                      item.lines[header]?.isError == 0
                                    ? '2px solid #37dcc7'
                                    : ''
                              }"
                              [value]="item.lines[header]?.Value"
                              [title]="item.lines[header]?.Value"
                              [name]="header"
                              [disabled]="!editable || fin_boolean"
                              class="form-control f-12 input_disabled"
                              (change)="onChangeLineValue(
                                header,
                                inputv.value,
                                item
                              )
                              "
                              (blur)="saveChanges()"
                            />
                            <span class="nonPo" *ngIf="item.lines[header]?.fieldType == 'Dropdown'">
                              <p-autoComplete
                                [(ngModel)]="item.lines[header]"
                                (onSelect)="onSelectPrePay(header,$event,item,'Dropdown')"
                                placeholder=""
                                [suggestions]="filteredPreData"
                                (completeMethod)="filterDropNon($event,header)"
                                field="lookupid"
                                [dropdown]="true"
                                styleClass="f-12 pl-0"
                                id="{{header}}-nonPO-{{i}}"
                                name="{{header}}-nonPO-{{i}}"
                                [disabled]="!editable || fin_boolean "
                                appendTo="body"
                                forceSelection="true"
                              >
                              <ng-template let-item pTemplate="item">
                                <span [ngClass]="{'disabled-item': item.disabled}">{{ item.lookupid }}</span>
                              </ng-template>
                              </p-autoComplete>
                            </span>

                            <span class="nonPo" *ngIf="item.lines[header]?.fieldType == 'Date'">
                              <p-calendar
                              #datePicker_non
                              [(ngModel)]="item.lines[header].Value"
                              [minDate]="minDate"
                              [maxDate]="maxDate"
                              dateFormat="dd/mm/yy"
                              placeholder=""
                              [readonlyInput]="true"
                              [showIcon]="true"
                              (onSelect)="onSelectPrePay(header,$event,item,'Date')"
                              name="{{header}}-nonpo-{{i}}"
                              styleClass="f-12 pl-0"
                              inputId="JT_Date"
                              appendTo="body"
                            ></p-calendar>
                            </span>
                            <div
                              *ngIf="
                                ['PurchaseOrder', 'InvoiceNumber'].includes(
                                  header
                                )
                              "
                            >
                              <p-autoComplete
                                [(ngModel)]="item.lines[header].Value"
                                (onSelect)="
                                  onSelectPrePay(
                                    $event,
                                    item.lines[header],
                                    header,
                                    x
                                  )
                                "
                                placeholder=""
                                [suggestions]="filteredPreData"
                                (completeMethod)="filterPreDrop($event, header)"
                                field=""
                                [dropdown]="true"
                                styleClass="f-12"
                                [name]="header"
                                [disabled]="!editable || fin_boolean"
                              >
                              </p-autoComplete>
                            </div>
                            <span
                              *ngIf="item?.lines[header]?.isChanged"
                              class="calc"
                              pTooltip="Please click here to auto calculate the amount"
                              tooltipPosition="top"
                              (click)="calculateAmount(item.ItemCode, item)"
                              ><i
                                class="fa fa-calculator"
                                aria-hidden="true"
                              ></i
                            ></span>
                            <span
                              *ngIf="item.lines[header]?.updates"
                              class="prev_value f-11"
                              [title]="item.lines[header]?.updates?.val"
                              >prev:
                              {{ item.lines[header]?.updates?.val }}
                            </span>
                            <span
                              *ngIf="item.lines[header]?.isError >= 1"
                              [pTooltip]="item.lines[header]?.ErrorMsg"
                              tooltipPosition="top"
                              ><i
                                [ngStyle]="{
                                  color:
                                    item.lines[header]?.isError >= 1 &&
                                    !item.lines[header]?.ErrorMsg?.includes('Low')
                                      ? '#D81616'
                                      : item.lines[header]?.isError >= 1 &&
                                        item.lines[header]?.ErrorMsg?.includes('Low')
                                      ? '#f7c83e'
                                      : ''
                                }"
                                class="fa fa-info-circle info_icon"
                                aria-hidden="true"
                              ></i
                            ></span>
                          </div>
                        </td>
                        <td *ngIf="editable">
                          <button
                            class="trash_btn f-14"
                            type="button"
                            (click)="delete_confirmation(item.ItemCode)"
                          >
                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        </td>
                      </ng-container>
                    </tr>
                    <tr *ngIf="pageType == 'mapping'" class="po_color">
                      <ng-container *ngIf="item.lines">
                        <td
                          class="linevalue p-2 text-center"
                          style="width: 50px"
                        >
                          <span style="visibility: hidden">{{ i + 1 }}</span>
                        </td>
                        <td *ngFor="let header of lineTableHeaders">
                          <div
                          class="f-12 d-flex td_padding po_color doc_height"
                          data-text="PO"
                          [ngClass]="
                            header == 'Description'
                              ? 'textType po_bg_clr'
                              : ''
                          "
                          >
                            <input
                              *ngIf="
                                !(
                                  header == 'Description' &&
                                  submitBtn_boolean &&
                                  !fin_boolean
                                )
                              "
                              type="text"
                              [ngClass]="
                                header.includes(
                                  'Description',
                                  'Name',
                                  'POLineDescription',
                                  'GRNLineDescription'
                                )
                                  ? 'w-des'
                                  : 'w-sm'
                              "
                              [value]="
                                item.lines[header]?.po_line.Value || 'NA'
                              "
                              [title]="item.lines[header]?.po_line.Value"
                              [name]="header"
                              disabled
                              class="form-control f-12 input_disabled"
                            />
                            <div
                              class="mapping"
                              *ngIf="
                                header == 'Description' &&
                                submitBtn_boolean &&
                                !fin_boolean
                              "
                            >
                              <p-autoComplete
                                (onSelect)="
                                  lineMapping(item?.ItemCode, $event.itemCode)
                                "
                                [(ngModel)]="item.lines[header].po_line"
                                [suggestions]="filteredPOLine"
                                (completeMethod)="filterPOLine($event)"
                                field="Value"
                                [id]="item.ItemCode"
                                name="poline{{
                                  item.lines[header]?.po_line?.Value
                                }}-{{ item?.ItemCode }}"
                                styleClass="f-11 input_disabled sel_drp"
                                [dropdown]="true"
                                appendTo="body"
                              >
                              </p-autoComplete>
                            </div>
                          </div>
                        </td>
                        <td></td>
                      </ng-container>
                    </tr>
                  </ng-container>
                </tbody>
              </table>

              <table *ngIf="Itype == 'Invoice'" class="f-13 total_table">
                <tr class="mb-1">
                  <td></td>
                  <td class="total_txt">Invoice Lines Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td
                    class="total_count"
                    [pTooltip]="lineTooltip"
                    tooltipPosition="top"
                  >
                    {{ totalInvCost }}
                  </td>
                  <td *ngIf="editable" style="width: 40px"></td>
                </tr>
                <tr
                  *ngIf="
                    documentType != 'advance invoice' &&
                    documentType != 'non-po'
                  "
                >
                  <td></td>
                  <td class="total_txt">Purchase Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="total_count">{{ po_total }}</td>
                  <td *ngIf="editable" style="width: 40px"></td>
                </tr>
              </table>
            </div>

            <!-- Create GRN screen Line details -->
            <div *ngIf="grnCreateBoolean">
              <!--GRN Creation With Invoice-->
              <div
                class="d-flex justify-content-between flex-wrap"
                style="height: 30px"
              >
                <div class="d-flex">
                  <div class="f-14 mr-3">
                    {{ headerName }}
                  </div>
                  <div
                    class="f-12 status_btn"
                    style="background-color: inherit;"
                    *ngIf="
                      !router.url.includes('Inv_vs_GRN_details') &&
                      !router.url.includes('GRN_approvals')
                    "
                  >
                    {{ doc_status }}
                  </div>
                </div>

                <div
                  class="d-flex f-12 basic_details_grn text_color"
                  *ngIf="!isServiceData"
                >
                  <div class="mr-2">{{ vendorName }}</div>
                  <div class="pipe mr-2">|</div>
                  <div class="mr-2">{{ po_num }}</div>
                  <div *ngIf="client_name != 'Cenomi'" class="pipe mr-2">|</div>
                  <div *ngIf="client_name != 'Cenomi'">
                    <span *ngIf="!GRN_PO_Bool">{{ invoiceNumber }}</span>
                    <input
                      *ngIf="GRN_PO_Bool"
                      type="text"
                      name="inv_number"
                      class="inv_number f-12 text_color"
                      placeholder="Add invoice number"
                      [(ngModel)]="invoiceNumber"
                    />
                  </div>
                </div>

                <div class="d-flex">
                  <div class="ml-1" *ngIf="client_name == 'SRG'">
                    <label class="f-12">Invoice Description</label>
                    <button class="edit_btn" type="button" (click)="openFilterDialog($event)">
                      <i class="fa fa-edit"></i>
                    </button>
                  </div>

                  <div class="ml-3 grn" *ngIf="client_name == 'SRG'">
                    <label class="f-12">GRN Date:</label>
                    <p-calendar
                      #grnDate
                      [(ngModel)]="invoiceDate"
                      [minDate]="minDate"
                      [maxDate]="maxDate"
                      placeholder=""
                      [readonlyInput]="true"
                      [showIcon]="true"
                      name="grnDate"
                      inputId="GRN_Date"
                    ></p-calendar>
                  </div>

                  <div
                    *ngIf="client_name == 'Cenomi'"
                    [ngClass]="
                      GRN_PO_Bool && client_name == 'Cenomi' ? '' : 'hide'
                    "
                  >
                    <!-- <div class="f-12" (click)="open_dialog_comp('manpower_metadata')" *ngIf="isManpowerTags">Edit Metadata <i class="fa fa-pencil-square-o" aria-hidden="true"></i> </div> -->
                    <!-- <label class="f-12">Time sheet
                      <input type="checkbox" (change)="onChecked($event.target.checked)">
                    </label> -->
                    <button
                      class="f-12 primary_btn mr-2"
                      *ngIf="isManpower"
                      type="button"
                      (click)="open_dialog_comp('manpower')"
                    >
                      Timesheet
                    </button>
                  </div>

                  <div *ngIf="GRN_PO_Bool">
                    <button
                      style="min-width: 30px"
                      class="f-12 secondary_btn secondary_act_btn"
                      (click)="deleteGrnLine()"
                      type="button"
                      [disabled]="selectALL_grn_lines"
                    >
                      <i class="fa fa-trash-o"></i>
                    </button>
                  </div>
                </div>
              </div>
              <table
                *ngIf="lineDisplayData?.length > 0"
                class="mt-2 w-100 ex_table"
                id="grnTable"
              >
                <tr>
                  <ng-container *ngFor="let grn of lineTable">
                    <th
                      class="lineName"
                      style="min-width: 50px"
                      *ngIf="grn.header == 'S.No'"
                    >
                      <div class="d-flex">
                        <div *ngIf="GRN_PO_Bool">
                          <input
                            type="checkbox"
                            name="selectAll"
                            [(ngModel)]="selectALL_grn_lines"
                            (change)="selectAllLines($event.target.checked)"
                          />
                        </div>
                        <div class="count_line ml-1 f-12">{{ grn.header }}</div>
                      </div>
                    </th>
                    <th class="lineName" *ngIf="grn.header != 'S.No'">
                      <div *ngIf="grn.header != 'S.No'">{{ grn.header }}</div>
                    </th>
                  </ng-container>
                </tr>
                <tr
                  *ngFor="
                    let item of lineDisplayData;
                    let i = index;
                    trackBy: trackByIndex
                  "
                >
                  <!-- Iterate over headers to match the data field for each row -->
                  <td *ngFor="let header of lineTable">
                    <ng-container [ngSwitch]="header.header">
                      <!-- S.No -->
                      <ng-container *ngSwitchCase="'S.No'">
                        <div class="d-flex">
                          <div *ngIf="GRN_PO_Bool">
                            <input
                              type="checkbox"
                              name="select_line"
                              id="check{{ i }}"
                              [(ngModel)]="item.checked"
                              (change)="
                                selectLine($event.target.checked, index, item)
                              "
                            />
                          </div>
                          <div class="count_line ml-1 f-12">{{ i + 1 }}</div>
                        </div>
                      </ng-container>

                      <!-- GRN - Quantity -->
                      <ng-container *ngSwitchCase="'GRN - Quantity'">
                        <div>
                          <input
                            #inputval
                            type="text"
                            style="border: 0.2px solid #358dc0"
                            [name]="item[header.field]?.Value"
                            [value]="item[header.field].Value"
                            (change)="
                              onChangeGrn(header.field, inputval.value, item)
                            "
                            [readonly]="item['isTimesheets']?.Value"
                            class="form-control inputHeight w-sm"
                            [title]="item[header.field]?.Value"
                          />
                        </div>
                      </ng-container>

                      <ng-container
                        *ngSwitchCase="'AmountExcTax'"
                        [ngSwitch]="shouldRenderInput()"
                      >
                        <ng-container *ngSwitchCase="true">
                          <div>
                            <input
                              #inputval
                              type="text"
                              style="border: 0.2px solid #358dc0"
                              [name]="item[header.field]?.Value"
                              [value]="item[header.field]?.Value"
                              (change)="onChangeGrnAmount(item, inputval.value)"
                              [readonly]="item['isTimesheets']?.Value"
                              class="form-control inputHeight w-sm"
                              [title]="item[header.field]?.Value"
                            />
                          </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <div>
                            <input
                              type="text"
                              [ngClass]="{
                                'w-des': header.header === 'Description',
                                'w-sm': item.TagName !== 'Description'
                              }"
                              [ngStyle]="{
                                border:
                                  item[header.field]?.is_mapped === 1
                                    ? '2px solid #f2be42'
                                    : ''
                              }"
                              [value]="
                                item[header.field]?.Value
                                  ? item[header.field]?.Value
                                  : 'NA'
                              "
                              [title]="
                                item[header.field]?.Value
                                  ? item[header.field]?.Value
                                  : ''
                              "
                              class="form-control inputHeight"
                              [name]="item[header.field]?.Value"
                              disabled
                              [ngModelOptions]="{ standalone: true }"
                            />
                          </div>
                        </ng-container>
                      </ng-container>

                      <!-- Comments -->
                      <ng-container *ngSwitchCase="'Comments'">
                        <div>
                          <input
                            #inputComment
                            type="text"
                            style="width: 150px; border: 0.2px solid #358dc0"
                            class="form-control inputHeight"
                            [title]="
                              item[header.field]?.ErrorMsg
                                ? item[header.field]?.ErrorMsg
                                : ''
                            "
                            [ngModelOptions]="{ standalone: true }"
                          />
                        </div>
                      </ng-container>

                      <!-- Actions -->
                      <!-- <ng-container *ngSwitchCase="'Actions'">
                          <span class="linevalue invert_thtd">
                            <button class="trash_btn" (click)="deleteGrnLine(item)">
                              <i class="fa fa-trash-o" aria-hidden="true"></i>
                            </button>
                          </span>
                        </ng-container> -->

                      <!-- Default case for other fields -->
                      <ng-container *ngSwitchDefault>
                        <div>
                          <input
                            type="text"
                            [ngClass]="{
                              'w-des': header.header === 'Description',
                              'w-sm': item.TagName !== 'Description'
                            }"
                            [ngStyle]="{
                              border:
                                item[header.field]?.is_mapped === 1
                                  ? '2px solid #f2be42'
                                  : ''
                            }"
                            [value]="
                              item[header.field]?.Value
                                ? item[header.field]?.Value
                                : 'NA'
                            "
                            [title]="
                              item[header.field]?.Value
                                ? item[header.field]?.Value
                                : ''
                            "
                            class="form-control inputHeight"
                            [name]="item[header.field]?.Value"
                            disabled
                            [ngModelOptions]="{ standalone: true }"
                          />
                        </div>
                      </ng-container>
                    </ng-container>
                  </td>
                </tr>
              </table>
            </div>
            <table
              class="total"
              style="bottom: 0px;"
              *ngIf="
                router.url.includes('Inv_vs_GRN_details') ||
                router.url.includes('GRN_approvals')
              "
            >
              <tr>
                <td class="w-st hide">Total</td>
                <td class="w-des f-12">Total</td>
                <td class="w-st hide">Total</td>
                <td class="w-st hide" *ngIf="!GRN_PO_Bool">Total</td>
                <td
                  class="w-st f-12"
                  [ngClass]="GRN_PO_Bool ? 'text-center' : ''"
                  [ngStyle]="{ paddingLeft: GRN_PO_Bool ? '40px' : '' }"
                >
                  {{ decimalRoundOff(GRN_line_total) }}
                </td>
                <td
                  class="w-st hide"
                  [ngStyle]="{ width: GRN_PO_Bool ? '54px' : '200px' }"
                >
                  Total
                </td>
                <td class="w-st hide">Total</td>
              </tr>
            </table>
          </div>
        </ng-template>

        <!-- Cost -->
        <ng-template #cost>
          <div
            class="tab-pane fade header_container"
            [ngClass]="currentTab == 'cost' ? 'show active' : ''"
            id="cost"
            role="tabpanel"
            aria-labelledby="cost-tab"
          >
            <div
              *ngIf="reqServiceprovider"
              class="displayFlex"
              style="display: flex; flex-direction: column"
            >
              <div *ngIf="editable">
                <div
                  *ngFor="let row of rows; let i = index"
                  style="display: flex; flex-direction: row"
                  class="head-box"
                >
                  <div style="position: relative">
                    <label class="label-head">Driver Name</label>
                    <input
                      id="d_name_{{ i }}"
                      type="text"
                      class="form-control mb-1 inputHeight"
                      [(ngModel)]="row.driver_name"
                      name="driver_name_{{ i }}"
                      (input)="reqDataValidation()"
                    />
                  </div>
                  <div style="position: relative; margin-left: 20px">
                    <label class="label-head">Company Name</label>
                    <select
                      class="form-control mb-1 inputHeight"
                      [(ngModel)]="row.company_name"
                      name="company_name_{{ i }}"
                      required
                      (change)="reqDataValidation()"
                    >
                      <option value=""></option>
                      <option value="FASTLINK">FAST LINK</option>
                      <option value="AMGLOBAL">AM GLOBAL</option>
                      <option value="ONTIME">ONTIME</option>
                      <option value="INNOVATION">INNOVATION</option>
                    </select>
                    <!-- <button class="btn btnVender bg-btn-cancel mr-3" (click)="removeRow(i)">Remove</button> -->
                  </div>
                  <div class="buttonposition">
                    <button class="addbtn btn btn-add" (click)="addRow(i)">
                      <mat-icon class="addicon">add_circle_outline</mat-icon>
                    </button>
                    <button
                      class="addbtn btn bg-btn-cancel"
                      (click)="removeRow(i)"
                      [disabled]="rows.length === 1"
                    >
                      <mat-icon class="addicon">delete_sweep</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="!editable && !normalCostAllocation">
                <div *ngFor="let dynamic of dynamicdata; let i = index">
                  <h6 class="f-12 cost_head">Cost Allocation - {{ i + 1 }}</h6>
                  <div class="displayFlex">
                    <div *ngFor="let item of dynamicAllocationFileds">
                      <div style="position: relative">
                        <label class="label-head" style="margin-bottom: -5px"
                          >{{ item.header }}
                        </label>
                        <input
                          type="text"
                          [value]="dynamic[item.field]"
                          [title]="dynamic[item.field]"
                          (input)="
                            updateEditedValue(
                              dynamic.iddynamiccostallocation,
                              item.field,
                              $event.target.value
                            )
                          "
                          class="form-control mb-1 inputHeight"
                          [readonly]="
                            item.field === 'iddynamiccostallocation' ||
                            item.field === 'documentID'
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="!reqServiceprovider">
              <div *ngFor="let a of costAllocation; let i = index">
                <h6 class="f-12 cost_head">Cost Allocation - {{ i + 1 }}</h6>
                <div class="displayFlex">
                  <div *ngFor="let b of allocationFileds">
                    <div style="position: relative" class="input_container">
                      <label class="label-head" style="margin-bottom: -5px"
                        >{{ b.header }}
                      </label>
                      <input
                        #inputv
                        type="text"
                        [value]="a[b.field]"
                        [title]="a[b.field]"
                        disabled
                        class="form-control inv_inp_cls"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <ngx-json-viewer [json]="JsonData" [depth]="3"></ngx-json-viewer>
            </div>
          </div>
        </ng-template>

        <!--Dynamic cost allocation-->
        <ng-template #dynamic>
          <div
            class="tab-pane fade header_container"
            [ngClass]="currentTab == 'dynamic' ? 'show active' : ''"
            id="dynamic"
            role="tabpanel"
            aria-labelledby="dynamic-tab"
          >
            <div
              class="f-12 d-flex justify-content-end"
              [ngStyle]="{ color: subStatusId == 110 ? 'red' : '' }"
            >
              <span
                *ngIf="subStatusId == 110"
                class="mr-2"
                pTooltip="Please compare the current month VAT in the header data"
                tooltipPosition="top"
              >
                <i class="fa fa-info-circle"></i>
              </span>
              Total Calculated Tax -
              {{ decimalRoundOff(totalTaxDynamic) }}
            </div>
            <div>
              <table class="dynamic">
                <tr>
                  <th class="f-12 lineName s_count">S.No</th>
                  <th
                    *ngFor="let item of dynamicAllocationFileds"
                    class="f-12 lineName"
                  >
                    {{ item.header }}
                  </th>
                </tr>
                <tr
                  class="invert_tr"
                  *ngFor="let dynamic of dynamicdata; let i = index"
                >
                  <!-- <div style="position: relative" class="input_container" [ngStyle]="{border: (subStatusId == 110 && item.header == 'Tax amount') ? '1px solid red':''}"> -->
                  <td class="s_count f-12 linevalue invert_th_td">
                    {{ i + 1 }}
                  </td>
                  <td
                    class="linevalue invert_th_td"
                    *ngFor="let item of dynamicAllocationFileds"
                  >
                    <input
                      type="text"
                      [value]="dynamic[item.field]"
                      [title]="dynamic[item.field]"
                      (input)="
                        updateEditedValue(
                          dynamic.iddynamiccostallocation,
                          item.field,
                          $event.target.value
                        )
                      "
                      class="form-control inv_inp_cls"
                      [readonly]="
                        item.field === 'iddynamiccostallocation' ||
                        item.field === 'documentID' ||
                        !editable
                      "
                      [ngStyle]="{
                        border:
                          subStatusId == 110 && item.header == 'Tax amount'
                            ? '1px solid red'
                            : ''
                      }"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </ng-template>

        <!-- Cost -->
        <ng-template #prepay>
          <div
            class="tab-pane fade header_container"
            [ngClass]="currentTab == 'prepay' ? 'show active' : ''"
            id="prepay"
            role="tabpanel"
            aria-labelledby="prepay-tab"
          >
            <div class="f-14 mr-3">
              Pre payment details
            </div>
            <div class="displayFlex">
              <div *ngFor="let data of prePayData | keyvalue; let i = index">
                <div
                  class="input_container"
                  [ngClass]="
                    data?.key?.includes('VendorName') ? 'large_input_box' : ''
                  "
                  *ngIf="!data?.key?.startsWith('id')"
                >
                  <label class="label-head" style="margin-bottom: -5px"
                    >{{ data.key }}
                  </label>
                  <input
                    type="text"
                    [value]="data.value"
                    [title]="data.value"
                    class="form-control inv_inp_cls"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </ng-template>  
        <!--PO lines-->
        <ng-template #poline>
          <div
            *ngIf="currentTab == 'poline'"
            class="tab-pane fade"
            [ngClass]="currentTab == 'poline' ? 'show active' : ''"
            id="POline"
            role="tabpanel"
            aria-labelledby="POline-tab"
          >
            <div style="margin-top: 35px">
              <po-lines [po_num]="po_num"></po-lines>
            </div>
          </div>
        </ng-template>

        <ng-template #grn>
          <div
            class="tab-pane fade"
            [ngClass]="currentTab == 'grn' ? 'show active' : ''"
            id="grn"
            role="tabpanel"
            aria-labelledby="grn-tab"
          >
            <mapped-grn
              [GRNTabData]="GRNTabData"
              [grnTabDatalength]="grnTabDatalength"
            ></mapped-grn>
          </div>
        </ng-template>

        <!-- Support Doc -->
        <ng-template #support>
          <div
            class="tab-pane fade"
            [ngClass]="currentTab == 'support' ? 'show active' : ''"
            id="support"
            role="tabpanel"
            aria-labelledby="support-tab"
            style="position: relative"
          >
            <div
              *ngIf="
                (supportTabBoolean && support_doc_list?.length > 0) ||
                (grnAttachmentArray && !approval_selection_boolean)
              "
            >
              <table class="table" *ngIf="support_doc_list?.length > 0">
                <tr class="f-12 lineName">
                  <th style="width: 100px;">S.No</th>
                  <th>Name</th>
                  <th></th>
                </tr>
                <tr
                  *ngFor="let file of support_doc_list; let i = index"
                  class="f-11"
                >
                  <td>{{ i + 1 }}</td>
                  <td
                    class="break_word pointer"
                    (click)="downloadDoc(file, 'view')"
                  >
                    {{ file }}
                  </td>
                  <td style="width: 100px;">
                    <i
                      class="fa fa-download pointer mr-2"
                      aria-hidden="true"
                      (click)="downloadDoc(file, 'down')"
                    ></i>
                    <i
                      *ngIf="editable"
                      class="fa fa-trash-o pointer"
                      aria-hidden="true"
                      (click)="deleteSupport(file)"
                    ></i>
                  </td>
                </tr>
              </table>
              <h6 class="f-14" *ngIf="grnAttachmentArray">GRN Attachment</h6>
              <ng-container *ngIf="grnAttachmentArray">
                <ng-container *ngFor="let attachment of grnAttachmentArray">
                  <div class="f-12" *ngIf="attachment && attachment[0]">
                    GRN number: {{ attachment[0]?.grnNumber }}
                  </div>
                  <table class="table" *ngIf="attachment && attachment[0]">
                    <tr class="f-12 lineName">
                      <th style="width: 100px">S.No</th>
                      <th>Name</th>
                      <th></th>
                    </tr>
                    <tr
                      class="f-12"
                      *ngFor="let grn of attachment; let i = index"
                    >
                      <td>{{ i + 1 }}</td>
                      <td
                        class="break_word pointer f-12"
                        (click)="grnAttachmentDoc(grn.base64, 'view')"
                      >
                        GRN attachment file {{ i + 1 }}
                      </td>
                      <td style="width: 100px;">
                        <i
                          class="fa fa-download pointer"
                          aria-hidden="true"
                          (click)="grnAttachmentDoc(grn.base64, 'down')"
                        ></i>
                      </td>
                    </tr>
                  </table>
                </ng-container>
              </ng-container>

              <p class="f-11">
                *SERINA is not reading the supporting document
                <strong>(.pdf only)</strong>
                . Please check and review it manually.
              </p>
            </div>
            <div
              *ngIf="
                supportTabBoolean &&
                !(support_doc_list?.length > 0) &&
                grnAttachmentString == undefined &&
                !approval_selection_boolean
              "
            >
              <div class="no_doc_div f-14">
                <div>
                  No Supporting Document available,
                  <span *ngIf="!(editable || fin_boolean)">
                    please upload it in exception screen if you have any.</span
                  ><span *ngIf="editable || fin_boolean">
                    please upload if you have any.</span
                  >
                  <br />
                </div>
                <div *ngIf="GRN_PO_Bool">
                  Please note once you create the GRN then only supporting
                  documents will be stored.
                </div>
              </div>
            </div>
            <div class="file_table" *ngIf="uploadFileList.length > 0">
              <table class="table">
                <tr class="f-12">
                  <th>S.No</th>
                  <th>Name</th>
                  <th></th>
                </tr>
                <tr
                  *ngFor="let file of uploadFileList; let i = index"
                  class="f-11"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ file.name }}</td>
                  <td>
                    <i
                      class="fa fa-trash-o"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="removeUploadQueue(i)"
                    ></i>
                  </td>
                </tr>
              </table>
            </div>
            <div
              *ngIf="
                supportTabBoolean &&
                !approval_selection_boolean &&
                (editable || fin_boolean)
              "
            >
              <label
                for="img"
                class="img-browse"
                [ngClass]="uploadFileList.length > 0 ? 'disable_clas' : ''"
              >
                Attach Document
              </label>
              <input
                (change)="onSelectFile($event)"
                #fileInput
                type="file"
                id="img"
                name="img"
                accept=".pdf,.doc, .docx"
                multiple
                [disabled]="uploadFileList.length > 0"
                hidden
              />
              <!-- <input type="file" multiple (change)="uploadSupport($event)"> -->

              <button
                *ngIf="!GRN_PO_Bool"
                class="img-browse h-35 b-0 ml-2"
                (click)="uploadSupport(invoiceID)"
                type="button"
                [disabled]="!(uploadFileList.length > 0)"
                [ngClass]="!(uploadFileList.length > 0) ? 'disable_clas' : ''"
              >
                Upload
              </button>
            </div>
          </div>
        </ng-template>

        <ng-template #history>
          <div
            class="tab-pane fade reject_comment"
            [ngClass]="currentTab == 'history' ? 'show active' : ''"
            id="support"
            style="position: relative"
          >
            <!-- <table class="reject_comment"> 
                <tr><th>S.No</th><th>Rejected By</th><th>Comments</th></tr>
                <tr *ngFor="let item of approvalRejectRecord;let i = index;">
                  <td>{{i+1}}</td>
                  <td>{{item.firstName}}{{item.lastName}}</td>
                  <td>{{item.DocumentHistoryLogs.documentdescription}}</td>
                </tr>
              </table> -->
            <span *ngFor="let item of approvalRejectRecord; let i = index">
              <span
                [ngStyle]="{
                  visibility: item.DocumentHistoryLogs.CreatedOn
                    ? 'visible'
                    : 'hidden'
                }"
                class="date_box"
              >
                <span *ngIf="item.DocumentHistoryLogs.CreatedOn">{{
                  item.DocumentHistoryLogs?.CreatedOn + "Z" | date : "medium"
                }}</span>
              </span>
              <span class="circles"></span>
              <div class="circle-desc">
                <div class="row">
                  <div class="col-sm-6">
                    <div>
                      <span class="f-12"
                        ><span
                          *ngIf="
                            item.DocumentHistoryLogs?.documentSubStatusID == 82
                          "
                          style="color: red"
                          >Rejection </span
                        >Done by</span
                      >
                      :
                      <span style="font-weight: 500"
                        >{{ item.firstName }} {{ item.lastName }}</span
                      >
                    </div>
                    <div *ngIf="item.DocumentHistoryLogs.documentdescription">
                      <span class="f-12">Description</span> :
                      <span style="font-weight: 500">{{
                        item.DocumentHistoryLogs.documentdescription
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <span
                class="bar"
                *ngIf="i != approvalRejectRecord.length - 1"
              ></span>
            </span>
          </div>
        </ng-template>

        <!--Approval Selection-->
        <ng-template #approver_selection>
          <div
            class="tab-pane fade"
            [ngClass]="
              approval_selection_boolean &&
              !isLCMInvoice &&
              currentTab == 'approver_selection'
                ? 'show active'
                : ''
            "
            id="approver_selection"
            role="tabpanel"
            aria-labelledby="approver_selection-tab"
            style="position: relative"
          >
            <div *ngIf="selectionTabBoolean" class="selection_Div">
              <div
                class="d-flex justify-content-between flex-wrap f-12"
                style="gap: 10px"
              >
                <div class="input_box">
                  <label class="mb-0">Select Entity</label>
                  <select
                    class="form-control inputHeight input_box_field"
                    [(ngModel)]="entityName"
                    [title]="entityName"
                    name="entity"
                    disabled
                  >
                    <option
                      *ngFor="let entity of entityList"
                      [value]="entity.EntityName"
                    >
                      {{ entity.EntityName }}
                    </option>
                  </select>
                </div>
                <div class="input_box">
                  <label class="mb-0">Select Department</label>
                  <select
                    class="form-control inputHeight input_box_field"
                    name="department"
                    [(ngModel)]="selectedDepartment"
                    (change)="onSelectDepartment($event.target.value)"
                  >
                    <option
                      *ngFor="let name of DepartmentList"
                      [value]="name.DepartmentName"
                    >
                      {{ name.DepartmentName }}
                    </option>
                  </select>
                </div>
                <!-- <div>
                    <label>Select Category</label>
                    <select
                      class="form-control inputHeight"
                      (change)="onSelectCategory($event.target.value)"
                    >
                      <option value=""></option>
                    </select>
                  </div> -->
                <div
                  class="input_box"
                  *ngFor="let item of approverList | keyvalue; let i = index"
                >
                  <label class="mb-0">Select Approver {{ i + 1 }}</label>
                  <!-- <div>{{item.value | json}}</div> -->
                  <select
                    class="form-control inputHeight input_box_field"
                    name="approvers"
                    [disabled]="preApproveBoolean"
                    (change)="onSelectApprovers($event.target.value, i)"
                  >
                    <option
                      *ngFor="let element of item.value"
                      [value]="element.User.idUser"
                    >
                      {{ element.User.firstName }} {{ element.User.lastName }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="mt-2">
                <label for="pre_approve" class="f-12">
                  <input
                    type="checkbox"
                    id="pre_approve"
                    class="mr-2"
                    name="preApprove"
                    [(ngModel)]="preApproveBoolean"
                    (change)="onSelectPreApprove(preApproveBoolean)"
                  />
                  <span class="label_adjust">Pre Approved</span></label
                >
              </div>

              <div>
                <label
                  for="img"
                  class="img-browse bg-blue"
                  [ngClass]="uploadFileList.length > 0 ? 'disable_clas' : ''"
                >
                  Attach support Document
                </label>
                <input
                  (change)="onSelectFileApprove($event)"
                  #fileInput
                  type="file"
                  id="img"
                  name="img"
                  multiple
                  [disabled]="uploadFileList.length > 0"
                  hidden
                />
                <!-- <input type="file" multiple (change)="uploadSupport($event)"> -->

                <!-- <button
                    class="img-browse h-35 b-0 ml-2 bg-blue"
                    (click)="uploadSupport()"
                    [disabled]="!(uploadFileList.length > 0)"
                    [ngClass]="!(uploadFileList.length > 0) ? 'disable_clas' : ''"
                  >
                    Upload
                  </button> -->
                <div class="f-11" style="color: #e56767">
                  Note: Please upload supporting documents if you have any
                  <br />
                  *SERINA is not reading the supporting document<strong>
                    (.pdf only)</strong
                  >. Please check and review it manually.
                </div>
              </div>
            </div>
            <div
              *ngIf="
                currentTab == 'approver_selection' &&
                support_doc_list?.length > 0
              "
              class="selection_Div"
              style="min-height: auto"
            >
              <h6 class="f-13">Supporting Documents</h6>

              <table class="table">
                <tr class="f-12 lineName">
                  <th>S.No</th>
                  <th>Name</th>
                  <th></th>
                </tr>
                <tr
                  *ngFor="let file of support_doc_list; let i = index"
                  class="f-11"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ file }}</td>
                  <td>
                    <i
                      class="fa fa-download"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="downloadDoc(file, '')"
                    ></i>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </ng-template>

        <div class="btnFooter">
          <div class="m_r_80" *ngIf="!grnCreateBoolean">
            <button
              *ngIf="submitBtn_boolean && editable && !isMoreRequired"
              class="level_3_btn f-12 mr-3"
              type="button"
              (click)="open_dialog('reject')"
            >
              Reject
            </button>
            <button
              class="secondary_btn f-12 mr-3"
              type="button"
              (click)="backToInvoice()"
            >
              Back
            </button>

            <button
              *ngIf="submitBtn_boolean && editable && !fin_boolean"
              class="primary_btn f-12"
              type="button"
              (click)="
                approval_selection_boolean && isLCMCompleted
                  ? onSubmitApprovers()
                  : proceedToBatch('false')
              "
            >
              {{ subStatusId == 206 ? "Approve" : "Next" }}
            </button>
            <button
              *ngIf="
                fin_boolean &&
                client_name == 'Enova' &&
                !isMoreRequired &&
                !isServiceData
              "
              type="button"
              class="primary_btn f-12 mr-3"
              (click)="open_dialog('more')"
            >
              More info required
            </button>
            <button
              *ngIf="fin_boolean && !isMoreRequired"
              class="primary_btn f-12"
              type="button"
              (click)="batch_id ? bulk_confirm() : open_dialog('approve')"
            >
              Approve
            </button>
            <button
              *ngIf="fin_boolean && client_name == 'Enova' && isMoreRequired"
              type="button"
              class="primary_btn f-12 mr-3"
              (click)="open_dialog('more')"
            >
              Proceed
            </button>
          </div>

          <div *ngIf="grnCreateBoolean && isGRNDataLoaded" class="m_r_80">
            <button
              *ngIf="!GRN_PO_Bool"
              class="level_3_btn f-12 mr-3"
              type="button"
              (click)="open_dialog('reject')"
            >
              Reject
            </button>
            <button
              class="secondary_btn f-12 mr-3"
              type="button"
              (click)="backToInvoice()"
            >
              Back
            </button>
            <button
              *ngIf="client_name == 'Cenomi'"
              class="primary_btn f-12 mr-3"
              type="submit"
              [disabled]="!enable_create_grn || disable_save_btn"
              [pTooltip]="disable_save_btn ? grnTooltip : null"
              tooltipPosition="top"
              (click)="
                router.url.includes('GRN_approvals')
                  ? Approve_grn()
                  : confirm_pop(form.value, true, 'GRN saved successfully')
              "
            >
              Save
            </button>
            <button
              class="primary_btn f-12 mr-3"
              type="submit"
              [pTooltip]="
                disable_save_btn
                  ? grnTooltip
                  : saveDisabled
                  ? 'Please fill all the dates data in the timesheet'
                  : null
              "
              tooltipPosition="top"
              [disabled]="
                !enable_create_grn || disable_save_btn || saveDisabled
              "
              (click)="
                router.url.includes('GRN_approvals')
                  ? Approve_grn()
                  : confirm_pop(form.value, true, 'GRN created successfully')
              "
            >
              {{
                router.url.includes("GRN_approvals") ? "Approve" : "Create GRN"
              }}
            </button>
          </div>
        </div>
      </form>
      <ngx-spinner
        bdColor="rgba(251, 251, 251, 0.8)"
        size="medium"
        color="#070900"
        [fullScreen]="true"
        type="ball-spin-clockwise"
      >
        <p style="color: rgb(0, 0, 0)"></p>
      </ngx-spinner>
    </div>
  </div>
  <div
    *ngIf="showPdf && !['PO', 'GRN'].includes(Itype)"
    class="col-lg-7 col-12 maxPage ShowInvoiceTypeSize doc_mobile"
    [ngStyle]="{
      display: documentViewBool || (isDesktop && showPdf) ? 'block' : 'none'
    }"
  >
    <section *ngIf="isDesktop">
      <app-pdfview
        [vendorName]="vendorName"
        [invoiceNumber]="invoiceNumber"
        [fieldName]="fieldName"
        [fieldToPDFMapping]="inputData"
        [btnText]="btnText"
        (boolEmit)="getPdfBool($event)"
        (PdfBtnClick)="viewPdf()"
      ></app-pdfview>
    </section>
    <div *ngIf="!isDesktop" class="doc_cls_btn" (click)="doc_view()">
      <i class="fa fa-close"></i>
    </div>
    <div *ngIf="!isDesktop">
      <app-pdfview
        [vendorName]="vendorName"
        [invoiceNumber]="invoiceNumber"
        [btnText]="btnText"
        (boolEmit)="getPdfBool($event)"
        (PdfBtnClick)="viewPdf()"
      ></app-pdfview>
    </div>
  </div>
</div>

<div *ngIf="serverError" class="f-12 text-center pt-5">
  <div>
    <img src="/assets/Serina Assets/new_theme/Group 1599.svg" alt="error" />
  </div>
  An unexpected error occurred. Please try again later. <br />
  <button class="f-12 primary_btn mt-2" (click)="backToInvoice()">
    Return to Invoice list
  </button>
</div>
<!-- Add label-->
<p-dialog
  [header]="headerpop"
  [(visible)]="progressDailogBool"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: p_width, maxHeight: '80svh' }"
  [baseZIndex]="10000"
  [draggable]="true"
  [resizable]="false"
  [closeIcon]="false"
>
  <div *ngIf="headerpop == 'Batch Progress'">
    <div class="f-12" *ngIf="!(batchData?.length > 0)">
      Hey, Please hold on the batch is running...
    </div>
    <ul class="f-12" *ngIf="batchData?.length > 0">
      <li *ngFor="let st of batchData">
        <span [ngStyle]="{ color: st.status == 1 ? 'green' : 'red' }">{{
          st.msg
        }}</span>
      </li>
    </ul>

    <div *ngIf="batch_count > 2" class="f-12 mt-2" style="color: red">
      Oops, looks like something is wrong with the invoice, Please check with
      the Serina support team.
    </div>
  </div>
  <div *ngIf="headerpop == 'Please select the status'">
    <mat-radio-group class="example-radio-group" [(ngModel)]="status_change_op">
      <mat-radio-button
        class="example-radio-button f-13 d-block"
        *ngFor="let st of status_arr"
        [value]="st.st_obj"
      >
        {{ st.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div
    class="row"
    *ngIf="headerpop == 'Confirm PO number' || headerpop == 'Select GRN'"
    style="min-height: 40vh; max-height: 60vh"
  >
    <div class="col-md-4">
      <input
        type="text"
        class="form-control f-12"
        (keyup)="POsearch($event.target.value)"
        placeholder="Search.."
      />
      <ul class="ulPOlist f-12 mt-1" *ngIf="headerpop == 'Confirm PO number'">
        <li
          *ngFor="let poitem of poNumbersList; let i = index"
          id="{{i}}-confirmPo"
          (click)="selectedPO(i, $event.target.value)"
          [ngClass]="activePOId == poitem?.PODocumentID ? 'activePO' : ''"
        >
          {{ poitem.PODocumentID }}
        </li>
      </ul>
      <div
        class="f-12"
        *ngIf="!(poNumbersList?.length > 0) && headerpop == 'Confirm PO number'"
      >
        Sorry! NO records found.
      </div>

      <ul class="ulPOlist f-12 mt-1" *ngIf="headerpop == 'Select GRN'">
        <li *ngFor="let grn_item of grnList; let i = index" [id]="i" >
          <div class="badge" *ngIf="grn_item?.mapped">Received</div>
          <input
            type="checkbox"
            [id]="grn_item.GRNNumber"
            [checked]="grn_item.isChecked"
            (change)="selectedGRN($event, grn_item)"
            class="mr-2"
          />
          <label [for]="grn_item.GRNNumber"
            >{{ grn_item.GRNNumber }}
            <span style="color: #358cd0" *ngIf="grn_item?.InvoiceNumber"
              >| Inv -
            </span>
            {{ grn_item?.InvoiceNumber }}</label
          >
        </li>
      </ul>
      <div
        class="f-12"
        *ngIf="!(grnList?.length > 0) && headerpop == 'Select GRN'"
      >
        Sorry! NO records found.
      </div>
    </div>
    <div class="col-md-8">
      <div class="d-flex selection" *ngIf="headerpop == 'Confirm PO number'">
        <div class="w-100">
          <label class="f-12 z_index">Please select the date range</label><br />
          <p-calendar
            #datePicker
            [(ngModel)]="rangeDates"
            selectionMode="range"
            [minDate]="minDate"
            [maxDate]="maxDate"
            placeholder=""
            [readonlyInput]="true"
            [showIcon]="true"
            showButtonBar="true"
            name="dateRange"
            (onClearClick)="clearDates($event)"
            (onSelect)="filterByDate(rangeDates, $event)"
            inputId="range"
          ></p-calendar>
        </div>
      </div>
      <h6
        class="f-14 mt-1 d-flex justify-content-between"
        *ngIf="PO_GRN_Number_line?.length > 0"
      >
        <div>Line details</div>
        <div
          class="f-13 po_date"
          *ngIf="headerpop == 'Confirm PO number' && poDate"
        >
          PO Date: {{ poDate | date : "medium" }}
        </div>
      </h6>
      <div class="POSelectionDiv">
        <table
          class="table f-12"
          *ngIf="
            PO_GRN_Number_line?.length > 0 && headerpop == 'Confirm PO number'
          "
        >
          <tr>
            <th style="width: 60px">S.No</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>UnitPrice</th>
            <th>Net Total</th>
          </tr>
          <tr *ngFor="let item of PO_GRN_Number_line; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ item.Name }}</td>
            <td>{{ item.PurchQty }}</td>
            <td>{{ item.UnitPrice }}</td>
            <td>
              {{ decimalRoundOff(item.PurchQty * item.UnitPrice) }}
            </td>
          </tr>
        </table>
      </div>
      <ng-container *ngIf="headerpop == 'Select GRN'">
        <div class="maxHDiv" >
          <div *ngFor="let grnLine of selectedGRNLines">
            <div class="f-13">GRN : {{ grnLine?.grnNumber }}</div>
            <div >
              <table
                class="table f-12"
                style="border: 1px solid lightgray"
                *ngIf="grnLine?.linesData?.length > 0"
              >
                <tr>
                  <th style="width: 60px">S.No</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>UnitPrice</th>
                  <th>Discount</th>
                  <!-- <th>AmountExcTax</th> -->
                </tr>
                <tr *ngFor="let item of grnLine?.linesData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.Description }}</td>
                  <td>{{ item.Quantity }}</td>
                  <td>{{ item.UnitPrice }}</td>
                  <td>{{ item?.Discount || 'NA' }}</td>
                  <!-- <td>{{ item.AmountExcTax }}</td> -->
                </tr>
              </table>
            </div>
          </div>
          <div
            *ngIf="selected_GRN_total"
            class="d-flex justify-content-between total_grn f-12"
          >
            <div>Total</div>
            <div>{{ decimalRoundOff(selected_GRN_total) }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="headerpop == 'GRN Creation Status'"
    class="f-13"
    style="color: #f38a6b"
  >
    {{ APIResponse }}
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end">
      <button
        *ngIf="
          headerpop == 'Batch Progress' && (!isAprUser || subStatusId != 71)
        "
        type="button"
        class="primary_btn f-12 mt-2"
        (click)="
          isBatchFailed && batch_count <= 2 ? syncBatch() : routeToMapping()
        "
      >
        <span>{{ isBatchFailed && batch_count <= 2 ? "Re-try" : "Next" }}</span>
      </button>
      <button
        *ngIf="
          headerpop == 'Batch Progress' &&
          isAprUser &&
          subStatusId == 71 &&
          !isServiceData
        "
        type="button"
        class="primary_btn f-12 mt-2 mr-2"
        (click)="open_dialog('more')"
      >
        More info required
      </button>
      <button
        *ngIf="headerpop == 'Batch Progress' && isAprUser && subStatusId == 71"
        type="button"
        class="primary_btn f-12 mt-2"
        (click)="open_dialog('approve')"
      >
        Approve & Proceed
      </button>
      <button
        *ngIf="headerpop != 'Batch Progress'"
        type="button"
        class="secondary_btn f-12 mt-2 mr-2"
        (click)="progressDailogBool = false"
      >
        Close
      </button>
      <button
        *ngIf="headerpop == 'Select GRN'"
        type="button"
        class="primary_btn f-12 mt-2"
        [disabled]="!(selectedGRNList?.length > 0)"
        (click)="ChangeGRNData()"
      >
        Change
      </button>
      <button
        *ngIf="headerpop == 'GRN Creation Status'"
        type="button"
        class="secondary_btn f-12 mt-2 mr-2"
        (click)="backToInvoice()"
      >
        Go Back
      </button>
      <button
        *ngIf="headerpop == 'Confirm PO number'"
        type="button"
        class="primary_btn f-12 mt-2"
        [disabled]="!activePOId"
        (click)="confirmPO()"
      >
        Confirm
      </button>
      <button
        *ngIf="headerpop == 'Please select the status'"
        type="button"
        class="primary_btn f-12 mt-2"
        [disabled]="!status_change_op"
        (click)="status_change()"
      >
        Confirm
      </button>
    </div>
  </ng-template>
</p-dialog>

<!-- Reject modal-->
<p-dialog
  [header]="rejectModalHeader"
  [(visible)]="displayrejectDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '450px' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div>
    <div
      class="input_box mb-2"
      *ngIf="rejectModalHeader == 'ADD Rejection Comments' && fin_boolean"
    >
      <label class="f-12 mb-0">Please select the user to reject</label>
      <select
        name="reject_user"
        id="reason_user"
        class="form-control f-14 p-2 input_box_field"
        (change)="selectUserForReject($event.target.value)"
      >
        <option value="" disabled>Select User</option>
        <option *ngFor="let x of userList_approved" [value]="x.idUser">
          {{ x.firstName }} {{ x.lastName }}
        </option>
      </select>
    </div>
    <div
      class="input_box"
      *ngIf="rejectModalHeader == 'ADD Rejection Comments'"
    >
      <div class="d-flex justify-content-between">
        <label class="f-12 mb-0">Please select the reason for rejection</label>
        <i
          *ngIf="this.rejectionComments != ''"
          class="fa fa-edit ml-1 z_index"
          style="cursor: pointer"
          (click)="open_dialog_comp('Amend')"
        ></i>
      </div>
      <select
        *ngIf="!addrejectcmtBool"
        name="reject"
        id="reason"
        class="form-control f-14 p-2 input_box_field"
        (change)="selectReason(rejectionComments)"
        [(ngModel)]="rejectionComments"
      >
        <option value="" disabled>Select reason</option>
        <option *ngFor="let x of rejectReason" [value]="x.reason">
          {{ x.reason }}
        </option>
      </select>
      <input
        *ngIf="addrejectcmtBool"
        type="text "
        class="form-control input_box_field f-14"
        (keyup)="rejectKepup($event.target.value)"
        placeholder="Please enter the reason"
      />
    </div>

    <div *ngIf="rejectModalHeader == 'Check Item Code Availability'">
      <div class="input_box">
        <label class="f-12 mb-0"
          >Please enter Item code number and Check is it available or
          not.</label
        >
        <input
          #ItemCode
          type="number"
          class="form-control f-14 input_box_field"
        />
      </div>
      <div class="d-flex justify-content-end mt-2">
        <button
          class="primary_btn f-12"
          (click)="CheckItemStatus(ItemCode.value)"
        >
          Check & ADD
        </button>
      </div>
    </div>

    <div
      *ngIf="
        rejectModalHeader == 'Add Pre-approval Comments' ||
        rejectModalHeader == 'Please Add Comments'
      "
    >
      <label class="label-head mt-2 mb-0">Comments</label> <br />

      <textarea
        required
        name="comment"
        class="form-control"
        style="font-size: 13px"
        minlength="0"
        (keyup)="addComments($event.target.value)"
        cols="50"
        rows="2"
      ></textarea>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div
      class="d-flex justify-content-end"
      *ngIf="rejectModalHeader == 'ADD Rejection Comments'"
    >
      <button
        type="button"
        class="f-12 primary_btn"
        [disabled]="rejectionComments == '' || rejectionComments == 'Others'"
        [ngStyle]="{
          cursor:
            rejectionComments == '' || rejectionComments == 'Others'
              ? 'not-allowed'
              : 'pointer'
        }"
        (click)="Reject()"
      >
        Send for Rejection
      </button>
    </div>
    <div
      class="d-flex justify-content-end"
      *ngIf="rejectModalHeader != 'ADD Rejection Comments'"
    >
      <button
        *ngIf="preApproveBoolean"
        type="button"
        class="primary_btn f-12"
        (click)="displayrejectDialog = false"
      >
        Ok
      </button>

      <button
        *ngIf="isApproveCommentBoolean"
        type="button"
        class="primary_btn f-12"
        [disabled]="commentsBool"
        [ngStyle]="{ cursor: commentsBool ? 'not-allowed' : 'pointer' }"
        (click)="financeApprove()"
      >
        Approve
      </button>
      <button
        *ngIf="moreInfoBool"
        type="button"
        class="primary_btn f-12"
        [disabled]="commentsBool"
        [ngStyle]="{ cursor: commentsBool ? 'not-allowed' : 'pointer' }"
        (click)="isMoreRequired ? proceedMoreInfo() : moreInfoFun()"
      >
        {{ !isMoreRequired ? "More info required" : "Proceed" }}
      </button>
      <button
        *ngIf="isLCMSubmitBoolean && !preApproveBoolean"
        type="button"
        class="primary_btn f-12"
        (click)="submitLCMLines()"
      >
        Confirm
      </button>
    </div>
  </ng-template>
</p-dialog>

<!-- <dialog>
  
  <div *ngIf="!this.router.url.includes('Create_GRN_inv_list')">
    <div class="d-flex justify-content-between mb-2">
      <h6 class="f-14">Approval comments</h6>
      <div class="f-14 pointer" (click)="closeDialog()"> <i class="fa fa-close"></i></div>
    </div>
    <div>
      <div>
        <label class="label-head mt-2 mb-0">Comments</label> <br />
    
        <textarea
          required
          name="comment"
          class="form-control"
          style="font-size: 13px"
          minlength="0"
          (keyup)="addComments($event.target.value)"
          cols="50"
          rows="2"
        ></textarea>
      </div>
      <div class="f-12" id="cmt">
        Note: Please add comment for the bulk approval for the same batch invoices.
      </div>
    </div>
  
    <div class="d-flex justify-content-end mt-3">
      <button
        type="button"
        class="primary_btn f-12"
        [disabled]="commentsBool"
        [ngStyle]="{ cursor: commentsBool ? 'not-allowed' : 'pointer' }"
        (click)="financeApprove()"
      >
        Approve
      </button>
    </div>
  </div>
</dialog> -->

<dialog #tagDialog class="dialog">
  <div>
    <label for="tag_name" class="f-13 mb-1">Add your description here</label
    ><br />
    <textarea
      class="form-control f-12"
      [(ngModel)]="invoiceDescription"
      Name="invoiceDescription"
      id="invoiceDescription"
      rows="5"
      style="height: 100px"
      (input)="updateCharacterCount($event)"
    ></textarea>

    <div class="mt-3">
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          [style.width.%]="(invoiceDescription?.length / 250) * 100"
          [attr.aria-valuenow]="invoiceDescription?.length"
          aria-valuemin="0"
          aria-valuemax="250"
        ></div>
      </div>
      <div class="d-flex justify-content-between f-10 mt-1">
        <div>Allow only 250 characters</div>
        <div>{{ 250 - invoiceDescription?.length }} characters left</div>
      </div>
    </div>

    <footer class="d-flex justify-content-end mt-2">
      <button
        class="secondary_btn secondary_act_btn f-12 mr-3"
        (click)="closeDialog()"
      >
        Close
      </button>
      <!-- <button class="primary_action_button f-12" (click)="savePrompt()">Save</button> -->
    </footer>
  </div>
</dialog>
<p-toast></p-toast>
