import { AlertService } from './../../../services/alert/alert.service';
import { ExceptionsService } from './../../../services/exceptions/exceptions.service';
import { AuthenticationService } from './../../../services/auth/auth-service.service';
import { DataService } from './../../../services/dataStore/data.service';
import { Subscription } from 'rxjs';
import { PermissionService } from './../../../services/permission.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/services/shared.service';
import { TaggingService } from './../../../services/tagging.service';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
// import { fabric } from 'fabric';
import { DatePipe, Location } from '@angular/common';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import * as $ from 'jquery';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { DomSanitizer } from '@angular/platform-browser';
import IdleTimer from '../../idleTimer/idleTimer';
import * as fileSaver from 'file-saver';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupComponent } from '../../popup/popup.component';
import { take } from 'rxjs/operators';
import { MatAccordion } from '@angular/material/expansion';
@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewInvoiceComponent implements OnInit, OnDestroy {
  @ViewChild('canvas') canvasRef: ElementRef<HTMLCanvasElement>;
  @ViewChild(PdfViewerComponent, { static: false })
  private pdfViewer: PdfViewerComponent;
  ctx: CanvasRenderingContext2D;
  zoomX = 1;
  orgX = '0px';
  orgY = '0px';
  @ViewChild('pdfviewer') pdfviewer;
  vendorsSubscription: Subscription;
  isEditable: boolean;
  editable: boolean;
  rect: any;
  rectCoords: any;
  inputData = [];
  vendorDetails: FormGroup;
  isRect: boolean;
  isTagged: boolean = false;

  mergedArray: any;
  inputDisplayArray = [];
  vendorData = [];
  lineDisplayData: any;
  lineData = [];
  Itype: string;
  updateInvoiceData: any = [];
  imgArray: { id: string; url: string }[];
  headerName: string;
  editPermissionBoolean: boolean;
  changeApproveBoolean: boolean;
  financeApproveBoolean: boolean;
  fin_boolean: boolean;
  submitBtn_boolean: boolean;
  approveBtn_boolean: boolean;
  innerHeight: number;
  InvoiceHeight: number = 560;
  zoomdata: number = 1;
  showInvoice: any;
  page: number = 1;
  totalPages: number;
  isLoaded: boolean = false;
  invoiceID: any;
  routeIdCapture: Subscription;
  byteArray: Uint8Array;

  vendorDetalilsEditBoolean: boolean = false;
  displayrejectDialog: boolean;
  rejectOption = { value: '' };
  rejectionComments: string = '';
  rejectReason: any;

  vendorUplaodBoolean: boolean;

  isPdfAvailable: boolean;
  userDetails: any;
  isServiceData: boolean;
  serviceData: any;
  showPdf: boolean;
  btnText = 'View PDF';
  isImgBoolean: boolean;
  zoomVal: number = 0.8;
  rotation = 0;
  readvendorsData: any;
  timer: any;
  callSession: any;
  GRNUploadID: any;
  reuploadBoolean: boolean;
  vendorName: any;
  invoiceNumber = '';
  rejectpopBoolean: boolean;
  deletepopBoolean: boolean;
  checkItemBoolean: boolean;
  popUpHeader: string;
  lineTabBoolean: boolean;
  item_code: any;
  uploadtime: string = "00:00";
  content_type: any;
  imageCanvas: HTMLImageElement;
  addrejectcmtBool: boolean;
  poLineData = [];
  isAdmin: boolean;
  polineTableData = [
    { TagName: 'LineNumber', linedata: [] },
    { TagName: 'ItemId', linedata: [] },
    { TagName: 'Name', linedata: [] },
    { TagName: 'ProcurementCategory', linedata: [] },
    { TagName: 'PurchQty', linedata: [] },
    { TagName: 'UnitPrice', linedata: [] },
    { TagName: 'DiscAmount', linedata: [] },
    { TagName: 'DiscPercent', linedata: [] }
  ]
  POlineBool: boolean;
  poDocId: any;
  po_num: any;
  grnList: any[];
  selectedGRNList = [];
  currentTab = 'head';
  currentlyOpenedItemIndex = -1;
  GRNTabData: any;
  grnTabDatalength: number;
  progressDailogBool: boolean;
  batchData = [];
  portalName: string;
  subStatusId: any;
  isAmtStr: boolean;
  isDesktop: boolean;
  documentViewBool: boolean;
  linedata_mobile = [];
  @ViewChild(MatAccordion) accordion: MatAccordion;
  isEmpty: boolean;
  p_dailog_head: string;
  p_width: string;
  poNumbersList: any;
  filteredPO: any[];
  PO_GRN_Number_line = [];
  vendorId: any;
  activePOId: string;
  poDate: any;
  displayYear;
  minDate: Date;
  maxDate: Date;
  lastYear: number;
  months: string[];
  selectedMonth: string;
  selectDate: Date;
  filterDataPO: any;
  searchPOArr: any;
  status_arr: any[];
  status_change_op: any;
  isBatchFailed: boolean;
  batch_count = 0;

  constructor(
    private tagService: TaggingService,
    private router: Router,
    private authService: AuthenticationService,
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private SpinnerService: NgxSpinnerService,
    private permissionService: PermissionService,
    private dataService: DataService,
    private exceptionService: ExceptionsService,
    private AlertService: AlertService,
    private SharedService: SharedService,
    private mat_dlg: MatDialog,
    private route: ActivatedRoute,
    private datePipe:DatePipe
  ) {
    this.exceptionService.getMsg().pipe(take(2)).subscribe((msg) => {
      if (msg == 'normal') {
        this.getInvoiceFulldata();
      }
    })
  }

  ngOnInit(): void {
    this.rejectReason = this.dataService.rejectReason;
    this.isDesktop = this.dataService.isDesktop;
    this.route.queryParams.subscribe(params => {
      this.uploadtime = params.uploadtime;
    })
    this.userDetails = this.authService.currentUserValue;
    if (this.userDetails.user_type == 'vendor_portal') {
      this.portalName = 'vendorPortal';
    } else {
      this.portalName = 'customer'
    }
    this.init();
    this.AddPermission();
    this.readVendors();
    this.isAdmin = this.dataService.isAdmin;
    if (this.tagService.editable == true) {
      this.updateSessionTime();
      this.idleTimer(180, 'Start');
      this.callSession = setTimeout(() => {
        this.updateSessionTime();
      }, 250000);
    }
  }
  getPdfBool(event) {
    this.isPdfAvailable = event;
  }
  doc_view() {
    this.showPdf = true;
    this.documentViewBool = !this.documentViewBool
  }
  init() {
    if (
      this.router.url.includes('invoice/InvoiceDetails/vendorUpload') ||
      this.router.url.includes('invoice/InvoiceDetails/CustomerUpload')
    ) {
      this.vendorUplaodBoolean = true;
    } else {
      this.vendorUplaodBoolean = false;
    }
    this.routeIdCapture = this.activatedRoute.params.subscribe((params) => {
      this.SharedService.invoiceID = params['id'];
      this.exceptionService.invoiceID = params['id'];
      this.invoiceID = params['id'];
      this.getInvoiceFulldata();
      this.readFilePath();
    });
    if (this.router.url.includes('InvoiceDetails')) {
      this.Itype = 'Invoice';
      // this.readPOLines();
      // this.getGRNtabData();
    } else if (this.router.url.includes('PODetails')) {
      this.Itype = 'PO';
    } else if (this.router.url.includes('GRNDetails')) {
      this.Itype = 'GRN';
    }

    this.onResize();
    // this.Itype = this.tagService.type;
    this.editable = this.tagService.editable;
    this.fin_boolean = this.tagService.financeApprovePermission;
    this.submitBtn_boolean = this.tagService.submitBtnBoolean;
    this.approveBtn_boolean = this.tagService.approveBtnBoolean;
    this.headerName = this.tagService.headerName;
    this.subStatusId = this.dataService.subStatusId;

    this.showPdf = true;
    this.btnText = 'Close';
  }

  idleTimer(time, str) {
    this.timer = new IdleTimer({
      timeout: time, //expired after 180 secs
      clean: str,
      onTimeout: () => {
        if (this.router.url.includes('ExceptionManagement/InvoiceDetails')) {
          this.router.navigate([`${this.portalName}/ExceptionManagement`]);
          this.AlertService.errorObject.detail =
            'Session Expired for Editing Invoice';
          this.messageService.add(this.AlertService.errorObject);
        }
      },
    });
  }

  updateSessionTime() {
    let sessionData = {
      session_status: true,
      "client_address": JSON.parse(localStorage.getItem('userIp'))
    };
    this.exceptionService
      .updateDocumentLockInfo(JSON.stringify(sessionData))
      .subscribe((data: any) => { });
  }

  readVendors() {
    this.vendorsSubscription = this.dataService
      .getVendorsData()
      .subscribe((data: any) => {
        this.readvendorsData = data;
      });
  }
  AddPermission() {
    if (
      this.permissionService.editBoolean == true &&
      this.permissionService.changeApproveBoolean == false &&
      this.permissionService.financeApproveBoolean == false
    ) {
      this.editPermissionBoolean = true;
    } else if (
      this.permissionService.editBoolean == true &&
      this.permissionService.changeApproveBoolean == true &&
      this.permissionService.financeApproveBoolean == false
    ) {
      this.changeApproveBoolean = true;
    } else if (
      this.permissionService.editBoolean == true &&
      this.permissionService.changeApproveBoolean == true &&
      this.permissionService.financeApproveBoolean == true
    ) {
      this.financeApproveBoolean = true;
    }
  }
  getInvoiceFulldata() {
    this.SpinnerService.show();
    this.inputDisplayArray = [];
    this.lineData = [];
    this.SharedService.getInvoiceInfo().subscribe(
      (data: any) => {
        const pushedArrayHeader = [];
        if (data.ok.uploadtime) {
          this.uploadtime = data.ok.uploadtime;
        }
        data.ok.headerdata.forEach((element) => {
          this.mergedArray = {
            ...element.DocumentData,
            ...element.DocumentTagDef,
          };
          this.mergedArray.DocumentUpdates = element.DocumentUpdates;
          pushedArrayHeader.push(this.mergedArray);
        });
        this.inputData = pushedArrayHeader;
        let inv_num_data: any = this.inputData.filter((val) => {
          return (val.TagLabel == 'InvoiceId') || (val.TagLabel == 'bill_number');
        });
        this.invoiceNumber = inv_num_data[0]?.Value;
        let po_num_data = this.inputData.filter((val) => {
          return (val.TagLabel == 'PurchaseOrder');
        });
        this.po_num = po_num_data[0]?.Value;
        // this.getPODocId(this.po_num);
        // this.getGRNnumbers(this.po_num);
        if (data.ok.vendordata) {
          this.isServiceData = false;
          this.vendorData = {
            ...data.ok.vendordata[0].Vendor,
            ...data.ok.vendordata[0].VendorAccount,
            ...data.ok.vendordata[0].VendorUser,
          };
          this.vendorName = this.vendorData['VendorName'];
          this.vendorId = this.vendorData['idVendor'];
        }
        if (data.ok.servicedata) {
          this.isServiceData = true;
          this.vendorData = {
            ...data.ok.servicedata[0].ServiceAccount,
            ...data.ok.servicedata[0].ServiceProvider,
          };
          this.vendorName = this.vendorData['ServiceProviderName'];
        }
        if (this.Itype == 'PO') {
          let count = 0;
          let array = data.ok.linedata;
          array.forEach((val) => {
            if (val.TagName == 'LineNumber') {
              val.id = 1;
            } else if (val.TagName == 'ItemId') {
              val.id = 2;
            } else if (val.TagName == 'Name') {
              val.id = 3;
            } else if (val.TagName == 'ProcurementCategory') {
              val.id = 4;
            } else if (val.TagName == 'PurchQty') {
              val.id = 5;
            } else if (val.TagName == 'UnitPrice') {
              val.id = 6;
            } else if (val.TagName == 'DiscAmount') {
              val.id = 7;
            } else if (val.TagName == 'DiscPercent') {
              val.id = 8;
            } else {
              count = count + 9;
              val.id = count;
            }
          });
          this.lineDisplayData = array.sort((a, b) => a.id - b.id);
        } else {
          this.lineDisplayData = data.ok.linedata;
          if (this.isDesktop) {
            this.lineDisplayData.unshift({
              TagName: 'S.No',
              idDocumentLineItemTags: 1,
            });

          } else {
            // Get the maximum number of linedata entries across all tags
            const maxLinedataEntries = Math.max(...this.lineDisplayData.map(tag => tag.linedata.length));

            // Iterate through the index of linedata entries
            for (let dataIndex = 0; dataIndex < maxLinedataEntries; dataIndex++) {
              const transformedData:any = [];
              let hasError = false;
              let hasUpdated = false;

              // Iterate through the received data
              this.lineDisplayData.forEach(tag => {
                const tagName = tag.TagName;
                const linedata = tag.linedata[dataIndex];
                const itemData = linedata.DocumentLineItems;

                // Check if any isError is 1
                if (itemData.isError === 1) {
                  hasError = true;
                }
                if (itemData.IsUpdated === 1) {
                  hasUpdated = true;
                }

                // Create an object with the TagName and linedata for the current index
                const tagObject = {
                  TagName: tagName,
                  linedata: linedata
                };

                // Add the tagObject to the transformedData array
                transformedData.push(tagObject);
              });
              transformedData.hasError = hasError;
              transformedData.hasUpdated = hasUpdated;
              // Add the transformedData array for the current index to the main array
              this.linedata_mobile.push(transformedData);
            }
          }
          if (this.editable) {
            this.lineDisplayData.push({
              TagName: 'Actions',
              idDocumentLineItemTags: 1,
            });
          }
          this.lineDisplayData.forEach((ele) => {
            if (ele.TagName == 'S.No') {
              ele.linedata = this.lineDisplayData[1]?.linedata;
            } else if (ele.TagName == 'Actions') {
              ele.linedata = this.lineDisplayData[1]?.linedata;
            }
          });
        }
        this.SpinnerService.hide();
      },
      (error) => {
        this.SpinnerService.hide();
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: 'Server error',
        });
      }
    );
  }

  readFilePath() {
    this.showInvoice = '';
    this.SpinnerService.show();
    this.SharedService.getInvoiceFilePath().subscribe(
      (data: any) => {
        this.content_type = data?.result?.content_type;
        if (
          data.result.filepath &&
          data.result.content_type == 'application/pdf'
        ) {
          this.isPdfAvailable = false;
          this.isImgBoolean = false;

          /*covert base64 to blob */
          this.byteArray = new Uint8Array(
            atob(data.result.filepath)
              .split('')
              .map((char) => char.charCodeAt(0))
          );
          this.showInvoice = window.URL.createObjectURL(
            new Blob([this.byteArray], { type: 'application/pdf' })
          );
        } else if (data.result.content_type == 'image/jpg' || data.result.content_type == 'image/png') {
          let filetype = data.result.content_type
          this.isPdfAvailable = false;
          this.isImgBoolean = true;
          this.byteArray = new Uint8Array(
            atob(data.result.filepath)
              .split('')
              .map((char) => char.charCodeAt(0))
          );
          this.showInvoice = window.URL.createObjectURL(
            new Blob([this.byteArray], { type: filetype })
          );
          this.loadImage();
        } else {
          this.isPdfAvailable = true;
          this.showInvoice = '';
        }
        this.SpinnerService.hide();
      },
      (error) => {
        this.SpinnerService.hide();
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: 'Server error',
        });
      }
    );
  }

  DownloadPDF() {
    let extension;
    if (this.content_type == 'application/pdf') {
      extension = '.pdf';
    } else if (this.content_type == 'image/jpg') {
      extension = '.jpg';
    } else if (this.content_type == 'image/png') {
      extension = '.png';
    }
    fileSaver.saveAs(this.showInvoice, `${this.vendorName}_${this.invoiceNumber}${extension}`);
  }

  loadImage() {
    if (this.isImgBoolean == true) {
      setTimeout(() => {
        this.zoomVal = 1;
        (<HTMLDivElement>document.getElementById('canvas1')).style.transform =
          'scale(' + this.zoomVal + ')';
        this.ctx = this.canvasRef.nativeElement.getContext('2d');
        this.canvasRef.nativeElement.width = window.innerWidth;
        this.canvasRef.nativeElement.height = window.innerHeight;
        this.drawImagein();
      }, 50);

    }
  }
  drawImagein() {

    // const canvas = <HTMLCanvasElement>document.getElementById('canvas1');
    // canvas.height = window.innerHeight;
    // canvas.width = window.innerWidth;
    // const ctx = canvas.getContext('2d');
    this.imageCanvas = new Image();
    this.imageCanvas.src = this.showInvoice;
    let imageWidth, imageHeight;
    this.imageCanvas.onload = () => {
      // Calculate the aspect ratio of the image
      const imageAspectRatio = this.imageCanvas.width / this.imageCanvas.height;
      // Calculate the aspect ratio of the canvas
      const canvasAspectRatio = this.canvasRef.nativeElement.width / this.canvasRef.nativeElement.height;

      // Set the dimensions of the image to fit the canvas while maintaining the aspect ratio

      if (imageAspectRatio > canvasAspectRatio) {
        // The image is wider than the canvas, so set the width of the image to the width of the canvas
        // and scale the height accordingly
        imageWidth = this.canvasRef.nativeElement.width;
        imageHeight = imageWidth / imageAspectRatio;
      } else {
        // The image is taller than the canvas, so set the height of the image to the height of the canvas
        // and scale the width accordingly
        imageHeight = this.canvasRef.nativeElement.height;
        imageWidth = imageHeight * imageAspectRatio;
      }
      // Draw the image on the canvas
      this.ctx.drawImage(this.imageCanvas, 0, 0, imageWidth, imageHeight);

    };

  }


  onChangeValue(key, value, data) {
    this.updateInvoiceData = [];
    // this.inputData[0][key]=value;
    if (key == 'InvoiceTotal' || key == 'SubTotal') { 
      if (value == '' || isNaN(+value)) {
        this.isAmtStr = true ;
      } else {
        this.isAmtStr = false ;
      }
    }
    let updateValue = {
      documentDataID: data.idDocumentData,
      OldValue: data.Value || '',
      NewValue: value,
    };
    this.updateInvoiceData.push(updateValue);
  }
  onChangeLineValue(key,value, data) {
    this.updateInvoiceData = [];
    if (key == 'Quantity' || key == 'UnitPrice' || key == 'AmountExcTax') { 
      if (value == '' || isNaN(+value)) {
        this.isAmtStr = true ;
      } else {
        this.isAmtStr = false ;
      }
    } else if(key == 'Description') {
      if(value == ''){
        this.isEmpty = true;
      } else {
        this.isEmpty = false;
      }
    }
    let updateValue = {
      documentLineItemID: data.idDocumentLineItems,
      OldValue: data.Value || '',
      NewValue: value,
    };
    this.updateInvoiceData.push(updateValue);
  }
  saveChanges() {
    if(!this.isAmtStr && !this.isEmpty){
      if (this.updateInvoiceData.length != 0 && this.updateInvoiceData[0].NewValue != this.updateInvoiceData[0].OldValue) {
        this.SharedService.updateInvoiceDetails(
          JSON.stringify(this.updateInvoiceData)
        ).subscribe(
          (data: any) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Saved',
              detail: 'Changes saved successfully',
            });

            this.updateInvoiceData = [];
          },
          (err) => {
            this.updateInvoiceData = [];
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail: 'Server error or Please check the data',
            });
          }
        );
      }
    } else {
      this.updateInvoiceData = [];
      let err = ''
      if(this.isAmtStr){
        err = 'Strings are not allowed in the amount and quantity fields.';
      } else if(this.isEmpty){
        err = 'Please check, Description field should not be empty';
      }

      if(this.isAmtStr || this.isEmpty){
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: err,
        });
      }
      // this.errorTriger('Strings are not allowed in the amount and quantity fields.');
    }
  }
  onSubmitData() {
    // this.SpinnerService.show();
    // console.log(this.updateInvoiceData);
    // this.SharedService.updateInvoiceDetails(JSON.stringify(this.updateInvoiceData)).subscribe((data: any) => {
    //   console.log(data);
    //   if (data.result == 'success') {
    //     this.messageService.add({
    //       severity: "info",
    //       summary: "Updated",
    //       detail: "Updated Successfully"
    //     });
    //     this.getInvoiceFulldata();
    //   } else {
    //     this.messageService.add({
    //       severity: "error",
    //       summary: "error",
    //       detail: "Something went wrong"
    //     });
    //   }
    //   this.updateInvoiceData = [];
    //   this.SpinnerService.hide();
    // })
  }
  // ngAfterViewInit(){
  //   this.canvasRef.nativeElement.('mouse:wheel',(event){

  // })
  // }

  drawrectangleonHighlight() {
    // var rect = new fabric.Rect({
    //   left: 100,
    //   top: 50,
    //   fill: 'rgba(255,0,0,0.5)',
    //   width: 100,
    //   height: 30,
    //   selectable: false,
    //   lockMovementX: true,
    //   lockMovementY: true,
    //   lockRotation: true,
    //   transparentCorners: true,
    //   hasControls: false,
    // });

    // this.canvas.add(rect);
    // this.canvas.setActiveObject(rect);
    // document.getElementById(index + 1).scrollIntoView();
  }

  zoomin() {
    // this.isRect = false;
    // this.canvas.setZoom(this.canvas.getZoom() * 1.1);
    // this.panning();

    this.zoomVal = this.zoomVal + 0.2;
    this.zoomX = this.zoomX + 0.05;
    this.orgX = this.orgX + '50px';
    this.orgY = this.orgY + '50px';
    if (this.zoomVal >= 2.0 && this.zoomX >= 2.0) {
      this.zoomVal = 1;
      this.zoomX = 1;
      this.orgX = '0px';
      this.orgY = '0px';
    }
    (<HTMLDivElement>document.getElementById('canvas1')).style.transform = `scale(${this.zoomX},${this.zoomVal})`;
    (<HTMLDivElement>document.getElementById('canvas1')).style.transform = `translate(${this.orgX},${this.orgY})`;
  }
  zoomout() {
    // this.isRect = false;
    // this.canvas.setZoom(this.canvas.getZoom() / 1.1);
    // this.panning();
    this.zoomVal = this.zoomVal - 0.2;
    this.zoomX = this.zoomX - 0.05;
    // this.orgX  = this.orgX - '50px';
    // this.orgY  = this.orgY - '50px';
    if (this.zoomVal <= 0.5 && this.zoomX <= 0.8) {
      this.zoomVal = 1;
      this.zoomX = 1;
      this.orgX = '0px';
      this.orgY = '0px';
    }
    (<HTMLDivElement>document.getElementById('canvas1')).style.transform = `scale(${this.zoomX},${this.zoomVal})`;
    (<HTMLDivElement>document.getElementById('canvas1')).style.transform = `translate(${this.orgX},${this.orgY})`;
  }
  removeEvents() {
    // this.canvas.off('mouse:down');
    // this.canvas.off('mouse:up');
    // this.canvas.off('mouse:move');
  }

  panning() {
    // this.removeEvents();
    // let panning = false;
    // let selectable;
    // this.canvas.on('mouse:up', (e) => {
    //   panning = false;
    // });

    // this.canvas.on('mouse:down', (e) => {
    //   panning = true;
    //   selectable = false;
    // });
    // this.canvas.on('mouse:move', (e) => {
    //   if (panning && e && e.e) {
    //     selectable = false;
    //     var units = 10;
    //     var delta = new fabric.Point(e.e.movementX, e.e.movementY);
    //     this.canvas.relativePan(delta);
    //   }
    // });
  }

  addVendorDetails() {
    console.log(this.vendorDetails.value);
  }
  onVerify(e) {
    console.log(e);
  }
  submitChanges() {
    // if (this.vendorUplaodBoolean === false) {
    //   // let submitData = {
    //   //   "documentdescription": null
    //   // }
    //   // this.SpinnerService.show();
    //   // this.SharedService.submitChangesInvoice(JSON.stringify(submitData)).subscribe((data: any) => {
    //   //   this.dataService.invoiceLoadedData = [];
    //   //   if (data.result) {
    //   //     this.messageService.add({
    //   //       severity: "success",
    //   //       summary: "Updated",
    //   //       detail: "Updated Successfully"
    //   //     });
    //   //     this.SpinnerService.hide();
    //   //     setTimeout(() => {
    //   //       this._location.back()
    //   //     }, 1000);
    //   //   }
    //   // }, error => {
    //   //   this.messageService.add({
    //   //     severity: "error",
    //   //     summary: "error",
    //   //     detail: error.error
    //   //   });
    //   //   this.SpinnerService.hide();
    //   // })

    //   this.exceptionService.send_batch_approval_review(this.exceptionService.selectedRuleId).subscribe((data:any)=>{
    //     console.log(data);
    //     this.AlertService.addObject.detail = "Send to Batch review successfully";
    //     this.messageService.add(this.AlertService.addObject);
    //     // this.displayRuleDialog = false;
    //     setTimeout(() => {
    //       this._location.back();
    //     }, 2000);
    //   },error=>{
    //     this.AlertService.errorObject.detail = error.statusText;
    //     this.messageService.add(this.AlertService.errorObject);
    //   })
    // } else {

    this.getInvoiceFulldata();
    this.GRNUploadID = this.dataService.reUploadData?.grnreuploadID;
    if (this.GRNUploadID != undefined && this.GRNUploadID != null) {
      this.reuploadBoolean = true;
    } else {
      this.reuploadBoolean = false;
    }
    setTimeout(() => {
      let count = 0;
      let errorType: string;
      let errorTypeHead: string;
      let errorTypeLine: string;
      /* header Validation starts*/
      this.inputData.forEach((data: any) => {
        if (data.TagLabel == 'InvoiceTotal' || data.TagLabel == 'SubTotal') {
          if (data.Value == '' || isNaN(+data.Value)) {
            count++;
            errorTypeHead = 'AmountHeader';
          }
        } else if (
          data.TagLabel == 'PurchaseOrder' ||
          data.TagLabel == 'InvoiceDate' ||
          data.TagLabel == 'InvoiceId'
        ) {
          if (data.Value == '') {
            errorType = 'emptyHeader';
            count++;
          }
        }
      });
      /* header Validation end*/

      /* Line Details Validation starts*/
      this.lineDisplayData.forEach((element) => {
        if (
          element.TagName == 'Description' ||
          element.TagName == 'Quantity' ||
          element.TagName == 'UnitPrice' ||
          element.TagName == 'AmountExcTax' ||
          element.TagName == 'Amount'
        ) {
          element.linedata.forEach((ele1) => {
            if (
              element.TagName != 'Description' &&
              (ele1.DocumentLineItems?.Value == '' ||
              isNaN(+ele1.DocumentLineItems?.Value))
            ) {
              count++;
              errorTypeLine = 'AmountLine';
            } else if (element.TagName == 'Description' &&
                ele1.DocumentLineItems?.Value == '' ){
                  count++;
                  errorTypeLine = 'description';
            }

            if (element.TagName == 'Quantity') {
              if (
                ele1.DocumentLineItems?.Value == 0
              ) {
                count++;
                errorTypeLine = 'quntity';
              }
            }
          });
        }
      });
      /* Line Details Validation end*/

      if (count == 0) {
        if (!this.isServiceData) {
          this.vendorSubmit();
        } else {
          this.serviceSubmit();
        }
      } else {
        /* Error reponse starts*/
        if (errorTypeHead == 'AmountHeader') {
          setTimeout(() => {
            this.currentTab = 'head';
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail:
                'Please verify SubTotal and InvoiceTotal in Header details',
            });
          }, 50);
        }
        if (errorType == 'emptyHeader') {
          this.currentTab = 'head';
          this.AlertService.errorObject.detail =
            'Please Check PO Number, Invoice Date, InvoiceId fileds in header details';
          this.messageService.add(this.AlertService.errorObject);
        }
        if (errorTypeLine == 'AmountLine') {
          setTimeout(() => {
            this.currentTab = 'line';
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail:
                'Please verify Amount, Quntity, unitprice and AmountExcTax in Line details',
            });
          }, 10);
        } else if (errorTypeLine == 'quntity') {
          setTimeout(() => {
            this.currentTab = 'line';
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail:
                'Please check the Quntity in the Line details',
            });
          }, 10);
        } else if (errorTypeLine == 'description') {
          setTimeout(() => {
            this.currentTab = 'line';
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail:
                'Please check the Description in the Line details',
            });
          }, 10);
        }
        /* Error reponse end*/
      }
    }, 2000);
  }

  vendorSubmit() {
    this.SharedService.vendorSubmit(this.reuploadBoolean, this.uploadtime).subscribe(
      (data: any) => {
        this.dataService.invoiceLoadedData = [];
        this.SpinnerService.hide();
        if (this.router.url.includes('ExceptionManagement')) {
          this.AlertService.addObject.detail = 'send to batch successfully';
          this.AlertService.addObject.summary = 'sent';
          this.messageService.add(this.AlertService.addObject);
        } else {
          if (!this.GRNUploadID) {
            this.messageService.add({
              severity: 'success',
              summary: 'Uploaded',
              detail: 'Uploaded to serina successfully',
            });
          }
        }
        let query = '';
        if (this.GRNUploadID) {
          query = `?re_upload=${this.reuploadBoolean}&grnreuploadID=${this.GRNUploadID}`;
        } else {
          query = `?re_upload=${this.reuploadBoolean}`;
        }
        this.synchBatch(query)
        
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: error.statusText,
        });
      }
    );
  }

  synchBatch(query){
    this.SpinnerService.show();
    this.SharedService.syncBatchTrigger(query).subscribe((data: any) => {
      this.progressDailogBool = true;
      this.p_dailog_head = "Batch Progress";
      this.p_width = '31vw';
      this.batchData = data[this.invoiceID]?.complete_status;
      let last_msg = this.batchData[this.batchData.length - 1].msg;
      this.isBatchFailed = false;
      if( last_msg == 'Batch ran to an Exception!' || last_msg == 'Matching Failed - Batch Failed' && this.batch_count <=2 ){
        this.batch_count++
        this.isBatchFailed = true;
      }
      if(!(this.batch_count <=2)){
        this.AlertService.errorObject.detail = "Hey, Please check with the Serina's support team regarding this invoice.";
        this.messageService.add(this.AlertService.errorObject);
        // setTimeout(() => {
        //   this.router.navigate([`${this.portalName}/ExceptionManagement`])
        // }, 2000);
      }
      if (
        this.vendorUplaodBoolean == true &&
        this.reuploadBoolean == true
      ) {
        if (data[0] == 0) {
          this.messageService.add({
            severity: 'error',
            summary: 'Rejected',
            detail: data[1],
          });
        } else {
          this.messageService.add({
            severity: 'success',
            summary: 'Uploaded',
            detail: data[1],
          });
        }
      }
      this.SpinnerService.hide();
      this.dataService.reUploadData = [];
    },err=>{
      this.SpinnerService.hide();
    })
  }

  routeToMapping() {
    this.exceptionService.invoiceID = this.invoiceID;
    this.tagService.editable = true;
    this.tagService.submitBtnBoolean = true;
    this.tagService.headerName = 'Edit Invoice';
    
    let sub_status = null;
    for (const el of this.batchData) {
      if (el.status == 0) {
        sub_status = el.sub_status;
      }
    };
    if(!sub_status){
      sub_status = this.batchData[this.batchData.length-1].sub_status;
    }
    this.subStatusId = sub_status;
    this.dataService.subStatusId = sub_status;
    if (this.portalName == 'vendorPortal') {
      if (sub_status == 8 ||
        sub_status == 16 ||
        sub_status == 33||
        sub_status == 21||
        sub_status == 27) {
        this.router.navigate([
          `${this.portalName}/ExceptionManagement/batchProcess/comparision-docs/${this.invoiceID}`,
        ]);
      } else {
        this.router.navigate([`${this.portalName}/invoice/allInvoices`]);
      }
    } else {
      if (sub_status == 8 ||
        sub_status == 16 ||
        sub_status == 17 ||
        sub_status == 33 ||
        sub_status == 21 ||
        sub_status == 27 ||
        sub_status == 75) {
        this.router.navigate([
          `${this.portalName}/ExceptionManagement/batchProcess/comparision-docs/${this.invoiceID}`,
        ]);
      } else if (sub_status == 34) {
        this.AlertService.updateObject.summary = 'Suggestion';
        if(sub_status == 34){
          this.AlertService.updateObject.detail = 'Please compare the PO lines with invoices and we recommend PO flip method to solve this issues.';
        }
        this.messageService.add(this.AlertService.updateObject)
      } else if (sub_status == 7 || sub_status == 23 || sub_status == 10 || sub_status == 35 || sub_status == 0) {
        this.router.navigate([`${this.portalName}/ExceptionManagement`]);
      } else {
        this.router.navigate([`${this.portalName}/invoice/allInvoices`]);
      }
    }

    this.progressDailogBool = false;
  }


  serviceSubmit() {
    this.SharedService.serviceSubmit().subscribe((data: any) => {
      this.AlertService.addObject.detail = 'send to batch successfully';
      this.AlertService.addObject.summary = 'sent';
      this.messageService.add(this.AlertService.addObject);
      setTimeout(() => {
        this._location.back();
      }, 1000);
    }, err => {
      this.messageService.add({
        severity: 'error',
        summary: 'error',
        detail: "Server error",
      });
    })
  }
  approveChanges() {
    // let approve = {
    //   "documentdescription": ""
    // }
    // this.SharedService.approveInvoiceChanges(JSON.stringify(approve)).subscribe((data: any) => {
    //   this.dataService.invoiceLoadedData = [];
    //   this.messageService.add({
    //     severity: "success",
    //     summary: "Approved",
    //     detail: "Changes approved successfully"
    //   });
    //   setTimeout(() => {
    //     this._location.back()
    //   }, 1000);
    // }, error => {
    //   this.messageService.add({
    //     severity: "error",
    //     summary: "error",
    //     detail: error.statusText
    //   });
    // })

    this.exceptionService.send_batch_approval().subscribe(
      (data: any) => {
        this.AlertService.addObject.detail = 'Send to batch successfully';
        this.messageService.add(this.AlertService.addObject);
        setTimeout(() => {
          this._location.back();
        }, 2000);
      },
      (error) => {
        this.AlertService.errorObject.detail = error.statusText;
        this.messageService.add(this.AlertService.errorObject);
      }
    );
  }

  financeApprove() {
    this.SharedService.financeApprovalPermission().subscribe(
      (data: any) => {
        this.dataService.invoiceLoadedData = [];
        this.messageService.add({
          severity: 'success',
          summary: 'Approved',
          detail: data.result,
        });
        setTimeout(() => {
          this._location.back();
        }, 1000);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: error.statusText,
        });
      }
    );
  }

  selectReason(reasn) {
    if (reasn == 'Others') {
      this.addrejectcmtBool = true;
    } else {
      this.addrejectcmtBool = false;
    }
  }

  rejectKepup(val) {
    this.rejectionComments = val;
  }
  Reject() {
    let rejectionData = {
      documentdescription: this.rejectionComments,
      userAmount: 0,
    };
    if (this.rejectOption.value == 'IT_reject') {
      this.SharedService.ITRejectInvoice(
        JSON.stringify(rejectionData)
      ).subscribe(
        (data: any) => {
          this.dataService.invoiceLoadedData = [];
          this.messageService.add({
            severity: 'success',
            summary: 'Rejected',
            detail: 'Successfully send rejection for IT',
          });
          this.displayrejectDialog = false;
          setTimeout(() => {
            this._location.back();
          }, 1000);
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'error',
            detail: error.error,
          });
        }
      );
    } else {
      this.SharedService.vendorRejectInvoice(
        JSON.stringify(rejectionData)
      ).subscribe(
        (data: any) => {
          this.dataService.invoiceLoadedData = [];
          this.messageService.add({
            severity: 'success',
            summary: 'Rejected',
            detail: 'Successfully send rejection for Vendor',
          });
          this.displayrejectDialog = false;
          setTimeout(() => {
            this.router.navigate([`${this.portalName}/ExceptionManagement`]);
          }, 1000);
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'error',
            detail: 'Something went wrong',
          });
        }
      );
    }
  }

  backToInvoice() {
    if (this.vendorUplaodBoolean === false) {
      this._location.back();
    } else {
      if (
        confirm(
          ` Are you sure you want cancel process ? \n if you click OK you will lost your invoice meta data.`
        )
      ) {
        this._location.back();
      }
    }
    // else {
    //   this._location.back();
    // }
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerHeight = window.innerHeight;
    if (this.innerHeight > 550 && this.innerHeight < 649) {
      this.InvoiceHeight = 500;
    } else if (this.innerHeight > 650 && this.innerHeight < 700) {
      this.InvoiceHeight = 560;
    } else if (this.innerHeight > 750) {
      this.InvoiceHeight = 660;
    }
  }
  zoomIn() {
    this.zoomdata = this.zoomdata + 0.1;
  }
  zoomOut() {
    this.zoomdata = this.zoomdata - 0.1;
  }
  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }
  textLayerRendered(e: CustomEvent) { }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }
  selectedText(): void { }

  search(stringToSearch: string) {
    this.pdfViewer.pdfFindController.executeCommand('find', {
      caseSensitive: false,
      findPrevious: undefined,
      highlightAll: true,
      phraseSearch: true,
      query: stringToSearch,
    });
  }

  rotate(angle: number) {
    this.rotation += angle;
  }

  convertInchToPixel(arr: any) {
    // let diagonalpixel = Math.sqrt(Math.pow(window.screen.width,2)+Math.pow(window.screen.height,2));
    // let diagonalinch = diagonalpixel/72;
    // let ppi = diagonalpixel/diagonalinch;
    let ppi = 96;
    let Height = arr.Height * ppi;
    let Width = arr.Width * ppi;
    let Xcord = arr.Xcord * ppi;
    let Ycord = arr.Ycord * ppi;
    return [Height, Width, Xcord, Ycord];
  }

  hightlight(val) {
    let boundingBox = this.convertInchToPixel(val);
    let hgt: number = boundingBox[0];
    let wdt = boundingBox[1];
    let xa = boundingBox[2];
    let ya = boundingBox[3];
    var pageno = parseInt('1');
    var pageView = this.pdfViewer.pdfViewer._pages[pageno - 1];
    //datas - array returning from server contains synctex output values
    var left = xa;
    var top = ya;
    var width = wdt;
    var height = hgt;
    //recalculating top value
    top = pageView.viewport.viewBox[3] - top;
    var valueArray = [left, top, left + width, top + height];
    let rect = pageView.viewport.convertToViewportRectangle(valueArray);
    // rect       = PDFJS.disableTextLayer.normalizeRect(rect);
    var x = Math.min(rect[0], rect[2]),
      width = Math.abs(rect[0] - rect[2]);
    var y = Math.min(rect[1], rect[3]),
      height = Math.abs(rect[1] - rect[3]);
    const element = document.createElement('div');
    element.setAttribute('class', 'overlay-div-synctex');
    element.style.left = x + 'px';
    element.style.top = y + 'px';
    element.style.width = width + 'px';
    element.style.height = height + 'px';
    element.style.position = 'absolute';
    element.style.backgroundColor = 'rgba(200,0,0,0.5)';
    $('*[data-page-number="' + pageno + '"]').append(element);
    this.pdfviewer.pdfViewer._scrollIntoView({
      pageDiv: pageView.div,
    });
  }

  onClick(e) {
    const textLayer = document.getElementsByClassName('TextLayer');
    const x =
      window.getSelection().getRangeAt(0).getClientRects()[0].left -
      textLayer[0].getBoundingClientRect().left;
    const y =
      window.getSelection().getRangeAt(0).getClientRects()[0].top -
      textLayer[0].getBoundingClientRect().top;
  }

  open_dialog(str, val) {
    if (str == 'reject') {
      this.popUpHeader = ' ADD Rejection Comments';
      this.rejectpopBoolean = true;
      this.deletepopBoolean = false;
      this.checkItemBoolean = false;
    } else if (str == 'delete') {
      this.popUpHeader = ' Please confirm';
      this.deletepopBoolean = true;
      this.rejectpopBoolean = false;
      this.checkItemBoolean = false;
      this.item_code = val.itemCode;
    } else {
      this.popUpHeader = "Check Item code availability";
      this.deletepopBoolean = false;
      this.rejectpopBoolean = false;
      this.checkItemBoolean = true;
    }
    this.displayrejectDialog = true;
  }
  removeLine() {
    this.exceptionService.removeLineData(this.item_code).subscribe((data: any) => {
      if (data.status == "deleted") {
        this.AlertService.addObject.detail = "Line item deleted";
        this.messageService.add(this.AlertService.addObject);
        this.displayrejectDialog = false;
        this.getInvoiceFulldata();
      }
    }, err => {
      this.AlertService.errorObject.detail = "Server error";
      this.messageService.add(this.AlertService.errorObject);
      this.displayrejectDialog = false;
    })
  };

  CheckItemStatus(item) {
    this.exceptionService.checkItemCode(item).subscribe((data: any) => {
      if (data.status == "not exists") {
        let addLineData = {
          "documentID": this.invoiceID,
          "itemCode": item
        };
        this.exceptionService.addLineItem(JSON.stringify(addLineData)).subscribe((data: any) => {
          this.AlertService.addObject.detail = "Line item Added";
          this.messageService.add(this.AlertService.addObject);
          this.getInvoiceFulldata();
        });
        this.displayrejectDialog = false;
      } else {
        this.AlertService.errorObject.detail = "Item code already exist, Please try other item code";
        this.messageService.add(this.AlertService.errorObject);
      }
    }, err => {
      this.AlertService.errorObject.detail = "Server error";
      this.messageService.add(this.AlertService.errorObject);
      this.displayrejectDialog = false;
    })
  }
  async open_dialog_comp(str) {
    let w = '60%';
    let h = '80vh';
    let response;
    if (str == 'Amend') {
      this.displayrejectDialog = false;
      w = '40%';
      h = '40vh';
    } else if (str == 'flip') {
      try {
        const data: any = await this.exceptionService.getPOLines().toPromise();
        response = data.Po_line_details;
      } catch (error) {
        console.error('Error fetching PO lines:', error);
        return;
      }
    }
    this.SpinnerService.show();
    const matdrf: MatDialogRef<PopupComponent> = this.mat_dlg.open(PopupComponent, {
      width: w,
      height: h,
      hasBackdrop: false,
      data: { type: str, resp: response, rejectTxt: this.rejectionComments }
    });
    this.SpinnerService.hide();
    if(str == 'Amend') {
      matdrf.afterClosed().subscribe((resp:any)=>{
        this.rejectionComments = resp;
        if(resp){
          this.Reject();
        }
      })
    } else {
      matdrf.afterClosed().subscribe((resp: any) => {
        if (resp == 'success') {
          setTimeout(() => {
            this.router.navigate([`${this.portalName}/ExceptionManagement`]);
          }, 1000);
        }
      })
    }
  }

  // getPO_lines() {
  //   this.exceptionService.getPOLines().subscribe((data: any) => {
  //     this.poLineData = data.Po_line_details;
  //     this.SpinnerService.hide();
  //   }, err => {
  //     this.AlertService.errorObject.detail = "Server error";
  //     this.messageService.add(this.AlertService.errorObject);
  //     this.SpinnerService.hide();
  //   })
  // }
  
  readPOLines_data() {
    this.exceptionService.getPOLines().subscribe((data: any) => {
      this.poLineData = data.Po_line_details;
      if(this.poLineData){
        if (Object?.keys(this.poLineData[0])?.length > 0) {
          this.POlineBool = true;
        } else {
          this.POlineBool = false;
        }
      }
      this.SpinnerService.hide();
    }, err => {
      this.AlertService.errorObject.detail = "Server error";
      this.messageService.add(this.AlertService.errorObject);
      this.SpinnerService.hide();
    })
  }

  // getPODocId(po_num) {
  //   this.SharedService.get_poDoc_id(po_num).subscribe((data: any) => {
  //     this.poDocId = data.result;
  //   })
  // }

  // getGRNnumbers(po_num){
  //   this.SharedService.checkGRN_PO_duplicates(po_num).subscribe((data:any)=>{
  //     this.grnList = data.result;
  //   })
  // }
  // ChangeGRNData(){
  //   if(this.selectedGRNList.length > 0 && confirm('Please confirm, the selected GRN is correct or not')) {
  //     let arr = [];
  //     this.selectedGRNList.forEach(el=>{
  //       arr.push(el.docheaderID)
  //     })
  //     this.SharedService.updateGRNnumber(JSON.stringify(arr)).subscribe(data=>{
  //       this.AlertService.addObject.detail = "GRN Data Updated, please send the invoice to batch";
  //       this.messageService.add(this.AlertService.addObject);
  //     },err=>{
  //       this.AlertService.errorObject.detail = "Server error";
  //       this.messageService.add(this.AlertService.errorObject);
  //     })
  //   }
  // }
  // refreshPO() {
  //   this.SpinnerService.show();
  //   this.SharedService.updatePO(this.poDocId).subscribe((data: any) => {
  //     this.readPOLines();
  //     this.SpinnerService.hide();
  //     this.AlertService.addObject.detail = 'PO data updated.';
  //     this.messageService.add(this.AlertService.addObject);
  //   }, err => {
  //     this.SpinnerService.hide();
  //     this.AlertService.errorObject.detail = 'Server error';
  //     this.messageService.add(this.AlertService.errorObject);
  //   })
  // }
  viewPdf() {
    this.showPdf = !this.showPdf;
    if (this.showPdf != true) {
      this.btnText = 'View PDF';
    } else {
      this.btnText = 'Close';
    }
    this.loadImage();
  }

  changeTab(val, tab) {
    this.currentTab = tab;
    if (val === 'show') {
      this.showPdf = true;
      this.btnText = 'Close';
    } else {
      this.showPdf = false;
      this.btnText = 'View PDF';
    }
    if (tab == 'line') {
      this.lineTabBoolean = true;
    } else {
      this.lineTabBoolean = false;
    }
    this.loadImage();
  }

  // getGRNtabData() {
  //   this.SharedService.getGRNTabData().subscribe((data: any) => {
  //     this.GRNTabData = data?.result;
  //     this.grnTabDatalength = Object.keys(this.GRNTabData).length;
  //   })
  // }

  setOpened(itemIndex) {
    this.currentlyOpenedItemIndex = itemIndex;
  }

  setClosed(itemIndex) {
    if (this.currentlyOpenedItemIndex === itemIndex) {
      this.currentlyOpenedItemIndex = -1;
    }
  }

  getPO_numbers(idVen){
    this.SpinnerService.show();
    this.SharedService.getPo_numbers(idVen).subscribe((data:any)=>{
      this.poNumbersList = data.result;
      this.filteredPO = data.result;
      this.SpinnerService.hide();
    })
  }

  poDailog(data){
    this.progressDailogBool = true;
    this.p_dailog_head = "Confirm PO number";
    this.p_width = '70vw';
    this.getPO_numbers(this.vendorId);
    this.searchPOArr = data
    this.getDate();
  }
  filterPOnumber(event){
    let filtered: any[] = [];
    let query = event.query;
      if (this.poNumbersList?.length > 0) {
        for (let i = 0; i < this.poNumbersList?.length; i++) {
          let PO: any = this.poNumbersList[i];
          if (PO.PODocumentID.toLowerCase().includes(query.toLowerCase())) {
            filtered.push(PO);
          }
          this.filteredPO = filtered;
        }
      }
  }
  POsearch(val){
       this.poNumbersList = this.filteredPO;
      if (this.poNumbersList?.length > 0) {
        this.poNumbersList = this.poNumbersList.filter(el=>{
          return el.Document.PODocumentID.toLowerCase().includes(val.toLowerCase())
        });
      }
  }
  selectedPO(id,event){
    this.updateInvoiceData = [];
    let po_num = document.getElementById(id).innerHTML;
    this.poDate = this.poNumbersList.filter((val)=>{
      return val.Document.PODocumentID == po_num
    });
    this.activePOId = po_num;
    this.SharedService.po_doc_id = po_num;
    this.SharedService.po_num = po_num;
    this.readPOLines(po_num)
  }

  readPOLines(po_num) {
    this.SpinnerService.show();
    this.SharedService.getPO_Lines(po_num).subscribe((data: any) => {
      this.PO_GRN_Number_line = data.result;
      this.SpinnerService.hide();
    }, err => {
      this.AlertService.errorObject.detail = "Server error";
      this.messageService.add(this.AlertService.errorObject);
      this.SpinnerService.hide();
    })
  }

  confirmPO(){
    let updateValue = {
      documentDataID: this.searchPOArr.idDocumentData,
      OldValue: this.searchPOArr.Value || '',
      NewValue: this.SharedService.po_num,
    };
    this.updateInvoiceData.push(updateValue);
    this.saveChanges();
    this.progressDailogBool = false;
    setTimeout(() => {
      this.getInvoiceFulldata();
    }, 1000);
  }
  getDate() {
    this.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let today = new Date();
    let month = today.getMonth();
    this.selectedMonth = this.months[month];
    let year = today.getFullYear();
    this.lastYear = year - 2;
    this.displayYear = `${this.lastYear}:${year}`;
    let prevYear = year - 2;

    this.minDate = new Date();
    this.minDate.setMonth(month);
    this.minDate.setFullYear(prevYear);

    this.maxDate = new Date();
    this.maxDate.setMonth(month);
    this.maxDate.setFullYear(year);
  }

  applyDatefilter(date){
    this.PO_GRN_Number_line = [];
    delete this.activePOId;
    const month = this.datePipe.transform(date, 'yyyy-MM');
    // const frmDate = `${year}-01`;
    // const toDate = this.datePipe.transform(date, 'yyyy-MM');
    
    this.poNumbersList = this.filteredPO;
    this.poNumbersList = this.poNumbersList.filter((element) => {
      
      const dateF = this.datePipe.transform(element.DocumentData.Value, 'yyyy-MM')
      return dateF == month;
    });
  }

  status_dialog(){
    this.progressDailogBool = true;
    this.p_dailog_head = "Please select the status";
    this.p_width = '35vw';
    this.status_arr = [
      { name:'Sent to ERP',  st_obj:{status:'7', subStatus:'77'}},
      { name:'Posted', st_obj:{status:'14', subStatus:'77'}}
    ];
  }
  status_change(){
    this.SpinnerService.show();
    let obj = {
      "documentStatusID":this.status_change_op.status,
      "documentsubstatusID": this.status_change_op.subStatus
    }
    this.SharedService.changeStatus(obj).subscribe((data: any) => {
      this.AlertService.addObject.detail = data.result;
      this.messageService.add(this.AlertService.addObject);
      this.progressDailogBool = false;
      this.SpinnerService.hide();
    }, err => {
      this.SpinnerService.hide();
      this.AlertService.errorObject.detail = 'Server error';
      this.messageService.add(this.AlertService.errorObject);
    })
  }
  ngOnDestroy() {
    // if (this.tagService.editable == true) {
    let sessionData = {
      session_status: false,
      "client_address": JSON.parse(localStorage.getItem('userIp'))
    };
    this.exceptionService
      .updateDocumentLockInfo(sessionData)
      .subscribe((data: any) => { });
    clearTimeout(this.callSession);
    // }
    this.mat_dlg.closeAll();
    // this.idleTimer(0,"End");
    // this.tagService.financeApprovePermission = false;
    // this.tagService.approveBtnBoolean = false;
    // this.tagService.submitBtnBoolean = false;
    this.vendorsSubscription.unsubscribe();
  }


}









































