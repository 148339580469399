<div>
  <div class="scroll">
    <div
      class="t_s_div"
      *ngIf="
        router.url.includes('allInvoices') ||
        router.url.includes('GRNExceptions') ||
        router.url.includes('ServiceInvoices')
      "
    >
      <input
        class="t_search f-12"
        pInputText
        type="text"
        placeholder="Search + Enter"
        [(ngModel)]="globalSearch"
        (keyup)="searchInvoice($event.target.value)"
        (input)="allInvoice.filterGlobal($event.target.value, 'contains')"
      />
      <span class="material-icons searchIcon"> search </span>
    </div>
    <div class="scroller"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [scrollWindow]="false"
        (scrolled)="onScroll()">
      <p-table
      #allInvoice
      [value]="tableData"
      [rows]="rows"
      [first]="first"
      [globalFilterFields]="columnsToDisplay"
      (onPage)="paginate($event)"
      styleClass="p-datatable"
      responsiveLayout="scroll"
      [columns]="invoiceColumns"
      selectionMode="single"
      [(selection)]="selectedFields1"
      dataKey="id"
      stateStorage="session"
      [stateKey]="stateTable"
      [paginator]="showPaginatorAllInvoice"
      [showFirstLastIcon]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th
            *ngFor="let col of columns"
            [pTooltip]="col.columnDescription"
            tooltipPosition="top"
            [ngStyle]="{ width: col.columnName === 'Status' ? '20%' : '' }"
            [pSortableColumn]="col.dbColumnname"
          >
            {{ col.columnName }}
          </th>
          <th class="action_Zindex" *ngIf="isDesktop">
            Actions
            <i
              *ngIf="
                router.url.includes('allInvoices') ||
                router.url.includes('PO') ||
                router.url.includes('archived') ||
                router.url.includes('ServiceInvoices')
              "
              class="fa fa-pencil-square-o actionsBtn"
              style="border: 0"
              aria-hidden="true"
              (click)="showSidebar()"
            ></i>
          </th>
          <th *ngIf="!isDesktop" class="action_Zindex" [ngClass]="invoceDoctype ? 'ap_bgClor' : 'ar_bgClr'" style="width: 54px;"> Action</th>
        </tr>
        <tr>
          <td *ngFor="let col of columns" class="sticky_top">
            <div [ngSwitch]="col.dbColumnname">
              <div *ngSwitchCase="'docstatus'" class="f-12">
                <!-- <p-columnFilter
                  [field]="col.dbColumnname"
                  matchMode="contains"
                  [showMenu]="false"
                >
                  <ng-template pTemplate="filter">
                    <p-dropdown
                      [options]="statusData"
                      [(ngModel)]="selectedStatus"
                      (onChange)="filter($event.value,col.dbColumnname)"
                      placeholder="Select Status"
                      [showClear]="false"
                      styleClass="w-100 text-center f-11"
                    ></p-dropdown>
                  </ng-template>
                </p-columnFilter> -->
                <p-dropdown
                  [options]="statusData"
                  [(ngModel)]="selectedStatus"
                  (onChange)="filter($event.value)"
                  placeholder="Select Status"
                  [showClear]="false"
                  styleClass="w-100 text-center f-11"
                ></p-dropdown>
              </div>
              <div *ngSwitchDefault>
                <input
                  pInputText
                  type="text"
                  (input)="
                    allInvoice.filter(
                      $event.target.value || ' ',
                      col.dbColumnname,
                      'contains'
                    )
                  "
                  (change)="filteredData($event.target.value)"
                  [value]="allInvoice.filters[col.dbColumnname]?.value"
                  [placeholder]="'Search by ' + col.columnName"
                  class="tableSearchInput"
                />

                <!-- <p-columnFilter placeholder="Search by ID" type="text"  [field]="col.field"></p-columnFilter> -->
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template *ngIf="isDesktop" pTemplate="body" let-invoiceData let-columns="columns">
        <tr>
          <td
            *ngFor="let col of columns"
            [title]="invoiceData[col.dbColumnname]"
          >
            <div [ngSwitch]="col.dbColumnname">
              <div *ngSwitchCase="'docstatus'" style="z-index: 0">
                <!-- <td style="width: 20%;" class="statusTypeIcon"
                  [ngStyle]="{'background-color': (invoiceData.docstatus === 'Rejected' ) ? 'red' : (invoiceData.docstatus === 'In Progress' ) ? '#ff9494': (invoiceData.docstatus === 'Wait Approve' ) ? '#37dcc7': (invoiceData.docstatus === 'Approved' ) ? '#b4ea21': (invoiceData.docstatus === 'Financially Approved' ) ? '#2bd43c': (invoiceData.docstatus === 'paid' ) ? '#107e3e': (invoiceData.docstatus === 'Review' ) ? '#e6c213':'' } ">
                  {{invoiceData[col.dbColumnname]}}
                </td> -->
                <div *ngFor="let color of bgColorCode">
                  <!-- <td
                    class="statusType"
                    *ngIf="invoiceData.documentStatusID == color.sub_id && invoiceData.documentsubstatusID != 35 && invoiceData.documentsubstatusID != 39"
                    [ngStyle]="{
                      'border-color':
                        invoiceData.documentStatusID == color.id
                          ? color.bgcolor
                          : '',
                      color:
                        invoiceData.documentStatusID == color.id
                          ? color.textColor
                          : ''
                    }"
                  > -->
                  <td
                    class="statusType"
                    *ngIf="invoiceData.documentStatusID == color.sub_id && invoiceData.documentsubstatusID != 39"
                    [ngStyle]="{
                      'background-color':
                        invoiceData.documentStatusID == color.id
                          ? color.bgcolor
                          : '',
                      color:
                        invoiceData.documentStatusID == color.id
                          ? color.textColor
                          : ''
                    }"
                  >
                  <!-- <div class="d-flex"> -->
                    <!-- <span class="mr-2"><img [src]="color.icon" width="20" height="20" alt=""></span> -->
                    <span>{{ invoiceData[col.dbColumnname] }}
                      <button
                      *ngIf="invoiceData.documentStatusID == 1"
                      pTooltip="Trigger Batch"
                      tooltipPosition="top"
                      class="f-13 b-0"
                      style="color: red; font-weight: 800"
                      (click)="triggerBatch(invoiceData.idDocument)"
                      [disabled]="
                        triggerBoolean && invoiceID == invoiceData.idDocument
                      "
                    >
                      <i class="fa fa-refresh" aria-hidden="true"></i>
                    </button>
                    <button
                      *ngIf="invoiceData.documentStatusID == 1 && isAdmin"
                      pTooltip="Status roll back"
                      tooltipPosition="top"
                      class="f-13 b-0"
                      style="color: red; font-weight: 800"
                      (click)="changeStatus(invoiceData.idDocument)"
                    >
                      <i class="fa fa-undo" aria-hidden="true"></i>
                    </button>
                    <i
                      *ngIf="invoiceData.docstatus == 'Need To Review' || ((invoiceData.docstatus == 'Posted In ERP' || invoiceData.docstatus == 'Sent to ERP') && invoiceData.documentsubstatusID == 77)"
                      [pTooltip]="invoiceData.documentsubstatusID == 77 ? 'Manually Created/Posted':invoiceData?.status"
                      tooltipPosition="top"
                      class="f-13 ml-3"
                      style="color: red; font-weight: 800"
                    >
                      !</i
                    >
                    </span>
                  <!-- </div> -->
                    
                  </td>
                  
                </div>
              </div>
              <div class="elipse" *ngSwitchDefault>
                <span
                  *ngIf="col.dbColumnname == 'PODocumentID'"
                  (click)="openPOpage(invoiceData.PODocumentID)"
                  ><a>{{ invoiceData[col.dbColumnname] }}</a></span
                >
                <span *ngIf=" col.dbColumnname == 'CreatedOn' || col.dbColumnname == 'documentDate'">
                  <span *ngIf="invoiceData[col?.dbColumnname] && col.dbColumnname == 'CreatedOn'">{{ invoiceData[col?.dbColumnname] + "Z" | date:'yyyy-MM-dd, h:mm a' }}</span>  
                  <span *ngIf="invoiceData[col?.dbColumnname] && col.dbColumnname == 'documentDate'">{{ invoiceData[col?.dbColumnname] + "Z" | date:'yyyy-MM-dd' }}</span>  
                </span>
                <span
                 *ngIf="col.dbColumnname != 'PODocumentID' && col.dbColumnname != 'CreatedOn' && col.dbColumnname != 'documentDate'"
                 [ngClass]="(col.dbColumnname == 'InvoiceNumber' && invoiceData['InvoiceNumber'] == 'Invoice Pending')?'highlt':''"
                 >{{
                  invoiceData[col.dbColumnname]
                }}</span>
              </div>
            </div>
          </td>
          <td>
            <i
              class="pi pi-eye f-13 showEye mr-2 font_weight_500"
              (click)="viewInvoiceDetails(invoiceData)"
            ></i>
            <button
              *ngIf="router.url.includes('PO')"
              pTooltip="Click here to update PO"
              (click)="updatePO(invoiceData)"
              class="f-13 b-0 pos-rel"
            >
              <i class="fa fa-refresh" aria-hidden="true"></i>
            </button>
            <span *ngIf="router.url.includes('GRNExceptions')">
              <button
                class="actionsBtn text-success b-0 font_weight"
                (click)="reUpload(invoiceData)"
              >
                Re-Upload
              </button>
            </span>
            <i
              *ngIf="
                router.url.includes('allInvoices') ||
                router.url.includes('archived') ||
                router.url.includes('rejected')
              "
              pTooltip="Click here to view Full Status details"
              tooltipPosition="left"
              class="fa fa-info-circle actionsBtn f-13 icon_style i_padding font_weight_500"
              (click)="viewStatusPage(invoiceData)"
            ></i>

            <span
              *ngIf="
                router.url.includes('allInvoices') ||
                router.url.includes('archived') ||
                router.url.includes('ServiceInvoices')
              "
            >
              <span
                class="ml-2 icon_style"
                *ngIf="invoiceData.sourcetype == 'Web'"
                title="Uploaded through Portal"
              >
                <i
                  class="fa fa-cloud-upload f-13 font_weight_500"
                  aria-hidden="true"
                ></i>
              </span>

              <span
                class="ml-2 icon_style"
                *ngIf="invoiceData.sourcetype == 'Mail'"
                title="Uploaded through Mail"
              >
                <i
                  class="fa fa-envelope f-13 font_weight_500"
                  aria-hidden="true"
                ></i>
              </span>

              <span
                style="visibility: hidden"
                class="ml-2 icon_style"
                *ngIf="invoiceData.sourcetype == 'RPA'"
                title="Uploaded through Mail"
              >
                <i
                  class="fa fa-envelope f-13 font_weight_500"
                  aria-hidden="true"
                ></i>
              </span>

              <span
                class="ml-2 icon_style"
                *ngIf="invoiceData.sourcetype == 'API'"
                title="Uploaded through API"
              >
                <i
                  class="fa fa-code f-13 font_weight_500"
                  aria-hidden="true"
                ></i>
              </span>

              <span
                class="ml-2 icon_style"
                *ngIf="invoiceData.sourcetype == 'SharePoint'"
                title="Uploaded through SharePoint"
              >
                <i
                  class="fa fa-share f-13 font_weight_500"
                  aria-hidden="true"
                ></i>
              </span>

              <span
                class="ml-2 icon_style"
                *ngIf="invoiceData.sourcetype == 'WhatsApp'"
                title="Uploaded through WhatsApp"
              >
                <i
                  class="fa fa-whatsapp f-13 font_weight_500"
                  aria-hidden="true"
                ></i>
              </span>
            </span>
            <span
              *ngIf="
                router.url.includes('allInvoices') ||
                router.url.includes('ServiceInvoices') ||
                router.url.includes('payment-details-vendor')
              "
              pTooltip="Click here to view ERP Status details"
              tooltipPosition="left"
              class="fa fa-link actionsBtn f-13 font_weight_500 icon_style link_padding"
              (click)="checkStatus(invoiceData)"
            >
            </span>
          </td>
        </tr>
      </ng-template>

      <ng-template *ngIf="!isDesktop" pTemplate="body" let-invoiceData let-columns="columns"     
      >
        <tr class="mob_tr"
          [ngStyle]="{
            'background-color':
              invoiceData.documentStatusID == 4
                ? '#FEFFD6'
                : invoiceData.documentStatusID == 1
                ? '#FEF9EC'
                : invoiceData.documentStatusID == 2
                ? '#F3F4FF'
                : invoiceData.documentStatusID == 7
                ? '#d0fbdd'
                : invoiceData.documentStatusID == 12
                ? '#ECF9ED'
                : invoiceData.documentStatusID == 13
                ? '#E9E9E9'
                :'',
            'border-color':
              invoiceData.documentStatusID == 4
                ? '#DBD51C'
                : invoiceData.documentStatusID == 1
                ? '#F3BC45'
                : invoiceData.documentStatusID == 2
                ? '#7C83CF'
                : invoiceData.documentStatusID == 7
                ? '#14bb12'
                : invoiceData.documentStatusID == 12
                ? '#3EB948'
                : invoiceData.documentStatusID == 13
                ? '#4D4A4A'
                : ''
          }"
        >
          <td
            *ngFor="let col of columns"
            [title]="invoiceData[col.dbColumnname]"
          >
            <div [ngSwitch]="col.dbColumnname">
              <!-- <div *ngSwitchCase="'docstatus'" style="z-index: 0;">
                <div *ngFor="let color of bgColorCode">
                  <td
                    class="statusType"
                    *ngIf="invoiceData.documentStatusID == color.id"
                    [ngStyle]="{
                      'background-color':
                        invoiceData.documentStatusID == color.id
                          ? color.bgcolor
                          : '',
                      color:
                        invoiceData.documentStatusID == color.id
                          ? color.textColor
                          : ''
                    }"
                  >
                    {{ invoiceData[col.dbColumnname] }}
                    <button
                      *ngIf="invoiceData.documentStatusID == 1"
                      pTooltip="Trigger Batch"
                      tooltipPosition="top"
                      class="f-13 b-0"
                      style="color: red; font-weight: 800"
                      (click)="triggerBatch(invoiceData.idDocument)"
                      [disabled]="
                        triggerBoolean && invoiceID == invoiceData.idDocument
                      "
                    >
                      <i class="fa fa-refresh" aria-hidden="true"></i>
                    </button>
                    <button
                      *ngIf="invoiceData.documentStatusID == 1 && isAdmin"
                      pTooltip="Status roll back"
                      tooltipPosition="top"
                      class="f-13 b-0"
                      style="color: red; font-weight: 800"
                      (click)="changeStatus(invoiceData.idDocument)"
                    >
                      <i class="fa fa-undo" aria-hidden="true"></i>
                    </button>
                    <i
                      *ngIf="invoiceData.docstatus == 'Need To Review'"
                      [pTooltip]="invoiceData?.status"
                      tooltipPosition="top"
                      class="f-13 ml-3"
                      style="color: red; font-weight: 800"
                    >
                      !</i
                    >
                  </td>
                </div>
              </div> -->
              <div class="elipse" *ngSwitchDefault>
                <span *ngIf=" col.dbColumnname == 'CreatedOn' || col.dbColumnname == 'documentDate'">
                  <span *ngIf="invoiceData[col?.dbColumnname] && col.dbColumnname == 'CreatedOn'">{{ invoiceData[col?.dbColumnname] + "Z" | date:'dd-MM-yy, h:mm a' }}</span>  
                  <span *ngIf="invoiceData[col?.dbColumnname] && col.dbColumnname == 'documentDate'">{{ invoiceData[col?.dbColumnname] + "Z" | date:'dd-MM-yyyy' }}</span>  
                </span>
                <span *ngIf=" col.dbColumnname != 'CreatedOn' && col.dbColumnname != 'documentDate'">{{
                  invoiceData[col.dbColumnname]
                }}</span>
              </div>
            </div>
          </td>
          <td *ngIf="!isDesktop">
            <i
              class="pi pi-eye f-13 showEye mr-2 font_weight_500"
              (click)="viewInvoiceDetails(invoiceData)"
            ></i>
            <i *ngIf="!router.url.includes('PO')" class="fa fa-chevron-down pointer" aria-hidden="true" (click)="clickDrildown(invoiceData)"></i>
          </td>
        </tr>
        <tr *ngIf="drillBool && invoiceData.idDocument == docId" class="drill_tr mob_tr"
        [ngStyle]="{
          'background-color':
            invoiceData.documentStatusID == 4
              ? '#FEFFD6'
              : invoiceData.documentStatusID == 1
              ? '#FEF9EC'
              : invoiceData.documentStatusID == 2
              ? '#F3F4FF'
              : invoiceData.documentStatusID == 7
              ? '#d0fbdd'
              : invoiceData.documentStatusID == 12
              ? '#ECF9ED'
              : invoiceData.documentStatusID == 13
              ? '#E9E9E9'
              :'',
          'border-color':
            invoiceData.documentStatusID == 4
              ? '#DBD51C'
              : invoiceData.documentStatusID == 1
              ? '#F3BC45'
              : invoiceData.documentStatusID == 2
              ? '#7C83CF'
              : invoiceData.documentStatusID == 7
              ? '#14bb12'
              : invoiceData.documentStatusID == 12
              ? '#3EB948'
              : invoiceData.documentStatusID == 13
              ? '#4D4A4A'
              : ''
        }"
        >
          <div *ngFor="let data of drilldownarray" class="drill_div">
            <div class="f-12">{{data.header}}</div>
            <div class="f-11">{{data.field}}</div>
          </div>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="columnLength" class="p-t-30">
            <img
              src="assets/Group 2691.png"
              width="260"
              width="260"
              alt="imageUrl"
            />
            <br />
            No invoices found.
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorleft"> </ng-template>
      <ng-template pTemplate="paginatorright"> </ng-template>
    </p-table>
    <!-- <ngx-spinner
      bdColor="rgba(251, 251, 251, 0)"
      size="medium"
      color="#070900"
      [fullScreen]="false"
      type="ball-spin-clockwise"
    >
      <p style="color: rgb(0, 0, 0)"></p>
    </ngx-spinner> -->
  </div>
</div>
<!-- <p-sidebar [(visible)]="visibleSidebar2" position="right" [baseZIndex]="10000">
  <h6 style="font-weight: 600;">Select Columns</h6>

  <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
    selectedItemsLabel="{0} columns selected" [style]="{minWidth: '300px'}" placeholder="Choose Columns">
  </p-multiSelect>

  <div class="btnFooterSave" mat-dialog-actions>
    <button class="btn btnVender mr-4 clor" type="button" (click)="visibleSidebar2 = false">Cancel</button>
    <button class="btn btnVender clor" (click)="onSave()" cdkFocusInitial>Save</button>
  </div>

</p-sidebar> -->

<!-- Cnfirmation Dialog -->
<p-dialog
  header=" "
  [(visible)]="checkstatusPopupBoolean"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '30vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div class="text-center">
    <div>
      <!-- <i
        class="pi pi-exclamation-triangle"
        style="font-size: 4rem; color: red"
      ></i> -->
    </div>
    <div class="deleteDivText">
      {{ statusText }} <br />
      <div class="f-12">{{ statusText1 }}</div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <!-- <div class="d-flex justify-content-center mb-3">
      <button class="btn btnVender bg-btn-success mr-3" (click)="logout()">
        Yes
      </button>
      <button
        class="btn btnVender bg-btn-cancel"
        (click)="displayResponsivepopup = false"
      >
        Cancel
      </button>
    </div> -->
  </ng-template>
</p-dialog>
<p-toast></p-toast>
