<!-- upload Invoice -->
<!-- <h6 class="headh6">Upload Section</h6> -->
<div class="row pt-3 m-0">
  <div class="col-md-4 pl-0 pr-0">
    <div class="imgPlaceholder">
      <!-- <img src="../../../assets/Serina Assets/new_theme/Group 933.svg" alt=""> -->
    </div>
    <div class="welcomtext">
      <h6 class="f-16 mt-4 text-center">Welcome. Let's get you settled in.</h6>
    </div>
    <div class="f-14 shade_color wlcom_bdy">
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
       when an unknown printer took a galley of type and scrambled it to make a type specimen book.
    </div>
  </div>
  <div class="col-md-8 p-0" style="padding-left: 30px !important;">
    <div class="f-18 d-flex">
      Hey User,<img src="../../../assets/Serina Assets/new_theme/wave.png" alt=""> Please select the below options to upload the invoice
    </div>
    <div class="tabs_div mr_54 mt-3">
      <ul class="nav nav-tabs nav_sub" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="ideal-tab"
            data-toggle="tab"
            href="#ideal"
            role="tab"
            aria-controls="ideal"
            aria-selected="true"
            style="height: 90px;"
            >
            <div class="d-flex align-items-center ideal_tab_msg">
              <div class="d-flex flex-column align-items-center icon_tag">
                  <span class="material-icons icon_span">
                    upload_file
                    </span>
                    Ideal Upload
              </div>
              <div class="shade_color primary_clr f-12">
                We usually recommend uploading one invoice at a time for better accuracy
              </div>
            </div>
            </a>
        </li>
        <li class="nav-item" style="display: none;">
          <a
            class="nav-link"
            id="quick-tab"
            data-toggle="tab"
            href="#quick"
            role="tab"
            aria-controls="quick"
            aria-selected="true"
            >
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column align-items-center">
                  <span class="material-icons">
                    upload_file
                    </span>
                    Quick Upload
              </div>
              <div class="shade_color f-12">
                We also support multiple uploads at one go
              </div>
            </div>
            </a>
        </li>
      </ul>
    </div>
    <div class="tab-content mt-2" id="myTabContent">
      <div class="tab-pane fade show active pt-2" id="ideal">
        <!--Vendor Portal Upload-->
        <form
          #uploadForm="ngForm"
          *ngIf="!isCustomerPortal"
          class="d-flex flex-wrap gap"
        >
          <div class="selectFilter">
            <label class="f-12 mb-0 " >Select Entity</label>
            <p-autoComplete
            
              placeholder="Search or select from dropdown"
              id="entity_v"
              (onSelect)="selectEntity($event)"
              [group]="false"
              [suggestions]="filteredEnt"
              (completeMethod)="filterEntity($event)"
              field="EntityName"
              [dropdown]="true"
              [completeOnFocus]="true"
              [autofocus]="true"
              name="EntityName"
              [(ngModel)] = "EntityName"
              required
            >
              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <span class="f-12">{{ group.EntityName }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
          <div class="selectFilter">
            <label class="f-12 mb-0 z_index" >Select Vendor Account</label>
            <select
             placeholder="Search or select from dropdown"
              class="form-control f-14 accountSelect"
              [(ngModel)]="vendorAccountName"
              (change)="selectVendorAccount_vdr(vendorAccountName)"
              name="vendor"
              required
            >
              <option
                *ngFor="let vendorA of vendorAccount"
                [value]="vendorA.idVendorAccount"
              >
                {{ vendorA.Account }}
              </option>
            </select>
          </div>
          <div class="selectFilter">
            <label class="f-12 mb-0 " >Select PO Number</label>
            <p-autoComplete
            placeholder="Search or select from dropdown"
              id="po_v"
              (onSelect)="selectedPO($event)"
              [group]="false"
              [suggestions]="filteredPO"
              (completeMethod)="filterPOnumber($event)"
              field="PODocumentID"
              [dropdown]="true"
              name="PONumber"
              [(ngModel)]="PONumber"
              required
            >
              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <span class="f-12">{{ group.PODocumentID }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
        </form>

        <!--Customer Portal Upload-->
        <form
          #uploadForm="ngForm"
          *ngIf="isCustomerPortal"
          class="d-flex flex-wrap gap"
        >
          <!-- <div class="selectFilter mr-2">
              <label class="f-12 mb-0">Select Invoice Type</label><br />
              <select class="form-control accountSelect f-12"(change)="selectType($event.target.value)">
                <option value="" style="font-weight: 500;">Select type</option>
                <option>Vendor invoice</option>
                <option>Service invoice</option>
              </select>
            </div> -->
          <div class="selectFilter">
            <label class="f-12 mb-0 " >Select Entity</label>
            <p-autoComplete
            placeholder="Search or select from dropdown"
              id="entity_c"
              (onSelect)="selectEntity($event)"
              [group]="false"
              [suggestions]="filteredEnt"
              (completeMethod)="filterEntity($event)"
              field="EntityName"
              [dropdown]="true"
              [autofocus]="true"
              name="EntityName"
              [(ngModel)]="EntityName"
              required
            >
              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <span class="f-12">{{ group.EntityName }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>

          <!--Vendor Selection-->
          <div class="mr-2 selectFilter">
            <!-- <label class="f-12 mb-0">Select Vendor</label><br /> -->
            <label class="f-12 mb-0 " >{{
              selectedOption === "Service" ? "Select Service" : "Select Vendor"
            }}</label
            >
            <!-- select vendor -->
            <p-autoComplete
            placeholder="Search or select from dropdown"
              *ngIf="selectedOption === 'Vendor'"
              id="vendr_c"
              (onSelect)="selectVendorAccount($event)"
              [group]="false"
              [suggestions]="filteredVendors"
              (completeMethod)="filterVendor($event)"
              field="VendorName"
              [dropdown]="true"
              name="vendor"
              ngModel
              required
            >
              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <span class="f-12">{{ group.VendorName }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
            <!-- select service -->
            <p-autoComplete
            placeholder="Search or select from dropdown"
              *ngIf="selectedOption === 'Service'"
              id="service_c"
              (onSelect)="selectService($event.idServiceProvider)"
              [group]="false"
              [suggestions]="filteredService"
              (completeMethod)="filterServices($event)"
              field="ServiceProviderName"
              [dropdown]="true"
              name="service"
            >
              <ng-template let-service pTemplate="item">
                <div class="flex align-items-center">
                  <span class="f-12">{{ service.ServiceProviderName }}</span>
                </div>
              </ng-template>

              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <span class="f-12">{{ group.ServiceProviderName }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
          <div class="mr-2 selectFilter">
            <label class="f-12 mb-0 " >Select PO Number</label>
            <p-autoComplete
            placeholder="Search or select from dropdown"
              id="po_c"
              (onSelect)="selectedPO($event)"
              [group]="false"
              [suggestions]="filteredPO"
              (completeMethod)="filterPOnumber($event)"
              field="PODocumentID"
              [dropdown]="true"
              name="PONumber"
              [(ngModel)]="PONumber"
              required
            >
              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <span class="f-12">{{ group.PODocumentID }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
          <!-- select service account -->
          <div class="mr-2 selectFilter" *ngIf="selectedOption === 'Service'">
            <label class="f-12 mb-0 " >Select Account </label><br />
            <p-autoComplete
            placeholder="Search or select from dropdown"
              id="serAc_c"
              (onSelect)="selectServiceAccount($event)"
              [group]="false"
              [suggestions]="filteredServiceAccount"
              (completeMethod)="filterServicesAccount($event)"
              field="Account"
              [dropdown]="true"
            >
              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <span class="f-12">{{ group.Account }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>

          <!--Vendor Account Selection based by entitys-->
          <div style="display: none">
            <label class="f-12 mb-0">Select Account</label><br />
            <select
              name="accounts"
              id="accounts"
              class="form-control accountSelect f-12"
              [(ngModel)]="vendorAccountName"
              (change)="onSelectAccountByEntity(vendorAccountName)"
            >
              <option
                class="f-12 p-3"
                style="color: gray"
                [value]="account.idVendorAccount"
                *ngFor="let account of vendorAccountByEntity"
              >
                {{ account.Account }}
              </option>
            </select>
          </div>
        </form>

        <!-- Upload Option-->
        <div class="mt-3" *ngIf="displaySelectPdfBoolean || reuploadBoolean">
          <div
            *ngIf="isuploadable"
            ng2FileDrop
            [uploader]="uploader"
            [ngClass]="{ 'file-over': hasBaseDropZoneOver }"
            (fileOver)="fileOverBase($event)"
            class="file-upload"
            (onFileDrop)="fileDrop($event)"
            class="dashed-border"
          >
            <div style="margin: 8px" *ngIf="isuploadable">
              <!-- <span class="material-icons f-80"> filter_none </span> -->
              <div class="f-13">
                Drag and Drop an Document to fetch the details
              </div>
              <small class="f-12">File type must be in .jpeg .png .pdf</small>
              <div class="fileText">Or</div>
              <label for="img" class="img-browse"> Upload </label>
              <input
                ng2FileSelect
                accept=".png, .jpg, .pdf"
                (change)="onSelectFile($event)"
                [uploader]="uploader"
                #fileInput
                type="file"
                id="img"
                name="img"
                hidden
              />
            </div>
          </div>

          <!--Filename dispaly with size-->
          <!-- <div> -->
            <div class="d-flex justify-content-between mr-2 flex-wrap"  *ngIf="progress || OcrProgress">
              
              <div class="img-name">{{ name }}</div>
              <!-- <div class="img-type">
                <strong>FileSize : &nbsp;</strong>{{ size }} &nbsp;MB
              </div> -->
              <div class="f-12">
                <div style="color: #14BB12;" *ngIf="OcrProgress && !isError"> <i class="fa fa-check-o mr-1"></i> Invoice Upload Completed</div>
                <div *ngIf="progressText == 'Duplicate Invoice Uploaded!'" style="color: #ED4040;"> <i class="fa fa-exclamation-triangle mr-1"></i>Upload failed- {{errorMsg}}</div>
              </div>
              <div class="d-flex f-12">
                  {{ processStage }} 
                  <span class="cls" aria-hidden="true" (click)="cancelQueue()">&times;</span>
              </div>
            </div>

            <!-- <div class="f-13 mt-2 mb-2">
              {{ processStage }} Step - 1/2 Completed.
            </div> -->
          <!-- </div> -->

          <!--Progress bar-->
          <div class="f-12 mr-2 d-flex justify-content-between" *ngIf="progress">
            <div>Uploading <span class="f-14">{{ progress }}{{ progressWidth }}%</span></div>
            <div>{{ minutes }}:{{ seconds }}</div>
          </div>
          <div class="progress mr-2" *ngIf="progress">
            <div
              class="progress-bar bg-success"
              [style.width]="progress + '%'"
            >
            </div>
          </div>
          <div
            class="f-12 percText d-flex justify-content-between mr-2"
            *ngIf="OcrProgress"
            id="percText"
            [ngClass]="isError ? 'duplicate' : 'normal'"
          >
            
            <div *ngIf="isError">
              Upload Failed
            </div>
          </div>
          <div class="progress mb-2 mr-2" *ngIf="OcrProgress">
            <div
              class="progress-bar"
              id="precWidth"
              [ngClass]="isError ? 'bg-red' : 'bg-success'"
              [style.width]="progressWidth + '%'"
            >
              
            </div>
            
          </div>
              
              <div class="f-12 d-flex justify-content-between mr-2" *ngIf="OcrProgress">
                {{progressText}} 
                <a class="f-12 pointer" *ngIf="isError" (click)="cancelQueue()"> Retry Upload </a>
              </div>
          <!-- <div
            class="f-12 percText"
            
            style="float: right;"
          >
            <p style="color: #ed4040">
              Upload failed <br />
              <span
                class="f-11"
                *ngIf="progressText == 'Duplicate Invoice Uploaded!'"
                >The invoice is already present in the system</span
              >
            </p>
          </div> -->

          <div class="f-12" *ngIf="returnmessage">Upload progressbar</div>
          <div class="f-12" *ngIf="returnmessage" id="percText">
            {{ progressText }}
          </div>
          <div class="progress mb-2" *ngIf="returnmessage">
            <div
              class="progress-bar bg-success progress-bar-striped progress-bar-animated"
              id="precWidth"
              [style.width]="progressbar + '%'"
            >
              {{ progressbar }}%
            </div>
          </div>

          <!--buttons-->
          <!-- <div class="text-center" *ngIf="!isuploadable && !isError">
            <button
              type="button"
              class="btn btnVender bg-btn-cancel mr-2"
              (click)="cancelQueue()"
            >
              Cancel
            </button>
            <button
              class="btn btnVender bg-btn-success"
              [disabled]="progress || OcrProgress"
              (click)="
                selectedOption === 'Vendor' ? uploadInvoice() : uploadService()
              "
            >
              Upload
            </button>
          </div> -->
        </div>

        <div *ngIf="showFunFactsComponent" class="facts">
          <fun-facts [factsList]="factsList"></fun-facts>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
bdColor="rgba(251, 251, 251, 0.8)"
size="medium"
color="#070900"
[fullScreen]="false"
type="ball-spin-clockwise"
>
<div class="loader_text">
  Hey, please wait we are moving into invoice details page...
</div>
</ngx-spinner>
<p-toast></p-toast>
