<h6 *ngIf="normalRole" class="headh6">User Management</h6>

<div class="rolespage">
  <div *ngIf="normalRole">
    <div class="d-flex justify-content-between">
      <ul class="nav nav-tabs tabBox pl-2">
        <li [routerLink]="['/customer/roles', 'createdUsers']">
          <a
            class="nav-link"
            [class.active]="viewType == 'user'"
            (click)="viewType = 'user'"
            >Users</a
          >
        </li>
        <li [routerLink]="['/customer/roles', 'definedRoles']">
          <a
            class="nav-link"
            [class.active]="viewType == 'roles'"
            (click)="viewType = 'roles'"
            >Roles</a
          >
        </li>
        <li [routerLink]="['/customer/roles', 'vendorAdmin']">
          <a
            class="nav-link"
            [class.active]="viewType == 'vendorAdminUser'"
            (click)="viewType = 'vendorAdminUser'"
            >Vendor Admin</a
          >
        </li>

        <li [routerLink]="['/customer/roles', 'vendorAdminApproval']">
          <a
            class="nav-link"
            [class.active]="viewType == 'vendorAdminUserApprve'"
            (click)="viewType = 'vendorAdminUserApprve'"
            >Admin Approval</a
          >
        </li>
      </ul>
      <div class="tabBox pl_20">
        <label class="f-12 z_index">Search</label><br />
        <input
          type="text"
          class="form-control f-14"
          placeholder="Ex: John"
          [(ngModel)]="searchText"
        />
      </div>
    </div>

    <div [ngSwitch]="viewType" class="tableDataDiv pt-2 pb-2">
      <!-- All users -->
      <div *ngSwitchCase="'user'">
        <div class="positionCreateUpload">
          <!-- <button class=" btnUpload mr-2">Export</button> -->
          <button class="btnUpload mr-53" (click)="createCustomerUserPage()">
            <span class="f-16">+</span> Add New User
          </button>
        </div>
        <div class="card_container d-flex justify-content-between">
          <!-- <div>
            <div class="searchBar">
              <input
                class="searchInput"
                pInputText
                type="text"
                placeholder="Search..."
                (input)="userRole.filterGlobal($event.target.value, 'contains')"
              />
              <span class="material-icons searchIcon"> search </span>
            </div>
            <p-table
              #userRole
              [value]="CustomerUserReadData"
              [paginator]="showPaginator"
              [rows]="row_customer"
              [first]="first_cust"
              [showCurrentPageReport]="true"
              styleClass="p-datatable-striped"
              [globalFilterFields]="[
                'idUser',
                'LogName',
                'NameOfRole',
                'entity_site_count',
                'isActive',
                'firstName',
                'lastName'
              ]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [rowsPerPageOptions]="[10, 25, 50]"
              (onPage)="paginate($event, 'cust')"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="LogName">User Name</th>
                  <th pSortableColumn="firstName">Name</th>
                  <th pSortableColumn="NameOfRole">Role</th>

                  <th *ngIf="isDesktop" [ngClass]="ap_boolean ? 'ap_bgClor' : 'ar_bgClr'" pSortableColumn="entity_site_count">
                    No of Entity sites
                  </th>
                  <th *ngIf="isDesktop"  pSortableColumn="isActive">Status</th>
                  <th class="w-30" style="text-align: center">Actions</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-users>
                <tr class="mb_tr" [ngStyle]="{
                  'border-color':
                      users.isActive == 1 && !isDesktop
                      ? '#89d390'
                      : users.isActive == 0 && !isDesktop
                      ? '#F3BC45'
                      : ''
                }">
                  <td>{{ users.LogName }}</td>
                  <td>{{ users.firstName }} {{ users.lastName }}</td>
                  <td>{{ users.NameOfRole }}</td>

                  <td *ngIf="isDesktop">
                    <span style="margin-left: 16px">{{
                      users.entity_site_count
                    }}</span>
                  </td>
                  <td *ngIf="users.isActive == 1 && isDesktop">
                    <span style="color: #000000">Activated</span>
                  </td>
                  <td *ngIf="users.isActive == 0 && isDesktop">
                    <span style="color: #ff3900">Inactive</span>
                  </td>
                  <td>
                    <i
                      class="fa fa-pencil-square-o actionsBtn"
                      (click)="editUser(users)"
                      aria-hidden="true"
                    ></i>
                    <button
                      *ngIf="users.isActive == 0"
                      class="statusType btnUpload actveBtn"
                      style="background-color: #8eb67f"
                      (click)="confirmationPopUp(users.idUser,  users.isActive,$event)"
                    >
                      Activate
                    </button>
                    <button
                      *ngIf="users.isActive == 1"
                      class="statusType btnUpload actveBtn"
                      style="background-color: #f08a6b"
                      (click)="confirmationPopUp(users.idUser,  user.isActive,$event)"
                    >
                      Deactivate
                    </button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="thCount" class="p-t-30 text-center">
                    <img
                      src="assets/Group 2691.png"
                      width="260"
                      alt="imageUrl"
                    />
                    <br />
                    No customers found.
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div> -->
          <ng-container
            *ngFor="let user of CustomerUserReadData | filter : searchText"
          >
            <div class="card" (click)="editUser(user)">
              <div class="d-flex justify-content-between user_data">
                <div class="d-flex">
                  <div class="user_icon">
                    <i class="fa fa-user"></i>
                  </div>
                  <div class="user_name">
                    <div class="f-16">
                      {{ user.firstName }} {{ user.lastName }}
                    </div>
                    <div class="f-14 shade_color">{{ user.LogName }}</div>
                  </div>
                </div>
                <!-- <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="user.isActive"
                      (click)="
                        confirmationPopUp(user.idUser, user.isActive, $event)
                      "
                    />
                    <span class="slider round"></span>
                  </label>
                </div> -->
              </div>
              <div class="user_down">
                <div class="f-12 d-flex justify-content-between">
                  <div>{{ user.NameOfRole }}</div>
                  <!-- <div>No of Entities</div> -->
                </div>
                <div class="f-12 mt-2 d-flex justify-content-between">
                  <div
                    [ngStyle]="{
                      color:
                        user.isActive == 1
                          ? '#00D42A'
                          : user.isActive == 0
                          ? '#BF2D28'
                          : ''
                    }"
                  >
                    {{ user.isActive ? "Activated" : "Inactive" }}
                  </div>
                  <!-- <div class="e_count">{{ user.entity_site_count }}</div> -->
                  <button *ngIf="!user.isActive" class="primary_btn f-12 actBtn" (click)="
                  confirmationPopUp(user.idUser, user.isActive, $event)
                ">Activate</button>
                  <button *ngIf="user.isActive" class="primary_btn f-12 deactBtn" (click)="
                  confirmationPopUp(user.idUser, user.isActive, $event)
                ">De-activate</button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- roles -->
      <div *ngSwitchCase="'roles'">
        <div class="positionCreateUpload">
          <button class="btnUpload mr-53" (click)="createRoles()">
            <span class="f-16">+</span> Create New Role
          </button>
        </div>
        <p-table
          *ngIf="isTableView"
          [value]="DisplayRoleName"
          [paginator]="false"
          [rows]="10"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[10, 25, 50]"
          styleClass="p-datatable-striped"
        >
          <ng-template pTemplate="header">
            <tr>
              <th style="text-align: left" pSortableColumn="NameOfRole">
                Role Name
              </th>
              <th style="text-align: left" pSortableColumn="Priority">
                Priority
              </th>
              <th style="text-align: center">Actions</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-users>
            <tr>
              <td style="text-align: left; padding: 10px">
                {{ users.NameOfRole }}
              </td>
              <td style="text-align: left; padding-left: 12px">
                {{ users.Priority }}
              </td>
              <td style="text-align: center; padding: 10px">
                <!-- <p-toast></p-toast> -->
                <!-- (click)="confirm($event,users)"  -->
                <p-confirmPopup></p-confirmPopup>
                <i
                  *ngIf="users.isDefault == 0"
                  (click)="showDialog($event, users)"
                  class="fa fa-trash-o actionsBtn mr-2"
                  aria-hidden="true"
                ></i>
                <i
                  *ngIf="users.isDefault == 0"
                  class="fa fa-pencil-square-o actionsBtn"
                  (click)="editRole(users)"
                  aria-hidden="true"
                ></i>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="3" class="p-t-30 text-center">
                <img src="assets/Group 2691.png" width="260" alt="imageUrl" />
                <br />
                No roles found.
              </td>
            </tr>
          </ng-template>
        </p-table>

        <div class="d-flex role card_container" *ngIf="!isTableView">
          <ng-container *ngFor="let role of DisplayRoleName">
            <div class="card" (click)="editRole(role)">
              <div class="d-flex justify-content-between user_data">
                <div class="d-flex">
                  <div class="user_icon">
                    <i class="fa fa-user"></i>
                  </div>
                  <div class="user_name">
                    <div class="f-14">{{ role.NameOfRole }}</div>
                  </div>
                </div>
                <div class="f-12">
                  Priority
                  <div class="e_count f-14">{{ role.Priority }}</div>
                </div>
                <div class="deleteRole">
                  <i
                    *ngIf="role.isDefault == 0"
                    (click)="showDialog($event, role)"
                    class="fa fa-trash-o"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- vendor Admin users -->
      <div *ngSwitchCase="'vendorAdminUser'">
        <div class="positionCreateUpload">
          <button class="btnUpload mr-53" (click)="addVendorUser()">
            <span class="f-16">+</span> Add Vendor
          </button>
        </div>
        <div>
          <div *ngIf="isTableView">
            <div class="searchBar" style="right: 120px">
              <input
                class="searchInput"
                pInputText
                type="text"
                placeholder="Search..."
                (input)="userRole.filterGlobal($event.target.value, 'contains')"
              />
              <span class="material-icons searchIcon"> search </span>
            </div>
            <p-table
              #userRole
              [value]="vendorAdminReadData"
              [paginator]="showPaginatorSp"
              [rows]="row_vendor"
              [first]="first_vendor"
              [showCurrentPageReport]="true"
              styleClass="p-datatable-striped"
              [globalFilterFields]="[
                'LogName',
                'firstName',
                'lastName',
                'NameOfRole',
                'vendor_data.VendorName',
                'email',
                'isActive'
              ]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [rowsPerPageOptions]="[10, 25, 50]"
              (onPage)="paginate($event, 'vendor')"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th
                    *ngIf="isDesktop"
                    pSortableColumn="LogName"
                    style="width: 12%"
                  >
                    User Name
                  </th>
                  <th *ngIf="isDesktop" pSortableColumn="firstName">Name</th>
                  <th pSortableColumn="email">Email</th>

                  <th
                    *ngIf="isDesktop"
                    pSortableColumn="vendor_data.VendorName"
                  >
                    Vendor Name
                  </th>
                  <th pSortableColumn="isActive" style="width: 114px">
                    Status
                  </th>
                  <th class="w-30" style="text-align: center">Actions</th>
                  <!-- <th pSortableColumn="rnk">Financial Approval Level</th> -->
                  <!-- <th>Action</th> -->
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-users>
                <tr
                  class="mb_tr"
                  [ngStyle]="{
                    'border-color':
                      users.isActive == 1 && !isDesktop
                        ? '#89d390'
                        : users.isActive == 0 && !isDesktop
                        ? '#F3BC45'
                        : ''
                  }"
                >
                  <td *ngIf="isDesktop" [title]="users.LogName">
                    {{ users.LogName }}
                  </td>
                  <td *ngIf="isDesktop">
                    {{ users.firstName }} {{ users.lastName }}
                  </td>
                  <td [title]="users.email">{{ users.email }}</td>

                  <td [title]="users.vendor_data.VendorName">
                    {{ users.vendor_data.VendorName }}
                  </td>
                  <td *ngIf="isDesktop">
                    <span *ngIf="users.isActive == 1" style="color: #000000"
                      >Activated</span
                    >
                    <span *ngIf="users.isActive == 0" style="color: #ff3900">
                      <span *ngIf="users.vendor_data?.VendorCode == ''"
                        >Approval Pending</span
                      >
                      <span *ngIf="users.vendor_data?.VendorCode != ''"
                        >Inactive</span
                      >
                    </span>
                  </td>
                  <td>
                    <button
                      class="border_btn"
                      [disabled]="users.vendor_data?.VendorCode == ''"
                      (click)="editvendorUser(users)"
                      [ngStyle]="{
                        cursor:
                          users.vendor_data?.VendorCode == ''
                            ? 'not-allowed'
                            : 'pointer'
                      }"
                    >
                      <i
                        class="fa fa-pencil-square-o actionsBtn"
                        [ngStyle]="{
                          cursor:
                            users.vendor_data?.VendorCode == ''
                              ? 'not-allowed'
                              : 'pointer'
                        }"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <button
                      *ngIf="isDesktop"
                      class="btnUpload ml-2"
                      [disabled]="users.isActive == 0"
                      [ngStyle]="{
                        cursor: users.isActive == 0 ? 'not-allowed' : 'pointer'
                      }"
                      (click)="resetPasswordVendor(users.email)"
                    >
                      Reset
                    </button>
                    <button
                      *ngIf="
                        users.isActive == 0 &&
                        users.vendor_data?.VendorCode != ''
                      "
                      class="statusType btnUpload actveBtn"
                      style="background-color: #8eb67f"
                      (click)="
                        confirmationPopUp(users.idUser, users.isActive, $event)
                      "
                    >
                      Activate
                    </button>
                    <button
                      *ngIf="
                        users.isActive == 0 &&
                        users.vendor_data?.VendorCode == ''
                      "
                      class="statusType btnUpload actveBtn"
                      style="background-color: #8eb67f"
                      (click)="approveVendoraccess(users)"
                    >
                      Activate
                    </button>
                    <button
                      *ngIf="users.isActive == 1"
                      class="statusType btnUpload actveBtn"
                      style="background-color: #f08a6b"
                      (click)="
                        confirmationPopUp(users.idUser, users.isActive, $event)
                      "
                    >
                      Deactivate
                    </button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="5" class="p-t-30 text-center">
                    <img
                      src="assets/Group 2691.png"
                      width="260"
                      alt="imageUrl"
                    />
                    <br />
                    No vendor admins found.
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div
            *ngIf="!isTableView"
            class="card_container d-flex justify-content-between"
          >
            <ng-container
              *ngFor="let user of vendorAdminReadData | filter : searchText"
            >
              <div class="card" (click)="editvendorUser(user)" style="height: 190px;">
                <div class="d-flex justify-content-between user_data">
                  <div class="d-flex">
                    <div class="user_icon">
                      <i class="fa fa-user"></i>
                    </div>
                    <div class="user_name">
                      <div class="f-16">{{ user.vendor_data.VendorName }}</div>
                      <div class="f-14 shade_color">{{ user.email }}</div>
                    </div>
                  </div>
                  <!-- <div>
                    <label class="switch">
                      <input
                        type="checkbox"
                        [checked]="user.isActive"
                        (click)="
                          confirmationPopUp(user.idUser, user.isActive, $event)
                        "
                      />
                      <span class="slider round"></span>
                    </label>
                  </div> -->
                </div>
                <div class="user_down p-0">
                  <div class="f-12 d-flex justify-content-between">
                    <div>
                      <div class="f-12 shade_color">Username</div>
                      <div>{{ user.LogName }}</div>
                    </div>
                    <div>
                      <div class="f-12 shade_color">Name</div>
                      <div>{{ user.firstName }} {{ user.lastName }}</div>
                    </div>
                  </div>
                  <div class="f-12 d-flex justify-content-between">
                    <div
                      [ngStyle]="{
                        color:
                          user.isActive == 1
                            ? '#00D42A'
                            : user.isActive == 0
                            ? '#BF2D28'
                            : ''
                      }"
                    >
                      {{ user.isActive ? "Activated" : "Inactive" }}
                    </div>
                    <!-- <div class="e_count">{{ user.entity_site_count }}</div> -->
                    <button *ngIf="!user.isActive" class="primary_btn f-12 actBtn mt-1" (click)="
                      confirmationPopUp(user.idUser, user.isActive, $event)
                    ">Activate</button>
                      <button *ngIf="user.isActive" class="primary_btn f-12 deactBtn mt-1" (click)="
                      confirmationPopUp(user.idUser, user.isActive, $event)
                    ">De-activate</button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- vendor Admin users approval -->
      <div *ngSwitchCase="'vendorAdminUserApprve'">
        <div class="positionCreateUpload">
          <button class="btnUpload mr-53" (click)="addVendorUser()">
            <span class="f-16">+</span> Add Vendor
          </button>
        </div>
        <div *ngIf="isTableView">
          <div>
            <div class="searchBar" style="right: 120px">
              <input
                class="searchInput"
                pInputText
                type="text"
                placeholder="Search..."
                (input)="userRole.filterGlobal($event.target.value, 'contains')"
              />
              <span class="material-icons searchIcon"> search </span>
            </div>
            <p-table
              #userRole
              [value]="vendorAdminReadDataAP"
              [paginator]="showPaginatorAp"
              [rows]="row_vendor"
              [first]="first_vendor"
              [showCurrentPageReport]="true"
              styleClass="p-datatable-striped"
              [globalFilterFields]="[
                'LogName',
                'firstName',
                'lastName',
                'NameOfRole',
                'vendor_data.VendorName',
                'email',
                'isActive'
              ]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [rowsPerPageOptions]="[10, 25, 50]"
              (onPage)="paginate($event, 'vendor')"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th
                    *ngIf="isDesktop"
                    pSortableColumn="LogName"
                    style="width: 12%"
                  >
                    User Name
                  </th>
                  <th *ngIf="isDesktop" pSortableColumn="firstName">Name</th>
                  <th pSortableColumn="email">Email</th>

                  <th pSortableColumn="vendor_data.VendorName">Vendor Name</th>
                  <th
                    *ngIf="isDesktop"
                    pSortableColumn="isActive"
                    style="width: 114px"
                  >
                    Status
                  </th>
                  <th class="w-30" style="text-align: center">Actions</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-users>
                <tr>
                  <td *ngIf="isDesktop" [title]="users.LogName">
                    {{ users.LogName }}
                  </td>
                  <td *ngIf="isDesktop">
                    {{ users.firstName }} {{ users.lastName }}
                  </td>
                  <td [title]="users.email">{{ users.email }}</td>

                  <td [title]="users.vendor_data.VendorName">
                    {{ users.vendor_data.VendorName }}
                  </td>
                  <td *ngIf="isDesktop" class="text-center">
                    <span *ngIf="users.isActive == 0" style="color: #ff3900">
                      <span *ngIf="users.vendor_data?.VendorCode == ''"
                        >Approval Pending</span
                      ></span
                    >
                  </td>
                  <td>
                    <button
                      *ngIf="
                        users.isActive == 0 &&
                        users.vendor_data?.VendorCode == ''
                      "
                      class="statusType btnUpload actveBtn"
                      style="background-color: #8eb67f"
                      (click)="approveVendoraccess(users)"
                    >
                      Activate
                    </button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="5" class="p-t-30 text-center">
                    <img
                      src="assets/Group 2691.png"
                      width="260"
                      alt="imageUrl"
                    />
                    <br />
                    No vendor admins found.
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div
          *ngIf="!isTableView"
          class="card_container d-flex justify-content-between"
        >
          <ng-container
            *ngFor="let user of vendorAdminReadDataAP | filter : searchText"
          >
            <div class="card" (click)="approveVendoraccess(user)" style="height: 190px;">
              <div class="d-flex justify-content-between user_data">
                <div class="d-flex">
                  <div class="user_icon">
                    <i class="fa fa-user"></i>
                  </div>
                  <div class="user_name">
                    <div class="f-16">{{ user.vendor_data.VendorName }}</div>
                    <div class="f-14 shade_color">{{ user.email }}</div>
                  </div>
                </div>
                <!-- <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="user.isActive"
                      (click)="
                        confirmationPopUp(user.idUser, user.isActive, $event)
                      "
                    />
                    <span class="slider round"></span>
                  </label>
                </div> -->
              </div>
              <div class="user_down p-0">
                <div class="f-12 d-flex justify-content-between">
                  <div>
                    <div class="f-12 shade_color">Username</div>
                    <div>{{ user.LogName }}</div>
                  </div>
                  <div>
                    <div class="f-12 shade_color">Name</div>
                    <div>{{ user.firstName }} {{ user.lastName }}</div>
                  </div>
                </div>
                <div class="f-12 d-flex justify-content-between">
                  <div
                    [ngStyle]="{
                      color:
                        user.isActive == 1
                          ? '#00D42A'
                          : user.isActive == 0
                          ? '#BF2D28'
                          : ''
                    }"
                  >
                    {{ user.isActive ? "Activated" : "Approval pending" }}
                  </div>
                  <button *ngIf="!user.isActive" class="primary_btn f-12 actBtn mt-2">Approve</button>
                  <!-- <div class="e_count">{{ user.entity_site_count }}</div> -->
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- create new Role -->
  <p-dialog
    [header]="header_role"
    [(visible)]="CreateNewRole"
    position="right"
    [modal]="true"
    [style]="{ width: '55vw', height: '100vh' }"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
  >
  <div>
      <div class="d-flex d_flex_wrap mt-2 mb-3">
        <div class="inputDiv">
          <label class="f-12 shade_color">Role name</label>
          <input
            type="text"
            class="form-control inputForm f-14"
            id="newRole"
            [(ngModel)]="newRoleName"
            name="createNewRole"
            ngModel
            #createRole="ngModel"
            placeholder="Role Name"
            [ngClass]="createRoleRequiredBoolean ? 'formInvalid' : 'inputForm'"
            required
          />

          <div
            style="color: red; margin-top: -15px; font-size: 13px"
            *ngIf="createRole.touched && !createRole.valid"
          >
            <div *ngIf="createRole.errors.required">Role name required</div>
          </div>
          <!-- <div *ngIf="createRoleRequiredBoolean" style="color: red; font-size: 13px;">Role name required</div> -->
        </div>

        <div class="inputDiv">
          <label class="f-12 shade_color">Role Priority</label>
          <input
            type="number"
            [(ngModel)]="role_priority"
            name="rolePriority"
            ngModel
            #createRolePriority="ngModel"
            placeholder="Role Priority"
            class="form-control inputForm f-14"
            required
          />
        </div>
      </div>

      <div class="f-14B roleAccess">Role Access</div>
      <div class="row">
        <div class="col-sm-6">
          <div class="mb-2">
            <div class="permisionDiv">
              <div>
                <span class="textth">Add/Modify users</span>
                <span class="f-right">
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="AddorModifyUserBoolean"
                      (change)="changeUserPermission($event)"
                    />
                    <span class="slider round"></span>
                  </label>
                </span>
              </div>
            </div>
          </div>

          <div class="mb-2">
            <div class="permisionDiv">
              <div>
                <span class="textth">Add/Modify user role</span>
                <span class="f-right">
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="userRoleBoolean"
                      (change)="changeUserRolePermission($event)"
                    />
                    <span class="slider round"></span>
                  </label>
                </span>
              </div>
            </div>
          </div>

          <div class="mb-2">
            <div class="permisionDiv">
              <div>
                <span class="textth">Add/Upload new Invoice</span>
                <span class="f-right">
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="invoiceBoolean"
                      (change)="changeInvoicePermission($event)"
                    />
                    <span class="slider round"></span>
                  </label>
                </span>
              </div>
            </div>
          </div>

          <div class="mb-2">
            <div class="permisionDiv">
              <div>
                <span class="textth"> Service invoices batch trigger</span>
                <span class="f-right">
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="spTriggerBoolean"
                      (change)="changeSpTriggerPermission($event)"
                    />
                    <span class="slider round"></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
          <div class="mb-2">
            <div class="permisionDiv">
              <div>
                <span class="textth">Vendor/ServiceProvider pages access</span>
                <span class="f-right">
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="vendorPageBoolean"
                      [(ngModel)]="vendorPageBoolean"
                    />
                    <span class="slider round"></span>
                  </label>
                </span>
              </div>
            </div>
          </div>

          <div class="mb-2">
            <div class="permisionDiv">
              <div>
                <span class="textth">Exception page access</span>
                <span class="f-right">
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="exceptionPageBoolean"
                      [(ngModel)]="exceptionPageBoolean"
                    />
                    <span class="slider round"></span>
                  </label>
                </span>
              </div>
            </div>
          </div>

          <!-- <div class="mb-2">
              <div class="permisionDiv">
                <div>
                  <span class="textth"> Flip PO approval access</span>
                  <span class="f-right">
                    <label class="switch">
                      <input
                        type="checkbox"
                        [checked]="is_fpa"
                        [(ngModel)]="is_fpa"
                      />
                      <span class="slider round"></span>
                    </label>
                  </span>
                </div>
              </div>
            </div> -->
        </div>

        <div class="col-sm-6">
          <div class="mb-2">
            <div class="permisionDiv">
              <div>
                <span class="textth"> Settings page access</span>
                <span class="f-right">
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="settingsPageBoolean"
                      [(ngModel)]="settingsPageBoolean"
                    />
                    <span class="slider round"></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
          <div class="mb-2">
            <div class="permisionDiv">
              <div>
                <span class="textth"> Create GRN page access</span>
                <span class="f-right">
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="GRNPageBoolean"
                      [(ngModel)]="GRNPageBoolean"
                    />
                    <span class="slider round"></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
          <div class="mb-2">
            <div class="permisionDiv">
              <div>
                <span class="textth"> Configuration portal access</span>
                <span class="f-right">
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="configAccessBoolean"
                      [(ngModel)]="configAccessBoolean"
                    />
                    <span class="slider round"></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
          <div class="mb-2">
            <div class="permisionDiv">
              <div>
                <span class="textth"> GRN approval access</span>
                <span class="f-right">
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="is_grn_approval"
                      [(ngModel)]="is_grn_approval"
                    />
                    <span class="slider round"></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
          <div>
            <div class="permisionDiv">
              <div><span class="textth">Invoices Permission:</span></div>
            </div>
            <div>
              <div class="permisionDiv">
                <div>
                  <span class="f-13">View</span>
                  <span class="f-right">
                    <label class="switch">
                      <input
                        type="checkbox"
                        [checked]="viewInvoiceBoolean"
                        (change)="changeViewInvoice($event)"
                      />
                      <span class="slider round"></span>
                    </label>
                  </span>
                </div>
              </div>
              <div
                class="permisionDiv"
                [ngClass]="financeapproveDisplayBoolean ? '' : 'b-btm'"
              >
                <div>
                  <span class="f-13">Edit</span>
                  <span class="f-right">
                    <label class="switch">
                      <input
                        type="checkbox"
                        [checked]="editInvoiceBoolean"
                        (change)="changeEditInvoice($event)"
                      />
                      <span class="slider round"></span>
                    </label>
                  </span>
                </div>
              </div>
              <!-- <div class="permisionDiv12 b-btm">
                  <div> <span class="f-13">Change approve</span>
                    <span class="f-right">
                      <label class="switch">
                        <input type="checkbox" [checked]="changeApproveBoolean"
                          (change)="changeChangeApproveInvoice($event)">
                        <span class="slider round"></span>
                      </label>
                    </span>
                  </div>
                  <div class="clrDes">Description</div>
                </div> -->
              <div
                *ngIf="financeapproveDisplayBoolean"
                class="permisionDiv12"
                [ngClass]="!financeapproveDisplayBoolean ? '' : 'b-btm'"
              >
                <div>
                  <span class="f-13">Finance approve</span>
                  <span class="f-right">
                    <label class="switch">
                      <input
                        type="checkbox"
                        [checked]="financeApproveBoolean"
                        (change)="changeFinanceApproveInvoice($event)"
                      />
                      <span class="slider round"></span>
                    </label>
                  </span>
                </div>
                <div class="clrDes">Description</div>
              </div>
            </div>
            <div *ngIf="financeApproveBoolean && financeapproveDisplayBoolean">
              <!-- <div class="permisionDiv12">
                  <div> 
                      <label ><span class="f-13">Priority</span>
                        <input type="number" class="inputForm form-control w-100 f-13" >
                      </label>
                  </div>
                </div> -->
              <div class="mt-2">
                <div>
                  <label class="f-13 w-100"
                    >Max Approval Amount
                    <span style="color: #f35353">(*required field)</span>
                    <input
                      type="number"
                      [(ngModel)]="max_role_amount"
                      style="width: 100%"
                      class="inputForm form-control w-100 f-13"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btnAction fixed_pos">
        <button class="f-12 secondary_btn mr-3" (click)="cancelRoles()">
          Cancel
        </button>
        <button
          *ngIf="saveRoleBoolean"
          class="f-12 primary_btn"
          [disabled]="createRole.invalid || createRolePriority.invalid"
          [ngStyle]="{
            cursor: createRole.invalid ? 'not-allowed' : 'pointer'
          }"
          (click)="saveRoles()"
        >
          Create
        </button>
        <button
          *ngIf="!saveRoleBoolean"
          class="primary_btn f-12"
          (click)="updateRoleInfoData()"
        >
          Update
        </button>
      </div>
    </div>
  </p-dialog>

  <!--Edit user / create user  -->
  <p-dialog
    [header]="header_Ac"
    [(visible)]="editUserdata"
    position="right"
    [modal]="true"
    [style]="{ width: '55vw', height: '100vh' }"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
  >
  <div>
      <div *ngIf="!expandFull">
        <div class="f-14 mb-1">User Details</div>
        <div class="d_flex_wrap">
          <div class="inputDiv" *ngIf="header_Ac != 'Edit user'">
            <label class="label-head">User Name</label>
            <input
              type="text"
              class="form-control inputForm mb-1 f-14"
              name="username"
              ngModel
              #UserName="ngModel"
              [(ngModel)]="userName"
              (keyup)="userCheck($event.target.value)"
              [ngStyle]="{
                'border-left': userBoolean
                  ? '5px solid green'
                  : userNotBoolean
                  ? '5px solid red'
                  : ''
              }"
              placeholder="User name"
              minlength="6"
              required
            />
            <!-- <span *ngIf="userBoolean" class="f-13 mt-10" style="color: green;">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                  </span>
                  <span *ngIf="userNotBoolean" class="f-13 mt-10" style="color: #f35353;">
                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                  </span> -->
            <div
              *ngIf="UserName.errors && (UserName.touched || UserName.dirty)"
              class="alertDiv"
            >
              <div [hidden]="!UserName.errors.minlength">
                UserName should contains at least 6 letters.
              </div>
              <div [hidden]="!UserName.errors.required">**Required field</div>
            </div>
          </div>

          <div class="inputDiv">
            <label class="label-head">First Name</label>
            <input
              type="text"
              class="form-control inputForm mb-1 f-14"
              name="FirstName"
              ngModel
              #firstname="ngModel"
              [(ngModel)]="firstName"
              placeholder="First name"
              required
            />
            <div
              *ngIf="
                firstname.invalid && (firstname.touched || firstname.dirty)
              "
              class="alertDiv"
            >
              <div [hidden]="!firstname.errors.required">**Required field</div>
            </div>
          </div>

          <div class="inputDiv">
            <label class="label-head">Last Name</label>
            <input
              type="text"
              class="form-control inputForm mb-1 f-14"
              name="Lastname"
              ngModel
              #LastName="ngModel"
              [(ngModel)]="lastName"
              placeholder="Last name"
            />

            <!-- <div
                  *ngIf="LastName.invalid && (LastName.touched || LastName.dirty)"
                  class="alertDiv"
                >
                  <div [hidden]="!LastName.errors.required">**Required field</div>
                </div> -->
          </div>

          <div class="inputDiv">
            <label class="label-head">User Email</label>
            <input
              type="email"
              name="email"
              ngModel
              #mail="ngModel"
              class="form-control inputForm mb-1 f-14"
              [(ngModel)]="userEmail"
              placeholder="Email"
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
              required
            />
            <div
              *ngIf="mail.invalid && (mail.touched || mail.dirty)"
              class="alertDiv"
            >
              <div [hidden]="!mail.errors?.pattern">Not a valid email</div>
              <div [hidden]="!mail.errors.required">**Required field</div>
            </div>
          </div>

          <div class="inputDiv">
            <label class="label-head">Role Name</label>
            <select
              class="form-control inputForm f-12 mb-1"
              name="editrole"
              ngModel
              #editRole="ngModel"
              [(ngModel)]="editRoleName"
              (change)="selectRole(editRoleName)"
              required
            >
              <option
                *ngFor="let role of DisplayRoleName"
                [value]="role.NameOfRole"
              >
                {{ role.NameOfRole }}
              </option>
            </select>

            <div
              *ngIf="editRole.invalid && (editRole.touched || editRole.dirty)"
              class="alertDiv"
            >
              <div [hidden]="!editRole.errors.required">**Required field</div>
            </div>
          </div>

          <div class="inputDiv">
            <label class="label-head">Select Department</label>
            <select
              class="form-control inputForm f-12 mb-1"
              name="dept"
              ngModel
              #dept="ngModel"
              [(ngModel)]="department"
              required
            >
              <option
                *ngFor="let item of departmentData"
                [value]="item?.iduserdept"
              >
                {{ item?.DepartmentName }}
              </option>
            </select>

            <div
              *ngIf="dept.invalid && (dept.touched || dept.dirty)"
              class="alertDiv"
            >
              <div [hidden]="!dept.errors.required">**Required field</div>
            </div>
          </div>

          <!-- <div *ngIf="editRoleName == 'Finance Controller'">
                  <label class="label-head">Amount</label>
                  <input type="text" class="form-control inputForm mb-2 f-13" [(ngModel)]="Flevel" name="fLevel"
                    placeholder="Financial Level" >
                </div> -->

          <!-- <div>
                <button
                  *ngIf="headerEdituserboolean"
                  class="f-12 bg-btn inputForm"
                  (click)="resetPassword()"
                >
                  {{ resetBtnText }}
                </button>
              </div> -->
        </div>
      </div>
      <div class="mt-1 mb-1">
        <div *ngIf="!expandFull" class="f-14 mb-1 mt-2">
          Entity access related details
        </div>
        <div *ngIf="!expandFull" class="d_flex_wrap">
          <div class="inputDiv">
            <div class="f-12 label-head">Select Entity</div>
            <p-autoComplete
              (onSelect)="onSelectEntity($event)"
              [suggestions]="filteredEntities"
              (completeMethod)="filterEntity($event)"
              field="EntityName"
              [dropdown]="true"
            >
              <ng-template let-country pTemplate="item">
                <div class="country-item">
                  <div>{{ country.EntityName }}</div>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
          <!-- <div class="mr-2 inputDiv">
            <div class="f-13 label-head">Select Entitybody</div>
            <p-autoComplete
              (onSelect)="onSelectEntityBody($event)"
              [suggestions]="filterDentityBody"
              (completeMethod)="filterEntityBody($event)"
              field="EntityBodyName"
              [dropdown]="true"
            >
              <ng-template let-country pTemplate="item">
                <div class="country-item">
                  <div>{{ country.EntityBodyName }}</div>
                </div>
              </ng-template>
            </p-autoComplete>
          </div> -->
          <div class="inputDiv">
            <div class="f-13 label-head">Select Entity Department</div>
            <p-autoComplete
              (onSelect)="onSelectEntityDept($event)"
              [suggestions]="filterDentityDept"
              (completeMethod)="filterEntityDept($event)"
              field="DepartmentName"
              [dropdown]="true"
            >
              <ng-template let-country pTemplate="item">
                <div class="country-item">
                  <div>{{ country.DepartmentName }}</div>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>
        <hr />
        <div class="mt-2 mb-1">
          <div class="d-flex justify-content-between">
            <div class="f-14B">List of Entities that user can access</div>
            <div *ngIf="!expandFull" (click)="expand(true)" class="pointer">
              <i class="fa fa-expand" aria-hidden="true"></i>
            </div>
            <div *ngIf="expandFull" (click)="expand(false)" class="pointer">
              <i class="fa fa-compress" aria-hidden="true"></i>
            </div>
          </div>
          <ul
            class="p-0 ul-list"
            [ngClass]="!expandFull ? 'normal_height' : 'full_height'"
          >
            <li
              *ngFor="let value of selectedEntitys; let i = index"
              class="listEntity"
            >
              <span
                >{{ value.entity }}
                <span *ngIf="value.entityBody">-- {{ value.entityBody }}</span>
                <span *ngIf="value.entityDept"
                  >-- {{ value.entityDept }}</span
                ></span
              >
              <!-- <span><i class="fa fa-times "  aria-hidden="true"></i></span> -->
              <span
                class="material-icons removeList"
                (click)="onRemove(i, value)"
              >
                cancel
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="btnAction fixed_pos">
        <button class="f-12 secondary_btn mr-3" (click)="canceleditUser()">
          Cancel
        </button>
        <button
          *ngIf="header_Ac != 'Edit user'"
          class="primary_btn f-12"
          type="submit"
          (click)="toCreateUser()"
        >
          Create
        </button>
        <button
          *ngIf="header_Ac == 'Edit user'"
          class="f-12 primary_btn"
          [ngStyle]="{
            cursor:
              editRole?.invalid || mail?.invalid ? 'not-allowed' : 'pointer'
          }"
          [disabled]="editRole?.invalid || mail?.invalid"
          (click)="UpdateUser()"
        >
          Update
        </button>

        <!-- [disabled]="editRole.invalid || mail.invalid || UserName.invalid || firstname.invalid || LastName.invalid    "
            
            [ngStyle]="{'cursor':( !editRole.valid || !mail.valid || !UserName.valid || !firstname.valid || !LastName.valid)? 'not-allowed':'pointer'}" -->
      </div>
    </div>
  </p-dialog>
</div>

<!-- Delete Cnfirmation Dialog -->
<!-- <p-dialog
  header=" "
  [(visible)]="displayResponsive"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '30vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div class="deleteDiv">
    <div>
      <i
        class="pi pi-exclamation-triangle"
        style="font-size: 4rem; color: red"
      ></i>
    </div>
    <div class="deleteDivText text-center">
      {{ deleteBtnText }}
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button
      *ngIf="deleteRoleBoolean"
      class="btn btnVender bg-btn-success mr-3"
      (click)="DeleteRole()"
    >
      Yes, Delete this Role
    </button>
    <button
      *ngIf="deactivateBoolean"
      class="btn btnVender bg-btn-success mr-3"
      (click)="activa_deactive()"
    >
      Yes
    </button>
    <button
      *ngIf="vendorResetBtnBoolean"
      class="btn btnVender bg-btn-success mr-3"
      (click)="resetPassVendorAPI()"
    >
      Yes
    </button>
    <button
      *ngIf="userResetBtnBoolean"
      class="btn btnVender bg-btn-success mr-3"
      (click)="resetPasswordUserAPI()"
    >
      Yes
    </button>
    <button
      class="btn btnVender bg-btn-cancel"
      (click)="displayResponsive = false"
    >
      Cancel
    </button>
  </ng-template>
</p-dialog> -->

<!-- add vendor super user Dialog -->
<p-dialog
  [header]="dailogText"
  [(visible)]="displayAddUserDialog"
  position="right"
  [modal]="true"
  [style]="{ width: '55vw', height: '100vh' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div class="p-auto" style="min-height: 50vh">
    <form #vendorPortalUser="ngForm" class="d_flex_wrap mt-2">
      <div
        class="inputDiv"
        *ngIf="!editVndrUserbool"
        style="position: relative"
      >
        <label class="inviteLabel">Select Vendor</label> <br />
        <p-autoComplete
          placeholder="Select Vendor"
          (onSelect)="selectVendor($event, '')"
          [group]="false"
          [(ngModel)]="vendorCreate"
          [suggestions]="filteredVendors"
          (completeMethod)="filterVendor($event, '')"
          field="VendorName"
          inputStyleClass="form-control inputBox selectpicker"
          [dropdown]="true"
          name="vendrCreate"
        >
          <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
              <span class="f-12">{{ group.VendorName }}</span>
            </div>
          </ng-template>
        </p-autoComplete>

        <div
          *ngIf="
            vendrCreate?.errors && (vendrCreate?.touched || vendrCreate.dirty)
          "
          class="alertDiv"
        >
          <div [hidden]="!vendrCreate.errors.required">**Required field</div>
        </div>
      </div>
      <!-- <input list="Vendors" name="Vendor" id="Vendor" class="form-control inputBox selectpicker"  [(ngModel)]="vendorCreate" name="vendrCreate" ngModel #vendrCreate="ngModel"
    (change)="selectVendor(vendorCreate)" required>
    <datalist id="Vendors">
      <option class="f-11"  *ngFor="let vendor of vendorList" [value]="vendor.VendorName">
    </datalist> -->

      <div *ngIf="editVndrUserbool" class="update inputDiv">
        <label class="inviteLabel" for="entity">Select Entity</label> <br />
        <p-multiSelect
          [options]="entityForVendorCreation"
          [(ngModel)]="entitySelection"
          (onChange)="onSelectedEntityCode($event, 'update')"
          placeholder="Select entity"
          inputStyleClass="form-control inputBox selectpicker"
          optionLabel="EntityName"
          [maxSelectedLabels]="3"
          [selectedItemsLabel]="'{0} items selected'"
          name="EntityName"
        >
        </p-multiSelect>
      </div>
      <div *ngIf="!editVndrUserbool" class="inputDiv">
        <label class="inviteLabel" for="entity">Select Entity</label> <br />
        <p-multiSelect
          [options]="entityForVendorCreation"
          [(ngModel)]="entitySelection"
          (onChange)="onSelectedEntityCode($event, 'update')"
          placeholder="Select entity"
          inputStyleClass="form-control inputBox selectpicker"
          optionLabel="EntityName"
          [maxSelectedLabels]="3"
          [selectedItemsLabel]="'{0} items selected'"
          name="EntityName"
        >
        </p-multiSelect>
      </div>

      <div
        *ngIf="!editVndrUserbool"
        class="inputDiv"
        style="position: relative"
      >
        <label class="inviteLabel" for="username">Username</label> <br />
        <input
          type="text"
          id="username"
          class="form-control inputBox"
          name="userCreate"
          ngModel
          #userCreate="ngModel"
          [(ngModel)]="createUserName"
          (keyup)="userCheck($event.target.value)"
          minlength="6"
          [ngStyle]="{
            'border-left': userBoolean
              ? '5px solid green'
              : userNotBoolean
              ? '5px solid red'
              : ''
          }"
          required
        />
        <div
          *ngIf="
            userCreate?.errors && (userCreate?.touched || userCreate?.dirty)
          "
          class="alertDiv"
        >
          <div [hidden]="!userCreate?.errors?.minlength">
            UserName should contains at least 6 letters.
          </div>
          <div [hidden]="!userCreate?.errors?.required">**Required field</div>
        </div>
      </div>

      <!-- <span *ngIf="userBoolean" class="f-13 mt-user" style="color: green;">
      <i class="fa fa-check-circle" aria-hidden="true"></i>
    </span>
    <span *ngIf="userNotBoolean" class="f-13 mt-user" style="color: #f35353;">
      <i class="fa fa-times-circle" aria-hidden="true"></i>
    </span> -->

      <div class="inputDiv" style="position: relative">
        <label class="inviteLabel">First Name</label> <br />
        <input
          type="text"
          class="form-control inputBox"
          name="fnameCreate"
          ngModel
          #fnameCreate="ngModel"
          [(ngModel)]="createVfirstName"
          required
        />
        <div
          *ngIf="
            fnameCreate?.errors && (fnameCreate?.touched || fnameCreate?.dirty)
          "
          class="alertDiv"
        >
          <div [hidden]="!fnameCreate?.errors?.required">**Required field</div>
        </div>
      </div>

      <div class="inputDiv">
        <label class="inviteLabel">Last Name</label><br />
        <input
          type="text"
          class="form-control inputBox"
          name="lnameCreate"
          ngModel
          #lnameCreate="ngModel"
          [(ngModel)]="createVlastName"
        />
      </div>

      <!-- <div *ngIf="lnameCreate.errors &&(lnameCreate.touched || lnameCreate.dirty)" class="alertDiv">
      <div [hidden]="!lnameCreate.errors.required">
        **Required field
      </div>
    </div> -->

      <div
        *ngIf="!editVndrUserbool"
        class="inputDiv"
        style="position: relative"
      >
        <label class="inviteLabel" for="email"> Email ID</label> <br />
        <input
          type="email"
          id="email"
          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
          class="form-control inputBox"
          name="EmailCreate"
          ngModel
          #createMail="ngModel"
          [(ngModel)]="emailIdInvite"
          required
        />
        <div
          *ngIf="
            createMail?.errors && (createMail?.touched || createMail?.dirty)
          "
          class="alertDiv"
        >
          <div [hidden]="!createMail?.errors?.pattern">
            Please enter valid email.
          </div>
          <div [hidden]="!createMail?.errors?.required">**Required field</div>
        </div>
      </div>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="fixed_pos btnAction">
      <button
        type="button"
        class="f-12 secondary_btn mr-3"
        (click)="displayAddUserDialog = false"
      >
        Cancel
      </button>
      <button
        *ngIf="!editVndrUserbool"
        type="button"
        class="primary_btn f-12"
        [ngStyle]="{
          cursor:
            vendorPortalUser.invalid || userNotBoolean
              ? 'not-allowed'
              : 'pointer'
        }"
        [disabled]="vendorPortalUser.invalid || userNotBoolean"
        (click)="createVendorSuprUser()"
      >
        Add Vendor Super User
      </button>

      <button
        *ngIf="editVndrUserbool"
        type="button"
        class="primary_btn f-12"
        [ngStyle]="{
          cursor: vendorPortalUser.invalid ? 'not-allowed' : 'pointer'
        }"
        [disabled]="vendorPortalUser.invalid"
        (click)="updateVendorAccess()"
      >
        Update
      </button>
    </div>
  </ng-template>
</p-dialog>

<!-- add vendor super user from signup Dialog -->
<p-dialog
  header="Vendor User Approval"
  [(visible)]="approveDialog"
  position="right"
  [modal]="true"
  [style]="{ width: '55vw', height: '100vh' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div class="mt-2 d_flex_wrap">
    <div class="inputDiv">
      <label class="inviteLabel">User entered vendor name</label>
      <input
        type="text"
        class="form-control inputBox"
        name="tempName"
        [(ngModel)]="tempDisplayVName"
        #tempName="ngModel"
        required
      />
    </div>
    <div class="inputDiv">
      <label class="inviteLabel">Select Matching Vendor </label>
      <p-autoComplete
        placeholder="Select Vendor"
        (onSelect)="selectVendor($event, 'match')"
        [group]="false"
        [(ngModel)]="vendorMatch"
        [suggestions]="filteredVendors"
        (completeMethod)="filterVendor($event, 'match')"
        field="VendorName"
        inputStyleClass="form-control inputBox selectpicker"
        [dropdown]="true"
        name="vendrCreate"
      >
        <ng-template let-group pTemplate="group">
          <div class="flex align-items-center">
            <span class="f-12">{{ group.VendorName }}</span>
          </div>
        </ng-template>
      </p-autoComplete>
      <!-- <div
        *ngIf="
          vendrCreate?.errors && (vendrCreate?.touched || vendrCreate.dirty)
        "
        class="alertDiv"
      >
        <div [hidden]="!vendrCreate.errors.required">**Required field</div>
      </div> -->
    </div>

    <div class="inputDiv">
      <label class="inviteLabel" for="entity">Select Entity</label>
      <p-multiSelect
        [options]="entityForVendorCreation"
        [(ngModel)]="entitySelection"
        (onChange)="onSelectedEntityCode($event, 'activate')"
        placeholder="Select entity"
        filter="false"
        inputStyleClass="form-control inputBox selectpicker"
        optionLabel="EntityName"
        [maxSelectedLabels]="3"
        [selectedItemsLabel]="'{0} items selected'"
      >
      </p-multiSelect>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="fixed_pos btnAction">
      <button
        type="button"
        class="f-12 secondary_btn mr-3"
        (click)="approveDialog = false"
      >
        Cancel
      </button>

      <button
        type="button"
        class="primary_btn f-12"
        (click)="approveActivateVendor()"
      >
        Activate
      </button>
    </div>
  </ng-template>
</p-dialog>

<p-toast></p-toast>
<ngx-spinner
  bdColor="rgba(251, 251, 251, 0.8)"
  size="medium"
  color="#070900"
  [fullScreen]="false"
  type="ball-spin-clockwise"
>
  <p style="color: rgb(0, 0, 0)"></p>
</ngx-spinner>
