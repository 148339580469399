import { AlertService } from 'src/app/services/alert/alert.service';
import { Subscription } from 'rxjs';
import { DataService } from './../../services/dataStore/data.service';
import { SettingsService } from './../../services/settings/settings.service';
import { Location } from '@angular/common';
import { PermissionService } from './../../services/permission.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationComponent } from '../confirmation/confirmation.component';

export interface updateUserEntityData {
  idUserAccess?: number;
  EntityID?: number;
  EntityBodyID?: number;
  DepartmentID?: number;
}

export interface dropdownData {
  Entity: string;
  value: dropdownDatavalue[];
}
export interface dropdownDatavalue {
  name: string;
}

export interface selectedValue {
  idUserAccess?: number;
  entity?: string;
  entityBody?: string;
  entityDept?: string;
  EntityID?: number;
  EntityBodyID?: number;
  DepartmentID?: number;
}
@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit {
  normalRole: boolean = true;
  checked1: boolean = false;
  initialView: boolean = true;
  usersData;
  roles = [];
  NameOfRole: any;
  Flevel: any = null;

  selectedEntityName;
  selectedEntityBodyName;
  selectedEntityDeptName;

  createUserCode: string;
  createUserName: string;
  emailIdInvite: string;
  InviteRole: string;
  InviteUserPassword: string;
  InviteFlevel: any;
  createUserDesignation;

  createRoleRequiredBoolean: boolean = false;
  newRoleName: string;
  newRoleDescription: string;

  events1: any[];

  loginUserData: any;
  visible = true;
  filteredEntities: any[];
  dEntityBody: dropdownData[] = [];
  dEntityDept: dropdownData[] = [];
  filterDentityBody: any[] = [];
  filterDentityDept: any[] = [];

  selectedEntitys: selectedValue[] = [];
  CreateNewRole: boolean = false;
  editUserdata: boolean = false;
  saveRoleBoolean: boolean;
  header_Ac:string

  roletype: string;
  CustomerUserReadData;
  vendorAdminReadData = [];
  vendorAdminReadDataAP = [];

  userName: string;
  firstName: string;
  lastName: string;
  designation;
  userEmail: string;
  userCode: any;
  custuserid: any;
  editRoleName: string;
  selectedEntityBody: any;
  selectedEntityDept: any;
  entityList: any;
  selectedEntityId: any;
  entityBodyList: any[];
  entityDeptList: any;

  showPaginator: boolean;
  DisplayRoleName: any[];

  roleInfoDetails: any;
  someParameterValue;

  AddorModifyUserBoolean: boolean = false;
  userRoleBoolean: boolean = false;
  invoiceBoolean: boolean = false;
  viewInvoiceBoolean: boolean = false;
  editInvoiceBoolean: boolean = false;
  changeApproveBoolean: boolean = false;
  financeApproveBoolean: boolean = false;
  spTriggerBoolean = false;
  vendorTriggerBoolean = false;
  configAccessBoolean: boolean = false;
  dashboardAccessBoolean: boolean = false;
  vendorPageBoolean = false;
  settingsPageBoolean = false;
  GRNPageBoolean = false;
  exceptionPageBoolean = false;

  userAccess: number = 0;
  userRoleAccess = 0;
  invoiceAccess = 0;

  AccessPermissionTypeId: number;

  deleteBtnText: string;



  viewType = 'user';
  updateroleData;
  appied_permission_def_id: number;
  displayResponsive: boolean;
  displayAddUserDialog: boolean;
  headerEdituserboolean: boolean;
  updateUserEnt_body_id: number;
  updateUserEnt_dept_id: number;
  updateUsersEntityInfo: updateUserEntityData[] = [];
  updateEntityUserDummy: updateUserEntityData[] = [];
  userBoolean: boolean;
  userNotBoolean: boolean;
  resetBtnText: string;
  vendorList: any;
  vendorCreate: string;
  createVAccount: string;
  createVlastName: string;
  createVfirstName: string;
  idVendor: number;
  idVendorAccount: any;
  vendorSuperUsersReadData: any;
  showPaginatorSp: boolean;
  showPaginatorAp:boolean;
  deleteBtnTextBoolean: boolean;
  deleteRoleBoolean: boolean;
  deactivateBoolean: boolean;
  resetVendorMail: any;
  vendorResetBtnBoolean: boolean;
  userResetBtnBoolean: boolean;
  max_role_amount = 0;
  role_priority: number;

  financeapproveDisplayBoolean: boolean;
  addRoleBoolean: boolean;
  vendorsSubscription: Subscription;
  entitySubscription: Subscription;
  filteredVendors = [];
  entitySelection: any[];
  entityForVendorCreation = [];
  vendorEntityCodes: any;
  vendorCode: any;
  vendorMatch: any;

  row_customer: number = 10;
  row_vendor: number = 10;
  first_cust: number = 0;
  first_vendor: number = 0;
  editVndrUserbool: boolean;
  selectedEnt_venor: any[];
  vendorUserId: any;
  dailogText: string;
  updateIdaccessArr = [];
  updateVenrEntityAccess = [];
  entLengthforup_vendr: any;
  approveDialog: boolean;
  tempVendorName: string;
  tempDisplayVName:string;
  vendorMatchList = [];
  vendorOnboarderStatus: boolean;
  is_fp: boolean;
  is_fpa: boolean;
  is_grn_approval:boolean;
  isDesktop: boolean;
  thCount: number;
  searchText:string;
  departmentData = [];
  department:number;
  expandFull: boolean;
  isTableView:boolean;
  header_role: string;

  constructor(
    private dataService: DataService,
    private sharedService: SharedService,
    private router: Router,
    public routeIn: ActivatedRoute,
    private permissionService: PermissionService,
    private SpinnerService: NgxSpinnerService,
    private _location: Location,
    private settingsService: SettingsService,
    private AlertService : AlertService,
    private mat_dlg : MatDialog
  ) {
    routeIn.params.subscribe((params) => {
      this.setupComponent(params['someParam']);
    });
  }

  ngOnInit(): void {
    this.isDesktop = this.dataService.isDesktop;
    if(this.isDesktop){
      this.thCount = 6;
    } else {
      this.thCount = 4;
    }
    this.inIt();
  }

  inIt() {
    if (this.permissionService.addUsersBoolean == true) {
      this.router.navigate(['/customer/roles', 'createdUsers']);
      this.someParameterValue = 'createdUsers';
      this.DisplayCustomerUserDetails();
      // this.toGetEntity();
      this.getDisplayTotalRoles();
      this.getVendorsListTocreateNewVendorLogin();
      this.getVendorSuperUserList();
      this.readDepartmentData();
      this.financeapproveDisplayBoolean =
        this.settingsService.finaceApproveBoolean;
      this.addRoleBoolean = this.permissionService.addUserRoleBoolean;
    } else {
      alert('Sorry!, you do not have access');
      this._location.back();
    }
  }
  setupComponent(someParam) {
    this.someParameterValue = someParam;
  }

  showDialog(event:Event,e) {
    event.stopPropagation();
    this.CreateNewRole = false;
    if (this.addRoleBoolean == true) {
      this.confirmation_pop('Are you sure you want to delete this Role?','role');
      this.sharedService.ap_id = e.idAccessPermissionDef;
    } else {
      alert('Sorry, you do not have access!');
    }
  }

  DeleteRole() {
    this.sharedService.deleteRole().subscribe((data: any) => {
      if (data.result == 'success') {
        this.openCustomSnackbar_success('Deleted Successfully!');
        this.getDisplayTotalRoles();
      } else {
        this.openCustomSnackbar_error("Something went wrong");
      }
    },err=>{
      if(err.status == 400){
        this.openCustomSnackbar_error("This role is assigned for users. So, delete is not possible");
      } else {
        this.openCustomSnackbar_error("Server error");
      }
    });
    this.displayResponsive = false;
  }

  createRoles() {
    console.log("hi");
    this.header_role = "Add new role";
    this.CreateNewRole = true;
    if (this.addRoleBoolean == true) {
      // this.router.navigate(['/customer/roles', 'createNewRole']);
      this.SpinnerService.hide();
      // this.normalRole = false;
      this.newRoleName = '';
      // this.CreateNewRole = true;
      // this.editUserdata = false;
      this.saveRoleBoolean = true;
    } else {
      alert('Sorry!, you do not have access');
    }
  }
  cancelRoles() {
    this.router.navigate(['/customer/roles', 'definedRoles']);
    this.normalRole = true;
    this.CreateNewRole = false;
    this.editUserdata = false;
  }
  saveRoles() {
    if (!this.newRoleName) {
      this.createRoleRequiredBoolean = true;
    } else {
      this.addandUpdaterole();
      this.sharedService
        .createRole(JSON.stringify(this.updateroleData))
        .subscribe(
          (data: any) => {
            if (data.result) {
              this.openCustomSnackbar_success('Role Created Successfully');
              this.getDisplayTotalRoles();
              this.normalRole = true;
              this.CreateNewRole = false;
              this.editUserdata = false;
            } else {
              this.openCustomSnackbar_error("Something went wrong");
              this.getDisplayTotalRoles();
            }
          },
          (error) => {
            if (error.status == 400) {
              this.openCustomSnackbar_error("Please provide other priority, the given priority is already taken.");
            } else {
              this.openCustomSnackbar_error("Server error");
            }

          }
        );
      this.createRoleRequiredBoolean = false;
      this.normalRole = true;
      this.CreateNewRole = false;
      this.editUserdata = false;
    }
  }
  editRole(e) {
    this.header_role = "Edit Role";
    this.CreateNewRole = true;
    if (this.addRoleBoolean) {
      // this.router.navigate(['/customer/roles', `${e.idAccessPermissionDef}editRoleDetails`]);
      this.sharedService.ap_id = e.idAccessPermissionDef;
      this.newRoleName = e.NameOfRole;
      this.saveRoleBoolean = false;

      this.SpinnerService.show();
      this.max_role_amount = 0;
  
      this.sharedService.displayRoleInfo().subscribe((data: any) => {
        this.roleInfoDetails = data.roleinfo.AccessPermissionDef;
        this.role_priority = this.roleInfoDetails.Priority;
  
        this.max_role_amount = data.roleinfo?.AmountApproveLevel?.MaxAmount || 0;
  
        this.AddorModifyUserBoolean = this.roleInfoDetails.User === 1;
        this.userRoleBoolean = this.roleInfoDetails.Permissions === 1;
        this.invoiceBoolean = this.roleInfoDetails.NewInvoice === 1;
  
        this.vendorTriggerBoolean = this.roleInfoDetails.allowBatchTrigger;
        this.spTriggerBoolean = this.roleInfoDetails.allowServiceTrigger;
        this.configAccessBoolean = this.roleInfoDetails.isConfigPortal;
        this.dashboardAccessBoolean = this.roleInfoDetails.isDashboard;
        this.exceptionPageBoolean = this.roleInfoDetails.is_epa;
        this.GRNPageBoolean = this.roleInfoDetails.is_gpa;
        this.vendorPageBoolean = this.roleInfoDetails.is_vspa;
        this.settingsPageBoolean = this.roleInfoDetails.is_spa;
        this.is_fp = this.roleInfoDetails.is_fp;
        this.is_fpa = this.roleInfoDetails.is_fpa;
        this.is_grn_approval = this.roleInfoDetails.is_grn_approval;
  
        const accessPermissionTypeId = this.roleInfoDetails.AccessPermissionTypeId;
  
        this.viewInvoiceBoolean = accessPermissionTypeId >= 1;
        this.editInvoiceBoolean = accessPermissionTypeId >= 2;
        this.changeApproveBoolean = accessPermissionTypeId >= 3;
        this.financeApproveBoolean = accessPermissionTypeId === 4;
  
        this.SpinnerService.hide();
      });
    } else {
      alert('Sorry, you do not have access!');
    }
  }
  
  changeUserPermission(e) {
    if (e.target.checked === true) {
      this.userAccess = 1;
      this.AddorModifyUserBoolean = true;
    } else {
      this.userAccess = 0;
      this.AddorModifyUserBoolean = false;
    }
  }
  changeUserRolePermission(e) {
    if (e.target.checked === true) {
      this.userRoleAccess = 1;
      this.userRoleBoolean = true;
    } else {
      this.userRoleAccess = 0;
      this.userRoleBoolean = false;
    }
  }
  changeInvoicePermission(e) {
    if (e.target.checked === true) {
      this.invoiceAccess = 1;
      this.invoiceBoolean = true;
    } else {
      this.invoiceAccess = 0;
      this.invoiceBoolean = false;
    }
  }
  changeSpTriggerPermission(e) {
    if (e.target.checked === true) {
      this.spTriggerBoolean = true;
    } else {
      this.spTriggerBoolean = false;
    }
  }
  changeVendorTriggerPermission(e) {
    if (e.target.checked === true) {
      this.vendorTriggerBoolean = true;
    } else {
      this.vendorTriggerBoolean = false;
    }
  }
  changeViewInvoice(e) {
    this.handleChangeInvoice(e, true, false, false, false);
  }
  changeEditInvoice(e) {
    this.handleChangeInvoice(e, true, true, false, false);
  }
  changeChangeApproveInvoice(e) {
    this.handleChangeInvoice(e, true, true, true, false);
  }
  changeFinanceApproveInvoice(e) {
    this.handleChangeInvoice(e, true, true, true, true);
  }
  handleChangeInvoice(e, enableView, enableEdit, enableChangeApprove, enableFinanceApprove) {
    this.viewInvoiceBoolean = enableView && e.target.checked;
    this.editInvoiceBoolean = enableEdit && this.viewInvoiceBoolean;
    this.changeApproveBoolean = enableChangeApprove && this.editInvoiceBoolean;
    this.financeApproveBoolean = enableFinanceApprove && this.changeApproveBoolean && e.target.checked;
    
    if (!this.viewInvoiceBoolean) {
      this.editInvoiceBoolean = false;
      this.changeApproveBoolean = false;
      this.financeApproveBoolean = false;
    }
  }
  
  addandUpdaterole() {
    if (this.viewInvoiceBoolean && this.editInvoiceBoolean && this.changeApproveBoolean && this.financeApproveBoolean) {
      this.AccessPermissionTypeId = 4;
    } else if (this.viewInvoiceBoolean && this.editInvoiceBoolean && this.changeApproveBoolean) {
      this.AccessPermissionTypeId = 3;
    } else if (this.viewInvoiceBoolean && this.editInvoiceBoolean) {
      this.AccessPermissionTypeId = 2;
    } else if (this.viewInvoiceBoolean) {
      this.AccessPermissionTypeId = 1;
    }

    this.updateroleData = {
      NameOfRole: this.newRoleName,
      Priority: this.role_priority,
      User: this.AddorModifyUserBoolean,
      Permissions: this.userRoleBoolean,
      AccessPermissionTypeId: this.AccessPermissionTypeId,
      allowBatchTrigger: this.vendorTriggerBoolean,
      isConfigPortal: this.configAccessBoolean,
      isDashboard: this.dashboardAccessBoolean,
      allowServiceTrigger: this.spTriggerBoolean,
      NewInvoice: this.invoiceBoolean,
      max_amount: this.max_role_amount,
      is_epa: this.exceptionPageBoolean,
      is_gpa: this.GRNPageBoolean,
      is_vspa: this.vendorPageBoolean,
      is_spa: this.settingsPageBoolean,
      is_fp : this.is_fp,
      is_fpa:this.is_fpa,
      is_grn_approval: this.is_grn_approval
    };
  }

  updateRoleInfoData() {
    this.addandUpdaterole();
    this.sharedService
      .updateRoleData(JSON.stringify(this.updateroleData))
      .subscribe(
        (data: any) => {
          if (data.result) {
            // this.messageService.add(this.updateObject);
            this.openCustomSnackbar_success('Updated Successfully!');
            this.getDisplayTotalRoles();
            this.normalRole = true;
            this.CreateNewRole = false;
            this.editUserdata = false;
          } else {
            this.openCustomSnackbar_error("Something went wrong");
          }
        },
        (error) => {
            this.openCustomSnackbar_error("Server error");
        }
      );
  }

  toGetEntity() {
    this.entityList = [];
    this.sharedService.getEntityDept().subscribe((data: any) => {
      this.entityList = data;
    });
  }

  filterEntity(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.entityList.length; i++) {
      let country = this.entityList[i];
      if (country.EntityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }
    this.filteredEntities = filtered;
  }
  filterEntityBody(event) {
    if (this.entityBodyList) {
      let query = event.query;
      this.filterDentityBody = this.entityBodyList.filter((element) => {
        return (
          element.EntityBodyName.toLowerCase().indexOf(query.toLowerCase()) == 0
        );
      });
    } else {
      alert('Please select Entity');
    }
  }
  filterEntityDept(event) {
    if (this.entityDeptList) {
      let query = event.query;
      this.filterDentityDept = this.entityDeptList.filter((element) => {
        return (
          element.DepartmentName.toLowerCase().indexOf(query.toLowerCase()) == 0
        );
      });
    } else {
      alert('Please select Entitybody');
    }
  }
  onSelectEntity(value) {
    this.entityBodyList = [];
    this.entityDeptList = [];
    this.sharedService.selectedEntityId = value.idEntity;
    this.selectedEntityId = value.idEntity;
    this.sharedService.getEntitybody().subscribe((data: any) => {
      this.entityBodyList = data;
      // this.entityDeptList = this.entityBodyList[0].department
    });
    this.selectedEntitys.push({
      entity: value.EntityName,
      EntityID: value.idEntity,
    });
    this.updateUsersEntityInfo.push({
      idUserAccess: null,
      EntityID: value.idEntity,
    });
    this.selectedEntityName = value.EntityName;
  }
  onSelectEntityBody(e) {
    let ent_body_name = this.entityBodyList.filter((element) => {
      return element.EntityBodyName == e.EntityBodyName;
    });
    this.entityDeptList = ent_body_name[0].department;
    // this.updateUsersEntityInfo.push({EntityBodyID: e.idEntityBody});
    this.updateUsersEntityInfo.forEach((value) => {
      if (value.EntityID == this.selectedEntityId && !value.EntityBodyID) {
        value.EntityBodyID = e.idEntityBody;
        this.updateUserEnt_body_id = e.idEntityBody;
      }
    });
    this.selectedEntitys.forEach((element) => {
      if (
        element.entity == this.selectedEntityName &&
        (!element.entityBody || element.entityBody.length == 0)
      ) {
        element.entityBody = e.EntityBodyName;
        element.EntityBodyID = e.idEntityBody;
        this.selectedEntityBodyName = e.EntityBodyName;
      }
    });
  }

  onSelectEntityDept(e) {
    this.updateUsersEntityInfo.forEach((value) => {
      if (
        value.EntityID == this.selectedEntityId &&
        value.EntityBodyID == this.updateUserEnt_body_id &&
        !value.DepartmentID
      ) {
        value.DepartmentID = e.idDepartment;
        this.updateUserEnt_dept_id = e.idDepartment;
      }
    });
    let count = 0;
    this.selectedEntitys.forEach((element) => {
      if (element.entityDept === e.DepartmentName) {
        alert('Please select other Department');
      } else if (!element.entityDept || element.entityDept == '') {
        count = count + 1;
      } else {
        count = count + 1;
      }
    });
    if (count === this.selectedEntitys.length) {
      if (
        this.selectedEntitys[this.selectedEntitys.length - 1].entity ==
        this.selectedEntityName &&
        this.selectedEntitys[this.selectedEntitys.length - 1].entityBody ==
        this.selectedEntityBodyName &&
        (!this.selectedEntitys[this.selectedEntitys.length - 1].entityDept ||
          this.selectedEntitys[this.selectedEntitys.length - 1].entityDept
            .length == 0)
      ) {
        this.selectedEntitys[this.selectedEntitys.length - 1].entityDept =
          e.DepartmentName;
        this.selectedEntitys[this.selectedEntitys.length - 1].DepartmentID =
          e.idDepartment;
        this.selectedEntityDeptName = e.DepartmentName;
      }
    }
  }

  onRemove(index, value) {
    if (this.selectedEntitys.length > 1) {
      if (value.idUserAccess) {
        this.updateUsersEntityInfo.push({
          idUserAccess: value.idUserAccess,
          EntityID: value.EntityID,
          EntityBodyID: value.EntityBodyID,
          DepartmentID: value.DepartmentID,
        });
      } else {
        let Ent_id = value.EntityID ? value.EntityID : null;
        let Ent_body_id = value.EntityBodyID ? value.EntityBodyID : null;
        let Ent_dept_id = value.DepartmentID ? value.DepartmentID : null;
        this.updateUsersEntityInfo.forEach((element, index) => {
          if (Ent_id && Ent_body_id && Ent_dept_id) {
            if (
              element.EntityID == Ent_id &&
              element.EntityBodyID == Ent_body_id &&
              element.DepartmentID == Ent_dept_id
            ) {
              this.updateUsersEntityInfo.splice(index, 1);
            }
          } else if (Ent_id && Ent_body_id) {
            if (
              element.EntityID == Ent_id &&
              element.EntityBodyID == Ent_body_id
            ) {
              this.updateUsersEntityInfo.splice(index, 1);
            }
          } else {
            if (element.EntityID == Ent_id) {
              this.updateUsersEntityInfo.splice(index, 1);
            }
          }
        });
      }
      this.selectedEntitys.splice(index, 1);
    } else {
      this.openCustomSnackbar_error("At least one entity is required");
    }
  }

  editUser(value) {
    this.toGetEntity();
    this.header_Ac = "Edit user";

    // this.router.navigate(['/customer/roles', `${value.idUser}editUser`]);
    if (value.isActive == 0) {
      this.resetBtnText = 'Resend Activation Link';
    } else {
      this.resetBtnText = 'Reset Account';
    }
    this.sharedService.cuserID = value.idUser;
    // this.headerEdituserboolean = true;
    // this.normalRole = false;
    // this.CreateNewRole = false;
    this.editUserdata = true;
    this.firstName = value.firstName;
    this.lastName = value.lastName;
    this.userEmail = value.email;
    this.userCode = value.UserCode;
    this.editRoleName = value.NameOfRole;
    this.department = value.dept_id;
    if (value && value.MaxAmount) {
      this.Flevel = value.MaxAmount;
    }

    this.sharedService
      .readEntityUserData(value.idUser)
      .subscribe((data: any) => {
        data.result.forEach((element) => {
          this.selectedEntitys.push({
            entity: element.Entity?.EntityName,
            entityBody: element.EntityBody?.EntityBodyName,
            entityDept: element.Department?.DepartmentName,
            idUserAccess: element.UserAccess?.idUserAccess,
            EntityID: element.Entity?.idEntity,
            EntityBodyID: element.EntityBody?.idEntityBody,
            DepartmentID: element.Department?.idDepartment,
          });
          this.updateEntityUserDummy.push({
            idUserAccess: element.UserAccess?.idUserAccess,
            EntityID: element.Entity?.idEntity,
            EntityBodyID: element.EntityBody?.idEntityBody,
            DepartmentID: element.Department?.idDepartment,
          });
          // }
        });
      });
  }

  canceleditUser() {
    this.router.navigate(['/customer/roles', 'createdUsers']);
    this.normalRole = true;
    this.CreateNewRole = false;
    this.editUserdata = false;
    this.selectedEntitys = [];
    this.updateUsersEntityInfo = [];
    this.userNotBoolean = false;
    this.userBoolean = false;
  }
  UpdateUser() {
    let editUser = {
      User: {
        firstName: this.firstName,
        lastName: this.lastName,
        UserName: this.userName,
        email: this.userEmail,
        "dept_id": this.department
      },
      userentityaccess: this.updateUsersEntityInfo,
    };
    this.sharedService.updatecustomeruser(JSON.stringify(editUser)).subscribe(
      (data: any) => {
        if (data.result == 'Updated') {
          const userData = data.customer_user_details;
          let selectrole = {
            applied_uid: this.sharedService.cuserID,
            isUser: true,
            appied_permission_def_id: this.appied_permission_def_id,
          };
          this.sharedService
            .editRole(JSON.stringify(selectrole))
            .subscribe((data: any) => { });
          let amountApproval = {
            applied_uid: this.sharedService.cuserID,
            isUser: true,
            MaxAmount: this.Flevel,
          };
          // this.sharedService.newAmountApproval(JSON.stringify(amountApproval)).subscribe((data: any) => { });
          this.openCustomSnackbar_success('Updated Successfully!');
          this.ngOnInit();

          this.normalRole = true;
          this.CreateNewRole = false;
          this.editUserdata = false;
          this.selectedEntitys = [];
          this.firstName = '';
          this.lastName = '';
          this.updateUsersEntityInfo = [];
        } else {
          this.openCustomSnackbar_error("Something went wrong");
        }
      },
      (error) => {
        alert(error.statusText);
      }
    );
  }

  DisplayCustomerUserDetails() {
    this.roles = [];
    this.sharedService.readcustomeruser().subscribe((data: any) => {
      let usersList = [];
      data.forEach((element) => {
        let mergedData = {
          ...element.AccessPermission,
          ...element.AccessPermissionDef,
          ...element.rnk,
          ...element.User,
          ...element.AmountApproveLevel,
          ...element.rnk,
        };
        mergedData.LogName = element.LogName;
        usersList.push(mergedData);
      });
      this.CustomerUserReadData = usersList;
      if (this.CustomerUserReadData.length > 10 && this.isDesktop) {
        this.showPaginator = true;
      }
    });
  }

  createCustomerUserPage() {
    this.toGetEntity();
    // this.headerEdituserboolean = false;
    this.header_Ac = "Add new user";
    // this.normalRole = false;
    // this.CreateNewRole = false;
    this.editUserdata = true;
    this.userName = '';
    this.userEmail = '';
    this.editRoleName = '';
    delete this.department;
    this.Flevel = '';
    this.selectedEntitys = [];
  }

  userCheck(name) {
    if (name.length > 5) {
      this.sharedService.userCheck(name).subscribe((data: any) => {
        if (!data.LogName) {
          this.userBoolean = true;
          this.userNotBoolean = false;
        } else {
          this.userNotBoolean = true;
          this.userBoolean = false;
        }
      });
    }
  }

  toCreateUser() {
    if (
      this.updateUsersEntityInfo.length > 0 &&
      this.userName != '' &&
      this.userNotBoolean == false
    ) {
      if (this.Flevel == '') {
        this.Flevel = null;
      }
      let createUserData = {
        n_cust: {
          email: this.userEmail,
          firstName: this.firstName,
          lastName: this.lastName,
          userentityaccess: this.updateUsersEntityInfo,
          role_id: this.appied_permission_def_id,
          dept_id:this.department,
          max_amount: this.Flevel,
        },
        n_cred: {
          LogName: this.userName,
          LogSecret: 'string',
        },
      };
      this.sharedService
        .createNewUser(JSON.stringify(createUserData))
        .subscribe(
          (data) => {
            this.openCustomSnackbar_success('Created successfully!');
            this.normalRole = true;
            this.CreateNewRole = false;
            this.editUserdata = false;
            this.selectedEntitys = [];
            this.updateUsersEntityInfo = [];
            this.userNotBoolean = false;
            this.userBoolean = false;
            this.firstName = '';
            this.lastName = '';
            this.DisplayCustomerUserDetails();
          },
          (error) => {
            if (error.status == 422) {
              this.openCustomSnackbar_error("Please fill all the given fields");

            } else {
              this.openCustomSnackbar_error("Something went wrong");
            }
          }
        );
    } else {
      this.openCustomSnackbar_error("Please fill all the given fields");
    }
  }

  selectRole(e) {
    let item = this.DisplayRoleName.filter((item) => {
      return e.indexOf(item.NameOfRole) > -1;
    });
    this.appied_permission_def_id = item[0].idAccessPermissionDef;
  }
  readDepartmentData(){
    this.sharedService.getDepartmentList().subscribe((data:any)=>this.departmentData = data)
  }

  changeUserRole(e, value) {
    let item = this.DisplayRoleName.filter((item) => {
      return value.indexOf(item.NameOfRole) > -1;
    });
    let roleData = {
      applied_uid: e.idUser,
      isUser: true,
      appied_permission_def_id: item[0].idAccessPermissionDef,
    };
    this.sharedService
      .editRole(JSON.stringify(roleData))
      .subscribe((data: any) => {
        if (data.result == 'success') {
          this.openCustomSnackbar_success('Role Changed Successfully');
        } else {
          this.openCustomSnackbar_error("Something went wrong");
        }
      });
  }
  getDisplayTotalRoles() {
    this.SpinnerService.show();
    this.sharedService.displayRolesData().subscribe((data: any) => {
      this.SpinnerService.hide();
      this.DisplayRoleName = data.roles;
      this.DisplayRoleName = this.DisplayRoleName.sort(
        (a, b) => b.isDefault - a.isDefault
      );
    });
  }

  getVendorsListTocreateNewVendorLogin() {
    this.sharedService
      .getVendorUniqueData('?offset=1&limit=100')
      .subscribe((data: any) => {
        this.vendorList = data;
      });
  }
  filterVendor(event, name) {
    let query = event.query.toLowerCase();
    // if(name == ''){
    if (query != '') {
      this.sharedService
        .getVendorUniqueData(`?offset=1&limit=100&ven_name=${query}`)
        .subscribe((data: any) => {
          this.filteredVendors = data;
        });
    } else {
      if (name == '') {
        this.filteredVendors = this.vendorList;
      } else {
        this.filteredVendors = this.vendorMatchList;
      }
    }
  }

  selectVendor(value, type) {
    this.vendorCode = value.VendorCode;
    this.checkOnboardStatus(value.VendorCode);
    this.readEntityForVendorOnboard(value.VendorCode, null);

  }
  checkOnboardStatus(value) {
    this.sharedService.check_onboardStatus(value).subscribe((data: any) => {
      this.vendorOnboarderStatus = data.result.vendor_status;
      if (data.result.vendor_status == false) {
        this.openCustomSnackbar_error("Vendor template is not on-boarded");

      }
    });
  }

  onSelectedEntityCode(value, type) {
    let arr = [];
    if (type == 'update') {
      let currentCount = value.value.length;
      this.selectedEnt_venor.find(ele => {
        arr.push(ele.idEntity)
      })

      if (arr.includes(value.itemValue.idEntity)) {
        let arr1 = this.selectedEnt_venor.filter(id => value.itemValue.idEntity === id.idEntity);
        this.updateIdaccessArr.push(arr1[0].idVendorUserAccess);
      } else {
        this.entLengthforup_vendr = this.entitySelection.length;
        if (currentCount >= this.entLengthforup_vendr) {
          this.updateVenrEntityAccess.push(value.itemValue.idEntity);
        }
      }
      this.entLengthforup_vendr = value.value.length;
    } else {
      value.value.forEach(ele => {
        arr.push(ele.idEntity)
      })
      this.updateIdaccessArr = [];
      this.updateVenrEntityAccess = arr;
    }
  }

  readEntityForVendorOnboard(ven_code, uid) {
    this.SpinnerService.show();
    this.sharedService
      .getVendorsCodesToCreateNewlogin(ven_code)
      .subscribe((data: any) => {

        this.entityForVendorCreation = data.ent_details;
        if (!this.editVndrUserbool) {
          this.entitySelection = this.entityForVendorCreation;
        } else {
          this.getVendorUserAccess(uid, ven_code);
        }
        this.SpinnerService.hide();
      }, err => {
        this.SpinnerService.hide();
      });
  }

  addVendorUser() {
    this.dailogText = "Add Vendor admin";
    this.editVndrUserbool = false;
    this.displayAddUserDialog = true;
    this.vendorCreate = null;
    this.createVfirstName = null;
    this.createVlastName = null;
    this.emailIdInvite = null;
    this.createUserName = null;
    this.entitySelection = [];

  }
  createVendorSuprUser() {
    if (this.vendorOnboarderStatus) {
      let entityIdArray = [];
      this.entitySelection.forEach((ent_id) => {
        entityIdArray.push(ent_id.idEntity);
      });
      let vendorSpUserData = {
        n_ven_user: {
          firstName: this.createVfirstName,
          lastName: this.createVlastName,
          email: this.emailIdInvite,
          role_id: 7,
          uservendoraccess: [
            {
              vendorUserID: 0,
              vendorCode: this.vendorCode,
              entityID: entityIdArray,
              vendorAccountID: null,
            },
          ],
        },
        n_cred: {
          LogName: this.createUserName,
          LogSecret: 'string',
          userID: 0,
        },
      };
      this.sharedService
        .createVendorSuperUser(JSON.stringify(vendorSpUserData))
        .subscribe(
          (data: any) => {
            this.openCustomSnackbar_success('Created successfully!');
            this.displayAddUserDialog = false;

            this.getVendorSuperUserList();
          },
          (error) => {
            this.openCustomSnackbar_error("Something went wrong");
          }
        );
    } else {
      this.openCustomSnackbar_error("Vendor template is not onboarded");
    }
  }

  editvendorUser(val) {
    this.dailogText = "Update Vendor admin";
    this.displayAddUserDialog = true;
    this.editVndrUserbool = true;
    this.createVfirstName = val.firstName;
    this.createVlastName = val.lastName;
    this.readEntityForVendorOnboard(val.vendor_data.VendorCode, val.idUser);
    this.vendorUserId = val.idUser;
    this.vendorCode = val.vendor_data.VendorCode;
    // setTimeout(() => {
    //   this.getVendorUserAccess(val.idUser,val.vendor_data.VendorCode)
    // }, 1000);
  }
  updateVendorAccess() {
    let Obj = {
      "User": {
        "firstName": this.createVfirstName,
        "lastName": this.createVlastName,
      },
      "uservendoraccess": {
        "idVendorUserAccess": this.updateIdaccessArr,
        "vendorCode": this.vendorCode,
        "entityID": this.updateVenrEntityAccess,
      }
    }
    this.sharedService.updateVendorUserAccess(JSON.stringify(Obj), this.vendorUserId).subscribe((data: any) => {
      if (this.approveDialog) {
        this.openCustomSnackbar_success('Account activation completed!');
      } else {
        this.openCustomSnackbar_success('Updated Successfully!');
        this.displayAddUserDialog = false;
        this.getVendorSuperUserList();
      }
      this.updateVenrEntityAccess = [];
      this.updateIdaccessArr = [];
    }, err => {
      this.openCustomSnackbar_error("Server error");
    })
  }
  approveVendoraccess(user) {
    this.approveDialog = true;
    this.tempDisplayVName = user?.vendor_data?.VendorName;
    let word = user?.vendor_data?.VendorName.split(' ');
    let splited = word[0];
    if(word[0].length <=3 ){
      splited = word[0]+' '+word[1]
    }
    this.tempVendorName = splited;
    this.createVfirstName = user.firstName;
    this.createVlastName = user.lastName;
    this.vendorUserId = user.idUser;
    this.readVendorMatch(this.tempVendorName, `&ven_name_search=${this.tempVendorName}&offset=0&limit=0`)
  }
  approveActivateVendor() {
    if (this.vendorOnboarderStatus) {
      this.updateVendorAccess();
      this.sharedService.activate_vendor_signup(this.vendorUserId).subscribe(
        (data: any) => {
          this.openCustomSnackbar_success(data?.result);
          this.approveDialog = false;
          this.vendorUserId = null;
          // this.DisplayCustomerUserDetails();
          this.getVendorSuperUserList();
        },
        (error) => {
          this.openCustomSnackbar_error("Something went wrong");
        }
      );
    } else {
      this.openCustomSnackbar_error("Vendor template is not onboarded");
    }

  }
  readVendorMatch(ven_name, type) {
    this.SpinnerService.show();
    this.sharedService.getVendorMatch(ven_name, type).subscribe((data: any) => {
      if (data?.vendorlist?.length>0) {
        this.vendorMatchList = data.vendorlist;
        this.vendorMatch = this.vendorMatchList[0];
        this.vendorCode = this.vendorMatch?.VendorCode;
        this.checkOnboardStatus(this.vendorMatch?.VendorCode);
        this.readEntityForVendorOnboard(this.vendorMatch?.VendorCode, null);
      } else {
        this.openCustomSnackbar_error('Match not found please select');
      }
      // this.onSelectedEntityCode(this.entitySelection,type);
      let arr = [];

      setTimeout(() => {
        this.entitySelection?.forEach(ele => {
          arr.push(ele.idEntity)
        })
        this.updateVenrEntityAccess = arr;
      }, 1000);
      this.SpinnerService.hide();
    }, err => {
      this.SpinnerService.hide();
    })
  }
  getVendorUserAccess(uid, ven_code) {
    this.SpinnerService.show();
    this.entitySelection = [];
    this.selectedEnt_venor = [];
    this.sharedService.readVendorAccess(uid, ven_code).subscribe((data: any) => {
      let mergeArr = [];
      data?.ent_details?.forEach(ele => {
        let mergeObj = {
          ...ele.Entity,
          ...ele.VendorUserAccess
        }
        this.entitySelection.push({ ...ele.Entity });
        mergeArr.push(mergeObj)
      })
      this.selectedEnt_venor = mergeArr;
      this.SpinnerService.hide();
    }, err => {
      this.SpinnerService.hide();
    })
  }

  getVendorSuperUserList() {
    this.sharedService.readVendorSuperUsersList().subscribe((data: any) => {
      let vendorUsersList = [];
      let vendorUsersListAp = [];
      data.forEach((element) => {
        let mergerdObject = {
          ...element.AccessPermission,
          ...element.AccessPermissionDef,
          ...element.User,
          ...element.Vendor,
        };
        mergerdObject.LogName = element.LogName;
        if(mergerdObject.vendor_data?.VendorCode == ''){
          vendorUsersListAp.push(mergerdObject);
        } else {
          vendorUsersList.push(mergerdObject);
        }
      });
      this.vendorAdminReadData = vendorUsersList;
      this.vendorAdminReadDataAP = vendorUsersListAp;
      if (this.vendorAdminReadData.length > 10 && this.isDesktop) {
        this.showPaginatorSp = true;
      }
      if(this.vendorAdminReadDataAP.length >10 && this.isDesktop) {
        this.showPaginatorAp = true;
      }
    });
  }
  resetPassword() {
    this.confirmation_pop('Are you sure you want to reset this account?','reset_u');
  }
  resetPasswordUserAPI() {
    this.sharedService.resetPassword(this.userEmail).subscribe(
      (data: any) => {
        this.displayResponsive = false;
        if (data.result != 'failed mail') {
          this.openCustomSnackbar_success(data?.result);
          this.displayResponsive = false;
          this.DisplayCustomerUserDetails();
        } else {
          this.openCustomSnackbar_error(data?.result);
        }
      },
      (error) => {
          this.openCustomSnackbar_error("Something went wrong");
      }
    );
  }
  resetPasswordVendor(mail) {
    this.resetVendorMail = mail;
    this.confirmation_pop('Are you sure you want to reset this account?','reset_v');
  }

  resetPassVendorAPI() {
    this.sharedService.resetPassword(this.resetVendorMail).subscribe(
      (data: any) => {
        if (data.result != 'failed mail') {
          this.openCustomSnackbar_success(data?.result);
          this.displayResponsive = false;
          this.getVendorSuperUserList();
        } else {
          this.openCustomSnackbar_error(data?.result);
        }
      },
      (error) => {
        this.openCustomSnackbar_error("Server error");
      }
    );
  }

  confirmationPopUp(id, bool,event:Event) {
    event.stopPropagation();
    this.editUserdata = false
    let text = 'Activate';
    if(bool){
      text = 'Deactivate'
    }
    this.custuserid = id;
    this.confirmation_pop(`Are you sure you want to ${text} this Account?`,'active');
  }

  activa_deactive() {
    this.sharedService.activate_deactivate(this.custuserid).subscribe(
      (data: any) => {
        this.openCustomSnackbar_success('Created successfully!');
        this.displayResponsive = false;
        this.custuserid = null;
        this.DisplayCustomerUserDetails();
        this.getVendorSuperUserList();
      },
      (error) => {
        this.openCustomSnackbar_error("Server error");
      }
    );
  }
  paginate(event, type) {
    if (type == 'vendor') {
      this.row_vendor = event.rows;
      this.first_vendor = event.first;
    } else {
      this.row_customer = event.rows;
      this.first_cust = event.first;
    }
  }

  openCustomSnackbar_success(msg) {
    this.AlertService.success_alert(msg);
  }
  openCustomSnackbar_error(msg) {
   this.AlertService.error_alert(msg);
  }
  confirmation_pop(d_msg,type){
    const drf:MatDialogRef<ConfirmationComponent> = this.mat_dlg.open(ConfirmationComponent,{ 
      width : '400px',
      height: '300px',
      hasBackdrop: false,
      data : { body: d_msg, type: 'confirmation',heading:'Confirmation',icon:'assets/Serina Assets/new_theme/Group 1336.svg'}})

      drf.afterClosed().subscribe((bool)=>{
        if(bool){
          if(type == 'role') {
            this.DeleteRole()
          } else if(type == 'reset_u'){
            this.resetPasswordUserAPI();
          } else if(type == 'reset_v'){
            this.resetPassVendorAPI();
          } else if(type == 'active'){
            this.activa_deactive()
          }
        } 
      })
  }

  expand(bool){
    this.expandFull = bool;
  }
}
